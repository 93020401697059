import React from 'react';
import { connect } from 'react-redux';
// import TopBar from '../components/TopBar';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import MainNav from '../components/MainNav';

const drawerHeight = 54;

const useStyles = makeStyles(theme => ({
  content: {
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    backgroundColor: theme.content.bg,
    color: theme.content.color,
    height: '100vh',
    overflow: 'hidden',
  },
  contentInner: {
    flex: 1,
    position: 'relative',
    boxSizing: 'border-box',
    paddingTop: drawerHeight,
    minHeight: '100%',
    maxWidth: '100%',

    '& > div': {
      minHeight: '100%',
    },
  },
  '@media(max-width: 850px)': {
    contentInner: {
      padding: `${drawerHeight + 10}px 10px 10px`,
    },
  },
}));

const CadLayout = props => {
  const { children, contentBackground } = props;
  const classes = useStyles();

  return (
    <div className={clsx('app-wrapper', contentBackground)}>
      <MainNav />
      {/* <TopBar /> */}
      <div className={classes.content}>
        <div className={classes.contentInner}>{children}</div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  contentBackground: state.theme.contentBackground,
});

export default connect(mapStateToProps)(CadLayout);
