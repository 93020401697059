import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getOrderList } from './customIDHelper';

const ReOrderedSequence = props => {
  const { setGetRow, setCurrentOrder, row } = props;
  const { SequenceOrder, YearOrder, DayOrder, MonthOrder, SuffixOrder, PrefixOrder } = row || {};

  const [draggingId, setDraggingId] = useState(null);
  const [draggingIndex, setDraggingIndex] = useState(null);

  const [droppedItems, setDroppedItems] = useState(null);

  useEffect(() => {
    if (row) {
      const itemList = getOrderList(row);
      const newObj = {};
      itemList.forEach((item, index) => {
        newObj[item.key] = index + 1;
      });

      const finalObject = {
        ...row,
        ...newObj,
      };

      // setDroppedItems(newDroppedItems);
      setGetRow(finalObject);
      setCurrentOrder(itemList.length);
      setDroppedItems(itemList);
    }
  }, [SequenceOrder, YearOrder, DayOrder, MonthOrder, SuffixOrder, PrefixOrder]);
  const onDragStart = start => {
    setDraggingId(start.draggableId);
    setDraggingIndex(start.source.index);
  };

  const onDragEnd = result => {
    setDraggingId(null);
    setDraggingIndex(null);

    if (!result.destination) {
      return;
    }

    const droppedIndex = result.destination.index;

    if (droppedIndex === draggingIndex) {
      return;
    }

    const newDroppedItems = [...droppedItems];
    const droppedItem = newDroppedItems[draggingIndex];
    newDroppedItems.splice(draggingIndex, 1);
    newDroppedItems.splice(droppedIndex, 0, droppedItem);
    const newObj = {};
    newDroppedItems.forEach((item, index) => {
      newObj[item.key] = index + 1;
    });

    const finalObject = {
      ...row,
      ...newObj,
    };

    // setDroppedItems(newDroppedItems);
    setGetRow(finalObject);
  };

  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction="horizontal">
        {provided => (
          <>
            <h6>Drag and Drop to change the order</h6>
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{ display: 'flex', gap: '8px' }}>
              {droppedItems?.map((item, index) => (
                <Draggable
                  key={item.name}
                  draggableId={item.name}
                  index={index}
                  isDragDisabled={draggingId !== null && draggingId !== item.name}>
                  {(provided, snapshot) => (
                    <div
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                      style={{
                        ...provided.draggableProps.style,

                        padding: '10px 20px',
                        background: snapshot.isDragging ? '#666' : '#333',
                        color: 'white',
                      }}>
                      {item.name}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          </>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ReOrderedSequence;
