import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from 'components/Dialog';
import { closeEditSecProfile } from 'reducers/DialogsReducer';
import { Button } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import { findSecCode, getProfile, updateProfile } from 'Settings/Security/SecFunctions';
import { handleError } from 'reducers/ErrorReducer';
import { notify } from 'reducers/NotifierReducer';
import { gridStyle } from 'utils/formStyles';
import Autocomplete2 from 'components/Autocomplete2';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';


const useStyles = makeStyles(theme => ({
  item: {
    ...gridStyle(200, 800),
    margin: 0,
  }
}));

const AddSecProfileDialog = (props) => {
  const classes = useStyles();
  const { profileId } = props;
  const [name, setName] = useState('');
  const [currentType, setCurrentType] = useState(null);
  const [allTypes, setAllTypes] = useState([]);


  useEffect(() => {
    fetchData();
  }, [])


  const fetchData = async () => {
    const types = await findSecCode('profiletype');
    setAllTypes(types);
    const profile = await getProfile(profileId);
    setName(profile.Label);
    const curType = types.find(type => type.Code === profile.Type);
    setCurrentType(curType || null);
  }


  const close = () => {
    props.closeEditSecProfile();
  }

  const save = async () => {
    const data = {
      Label: name,
      Type: currentType?.Code || null,
    }
    try{
      const result = await updateProfile(profileId, data);
      props.notifyDataUpdate({type: 'edit-profile', data: profileId});
      props.notify("Profile Updated", "success");
      props.closeEditSecProfile();
    } catch(err) {
      props.handleError(err, 'Error Updating Profile');
    }
  }

  const renderActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={save} disabled={!name} >
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };


  const renderName = () => {
    return (
      <TextField 
        label="Profile Name"
        variant="outlined"
        value={name}
        size="small"
        onChange={(e) => setName(e.target.value)}
        fullWidth
      />
    )
  }


  const renderType = () => {
    const handleChange = (e, newValue) => setCurrentType(newValue);
    return (
      <div className='mt-3'>
        <Autocomplete2
          options={allTypes}
          className={classes.item}
          onChange={handleChange}
          value={currentType}
          label="Profile Type"
          getOptionLabel={option => option.Code}
          getOptionSelected={(option, value) => option.Code === value.Code}
          renderOption={option => `${option.Code} ${option.Description ? '- ' + option.Description : ''}`}
        />
      </div>
    )
  }


  return (
    <Dialog onClose={close} title="Edit Profile" actions={renderActions()} >
      <div>
        {renderName()}
        {renderType()}
      </div>
    </Dialog>
  )
}


export default connect(null, {  closeEditSecProfile, handleError, notify, notifyDataUpdate, })(AddSecProfileDialog);