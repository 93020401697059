import React from 'react';

function MasterSergeantIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      preserveAspectRatio="xMidYMid meet"
      {...props}>
      <g
        transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none">
        <path d="M133 432 c-80 -40 -113 -62 -113 -74 0 -10 2 -18 5 -18 3 0 55 25 115 55 l110 55 110 -55 c60 -30 112 -55 115 -55 3 0 5 8 5 18 0 12 -33 34 -115 75 -63 31 -116 57 -118 56 -1 0 -53 -26 -114 -57z" />
        <path d="M133 372 c-80 -40 -113 -62 -113 -74 0 -10 2 -18 5 -18 3 0 55 25 115 55 l110 55 110 -55 c60 -30 112 -55 115 -55 3 0 5 8 5 18 0 12 -33 34 -115 75 -63 31 -116 57 -118 56 -1 0 -53 -26 -114 -57z" />
        <path d="M133 312 l-113 -57 0 -41 c0 -36 4 -43 28 -53 99 -40 305 -40 405 0 23 10 27 17 27 53 l0 41 -115 58 c-63 31 -116 57 -118 56 -1 0 -53 -26 -114 -57z m228 -38 c70 -35 108 -59 102 -65 -15 -15 -143 -39 -213 -39 -70 0 -198 24 -213 39 -6 6 31 30 99 65 60 30 110 55 112 55 1 1 52 -24 113 -55z" />
        <path d="M20 132 c0 -12 10 -24 28 -31 131 -53 432 -32 432 31 0 15 -4 18 -17 13 -43 -17 -153 -35 -213 -35 -60 0 -170 18 -212 35 -14 5 -18 2 -18 -13z" />
        <path d="M20 72 c0 -12 10 -24 28 -31 131 -53 432 -32 432 31 0 15 -4 18 -17 13 -43 -17 -153 -35 -213 -35 -60 0 -170 18 -212 35 -14 5 -18 2 -18 -13z" />
      </g>
    </svg>
  );
}

export default MasterSergeantIcon;
