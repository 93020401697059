
    import React from 'react';
    import SvgIcon from '@material-ui/core/SvgIcon';
    
    function icons8escape100Icon(props) {
      return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100" height="100"><path d="M5.984 3.986A1 1 0 0 0 5.84 4H1a1 1 0 0 0-1 1v4.832a1 1 0 0 0 0 .326v7.674a1 1 0 0 0 0 .326V23a1 1 0 0 0 1 1h4.832a1 1 0 0 0 .326 0h4.674a1 1 0 0 0 .326 0H16a1 1 0 0 0 1-1v-4.832a1 1 0 0 0 0-.326v-7.674a1 1 0 0 0 0-.326V5a1 1 0 0 0-1-1h-4.846a1 1 0 0 0-.17-.014A1 1 0 0 0 10.84 4H6.154a1 1 0 0 0-.17-.014zM2 6h3v3H2V6zm5 0h3v3a1 1 0 1 0 0 2h.152c.166.878.464 1.757 1.026 2.568a1 1 0 1 0 1.644-1.136c-.3-.434-.49-.913-.62-1.432H15v6h-3a1 1 0 1 0-2 0 1 1 0 1 0 0 2v3H7v-3a1 1 0 0 0 .113-1.994c.11-.556.3-.998.602-1.307a1 1 0 0 0-.746-1.709 1 1 0 0 0-.684.31c-.731.75-1.069 1.7-1.205 2.7H2v-6h3a1 1 0 1 0 2 0 1 1 0 1 0 0-2V6zm5 0h3v3h-3V6zm28.49 1.02a4.468 4.468 0 0 0 0 8.935 4.468 4.468 0 0 0 0-8.935zm-7.021 7.996-7.047.007c-.913 0-1.789.47-2.383 1.41l-3.658 5.58a2.48 2.48 0 0 0-.25 1.897 2.48 2.48 0 0 0 1.164 1.518c1.193.688 2.724.278 3.392-.88l3.047-4.564h3.797l-3.584 7.211a3.534 3.534 0 0 0 1.063 4.436l7.683 5.676-2.285 7.466c-.116.657.03 1.32.412 1.868a2.478 2.478 0 0 0 2.055 1.068c.507 0 1.002-.154 1.426-.451a2.439 2.439 0 0 0 1.017-1.56l2.586-8.915a2.557 2.557 0 0 0-.01-.885c-.086-.685-.436-1.283-.94-1.654l-5.116-4.799 3.527-7.822a3.249 3.249 0 0 0-1.513-4.256L35.447 15.5c-.676-.337-1.225-.484-1.978-.484zM2 19h3v3H2v-3zm10 0h3v3h-3v-3zm29.078 1.455a4.733 4.733 0 0 1-.346 1.223l-1.904 4.22.635.87h.002l.021.033c.032.049.068.094.112.152.066.09.135.176.254.295.477.467 1.149.703 2.002.703l5.625-.01c1.36-.002 2.51-1.17 2.507-2.548 0-1.376-1.03-2.377-2.412-2.377l-.25-.008L43 22.996l-1.922-2.54zm-14.242 10.82-2.068 3.782H18.02c-1.379 0-2.439.939-2.439 2.318s1.121 2.5 2.5 2.5h8c.376 0 .75-.09 1.045-.24.583-.195 1.07-.59 1.342-1.067l2.529-3.595-2.727-2.012a5 5 0 0 1-1.435-1.686z"/></SvgIcon>
      );
    }
  
    export default icons8escape100Icon;