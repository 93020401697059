import { Box, Checkbox, FormControlLabel, Grid, TextField, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import DatePicker2 from 'components/DatePicker2';
import { getService } from 'reducers/service';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { notify } from 'reducers/NotifierReducer';
import { dateTimePicker, displayDate } from 'reducers/TimeReducer';
import LookUpCodesAddEditDialog, { DropdownForCategory } from './AddEditDialog';
import FormDivider from 'components/FormDivider';
import QuickFilter from 'components/QuickFilter';
import { XGrid } from '@material-ui/x-grid';
import { evidenceStorageSublocationColumns } from 'Settings/LookupCodes/CodeTableColumns';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
const useStyles = makeStyles(theme => ({
  filter: {
    width: '100%',
    marginTop: '10px',
  },
  list: {
    width: '100%',
    height: `400px`,
  },
  wrap: {
    padding: theme.spacing(2),
    '& > h5': {
      marginBottom: 20,
    },
  },
  searchBar: {
    padding: theme.spacing(1, 0, 2),
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '15px',
  },
  actions: {
    width: '100%',
    padding: theme.spacing(1, 0, 2),
    marginTop: '8px',
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  parentFilter: {
    minWidth: '200px',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  pageWrap: {
    display: 'flex',
    width: '100%',
    backgroundColor: theme.colors.paper,
  },
  menu: {
    // height: `calc(100vh - ${settings.mainNavHeight}px)`,
    overflowY: 'scroll',
  },
  content: {
    // flex: 1,
    width: '100%',
    marginTop: '10px',
  },
}));

const defaultValues = {
  Code: '',
  Description: '',
  Category: '',
  IsDefault: false,
  IsActive: true,
};
const item = {
  type: 'link',
  label: 'Sub Location',
  link: 'code-evidence-storage-sublocation',
  service: 'codeevidencestoragesublocation',
  codeCategory: 'codeevidencestoragelocation',
};
const RenderEvidenceStorageLocation = props => {
  const classes = useStyles();
  const { dictionary } = props;
  const { values, setValues, handleChange, mode, categoryCodes } = props;

  const service = getService('codeevidencestoragesublocation');
  const currentService = 'codeevidencestoragesublocation';

  const [columns, setColumns] = useState(evidenceStorageSublocationColumns);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [editableRow, setEditableRow] = useState(null);
  const [subLocationDialogMode, setSublocationDialogMode] = useState('add');
  useEffect(() => {
    if (mode === 'add') {
      setValues(defaultValues);
    } else {
      fetchLookupCode();
    }
  }, [mode]);
  const fetchLookupCode = async () => {
    try {
      setLoading(true);

      const codes = await service.find({
        query: { ParentCode: values.Code, IsDeleted: false, $limit: 0 },
      });
      const data = codes.data?.map(el => ({ ...el, id: el.Code }));

      setRows(data);
    } catch (error) {
      console.log('error ', error);
    } finally {
      setLoading(false);
    }
  };
  // hancle actions
  const onClickAdd = () => {
    setSublocationDialogMode('add');
    setEditableRow({});
    setOpenEdit(true);
  };
  const onClickEdit = () => {
    const obj = rows.find(row => row.Code === selection[0]);
    setEditableRow(obj);
    setSublocationDialogMode('edit');
    setOpenEdit(true);
  };

  const onClickDelete = async () => {
    const removeCode = await service.remove(selection[0]);
    const newRows = rows.filter(el => el.Code !== removeCode.Code);

    setRows(newRows);
  };
  const renderActions = () => {
    const disabled = selection.length === 0;
    //const selected = rows.find(a => a.id === selection[0]);
    //const dltDisabled = selected?.Icon === 0;
    return (
      <div className={classes.actions}>
        <Fab size="small" color="secondary" onClick={onClickAdd}>
          <AddIcon />
        </Fab>
        <Fab size="small" color="secondary" onClick={onClickEdit} disabled={disabled}>
          <EditIcon />
        </Fab>

        <Fab size="small" color="secondary" onClick={onClickDelete} disabled={disabled}>
          <DeleteIcon />
        </Fab>
      </div>
    );
  };
  const handleClose = async () => {
    await fetchLookupCode();
    setSublocationDialogMode('');
    setOpenEdit(false);
  };
  const handleSelectChange = ({ selectionModel }) => setSelection(selectionModel);

  return (
    <>
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              disabled={mode === 'edit'}
              style={{ width: '100%' }}
              type={'text'}
              value={values.Code}
              label={'Code/Description'}
              //error={error}
              variant={'outlined'}
              onChange={e => handleChange('Code', e.target.value)}
            />
          </Box>
        </Grid>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.Description}
              //onChange={handleChange}
              onChange={e => handleChange('Description', e.target.value)}
              helperText={''}
              label={'Description'}
              //error={error}
              variant={'outlined'}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <Autocomplete
              id="category-cOdes"
              size="small"
              autoHighlight
              autoSelect
              value={{ CategoryCode: values.Category || '' }}
              onChange={(event, newValue) => {
                handleChange('Category', newValue?.CategoryCode || '');
              }}
              options={categoryCodes || []}
              getOptionLabel={option => option.CategoryCode || ''}
              getOptionSelected={(option, value) =>
                (option.CategoryCode || '').toUpperCase() ===
                (value.CategoryCode || '').toUpperCase()
              }
              style={{ width: '100%' }}
              renderInput={params => <TextField {...params} variant="outlined" label="Category" />}
            />
          </Box>
        </Grid>

        <Grid item xs={2}>
          <Box style={{ marginLeft: 10 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.IsDefault}
                  onChange={e => handleChange('IsDefault', e.target.checked)}
                />
              }
              label="Default"
            />
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box style={{ marginLeft: 10 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.IsActive}
                  onChange={e => handleChange('IsActive', e.target.checked)}
                />
              }
              label="Active"
            />
          </Box>
        </Grid>
      </Grid>
      {mode === 'edit' && (
        <>
          <div className={classes.content}>
            <FormDivider title="Sub Locations" />
            <div className={classes.searchBar}>
              <QuickFilter
                rows={rows}
                columns={columns}
                setFilteredRows={setFilteredRows}
                currentService={currentService}
              />

              {renderActions()}
            </div>

            <div className={classes.list}>
              <XGrid
                // autoPageSize

                loading={loading}
                localeText={'Loading....'}
                columns={columns}
                style={{ width: '100%' }}
                rows={filteredRows}
                pageSize={10}
                onSelectionModelChange={handleSelectChange}
                //disableSelectionOnClick={view === 'edit'}
                disableMultipleSelection
                // hideFooter
              />
            </div>
          </div>

          <LookUpCodesAddEditDialog
            open={openEdit}
            closeDialog={handleClose}
            mode={subLocationDialogMode}
            data={editableRow}
            dictionary={dictionary}
            table={item.table}
            currentService={item?.service}
            codeCategory={item?.codeCategory}
            aditionalData={{
              storageSublocationParentCode: values.Code,
            }}
          />
        </>
      )}
    </>
  );
};
const mapStateToProps = state => {
  return {
    dictionary: state.dictionary,
    wsClient: state.websocket,
    user: state.user,
  };
};
export default connect(mapStateToProps, {
  showSpinner,
  hideSpinner,

  notify,
})(RenderEvidenceStorageLocation);
