// Websocket
export const SET_WEBSOCKET_CONNECTION = 'WEBSOCKET/SET_WEBSOCKET_CONNECTION';
export const SET_WEBSOCKET_AUTHENTICATED = 'WEBSOCKET/SET_WEBSOCKET_AUTHENTICATED';

export const setWebsocketConnection = (client) => {
  client.hooks({
    error(context) {
      console.error(`Error in '${context.path}' service method '${context.method}'`, context.error.stack);
    }
  });
	return (dispatch) => {
		dispatch({ type: SET_WEBSOCKET_CONNECTION, payload: client });
	}
}

export default function reducer(
  state = {
    // Websocket
    websocket: null,
    isAuthenticated: false

  },
  action
) {
  switch (action.type) {

    // Websocket
    case SET_WEBSOCKET_CONNECTION:
      return {
        ...state,
        websocket: action.payload
      };
    default:
      break;
  }
  return state;
}
