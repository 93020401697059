import React from 'react';

function SoldierManIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      {...props}
      preserveAspectRatio="xMidYMid meet">
      <g
        transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none">
        <path d="M189 455 c-25 -14 -79 -114 -79 -148 0 -9 13 -25 29 -36 40 -29 59 -86 37 -110 -15 -16 -14 -19 7 -39 24 -22 24 -22 42 -2 16 18 16 20 1 26 -9 3 -16 17 -16 31 0 13 -9 42 -19 63 -19 40 -18 78 3 92 6 4 31 8 56 8 25 0 50 -4 56 -8 21 -14 22 -52 3 -92 -10 -21 -19 -50 -19 -63 0 -14 -7 -28 -16 -31 -15 -6 -15 -8 1 -26 18 -20 18 -20 42 2 21 20 22 23 7 39 -22 24 -3 81 37 110 16 11 29 28 29 37 0 33 -61 139 -86 151 -33 15 -83 13 -115 -4z" />
        <path d="M81 95 c-17 -20 -31 -45 -31 -55 0 -19 8 -20 200 -20 192 0 200 1 200 20 0 24 -56 90 -77 90 -8 0 -30 -12 -49 -26 l-34 -26 -20 23 -20 24 -20 -24 -20 -23 -34 26 c-45 34 -58 33 -95 -9z m89 -40 c0 -9 -9 -15 -25 -15 -16 0 -25 6 -25 15 0 9 9 15 25 15 16 0 25 -6 25 -15z m210 0 c0 -9 -9 -15 -25 -15 -16 0 -25 6 -25 15 0 9 9 15 25 15 16 0 25 -6 25 -15z" />
      </g>
    </svg>
  );
}

export default SoldierManIcon;
