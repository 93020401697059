import { useEffect } from 'react';
import { connect } from 'react-redux';
import { logOut } from 'reducers/UserReducer';

function LogOut(props) {
  const { websocket } = props;

  useEffect(() => {
    props.logOut();
    // eslint-disable-next-line
  }, [websocket]);

  return '';
}

const mapStateToProps = (state) => {
  return { websocket: state.websocket }
}

export default connect(mapStateToProps, { logOut })(LogOut);
