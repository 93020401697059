import React from 'react';
import { connect } from 'react-redux';

import { notify } from '../reducers/NotifierReducer';
import CustomIDGenerator from 'global-components/CustomIDGenerator';

function CustomEvidence() {
  const initialData = {
    Application: 'RMS',
    AgencyID: '',
    EntityName: 'Evidence (Agency Specific)',
  };
  const dataForNewItem = {
    Application: 'RMS',
    EntityName: 'Evidence (Agency Specific)',
    Sequence: 0,
    SequenceLength: 9,
    PrefixOrder: 1,
    SuffixOrder: 0,
    MonthOrder: 0,
    DayOrder: 0,
    YearOrder: 0,
    SequenceOrder: 2,
    SequenceResetType: 'N',
    UseSeparator: false,
    IsDeleted: false,
    Prefix: '',
    Suffix: '',
    UseFourDigitYear: false,
    Sequencer: 0,
    Separator: '',
  };
  // states

  return (
    <CustomIDGenerator
      initialData={initialData}
      dataForNewItem={dataForNewItem}
      showAgency={true}
    />
  );
}

export default connect(null, {
  notify,
})(CustomEvidence);
