import { Fab } from '@material-ui/core';
import { colorRender } from 'Settings/EventTypeInitial';
import EventTypeIcons from 'components/EventTypeIcons';
import TagTypesIcon from 'components/TagTypesIcon';
import React from 'react';
import { displayDate, displayDateTime } from 'reducers/TimeReducer';

export const commonColumns = [
  { field: 'Code', headerName: 'Code', width: 150 },
  { field: 'Description', headerName: 'Description', width: 250 },
  { field: 'Category', headerName: 'Category', width: 150 },
  { field: 'IsDefault', headerName: 'Default', width: 150 },
  { field: 'IsDeleted', headerName: 'Deleted', width: 150 },
  { field: 'IsActive', headerName: 'Active', width: 150 },
  { field: 'Flags', headerName: 'Flags', width: 100 },
  { field: 'Code2', headerName: 'Code 2', width: 150 },
  { field: 'Description2', headerName: 'Description 2', width: 150 },
  { field: 'Code3', headerName: 'Code 3', width: 100 },
  { field: 'Description3', headerName: 'Description 3', width: 150 },
  { field: 'Code4', headerName: 'Code 4', width: 100 },
  { field: 'Description4', headerName: 'Description 4', width: 150 },
  { field: 'UpdatedBy', headerName: 'UpdatedBy', width: 150 },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 150,
    valueFormatter: params => displayDateTime(params.value),
    format: 'date',
  },
  { field: 'DeletedBy', headerName: 'Deleted By', width: 150 },
  { field: 'CreatedBy', headerName: 'Created By', width: 150 },
];

export const partyRelationshipColumns = [
  { field: 'Code', headerName: 'Code', width: 150 },
  { field: 'Description', headerName: 'Description', width: 250 },
  { field: 'Category', headerName: 'Category', width: 150 },
  { field: 'IsDefault', headerName: 'Default', width: 150 },
  { field: 'IsDeleted', headerName: 'Deleted', width: 150 },
  { field: 'IsActive', headerName: 'Active', width: 150 },
  { field: 'Flags', headerName: 'Flags', width: 100 },
  { field: 'Code2', headerName: 'Code 2', width: 150 },
  { field: 'Description2', headerName: 'Description 2', width: 150 },
  { field: 'Code3', headerName: 'Code 3', width: 100 },
  { field: 'Description3', headerName: 'Description 3', width: 150 },
  { field: 'Code4', headerName: 'Code 4', width: 100 },
  { field: 'Description4', headerName: 'Description 4', width: 150 },
  { field: 'UpdatedBy', headerName: 'UpdatedBy', width: 150 },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 150,
    valueFormatter: params => displayDateTime(params.value),
    format: 'date',
  },
  { field: 'DeletedBy', headerName: 'Deleted By', width: 150 },
  { field: 'CreatedBy', headerName: 'Created By', width: 150 },
  { field: 'Entities', headerName: 'Entities', width: 150 },
  { field: 'EmployeeRelated', headerName: 'EmployeeRelated', width: 150 },
  { field: 'Order', headerName: 'Order', width: 150 },
  { field: 'PrintOrder', headerName: 'Print Order', width: 150 },
];

export const codeCitiesColumns = [
  { field: 'CityDescription', headerName: 'City Description', width: 250 },
  { field: 'Category', headerName: 'Category', width: 150 },
  { field: 'State', headerName: 'State', width: 150 },
  { field: 'IsDefault', headerName: 'Default', width: 150 },
  { field: 'IsDeleted', headerName: 'Deleted', width: 150 },
  { field: 'IsActive', headerName: 'Active', width: 150 },
  { field: 'Flags', headerName: 'Flags', width: 100 },
  { field: 'Description2', headerName: 'Description 2', width: 150 },
  { field: 'Description3', headerName: 'Description 3', width: 150 },
  { field: 'UpdatedBy', headerName: 'UpdatedBy', width: 150 },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 150,
    valueFormatter: params => displayDateTime(params.value),
    format: 'date',
  },
  { field: 'DeletedBy', headerName: 'Deleted By', width: 150 },
  { field: 'CreatedBy', headerName: 'Created By', width: 150 },
];

export const codeDispositionColumns = [
  { field: 'AgencyID', headerName: 'AgencyID', width: 250 },
  { field: 'IsReportRequired', headerName: 'ReportRequired', width: 250 },
  ...commonColumns,
];

export const codeZonesColumns = [
  { field: 'AgencyID', headerName: 'AgencyID', width: 250 },
  { field: 'Zone1Type', headerName: 'Zone1Type', width: 250 },
  { field: 'Zone2Type', headerName: 'Zone2Type', width: 250 },
  { field: 'Zone3Type', headerName: 'Zone3Type', width: 250 },
  { field: 'Zone4Type', headerName: 'Zone4Type', width: 250 },
  { field: 'Zone5Type', headerName: 'Zone5Type', width: 250 },
  ...commonColumns.slice(2),
];

export const codeUnitActionColumns = [
  { field: 'Code', headerName: 'Code', width: 150 },
  { field: 'Description', headerName: 'Description', width: 250 },
  { field: 'Category', headerName: 'Category', width: 150 },
  { field: 'IsDefault', headerName: 'Default', width: 150 },
  { field: 'IsDeleted', headerName: 'Deleted', width: 150 },
  { field: 'IsActive', headerName: 'Active', width: 150 },
  { field: 'WaitTime', headerName: 'Wait Time', width: 150 },
  { field: 'Color', headerName: 'Color', width: 150, renderCell: colorRender },
  { field: 'Flags', headerName: 'Flags', width: 100 },
  { field: 'Code2', headerName: 'Code 2', width: 150 },
  { field: 'Description2', headerName: 'Description 2', width: 150 },
  { field: 'Code3', headerName: 'Code 3', width: 100 },
  { field: 'Description3', headerName: 'Description 3', width: 150 },
  { field: 'Code4', headerName: 'Code 4', width: 100 },
  { field: 'Description4', headerName: 'Description 4', width: 150 },
  { field: 'UpdatedBy', headerName: 'UpdatedBy', width: 150 },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 150,
    valueFormatter: params => displayDateTime(params.value),
    format: 'date',
  },
  { field: 'DeletedBy', headerName: 'Deleted By', width: 150 },
  { field: 'CreatedBy', headerName: 'Created By', width: 150 },
];

export const codeVehicleMakeModelsColumns = [
  { field: 'MakeCode', headerName: 'Make Code', width: 150 },
  { field: 'ModelCode', headerName: 'Model Code', width: 150 },
  { field: 'MakeDescription', headerName: 'Make Description', width: 150 },
  { field: 'ModelDescription', headerName: 'Model Description', width: 150 },
  ...commonColumns,
];

export const codeZonesCols = [
  { field: 'ZoneCode', headerName: 'Zone Code', width: 150 },
  { field: 'AgencyID', headerName: 'Agency ID', width: 150 },
  ...commonColumns.slice(1),
];

const iconRender = params => {
  const Icon = TagTypesIcon[params.value];

  return Icon ? (
    <Fab size="small" color="inherit">
      <Icon color="primary" style={{ width: '30px', height: '30px' }} />
    </Fab>
  ) : (
    <span></span>
  );
};

const renderPriority = params => (params.value ? 'High' : 'Low');
export const codeTagTypesColumns = [
  ...commonColumns.slice(0, 2),
  { field: 'Priority', headerName: 'Priority', width: 150, renderCell: renderPriority },
  { field: 'Parent', headerName: 'Parent', width: 150 },
  { field: 'Icon', headerName: 'Icon', width: 150, renderCell: iconRender },
  ...commonColumns.slice(2),
];

export const renderStatutesColumn = () => [
  { field: 'Code', headerName: 'Code', width: 120 },
  { field: 'Section', headerName: 'Section', width: 150 },
  { field: 'Description', headerName: 'Description', width: 200 },
  {
    field: 'EnactmentDate',
    headerName: 'Enactment Date',
    width: 150,
    valueFormatter: params => displayDate(params.value),
  },
  { field: 'Jurisdiction', headerName: 'Jurisdiction', width: 150 },
  { field: 'Keyword', headerName: 'Keyword', width: 150 },
  { field: 'Level', headerName: 'Level', width: 100 },
  {
    field: 'RepealDate',
    headerName: 'Repeal Date',
    width: 150,
    valueFormatter: params => displayDate(params.value),
  },
  { field: 'Status', headerName: 'Status', width: 100 },
  { field: 'AffidavitText', headerName: 'Affidavit Text', width: 200 },
  { field: 'Category', headerName: 'Category', width: 120 },
  { field: 'RSA', headerName: 'RSA', width: 100 },
  { field: 'StatuteID', headerName: 'Statute ID', width: 100 },
  { field: 'BondAmount', headerName: 'Bond Amount', width: 150 },
  { field: 'FBICode', headerName: 'FBI Code', width: 150 },
  { field: 'FBIDescription', headerName: 'FBI Code Description', width: 150 },
  { field: 'StatuteID', headerName: 'Statute ID', width: 120 },
  { field: 'Severity', headerName: 'Severity', width: 100 },
  { field: 'IsActive', headerName: 'Is Active', width: 120 },
  { field: 'MustAppear', headerName: 'Must Appear', width: 120 },
  { field: 'DNASampleRequired', headerName: 'DNA Sample Required', width: 180 },
  { field: 'Notes', headerName: 'Notes', width: 200 },

  { field: 'Flags', headerName: 'Flags', width: 100 },
  { field: 'IsLocked', headerName: 'Is Locked', width: 120 },
  { field: 'Locked', headerName: 'Locked', width: 120 },

  { field: 'IsSealed', headerName: 'Is Sealed', width: 120 },

  { field: 'IsDeleted', headerName: 'Is Deleted', width: 120 },
  { field: 'Deleted', headerName: 'Deleted', width: 120 },
  { field: 'DeletedBy', headerName: 'Deleted By', width: 150 },

  {
    field: 'Created',
    headerName: 'Created',
    width: 150,
    valueFormatter: params => displayDateTime(params.value),
    format: 'date',
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 150 },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 150,
    valueFormatter: params => displayDateTime(params.value),
    format: 'date',
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 150 },
];

const incidentIconRender = params => {
  const Icon = EventTypeIcons[parseInt(params.value)];
  return Icon ? <Icon /> : <span></span>;
};
export const codeIncidentTypesColumns = [
  { field: 'Code', headerName: 'Code', width: 150 },
  { field: 'Description', headerName: 'Description', width: 250 },
  { field: 'Category', headerName: 'CAD Event Type', width: 250 },
  { field: 'Icon', headerName: 'Icon', width: 150, renderCell: incidentIconRender },
  ...commonColumns.slice(3),
];
export const CallMethodsColumns = commonColumns.filter(column => column.field !== 'Flags');
export const evidenceStorageSublocationColumns = [
  { field: 'Code', headerName: 'Code', width: 150 },
  { field: 'Description', headerName: 'Description', width: 250 },
  { field: 'ParentCode', headerName: 'Category', width: 150 },
  { field: 'IsDefault', headerName: 'Default', width: 150 },
  { field: 'IsDeleted', headerName: 'Deleted', width: 150 },
  { field: 'IsActive', headerName: 'Active', width: 150 },
  { field: 'Flags', headerName: 'Flags', width: 100 },
  { field: 'Code2', headerName: 'Code 2', width: 150 },
  { field: 'Description2', headerName: 'Description 2', width: 150 },
  { field: 'Code3', headerName: 'Code 3', width: 100 },
  { field: 'Description3', headerName: 'Description 3', width: 150 },
  { field: 'Code4', headerName: 'Code 4', width: 100 },
  { field: 'Description4', headerName: 'Description 4', width: 150 },
  { field: 'UpdatedBy', headerName: 'UpdatedBy', width: 150 },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 150,
    valueFormatter: params => displayDateTime(params.value),
    format: 'date',
  },
  { field: 'DeletedBy', headerName: 'Deleted By', width: 150 },
  { field: 'CreatedBy', headerName: 'Created By', width: 150 },
];
