import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import settings from '../config/settings';
import Menu from './Menu';

const useStyles = makeStyles(theme => ({
  pageWrap: {
    display: 'flex',
    width: '100%',
    backgroundColor: theme.colors.paper,
  },
  menu: {
    width: 200,
    height: `calc(100vh - ${settings.mainNavHeight}px)`,
    overflowY: 'auto',
    borderRight: `2px solid ${theme.colors.grey1}`,
  },
  content: {
    flex: 1,
    height: `calc(100vh - ${settings.mainNavHeight}px)`,
    overflowY: 'hidden',
  },
}));

function SettingsPage(props) {
  const classes = useStyles();

  return (
    <div className={classes.pageWrap}>
      <div className={classes.menu}>
        <Menu />
      </div>
      <div className={classes.content}>{props.children}</div>
    </div>
  );
}

export default SettingsPage;
