import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import csvParser from 'csv-parse';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CustomAutocomplete from '../../components/CustomAutocomplete';
import Card from '@material-ui/core/Card';
import { handleError } from '../../reducers/ErrorReducer';
import { showSpinner, hideSpinner } from '../../reducers/UiReducer';
import { notify, closeSnackbar } from '../../reducers/NotifierReducer';
import CSVIcon from '@material-ui/icons/Description';
import Tooltip from '../../components/Tooltip';
import TextField from '@material-ui/core/TextField';
import { saveESN } from '../../reducers/EsnReducer';
import { Alert, AlertTitle } from '@material-ui/lab';
import { asyncForEach } from '../../utils/functions';
import { pathToStr } from '../../utils/mapFunctions';
import { zonesChanged } from '../../reducers/DataChangeReducer';

function getFormStyle(minWidth, maxWidth) {
  return {
    maxWidth: maxWidth,
    flexBasis: minWidth,
    minWidth: minWidth,
    flexGrow: 1,
    margin: `0 4px 12px`,
  };
}

const useStyles = makeStyles(theme => ({
  wrap: {
    width: 860,
    maxWidth: '100%',
    minHeight: 500,
  },
  importCard: {
    padding: theme.spacing(1.5),
    '& $form': {
      marginTop: theme.spacing(2),
    },
  },
  warning: {
    marginBottom: theme.spacing(1),
  },
  error: {
    marginBottom: theme.spacing(1),
  },
  columns: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > div': {
      width: `calc(50% - ${theme.spacing(1)}px)`,
    },
  },
  backupAction: {
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  form: {
    margin: `0 -${theme.spacing(0.5)}px`,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  edit: {
    padding: theme.spacing(2),
    paddingBottom: 12,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    '& h4': {
      margin: '0.5em 0',
    },
  },
  textField: {
    ...getFormStyle(200, 200),
  },
  importBtn: {
    margin: `0 ${theme.spacing(0.5)}px`,
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  btn: {
    margin: `-${theme.spacing(1)}px ${theme.spacing(0.5)}px 0`,
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  w100pr: {
    width: '100%',
    margin: `0 4px 8px`,
  },
  requiredInfo: {
    fontSize: '0.75rem',
    marginBottom: 0,
  },
  addressFilter: {
    margin: `0 ${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
  },
  checkbox: {
    margin: `-${theme.spacing(0.5)}px ${theme.spacing(0.5)}px 0`,
  },
  actions: {
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  actions2: {
    '& button': {
      marginLeft: theme.spacing(1),
      opacity: 0,
      transition: 'opacity 0.3s',
    },
  },
  row: {
    '&:hover': {
      '& button': {
        opacity: 1,
      },
    },
  },
  input: {
    display: 'none',
  },
}));

const delimiters = [
  { chr: ',', Code: 'Comma' },
  { chr: '\t', Code: 'Tab' },
];
const esnHeaders = ['PolyDescription', 'ESN'];
const lawHeaders = ['PolyLaw', 'LAW'];
const fireHeaders = ['PolyFire', 'FIRE'];
const emsHeaders = ['PolyEMS', 'MEDICAL'];

// const Types = settings.geofenceTypes;

function ImportCsvEsns(props) {
  const classes = useStyles();
  const { dictionary } = props;
  const [rawData, setRawData] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  // const [fileType, setFileType] = useState(null);
  const [headers, setHeaders] = useState([]);
  const [labelHeaders, setLabelHeaders] = useState([]);
  const [coordsHeaders, setCoordsHeaders] = useState([]);
  const [fileLoaded, setFileLoaded] = useState(false);
  // const [data, setData] = useState([]);
  // const [Type, setType] = useState(props.Type);
  // const [Agency, setAgency] = useState(props.Agency);
  // const [Agencies, setAgencies] = useState([]);
  const [esnCol, setEsnCol] = useState(null);
  const [lawCol, setLawCol] = useState(null);
  const [fireCol, setFireCol] = useState(null);
  const [emsCol, setEmsCol] = useState(null);
  const [pathCol, setPathCol] = useState(null);
  const [lawAgencies, setLawAgencies] = useState([]);
  const [fireAgenices, setFireAgencies] = useState([]);
  const [emsAgencies, setEmsAgencies] = useState([]);
  const [lawSettings, setLawSettings] = useState([]);
  const [fireSettings, setFireSettings] = useState([]);
  const [emsSettings, setEmsSettings] = useState([]);
  // const [showErrors, setShowErrors] = useState(false);
  const [importValid, setImportValid] = useState(false);
  const [duplicates, setDuplicates] = useState(false);
  const [delimiter, setDelimiter] = useState(delimiters[0]);
  const [labelOptions, setLabelOptions] = useState([]);
  const [coordsOptions, setCoordsOptions] = useState([]);
  const [errors, setErrors] = useState([]);

  const csvFileRef = useRef(null);

  useEffect(() => {
    if (!selectedFile) return;
    handleCSVLoad();
    // eslint-disable-next-line
  }, [selectedFile]);

  useEffect(() => {
    if (!lawCol) return;
    const idx = headers.indexOf(lawCol.Code);
    const zones = [];
    rawData.forEach(row => {
      const zone = row[idx];
      if (zones.indexOf(zone) === -1) zones.push(zone);
    });
    setLawSettings(
      zones.sort().map(zone => {
        return { name: zone, AgencyID: null };
      })
    );
    // eslint-disable-next-line
  }, [lawCol]);

  useEffect(() => {
    if (!fireCol) return;
    const idx = headers.indexOf(fireCol.Code);
    const zones = [];
    rawData.forEach(row => {
      const zone = row[idx];
      if (zones.indexOf(zone) === -1) zones.push(zone);
    });
    setFireSettings(
      zones.sort().map(zone => {
        return { name: zone, AgencyID: null };
      })
    );
    // eslint-disable-next-line
  }, [fireCol]);

  useEffect(() => {
    if (!emsCol) return;
    const idx = headers.indexOf(emsCol.Code);
    const zones = [];
    rawData.forEach(row => {
      const zone = row[idx];
      if (zones.indexOf(zone) === -1) zones.push(zone);
    });
    setEmsSettings(
      zones.sort().map(zone => {
        return { name: zone, AgencyID: null };
      })
    );
    // eslint-disable-next-line
  }, [emsCol]);

  useEffect(() => {
    const { Agencies } = dictionary;
    if (!Agencies) return;
    setLawAgencies(
      Agencies.filter(agency => agency.AgencyType === 1).map(agency => agency.AgencyID)
    );
    setFireAgencies(
      Agencies.filter(agency => agency.AgencyType === 2).map(agency => agency.AgencyID)
    );
    setEmsAgencies(
      Agencies.filter(agency => agency.AgencyType === 4).map(agency => agency.AgencyID)
    );
    // eslint-disable-next-line
  }, [dictionary]);

  useEffect(() => {
    if (!esnCol) return;
    const idx = headers.indexOf(esnCol.Code);
    const esns = [];
    let duplicates = false;
    rawData.forEach(row => {
      const ESN = row[idx];
      if (esns.indexOf(ESN) !== -1) {
        duplicates = true;
      }
      esns.push(ESN);
    });
    setDuplicates(duplicates);
    // eslint-disable-next-line
  }, [esnCol]);

  useEffect(() => {
    validateImport();
    // eslint-disable-next-line
  }, [lawCol, fireCol, emsCol, esnCol, lawSettings, fireSettings, emsSettings]);

  useEffect(() => {
    const labelOptions = labelHeaders.map(l => ({ Code: headers[l] }));
    const coordsOptions = coordsHeaders.map(l => ({ Code: headers[l] }));
    setLabelOptions(labelOptions);
    setCoordsOptions(coordsOptions);
    // eslint-disable-next-line
  }, [headers, labelHeaders, coordsHeaders]);

  const validateImport = () => {
    let valid = true;
    if (!esnCol || !lawCol || !fireCol || !emsCol) valid = false;
    lawSettings.forEach(s => {
      if (!s.AgencyID) valid = false;
    });
    fireSettings.forEach(s => {
      if (!s.AgencyID) valid = false;
    });
    emsSettings.forEach(s => {
      if (!s.AgencyID) valid = false;
    });
    setImportValid(valid);
  };

  const handleChangeCSVFile = ev => {
    // setFileType('csv');
    setSelectedFile(ev.target.files[0]);
  };

  const handleCSVLoad = () => {
    const reader = new FileReader();
    reader.onload = ev => {
      csvParser(ev.target.result, { comment: '#', delimiter: delimiter.chr }, (err, result) => {
        if (err) {
          console.log(err);
          props.notify('Error processing the file');
        } else {
          processCSV(result);
        }
      });
    };
    reader.onerror = () => {
      props.notify('Error, file not loaded', 'error');
    };
    reader.readAsText(selectedFile);
  };

  const processCSV = content => {
    if (content.length < 2) return props.notify('Processing file error!', 'error');
    const headers = content.shift();
    setHeaders(headers);
    const coordsHeaders = [];
    const labelHeaders = [];
    content[0].forEach((col, idx) => {
      const coords = parsePolygon(col);
      if (coords) {
        coordsHeaders.push(idx);
      } else {
        labelHeaders.push(idx);
      }
    });
    if (coordsHeaders.length === 0)
      return props.notify('Error, polygons not found in the file.', 'error');
    let errors = false;
    const data = content.map(row => {
      coordsHeaders.forEach(idx => {
        const polygon = parsePolygon(row[idx]);
        if (polygon) {
          row[idx] = polygon;
        } else {
          errors = false;
        }
      });
      return row;
    });
    if (errors) {
      props.notify('Error processing the file');
    } else {
      setHeaders(headers);
      setLabelHeaders(labelHeaders);
      setCoordsHeaders(coordsHeaders);
      setFileLoaded(true);
      setRawData(data);
      esnHeaders.forEach(Code => {
        if (headers.indexOf(Code) !== -1) setEsnCol({ Code });
      });
      lawHeaders.forEach(Code => {
        if (headers.indexOf(Code) !== -1) setLawCol({ Code });
      });
      fireHeaders.forEach(Code => {
        if (headers.indexOf(Code) !== -1) setFireCol({ Code });
      });
      emsHeaders.forEach(Code => {
        if (headers.indexOf(Code) !== -1) setEmsCol({ Code });
      });
      if (coordsHeaders.length === 1) {
        setPathCol({ Code: headers[coordsHeaders[0]] });
      }
    }
  };

  const parsePolygon = str => {
    let error = false;
    const coords = str
      .split(';')
      .map(str => str.trim())
      .filter(str => str.length > 5)
      .map(str => {
        const arr = str.split(',');
        const obj = { lat: 0, lng: 0 };
        if (arr.length === 2 && !isNaN(arr[0]) && !isNaN(arr[1])) {
          if (isNaN(arr)) obj.lat = parseFloat(arr[0]);
          obj.lng = parseFloat(arr[1]);
        } else {
          error = true;
        }
        return obj;
      });
    if (error || coords.length < 3) return null;
    return coords;
  };

  // const downloadBackup = () => {
  //   const fn = Type.Code === 'Zone' ? getZones : getESNs;
  //   props.showSpinner();
  //   fn(Agency.Code)
  //     .then(saveBackup)
  //     .catch(err => handleError(err, "Error, backup not successful."))
  //     .finally(props.hideSpinner);
  // }

  // const saveBackup = (data) => {
  //   if (data.length === 0) return props.notify('There is no data to back up for the options you have chosen.', 'warning');
  //   const saveData = [...data].map(row => {
  //     const newRow = {...row, Code: Type.Code};
  //     delete newRow.ptsGeofenceID;
  //     delete newRow.ROWGUID;
  //     delete newRow.center;
  //     return newRow;
  //   })
  //   const dataStr = JSON.stringify(saveData);
  //   const file = new Blob([dataStr], {type: 'text/json'});
  //   const dateStr = (new Date().toISOString()).replaceAll(':', '-');
  //   const name = `${Agency.Code}-${Type.Code}-${dateStr}.bak.json`;
  //   const $a = document.createElement('a');
  //   $a.href = window.URL.createObjectURL(file);
  //   $a.download = name;
  //   $a.click();
  // }

  // const handleChangeBackupFile = ev => {
  //   setFileType('backup');
  //   setSelectedFile(ev.target.files[0]);
  // }

  // const handleBackupLoad = () => {
  //   const reader = new FileReader()
  //   reader.onload = ev => {
  //     processBackupFile(ev.target.result);
  //   };
  //   reader.onerror = error => {
  //     props.notify('Error, file not loaded', 'error');
  //   }
  //   reader.readAsText(selectedFile)
  // }

  // const processBackupFile = async strData => {
  //   let errors = false;
  //   let data;
  //   try {
  //     data = JSON.parse(strData);
  //     data.forEach(row => {
  //       if (!row.Code || !row.ZoneCode || !row.AgencyID) errors = true;
  //     });
  //   } catch (err) {
  //     errors = true;
  //   }
  //   await duplicatesInData(data);
  //   if (!errors && data.length > 0) {
  //     props.closeSnackbar();
  //     setFileLoaded(true);
  //     setData(data);
  //   } else if(errors){
  //     props.notify('Processing file error', 'error');
  //   } else {
  //     props.notify('Backup file contains no data.', 'warning');
  //   }
  // }

  // const duplicatesInData = async (data) => {
  //   let duplicates = false;
  //   const fn = Type.Code === 'Zone' ? getZones : getESNs;
  //   props.showSpinner();
  //   try {
  //     const zones = await fn();
  //     data.forEach(zone => {
  //       const ZoneCode = zone.ZoneCode;
  //       const exists = zones.find(zone => zone.ZoneCode === ZoneCode);
  //       if (exists && (exists.AgencyID !== Agency.Code)) {
  //         duplicates = true;
  //       }
  //     });
  //   } catch (err) {
  //     props.handleError(err, 'Processing file error')
  //   }
  //   props.hideSpinner();
  //   setDataConflict(duplicates);
  // }

  // const restore = () => {
  //   const newData = [ ...data ].map(zone => {
  //     const newZone = { ...zone };
  //     if (zone.path) {
  //       const SurfaceText = pathToStr(zone.path);
  //       newZone.SurfaceText = SurfaceText;
  //       delete newZone.path;
  //     }
  //     newZone.center && delete newZone.center;
  //     return newZone;
  //   });
  //   props.showSpinner();
  //   const fn = Type.Code === 'Zone' ? importZones : importESNs;
  //   fn(Agency.Code, newData)
  //     .then(() => {
  //       cleanLoadData();
  //       props.notify('Zones successfully imported', 'success');
  //     })
  //     .catch(err => props.handleError(err, 'Error, data not restored.'))
  //     .finally(props.hideSpinner);
  //     props.zonesChanged();
  // }

  // const renderLoadedBackup = () => {
  //   if (!data || data.length === 0) return '';
  //   const dataTypeCorrect = data[0].Code === Type.Code && data[0].AgencyID === Agency.Code;
  //   const disabled = dataConflict;
  //   return (
  //     <Card variant="outlined" className={classes.importCard}>
  //       <h5>Restore Backup</h5>
  //       {!dataTypeCorrect && (
  //         <Alert severity="warning" className={classes.warning}>
  //           <AlertTitle>Warning</AlertTitle>
  //           Backup was created for different data type or agency. (<strong>{data[0].Code}</strong> / <strong>{data[0].AgencyID}</strong>)
  //         </Alert>
  //       )}
  //       {dataConflict && (
  //         <Alert severity="error" className={classes.warning}>
  //           <AlertTitle>Data Conflict</AlertTitle>
  //           There is a data conflict between zone codes in imported file and codes defined for another agency.
  //         </Alert>
  //       )}
  //       {!disabled && <p>If you continue importing backup file all existing data will be overwritten. <br />
  //       Are you sure you wan to proceed?</p>}
  //       <div className={classes.backupAction}>
  //         <Button color="primary" variant="outlined" onClick={restore} disabled={disabled}>Restore</Button>
  //         <Button color="primary" variant="contained" onClick={cleanLoadData}>Cancel</Button>
  //       </div>
  //     </Card>
  //   );
  // }

  const cleanLoadData = () => {
    setFileLoaded(false);
    setSelectedFile(null);
    // setData([]);
    setEsnCol(null);
    setPathCol(null);
    // setDataConflict(false);
    setRawData(false);
    if (csvFileRef.current) csvFileRef.current.value = '';
    // backupFileRef.current.value = '';
  };

  const mergePolygons = (polygon1, polygon2, pointIdx1, pointIdx2) => {
    const mergedPolygon = [];
    polygon1.forEach((point1, idx1) => {
      if (idx1 !== pointIdx1) {
        mergedPolygon.push(point1);
      } else {
        mergedPolygon.push(point1);
        for (let idx = pointIdx2; idx < polygon2.length; idx++) {
          mergedPolygon.push(polygon2[idx]);
        }
        for (let idx = 0; idx <= pointIdx2; idx++) {
          mergedPolygon.push(polygon2[idx]);
        }
        mergedPolygon.push(point1);
      }
    });

    return mergedPolygon;
  };

  const getDistance = (p1, p2) => Math.hypot(p2.lat - p1.lat, p2.lng - p1.lng);

  const findClosesPoints = (path1, paths) => {
    let point1Idx = 0; // index of point in path1
    let point2Idx = 0; // index of point in path2
    let path2Idx = 0; //  index of path2 in paths array
    let distance = getDistance(path1[0], paths[0][0]);
    path1.forEach((point1, idx1) => {
      paths.forEach((path2, pathIdx2) => {
        path2.forEach((point2, idx2) => {
          const dist = getDistance(point1, point2);
          if (dist < distance) {
            distance = dist;
            point1Idx = idx1;
            path2Idx = pathIdx2;
            point2Idx = idx2;
          }
        });
      });
    });
    return { point1Idx, point2Idx, path2Idx, distance };
  };

  const getMergedESNs = () => {
    const esnIdx = headers.indexOf(esnCol.Code);
    const pathIdx = headers.indexOf(pathCol.Code);
    const data = [];

    const processedESNs = []; // processed esns names
    rawData.forEach((row, rowIdx) => {
      const existingESN = data.find(el => el[esnIdx] === row[esnIdx]);
      if (existingESN) {
        const ESN = row[esnIdx];
        if (processedESNs.indexOf(ESN) === -1) {
          processedESNs.push(ESN);
          const path = existingESN[pathIdx];
          const paths = [row[pathIdx]];
          // find all duplicate rows
          if (rowIdx < rawData.length - 1) {
            for (let i = rowIdx + 1; i < rawData.length; ++i) {
              const row1 = rawData[i];
              if (row1[esnIdx] === ESN) paths.push(row1[pathIdx]);
            }
          }
          paths.forEach(p => p.pop());
          let joinedPath = [...path];
          while (paths.length) {
            const { point1Idx, point2Idx, path2Idx } = findClosesPoints(joinedPath, paths);
            joinedPath = mergePolygons(joinedPath, paths[path2Idx], point1Idx, point2Idx);
            paths.splice(path2Idx, 1);
          }
          existingESN[pathIdx] = joinedPath;
        }
      } else {
        data.push(row);
      }
    });
    return data;
  };

  const importData = async () => {
    const esnIdx = headers.indexOf(esnCol.Code);
    const lawIdx = headers.indexOf(lawCol.Code);
    const fireIdx = headers.indexOf(fireCol.Code);
    const emsIdx = headers.indexOf(emsCol.Code);
    const pathIdx = headers.indexOf(pathCol.Code);
    props.showSpinner();
    const data = getMergedESNs();
    const errors = [];
    try {
      await asyncForEach(data, async row => {
        const ESN = row[esnIdx];
        const law = lawSettings.find(s => s.name === row[lawIdx]).AgencyID;
        const fire = fireSettings.find(s => s.name === row[fireIdx]).AgencyID;
        const ems = emsSettings.find(s => s.name === row[emsIdx]).AgencyID;
        const path = row[pathIdx];
        const SurfaceText = pathToStr(path);
        const saveData = { ESN, law, fire, ems, SurfaceText };
        const isOk = await saveESN(saveData);
        if (!isOk) {
          errors.push(`Error, Invalid geofence ${ESN}. Check for possible polygon intersections.`);
        }
      });
      props.notify('Import successful', 'success');
    } catch (err) {
      props.handleError(err, 'Import file error.');
    }
    setErrors(errors);
    props.hideSpinner();
    props.zonesChanged();
    cleanLoadData();
  };

  const renderInputOptions = () => {
    return (
      <div className={classes.form}>
        {/* <Tooltip title="Backup current geofences">
          <span className={classes.btn}>
            <Button
              disabled={disabled}
              color="primary"
              onClick={downloadBackup}
            >
              <BackupIcon />Backup
            </Button>
          </span>
        </Tooltip> */}

        {/* <Tooltip title="Restore geofences">
          <div className={classes.importBtn}>
            <input
              accept=".json"
              className={classes.input}
              id="import-zone-backup-file"
              multiple
              type="file"
              onChange={handleChangeBackupFile}
              ref={backupFileRef}
            />
            <label htmlFor="import-zone-backup-file">
              <Button
                color="primary"
                component="span"
                disabled={disabled}
              >
                <RestoreIcon />
                Restore
              </Button>
            </label>
          </div>
        </Tooltip> */}

        <CustomAutocomplete
          className={classes.textField}
          label="Delimiter"
          options={delimiters}
          value={delimiter}
          onChange={val => setDelimiter(val)}
        />

        <Tooltip title="Import CSV ESNs">
          <div className={classes.importBtn}>
            <input
              accept=".csv"
              className={classes.input}
              id="import-esn-csv-file"
              multiple
              type="file"
              onChange={handleChangeCSVFile}
              ref={csvFileRef}
            />
            <label htmlFor="import-esn-csv-file">
              <Button color="primary" component="span" variant="outlined">
                <CSVIcon />
                Import CSV
              </Button>
            </label>
          </div>
        </Tooltip>
      </div>
    );
  };

  const handleLawZoneChange = idx => (ev, AgencyID) => {
    const newSettings = [...lawSettings];
    newSettings[idx].AgencyID = AgencyID;
    setLawSettings(newSettings);
  };

  const renderLawEnforcementSettings = () => {
    return (
      <div className={classes.form}>
        {lawSettings.map((zone, idx) => (
          <Autocomplete
            className={classes.textField}
            options={lawAgencies}
            value={zone.AgencyID}
            onChange={handleLawZoneChange(idx)}
            key={zone.name}
            renderInput={params => (
              <TextField {...params} label={zone.name} variant="outlined" size="small" />
            )}
          />
        ))}
      </div>
    );
  };

  const handleFireZoneChange = idx => (ev, AgencyID) => {
    const newSettings = [...fireSettings];
    newSettings[idx].AgencyID = AgencyID;
    setFireSettings(newSettings);
  };

  const renderFireSettings = () => {
    return (
      <div className={classes.form}>
        {fireSettings.map((zone, idx) => (
          <Autocomplete
            className={classes.textField}
            options={fireAgenices}
            value={zone.AgencyID}
            onChange={handleFireZoneChange(idx)}
            key={zone.name}
            renderInput={params => (
              <TextField {...params} label={zone.name} variant="outlined" size="small" />
            )}
          />
        ))}
      </div>
    );
  };

  const handleEmsSettings = idx => (ev, AgencyID) => {
    const newSettings = [...emsSettings];
    newSettings[idx].AgencyID = AgencyID;
    setEmsSettings(newSettings);
  };

  const renderEmsSettings = () => {
    return (
      <div className={classes.form}>
        {emsSettings.map((zone, idx) => (
          <Autocomplete
            className={classes.textField}
            options={emsAgencies}
            value={zone.AgencyID}
            onChange={handleEmsSettings(idx)}
            key={zone.name}
            renderInput={params => (
              <TextField {...params} label={zone.name} variant="outlined" size="small" />
            )}
          />
        ))}
      </div>
    );
  };

  const renderLoadedCSV = () => {
    return (
      <Card variant="outlined" className={classes.importCard}>
        <h6>CSV File Configuration</h6>
        <div className={classes.form}>
          <CustomAutocomplete
            className={classes.textField}
            label="ESN Codes*"
            options={labelOptions}
            value={esnCol}
            onChange={val => setEsnCol(val)}
          />
          <CustomAutocomplete
            className={classes.textField}
            label="Polygons*"
            options={coordsOptions}
            value={pathCol}
            onChange={val => setPathCol(val)}
          />
          <CustomAutocomplete
            className={classes.textField}
            label="Law Enforcement"
            options={labelOptions}
            value={lawCol}
            onChange={val => setLawCol(val)}
          />
          <CustomAutocomplete
            className={classes.textField}
            label="Fire Department"
            options={labelOptions}
            value={fireCol}
            onChange={val => setFireCol(val)}
          />
          <CustomAutocomplete
            className={classes.textField}
            label="EMS"
            options={labelOptions}
            value={emsCol}
            onChange={val => setEmsCol(val)}
          />
        </div>
        <hr />
        <h6>Law Enforcement Settings</h6>
        {renderLawEnforcementSettings()}
        <h6>Fire Department Settings</h6>
        {renderFireSettings()}
        <h6>EMS Settings</h6>
        {renderEmsSettings()}

        <hr />
        <p>
          If you continue importing geofences all existing data will be overwritten. <br />
          Are you sure you wan to proceed?
        </p>

        <div className={classes.backupAction}>
          <Button color="primary" variant="outlined" onClick={importData} disabled={!importValid}>
            Import
          </Button>
          <Button color="primary" variant="contained" onClick={cleanLoadData}>
            Cancel
          </Button>
        </div>
      </Card>
    );
  };

  return (
    <>
      {/* {renderZoneForm()}
        <hr /> */}
      {!fileLoaded && renderInputOptions()}
      {/* {fileLoaded && fileType === 'backup' && renderLoadedBackup()} */}
      {fileLoaded && renderLoadedCSV()}
      {errors.length > 0 && (
        <Alert severity="error" className={classes.error}>
          <AlertTitle>Error processing the file</AlertTitle>
          {errors.map((err, idx) => (
            <p key={idx}>{err}</p>
          ))}
        </Alert>
      )}
    </>
  );
}

const mapStateToProps = state => {
  return {
    dictionary: state.dictionary,
  };
};

export default connect(mapStateToProps, {
  notify,
  showSpinner,
  hideSpinner,
  handleError,
  closeSnackbar,
  zonesChanged,
})(ImportCsvEsns);
