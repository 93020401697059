import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { Grid, TextField, Typography, Checkbox, Button } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Content from '../../Content';

import { getService } from '../../../reducers/service';

function AddEditWorkFlowStep() {
  const params = useParams();
  const history = useHistory();
  const service = getService('ptsworkflowstep');
  const [workflowStep, setWorkflowStep] = useState({});

  const handleChange = (name, value) => {
    setWorkflowStep({
      ...workflowStep,
      ptsWorkflowID: params.id,
      [name]: value,
    });
  };

  const handleSaveClick = () => {
    if (!workflowStep.ptsWorkflowStepID) {
      service
        .create(workflowStep)
        .then(res => history.goBack())
        .catch(err => console.log(err));
    } else {
      service
        .patch(workflowStep.ptsWorkflowStepID, workflowStep)
        .then(res => history.goBack())
        .catch(err => console.log(err));
    }
  };

  useEffect(() => {
    if (params.stepid) {
      service
        .get(params.stepid)
        .then(res => setWorkflowStep(res))
        .catch(err => console.log(err));
    }
  }, [params.stepid, service]);

  return (
    <Content>
      <Grid container className="p-4">
        <Grid xs={11} item>
          <Typography variant="h6">
            {workflowStep.ptsWorkflowStepID ? 'Edit' : 'Add'} Step
          </Typography>
        </Grid>
        <Grid container className="pt-4" spacing={3}>
          <Grid item xs={4} lg={4}>
            <TextField
              autoComplete="hidden"
              size="small"
              id="standard-textarea-workflow-name"
              label="Step Name"
              placeholder="Step Name"
              variant="outlined"
              fullWidth
              multiline
              defaultValue={workflowStep?.Step || ''}
              onBlur={event => handleChange(event.target.name, event.target.value)}
              InputLabelProps={{ shrink: true }}
              name="Step"
            />
          </Grid>

          <Grid item>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    id="isused-checkbox"
                    key={workflowStep?.IsApproval}
                    checked={workflowStep?.IsApproval}
                    onChange={event => handleChange(event.target.name, event.target.checked)}
                    name="IsApproval"
                  />
                }
                label="Is Approval"
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid item className="pt-4">
          <Button onClick={handleSaveClick} variant="contained" color="primary">
            Save
          </Button>
        </Grid>
        <Grid item className="pl-3 pt-4">
          <Button onClick={() => history.goBack()} variant="contained" color="default">
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Content>
  );
}

export default AddEditWorkFlowStep;
