import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Content from '../../Content';
import { makeStyles } from '@material-ui/core/styles';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  Paper,
  TextField,
  Button,
  Toolbar,
  IconButton,
  Tooltip,
  Typography,
  Grid,
  Box,
} from '@material-ui/core';
import { FormatBold, FormatItalic, FormatUnderlined, KeyboardReturn } from '@material-ui/icons';
import SaveIcon from '@material-ui/icons/Save';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getService } from 'reducers/service';
import {
  findEmailInDatabase,
  findMailTemplateData,
  saveCDRdata,
  updateCDRdata,
} from './emailTempFunctions';
import { notify } from 'reducers/NotifierReducer';
import { handleError } from 'reducers/ErrorReducer';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  editor: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  saveBtn: {
    textAlign: 'right',
  },
}));

function DefaultEmailTemplate(props) {
  const classes = useStyles();

  const [defaultApplication, setDefaultApplication] = useState(null);
  const [link, setLink] = useState('');
  const [emailTemplete, setEmailTemplete] = useState('');
  const [smsTemplete, setSmsTemplete] = useState('');

  const cdr = getService('cdr');

  useEffect(() => {
    if (defaultApplication) {
      fetchData();
    }
  }, [defaultApplication]);

  const fetchData = async () => {
    const data = await findMailTemplateData(defaultApplication.name);

    setEmailTemplete(data.emailText);
    setSmsTemplete(data.usernameText);
    setLink(data.url);
  };

  const renderApplication = () => {
    const applicationList = [
      { name: 'RMS', index: 1 },
      { name: 'CAD', index: 2 },
      { name: 'CAD-Mobile', index: 3 },
    ];

    return (
      <Box>
        <Autocomplete
          style={{ paddingBottom: 20 }}
          options={applicationList}
          disablePortal
          getOptionLabel={option => option.name}
          renderInput={params => (
            <TextField {...params} label="Default Application" variant="outlined" />
          )}
          renderOption={option => `${option.name}`}
          size="small"
          value={defaultApplication}
          onChange={(e, value) => setDefaultApplication(value)}
        />
      </Box>
    );
  };

  const renderSiteLink = () => {
    return (
      <Box>
        <TextField
          label="Application Log In URL*"
          variant="outlined"
          value={link}
          size="small"
          onChange={e => setLink(e.target.value)}
          fullWidth
        />
      </Box>
    );
  };

  const handleSaveClick = async () => {
    if (validateInput()) return;

    const data = await saveCDRdata(defaultApplication.name, emailTemplete, smsTemplete, link);
    if (data) props.notify('Saved.', 'success');
    else props.notify('Save failure.', 'error');
  };

  const validateInput = () => {
    if (!defaultApplication) {
      props.notify('Default Application is required.', 'error');
      return true;
    }
    if (!link) {
      props.notify('Log In URl is required.', 'error');
      return true;
    }
    if (!emailTemplete) {
      props.notify('Email Body can not be empty.', 'error');
      return true;
    }
    if (!smsTemplete) {
      props.notify('SMS Body can not be empty.', 'error');
      return true;
    }
    return false;
  };

  const renderSave = () => {
    return (
      <div className={classes.saveBtn}>
        <Button onClick={handleSaveClick} variant="contained" color="primary">
          <SaveIcon className="mr-2" /> Save
        </Button>
      </div>
    );
  };

  return (
    <Content>
      <div className="m-4">
        <h4 className="mb-2">Email And SMS Templates</h4>
        {renderSave()}
        <Grid container item spacing={2} xs={12} style={{ marginTop: 10 }}>
          <Grid item xs={6} style={{ width: '100%' }}>
            {renderApplication()}
          </Grid>
          <Grid item xs={6} style={{ width: '100%' }}>
            {renderSiteLink()}
          </Grid>
          <Grid item xs={12} style={{ minHeight: '200px' }}>
            <h5>Activation Email</h5>
            <div>
              <ReactQuill
                style={{ height: '150px' }}
                value={emailTemplete}
                onChange={content => setEmailTemplete(content)}
              />
            </div>
          </Grid>
          <Grid item xs={12} style={{ minHeight: '200px', marginTop: '30px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h5>User Name</h5>
              <p>
                <span style={{ color: 'red' }}>***</span> Please use this text as placeholder for
                username <b>'(_user_name)'</b>
              </p>
            </div>

            <div>
              <ReactQuill
                style={{ height: '150px' }}
                value={smsTemplete}
                onChange={content => setSmsTemplete(content)}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </Content>
  );
}

export default connect(null, { handleError, notify })(DefaultEmailTemplate);
