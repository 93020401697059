import React from 'react';

function SkullCrossboneIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      preserveAspectRatio="xMidYMid meet"
      {...props}>
      <g
        transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none">
        <path d="M155 456 c-60 -28 -87 -56 -114 -116 -36 -79 -19 -183 42 -249 33 -36 115 -71 167 -71 52 0 134 35 167 71 34 37 63 110 63 159 0 52 -35 134 -71 167 -37 34 -110 63 -159 63 -27 0 -65 -10 -95 -24z m-15 -112 c15 -38 13 -42 -19 -40 -39 2 -44 8 -32 40 13 33 39 34 51 0z m271 0 c12 -32 7 -38 -32 -40 -32 -2 -34 2 -19 40 12 34 38 33 51 0z m-100 -13 c33 -33 38 -74 12 -103 -9 -10 -21 -28 -27 -41 -16 -31 -76 -31 -92 0 -6 13 -18 31 -27 41 -41 45 7 132 73 132 22 0 41 -9 61 -29z m-125 -153 c4 -6 2 -14 -5 -16 -7 -2 -16 -15 -21 -28 -12 -32 -38 -31 -50 2 -12 31 -4 47 20 39 10 -3 21 -1 25 5 8 13 22 13 31 -2z m159 2 c4 -6 15 -8 25 -5 24 8 32 -8 20 -39 -12 -33 -38 -34 -50 -2 -5 13 -14 26 -21 28 -7 2 -9 10 -5 16 9 15 23 15 31 2z" />
        <path d="M194 289 c-10 -17 23 -46 37 -32 7 7 9 19 6 27 -7 18 -33 21 -43 5z" />
        <path d="M263 284 c-3 -8 -1 -20 6 -27 14 -14 47 15 37 32 -10 16 -36 13 -43 -5z" />
        <path d="M236 234 c-3 -9 -6 -17 -6 -18 0 -1 9 -2 20 -2 11 0 20 1 20 2 0 11 -14 34 -20 34 -4 0 -11 -7 -14 -16z" />
      </g>
    </svg>
  );
}

export default SkullCrossboneIcon;
