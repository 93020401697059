import { Checkbox, FormControlLabel, TextField, makeStyles } from '@material-ui/core';
import Dictionary from 'components/Dictionary';
import FormDivider from 'components/FormDivider';
import React from 'react';

import { ColCard, Fills, Row, RowInner } from 'utils/formStyles';
const useStyles = makeStyles(theme => ({
  item: {
    width: '100%',
    flex: '40%',
  },
  checkboxItem: {
    width: '100%',
    flex: '10%',
  },
}));
const RenderRow = props => {
  const { handleChange, values, field, resourceType } = props;

  const classes = useStyles();
  return (
    <Row>
      <ColCard minWidth={500}>
        <FormDivider title={field.label} />
        <RowInner
          style={{
            justifyContent: 'space-between',
            width: '100%',
            gap: '15px',
            alignItems: 'center',
          }}>
          {!field.hideCheckbox && (
            <FormControlLabel
              className={classes.checkboxItem}
              control={
                <Checkbox
                  size="medium"
                  checked={(values[field.Path] && values[field.Path]['FieldUsed']) || false}
                  onChange={e => handleChange(field.Path, 'FieldUsed', e.target.checked)}
                />
              }
              label={`Use ${field.subLabel ? field.subLabel : field.label}`}
            />
          )}
          {field.Path === 'E911Collector.Location.Address.BldgIdentifier' && (
            <FormControlLabel
              className={classes.checkboxItem}
              control={
                <Checkbox
                  size="medium"
                  checked={(values[field.Path] && values[field.Path]['AddressLookup']) || false}
                  onChange={e => handleChange(field.Path, 'AddressLookup', e.target.checked)}
                />
              }
              label={`Lookup Address Via Field`}
            />
          )}

          {resourceType === '2' ? (
            field.Path === 'E911Collector.CallMethod' ? (
              <Dictionary
                options="CallMethods"
                className={classes.item}
                onChange={(e, val) => {
                  console.log('val', val);
                  handleChange(field.Path, 'XmlNode', val?.Code || '');
                }}
                value={{ Code: (values[field.Path] && values[field.Path]['XmlNode']) || '' }}
                label={field.label}
              />
            ) : (
              <TextField
                label="XML Node"
                variant="outlined"
                value={(values[field.Path] && values[field.Path]['XmlNode']) || ''}
                onChange={e => handleChange(field.Path, 'XmlNode', e.target.value)}
                size="small"
                className={classes.item}
              />
            )
          ) : (
            <>
              <TextField
                label="Start Position"
                variant="outlined"
                value={(values[field.Path] && values[field.Path]['StartPosition']) || ''}
                onChange={e => handleChange(field.Path, 'StartPosition', e.target.value)}
                size="small"
                className={classes.item}
              />
              <TextField
                label="End Position"
                variant="outlined"
                value={(values[field.Path] && values[field.Path]['EndPosition']) || ''}
                onChange={e => handleChange(field.Path, 'EndPosition', e.target.value)}
                size="small"
                className={classes.item}
              />
            </>
          )}

          <Fills className={classes.item} />
        </RowInner>
      </ColCard>
    </Row>
  );
};

export default RenderRow;
