import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { getFormStyle } from '../../utils/functions';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MessageIcon from '@material-ui/icons/Message';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(theme => ({
  form: {
    margin: '0 -4px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexGrow: 1,
  },
  dialogContent: {
    width: 1000,
    maxWidth: '100%',
    minHeight: 500,
  },
  textField: {
    margin: `0 ${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
  },
  w100x200: {
    ...getFormStyle(100, 200),
  },
  w170x400: {
    ...getFormStyle(170, 400),
  },
  w200x300: {
    ...getFormStyle(200, 300),
  },
  w120x180: {
    ...getFormStyle(120, 180),
  },
  w100pr: {
    width: '100%',
    margin: `0 4px 8px`,
  },
  trigger: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    margin: '1em 0',
  },
  clearRow: {
    marginRight: theme.spacing(1),
  },
  textBox: {
    ...getFormStyle(200, 500),
    marginLeft: theme.spacing(2),
  },
}));


function SOPActions(props) {
  const classes = useStyles();
  const { actions, setActions } = props;
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [textboxNo, setTextboxNo] = useState(0);
  const [checkboxNo, setCheckboxNo] = useState(0);

  const closeMenu = () => {
    setMenuAnchor(null)
  }

  const add = type => () => {
    const newActions = [...actions];
    switch (type) {
      case 'Text':
        newActions.push({
          "type": "Text",
          "name": `textbox${textboxNo + 1}`,
          "text": "",
          "value": ""
        });
        setTextboxNo(textboxNo + 1);
        break;
      case 'Question':
        newActions.push({
          "type": "Question",
          "name": `textbox${textboxNo + 1}`,
          "text": "",
          "value": ""
        });
        setTextboxNo(textboxNo + 1);
        break;
      case 'CheckBox':
        newActions.push({
          "type": "CheckBox",
          "name": `checkbox${checkboxNo + 1}`,
          "text": "",
          "value": ""
        });
        setCheckboxNo(checkboxNo + 1);
        break;
      default:
    }
    setActions(newActions);
    closeMenu();
  }

  const renderAddMenu = () => {
    return (
      <>
        <Button onClick={ev => setMenuAnchor(ev.currentTarget)}>
          <AddIcon /> Add
        </Button>
        <Menu
          anchorEl={menuAnchor}
          keepMounted
          open={Boolean(menuAnchor)}
          onClose={closeMenu}
        >
          <MenuItem onClick={add('Text')}>Text</MenuItem>
          <MenuItem onClick={add('Question')}>Question</MenuItem>
          <MenuItem onClick={add('CheckBox')}>Checkbox</MenuItem>
        </Menu>
      </>
    );
  }

  const update = (ev, idx) => {
    const text = ev.target.value;
    const newActions = [...actions];
    newActions[idx].text = text;
    setActions(newActions);
  }

  const removeItem = (idx) => {
    const newActions = [...actions];
    newActions.splice(idx, 1);
    setActions(newActions);
  }

  const getIcon = (type) => {
    switch (type) {
      case 'Text':
        return <MessageIcon />;
      case 'Question':
        return <QuestionAnswerIcon />;
      case 'CheckBox':
        return <CheckBoxIcon />;
      default:
    }
  }

  const getLabel = (type) => {
    switch (type) {
      case 'Text':
        return 'Text message';
      case 'Question':
        return 'Question';
      case 'CheckBox':
        return 'Check';
      default:
    }
  }
  return (
    <>
      <h5 className={classes.title}>Actions</h5>
      {renderAddMenu()}
      <div>
        {actions.map((action, idx) => <div key={idx} className={classes.form}>
          <IconButton
            size="small"
            className={classes.clearRow}
            tabIndex={-1}
            onClick={() => removeItem(idx)}
          >
            <ClearIcon fontSize="inherit" />
          </IconButton>
          {getIcon(action)}
          <TextField
            size="small"
            variant="outlined"
            value={action.text}
            onChange={ev => update(ev, idx)}
            className={classes.textBox}
            error={action.text.length === 0}
            label={getLabel(action.type)}
            multiline={action.type === 'Text' ? true : undefined}
            rows={action.type === 'Text' ? 4 : undefined}
          />
        </div>)}
      </div>
    </>
  );
}

export default SOPActions;
