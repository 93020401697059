import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Content from 'Settings/Content';

import { getService } from 'reducers/service';
import { handleError } from 'reducers/ErrorReducer';
import { notify } from 'reducers/NotifierReducer';

import { gridStyle } from 'utils/formStyles';
import { XGrid } from '@material-ui/x-grid';
import { Fab } from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { showAgencyBasedUnitSelection } from 'reducers/DialogsReducer';
import QuickFilter from 'components/QuickFilter';
import { formatDate } from 'utils/functions';
const useStyles = makeStyles(theme => ({
  item: {
    ...gridStyle(200, 800),
  },
  list: {
    width: '100%',
    height: 500,
  },
  wrap: {
    padding: theme.spacing(2),
    '& > h5': {
      marginBottom: 20,
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: `calc(100vh - 240px)`,
  },
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0, 2),
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
}));

function AgencyBasedUnitSelection(props) {
  const { wsClient, addAgencyUnitSelection } = props;
  const classes = useStyles();
  const service = getService('cdr');
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [open, setOpen] = useState(false);

  const [mode, setMode] = useState('');
  const [editableRow, setEditableRow] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    const search = async () => {
      service.timeout = 20000;
      try {
        const unitSettings = await service.find({
          query: { Path: 'AgencyBasedUnitSetting' },
        });

        const processData = unitSettings.map(dt => {
          return { ...dt, Created: formatDate(dt.Created) };
        });
        setRows(processData);
      } catch (error) {
        console.log(error);
      } finally {
        setLoaded(true);
      }
    };
    if (addAgencyUnitSelection == null) {
      search();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsClient.websocket, addAgencyUnitSelection]);
  const columnsItems = [
    { field: 'CDRKey', headerName: 'Agency ID', width: 150 },
    { field: 'ValueBoolean', headerName: 'Unit Selection', width: 150, type: 'boolean' },
    { field: 'CreatedBy', headerName: 'CreatedBy', width: 150 },
    { field: 'Created', headerName: 'Created', width: 200 },
  ];
  const handleSelectChange = ({ selectionModel }) => {
    setSelection(selectionModel);
  };

  const onClickAdd = () => {
    dispatch(showAgencyBasedUnitSelection({ type: 'add' }));
    setMode('add');
    setOpen(true);
  };
  const onClickEdit = () => {
    const obj = rows.find(row => row.ROWGUID == selection[0]);
    // console.log('obj', obj, selection);
    dispatch(showAgencyBasedUnitSelection({ type: 'edit', info: obj }));
    setMode('edit');
    setOpen(true);
  };

  const renderActions = () => {
    const disabled = selection.length === 0;
    const selected = rows.find(a => a.id === selection[0]);
    //const dltDisabled = selected?.Icon === 0;
    return (
      <div className={classes.actions}>
        <div>
          <QuickFilter rows={rows} columns={columnsItems} setFilteredRows={setFilteredRows} />
        </div>
        <div>
          <Fab size="small" color="secondary" onClick={onClickAdd}>
            <AddIcon />
          </Fab>
          <Fab size="small" color="secondary" onClick={onClickEdit} disabled={disabled}>
            <EditIcon />
          </Fab>
        </div>
      </div>
    );
  };
  return (
    <Content>
      <div className="m-4">
        <h4>Unit Selection</h4>
        {renderActions()}
        <div className={classes.gridWrap}>
          <XGrid
            columns={columnsItems}
            rows={filteredRows}
            loading={!loaded}
            rowHeight={38}
            getRowId={row => row.ROWGUID}
            // checkboxSelection
            onSelectionModelChange={handleSelectChange}
          />
        </div>
      </div>
    </Content>
  );
}

const mapStateToProps = state => {
  return {
    wsClient: state.websocket,
    addAgencyUnitSelection: state.dialogs.addAgencyUnitSelection,
  };
};

export default connect(mapStateToProps, {
  handleError,
  notify,
  showAgencyBasedUnitSelection,
})(AgencyBasedUnitSelection);
