import { Fab, makeStyles } from '@material-ui/core';
import React, { useContext } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch } from 'react-redux';
import { hideSpinner, showSpinner } from 'reducers/UiReducer';
import { notify } from 'reducers/NotifierReducer';
import { handleError } from 'reducers/ErrorReducer';
import { ReloadContext } from '.';
import { getService } from 'reducers/service';
const useStyles = makeStyles(theme => ({
  actions: {
    flexGrow: 0,
    flexShrink: 0,
    width: 'auto',
    width: '100%',
    marginLeft: theme.spacing(1),
    textAlign: 'right',
    paddingRight: '15px',
    paddingBottom: '5px',
  },
}));
const AttatchmentsActions = props => {
  const classes = useStyles();
  const service = getService('ptsassignedsops');
  const { selection, ParentType, ptsSOPID } = props;
  const { reload, setReload } = useContext(ReloadContext);
  const disabled = !selection || selection?.length === 0;
  const dispatch = useDispatch();
  const onClickDelete = async () => {
    try {
      dispatch(showSpinner());
      const data = await service.remove(null, {
        query: { ptsSOPID, ParentType, ptsParentID: selection },
      });
      setReload(!reload);
      dispatch(notify('SOP Deleted', 'warning'));
    } catch (err) {
      dispatch(handleError(err, 'Error, SOP not Deleted'));
    } finally {
      dispatch(hideSpinner());
    }
  };
  return (
    <div className={classes.actions}>
      <Fab size="small" color="secondary" onClick={onClickDelete} disabled={disabled}>
        <DeleteIcon />
      </Fab>
    </div>
  );
};

export default AttatchmentsActions;
