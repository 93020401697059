import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

import { handleError } from '../reducers/ErrorReducer';
import { showSpinner, hideSpinner } from '../reducers/UiReducer';
import { notify } from '../reducers/NotifierReducer';
import { closeCodeCategories } from '../reducers/DialogsReducer';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { DialogContent } from '@mui/material';
import { getService } from 'reducers/service';
import { allMenu } from 'Settings/LookupCodes/Menulist';

const useStyles = makeStyles(theme => ({
  actions: {
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  textField: {
    width: 600,
    maxWidth: '100%',
    marginTop: theme.spacing(1),
  },
  dialog: {
    '& $content': {
      margin: 0,
      padding: 0,
      background: 'red !important',
    },
  },
}));
function extractLinks(menuItems) {
  const links = [];
  for (const item of menuItems) {
    if (item.type === 'link') {
      links.push(item);
    } else if (item.type === 'menu' && item.submenu) {
      links.push(...extractLinks(item.submenu)); // Recursive call for submenus
    }
  }

  return links;
}
function AddEditCodeCategoriesDialog(props) {
  const classes = useStyles();
  const { wsClient, user } = props;
  const { type, info: selectedRow } = props.data;
  const username = user?.userData?.user?.Username;
  const ptsUserID = user?.userData?.user?.ptsUserID;
  const service = getService('codecategories');
  const [values, setValues] = useState({});

  const categoryLinks = extractLinks(allMenu, []);

  const processedCategoryList = (categoryLinks || []).map(item => {
    return {
      ...item,
      Code: (item.table || item.service || '').toLowerCase(),
      Description: item.label,
    };
  });

  useEffect(() => {
    if (type == 'edit') {
      const form = {
        CategoryCode: selectedRow.CategoryCode,
        CategoryKey: selectedRow.CategoryKey,
        IsDefault: selectedRow.IsDefault,
        IsActive: selectedRow.IsActive,
        IsDeleted: false,
        Flags: selectedRow.Flags || null,
        Description: selectedRow.Description || selectedRow.Code2,
        Description2: selectedRow.Description2,
        Description3: selectedRow.Description3,
      };
      setValues(form);
    }
  }, [selectedRow, type]);

  const close = () => {
    props.closeCodeCategories();
  };
  const save = async () => {
    props.showSpinner();
    try {
      const newForm = { ...values };
      if (type == 'edit') {
        const id = props.data?.info?.ptsCategoryID;
        const updatedData = await service.patch(id, { ...newForm, UpdatedBy: username });
        props.notify('Updated', 'success');
      } else {
        const addedData = await service.create({ ...newForm, CreatedBy: username });
        props.notify('Created', 'success');
      }
    } catch (err) {
      props.handleError(err, 'Error, Could not saved.', err);
    }
    props.hideSpinner();
    close();
  };
  const handleChange = (name, data) => {
    const newForm = { ...values };
    newForm[name] = data;
    setValues({ ...newForm });
  };

  const renderActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={save}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  return (
    <Dialog
      onClose={close}
      title={'Add Citation Court'}
      open={true}
      actions={renderActions()}
      //   className={classes.dialog}

      // toolbar={false}
      // draggable={false}
      fullWidth
      maxWidth="lg">
      <DialogTitle>{type == 'edit' ? 'Edit' : 'Add'} Category Code</DialogTitle>
      <DialogContent className="py-2">
        <>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={6}>
              <TextField
                //fullWidth
                style={{ width: '100%' }}
                type="text"
                value={values.CategoryCode}
                onChange={e => handleChange('CategoryCode', e.target.value)}
                label="Code"
                disabled={type === 'edit'}
                //error={error}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="category-codes"
                autoHighlight
                autoSelect
                value={{ Code: values.CategoryKey || '' }}
                onChange={(event, newValue) => {
                  handleChange('CategoryKey', newValue?.Code || '');
                }}
                options={processedCategoryList || []}
                getOptionLabel={option => option.Code || ''}
                getOptionSelected={(option, value) =>
                  (option.Code || '').toUpperCase() === (value.Code || '').toUpperCase()
                }
                renderOption={option => {
                  const { Code, Description } = option;
                  return (
                    <span>
                      {!!Description && <>{Description} - </>}
                      <strong>{Code}</strong>
                    </span>
                  );
                }}
                style={{ width: '100%' }}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Category Key"
                    error={values?.CategoryKey ? false : true}
                    helperText={values?.CategoryKey ? '' : 'Category Key is required'}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                //fullWidth
                style={{ width: '100%' }}
                type="text"
                value={values.Description}
                onChange={e => handleChange('Description', e.target.value)}
                label="Description"
                //error={error}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                //fullWidth
                style={{ width: '100%' }}
                type="text"
                value={values.Description2}
                onChange={e => handleChange('Description2', e.target.value)}
                label="Description2"
                //error={error}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                //fullWidth
                style={{ width: '100%' }}
                type="text"
                value={values.Description3}
                onChange={e => handleChange('Description3', e.target.value)}
                label="Description3"
                //error={error}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.IsDefault}
                    onChange={e => handleChange('IsDefault', e.target.checked)}
                  />
                }
                label="Default"
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.IsActive}
                    onChange={e => handleChange('IsActive', e.target.checked)}
                  />
                }
                label="Active"
              />
            </Grid>
          </Grid>
        </>
      </DialogContent>
      <DialogActions>
        <div className=" mb-1 mr-3">{renderActions()}</div>
      </DialogActions>
    </Dialog>
  );
}
const mapStateToProps = state => {
  return {
    dictionary: state.dictionary,
    wsClient: state.websocket,
    user: state.user,
  };
};
export default connect(mapStateToProps, {
  showSpinner,
  hideSpinner,
  handleError,
  notify,
  closeCodeCategories,
})(AddEditCodeCategoriesDialog);
