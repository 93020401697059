import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import UnitManagement from './UnitManagement';

const useStyles = makeStyles(theme => ({
  wrap: {
    padding: theme.spacing(2),
    '& > h5': {
      marginBottom: 20,
    },
  },
  form: {
    margin: '0 -4px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexGrow: 1,
  },
  textField: {
    margin: '0 4px 8px',
  },
  autocomplete: {
    margin: '0 4px 12px',
    maxWidth: 400,
    flexBasis: 400,
    minWidth: 400,
    flexGrow: 1,
  },
}));

const options = ['Person', 'Vehicle'];

function AssignToUnits(props) {
  const classes = useStyles();
  const [view, setView] = useState('Person');

  const onSelect = (ev, selection) => {
    setView(selection);
  };

  const renderForm = () => {
    return (
      <div className={classes.form}>
        <Autocomplete
          fullWidth
          options={options}
          value={view}
          className={classes.autocomplete}
          size="small"
          onChange={onSelect}
          renderInput={params => <TextField {...params} label="Resource Type" variant="outlined" />}
        />
      </div>
    );
  };

  return (
    <div className={classes.wrap}>
      {renderForm()}
      <UnitManagement assignment={view} />
      {/* {view === 'Person' && <Person />}
      {view === 'Vehicle' && <Vehicle />} */}
    </div>
  );
}

export default AssignToUnits;
