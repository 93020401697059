import { Subject } from 'rxjs';
import store from '../config/configureStore';

const ADD_KEYBOARD_EVENTS = 'ADD_KEYBOARD_EVENTS';
const DEL_KEYBOARD_EVENTS = 'DEL_KEYBOARD_EVENTS';
const usedKeys = [
  'F2', 'F3', 'F4', 'F5', 'F6', 'F7', 'F8', 'F9', 'F11', 'F12',
  'Ctrl+F8', 'Ctrl+KeyD', 'Ctrl+KeyE', 'Ctrl+KeyL', 'Ctrl+KeyM', 'Ctrl+KeyU', 'Ctrl+KeyP',
  'Ctrl+Space', 'Ctrl+Shift+Digit3',
  'Ctrl+Alt+KeyA', 'Ctrl+Alt+KeyC', 'Ctrl+Alt+KeyM', 'Ctrl+Alt+KeyR'
];

const defaultState = {
  subject: new Subject(),
  close: new Subject(),
};

export const subscribeKeyboard = () => (dispatch) => {
  dispatch({ type: ADD_KEYBOARD_EVENTS });
}

export const unsubscribeKeyboard = () => (dispatch) => {
  dispatch({ type: DEL_KEYBOARD_EVENTS });
}

const getKeyCode = (ev) => {
  const { code, ctrlKey, shiftKey, altKey } = ev;
  let key = ctrlKey ? 'Ctrl+' : '';
  if (shiftKey) key += 'Shift+';
  if (altKey) key += 'Alt+';
  key += code;
  return key;
}

const keyDownListener = (ev) => {
  const keyCode = getKeyCode(ev);
  if (usedKeys.indexOf(keyCode) !== -1) ev.preventDefault();
}

export default function reducer(state = defaultState, action) {
  const keyUpListener = (ev) => {
    const keyCode = getKeyCode(ev);
    if (keyCode === 'Escape') {
      const globalState = store.store.getState();
      const dialogsOpen = Object.entries(globalState.dialogs)
        .filter(([key, val]) => val !== null)
        .map(([key, val]) => key);
      state.close.next(dialogsOpen);
    } else {
      state.subject.next(keyCode);
    }
  }
  switch (action.type) {
    case ADD_KEYBOARD_EVENTS:
      document.addEventListener('keydown', keyDownListener);
      document.addEventListener('keyup', keyUpListener);
      return state;
    case DEL_KEYBOARD_EVENTS:
      document.removeEventListener('keydown', keyDownListener);
      document.removeEventListener('keyup', keyUpListener);
      return state;
    default:
      return state;
  }
}
