import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Content from 'Settings/Content';

import { getService } from 'reducers/service';
import { handleError } from 'reducers/ErrorReducer';
import { notify } from 'reducers/NotifierReducer';
import PublishIcon from '@material-ui/icons/Publish';
import { gridStyle } from 'utils/formStyles';
import { XGrid } from '@material-ui/x-grid';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  TextField,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import QuickFilter from 'components/QuickFilter';
import { Autocomplete } from '@material-ui/lab';
import { UpdateRotationUnit, addNewUnitOnRotation, removeUnitFromRotation } from './helperFunction';
const columnsItems = [
  { field: 'Unit', headerName: 'Unit', width: 150 },
  { field: 'id', headerName: 'Position', width: 150 },
  { field: 'ContactInfo', headerName: 'Contact Info', width: 150 },
];

const useStyles = makeStyles(theme => ({
  item: {
    width: '100%',
    marginTop: '10px',
  },
  list: {
    width: '100%',
    height: 500,
  },
  wrap: {
    padding: theme.spacing(2),
    '& > h5': {
      marginBottom: 20,
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: `400px`,
  },
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 0, 2),
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
}));

function UnitManagementForRotation(props) {
  const { wsClient, parentCode } = props;
  const classes = useStyles();
  const service = getService('cadrotationunits');
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [openAddUnitDialog, setOpenAddUnitDialog] = useState(false);
  const [refreshList, setRefreashList] = useState(false);
  const unitList = useSelector(state => state.dictionary.cadUnit);
  const [contactInfo, setContactInfo] = useState();
  const [filteredUnitList, setFilteredUnitList] = useState([]);
  const [dialogMode, setDialogMode] = useState('add');
  const dispatch = useDispatch();
  useEffect(() => {
    if (wsClient.websocket) {
      search();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsClient.websocket, refreshList]);
  useEffect(() => {
    if (unitList?.length > 0) {
      const filteredUnit = unitList.filter(
        unit => filteredRows.findIndex(item => item.Unit === unit.Unit) === -1
      );

      setFilteredUnitList(filteredUnit);
    }
  }, [unitList, unitList?.length, filteredRows, filteredRows?.length]);

  const search = async () => {
    service.timeout = 20000;
    try {
      const formDetails = await service.find({
        query: {
          $limit: 0,
          Rotation: parentCode,
          IsDeleted: 0,
          $sort: {
            Position: 1,
          },
        },
      });
      // console.log('formDetails', formDetails);
      const newList = formDetails.data.map((item, index) => {
        const unitName = unitList.find(unit => unit.ptsUnitID == item.ptsUnitID);
        return { ...item, Unit: unitName.Unit, id: index + 1 };
      });
      setRows(newList);
    } catch (error) {
      console.log(error);
    } finally {
      setLoaded(true);
    }
  };

  const handleSelectChange = ({ selectionModel }) => {
    setSelection(selectionModel);
  };
  const closeAddUnitDialog = () => {
    setOpenAddUnitDialog(false);
    setDialogMode('add');
    setContactInfo('');
    setSelectedUnit(null);
  };

  // unit related function
  const handleAddUnit = async () => {
    try {
      setLoaded(false);
      const Position = filteredRows.reduce((res, val) => Math.max(res, val.Position), 0) + 1;
      await addNewUnitOnRotation(parentCode, selectedUnit.ptsUnitID, Position, contactInfo);
      //   dispatch(notify('Unit added!', 'success'));
      setRefreashList(!refreshList);
    } catch (error) {
      dispatch(handleError(error, 'Error Adding Unit'));
    } finally {
      setLoaded(true);
      closeAddUnitDialog();
    }
  };

  const handleUpdateUnitPosition = async () => {
    try {
      const selectedRotation = rows.find(item => item.id == selection[0]);
      const { Rotation, ptsUnitID } = selectedRotation || {};
      const position = filteredRows.reduce((res, val) => Math.min(res, val.Position), 999999);

      await UpdateRotationUnit(Rotation, ptsUnitID, position);
      //   dispatch(notify('Unit added!', 'success'));
      setRefreashList(!refreshList);
    } catch (error) {
      dispatch(handleError(error, 'Error Updating Unit Position'));
    } finally {
      setLoaded(true);
      closeAddUnitDialog();
    }
  };

  const handleEditContactInfo = async () => {
    const { Rotation, ptsUnitID } = selectedUnit || {};
    if (!Rotation || !ptsUnitID) return;
    try {
      await UpdateRotationUnit(Rotation, ptsUnitID, null, contactInfo);
      //   dispatch(notify('Unit added!', 'success'));
      setRefreashList(!refreshList);
    } catch (error) {
      dispatch(handleError(error, 'Error Updating Contact Info'));
    } finally {
      setLoaded(true);
      closeAddUnitDialog();
    }
  };
  const onClickDelete = async () => {
    try {
      setLoaded(false);
      const selectedRotation = rows.find(item => item.id == selection[0]);
      const { Rotation, ptsUnitID } = selectedRotation || {};
      await removeUnitFromRotation(Rotation, ptsUnitID);

      setRefreashList(!refreshList);
    } catch (error) {
      dispatch(handleError(error, 'Error removing Unit'));
    } finally {
      setLoaded(true);
    }
  };

  const handleEditButtonClick = () => {
    const selectedRotation = rows.find(item => item.id == selection[0]);
    setSelectedUnit(selectedRotation);

    setContactInfo(selectedRotation?.ContactInfo || '');
    setDialogMode('edit');
    setOpenAddUnitDialog(true);
  };

  // UI

  const renderActions = () => {
    const disabled = selection.length === 0;
    const selected = rows.find(a => a.id === selection[0]);
    //const dltDisabled = selected?.Icon === 0;
    return (
      <div className={classes.actions}>
        <div>
          <QuickFilter rows={rows} columns={columnsItems} setFilteredRows={setFilteredRows} />
        </div>
        <div>
          <Fab size="small" disabled={disabled} color="secondary" onClick={handleEditButtonClick}>
            <EditIcon />
          </Fab>
          <Fab size="small" color="secondary" onClick={() => setOpenAddUnitDialog(true)}>
            <AddIcon />
          </Fab>
          <Fab
            size="small"
            color="secondary"
            onClick={handleUpdateUnitPosition}
            disabled={disabled}>
            <PublishIcon />
          </Fab>

          <Fab size="small" color="secondary" onClick={onClickDelete} disabled={disabled}>
            <DeleteIcon />
          </Fab>
        </div>
      </div>
    );
  };

  return (
    <div style={{ width: '100%' }}>
      {renderActions()}
      <div className={classes.gridWrap}>
        <XGrid
          columns={columnsItems}
          rows={filteredRows}
          loading={!loaded}
          rowHeight={38}
          // checkboxSelection
          onSelectionModelChange={handleSelectChange}
        />
      </div>
      <Dialog
        open={openAddUnitDialog}
        maxWidth="sm"
        onClose={closeAddUnitDialog}
        fullWidth
        title="Add Unit">
        <DialogTitle>Add Unit </DialogTitle>
        <DialogContent style={{ height: '200px' }}>
          <Autocomplete
            fullWidth
            focus
            autoHighlight
            autoSelect
            disabled={dialogMode === 'edit'}
            options={filteredUnitList || []}
            value={selectedUnit || {}}
            getOptionLabel={option => option.Unit}
            size="small"
            getOptionSelected={(option, value) => option.ptsUnitID === value.ptsUnitID}
            onChange={(ev, val) => {
              setSelectedUnit(val);
            }}
            renderInput={params => (
              <TextField {...params} label="Units" autoFocus variant="outlined" />
            )}
            renderOption={option => {
              const { Unit, AgencyID } = option;
              return (
                <span>
                  <strong>{Unit}</strong>
                  {!!AgencyID && <> - {AgencyID}</>}
                </span>
              );
            }}
          />
          <TextField
            label="Contact Info"
            variant="outlined"
            onChange={e => setContactInfo(e.target.value)}
            value={contactInfo}
            size="small"
            className={classes.item}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!selectedUnit}
            variant="contained"
            color="primary"
            onClick={dialogMode === 'add' ? handleAddUnit : handleEditContactInfo}>
            Save
          </Button>
          <Button onClick={closeAddUnitDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    wsClient: state.websocket,
  };
};

export default connect(mapStateToProps, {
  handleError,
  notify,
})(UnitManagementForRotation);
