import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Dialog from 'components/Dialog';
import { Button, DialogContentText, makeStyles, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import { closeRemoveSecUser } from 'reducers/DialogsReducer';
import { deleteSearchUser } from 'Settings/Security/SecFunctions';
import { handleError } from 'reducers/ErrorReducer';
import { notify } from 'reducers/NotifierReducer';

const useStyles = makeStyles(theme => ({}));

const RemoveSecUserDialog = props => {
  const classes = useStyles();
  const { data } = props;

  const close = () => {
    props.closeRemoveSecUser();
  };

  const remove = async () => {
    if (data?.selectedUser) {
      try {
        close();
        await deleteSearchUser(data.selectedUser);
        await data.fetchUsers();
        data.setSelection(null);
        props.notify('User deleted', 'success');
      } catch (error) {
        console.log(error);
        props.handleError(error, 'Error Updating User');
      }
    }
  };

  const renderActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={remove}>
          <DeleteIcon /> Remove
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon />
          Close
        </Button>
      </>
    );
  };

  return (
    <Dialog toolbar title="Remove User" actions={renderActions()} maxWidth="md" fullWidth>
      <DialogContentText>
        Are you sure you want to remove user
        <div>
          <b>UserName: {data.userName}</b>
        </div>
      </DialogContentText>
    </Dialog>
  );
};
const mapStateToProps = state => {
  return {};
};
export default connect(mapStateToProps, { closeRemoveSecUser, handleError, notify })(
  RemoveSecUserDialog
);
