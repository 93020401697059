import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { handleError } from '../reducers/ErrorReducer';
import { makeStyles } from '@material-ui/core/styles';
import { XGrid } from '@material-ui/x-grid';
import settings from '../config/settings';
import CheckIcon from '@material-ui/icons/Check';
import TextField from '@material-ui/core/TextField';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '../components/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import { filterResults } from '../utils/functions';

import { showAddEventSubTypes, closeAddEventSubTypes } from '../reducers/DialogsReducer';
import { showSpinner, hideSpinner } from '../reducers/UiReducer';
import { formatDate } from '../utils/functions';
import Content from './Content';
import { getService } from 'reducers/service';
import { notify } from '../reducers/NotifierReducer';
import { displayDateTime } from 'reducers/TimeReducer';

const useStyles = makeStyles(theme => ({
  wrap: {
    background: theme.card.bg,
    height: `calc(100vh - ${settings.mainNavHeight}px)`,
    padding: theme.spacing(2),
    '& h5': {
      padding: '0.75em 0',
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: `calc(100vh - 200px)`,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    minHeight: theme.spacing(6),
    width: '100%',
  },
  filterWrap: {
    flexShrink: 1,
    flexBasis: 400,
  },
  filter: {
    width: '100%',
  },
  action: {
    flexGrow: 0,
    flexShrink: 0,
    width: 'auto',
    marginLeft: theme.spacing(1),
    textAlign: 'right',
  },
}));

const bool = params => (params.value ? <CheckIcon /> : <span></span>);

const columns = [
  { field: 'Code', headerName: 'Code', width: 150 },
  { field: 'Description', headerName: 'Description', width: 250 },
  { field: 'Category', headerName: 'Category', width: 150 },
  { field: 'IsDefault', headerName: 'Default', width: 150, renderCell: bool },
  { field: 'IsDeleted', headerName: 'Deleted', width: 150, renderCell: bool },
  { field: 'IsActive', headerName: 'Active', width: 150, renderCell: bool },
  { field: 'Flags', headerName: 'Flags', width: 100, renderCell: bool },
  { field: 'Code2', headerName: 'Code 2', width: 150 },

  { field: 'Description2', headerName: 'Description 2', width: 150 },
  { field: 'Code3', headerName: 'Code 3', width: 100 },
  { field: 'Description3', headerName: 'Description 3', width: 150 },
  { field: 'Code4', headerName: 'Code 4', width: 100 },
  { field: 'Description4', headerName: 'Description 4', width: 150 },
  { field: 'UpdatedBy', headerName: 'UpdatedBy', width: 150 },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 150,
    valueFormatter: params => displayDateTime(params.value),
    format: 'date',
  },
  { field: 'DeletedBy', headerName: 'Deleted By', width: 150 },
  { field: 'CreatedBy', headerName: 'Created By', width: 150 },
];

function EventSubTypes(props) {
  const classes = useStyles();
  const { addEventSubTypes, CallerType, ptsParentID, ParentType } = props;
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const service = getService('codecadsubtypes');
  const [loaded, setLoaded] = useState(false);
  const [selection, setSelection] = useState(null);
  const [filter, setFilter] = useState('');
  useEffect(() => {
    search();
    // eslint-disable-next-line
  }, [addEventSubTypes]);
  const xgridRef = useRef();

  useEffect(() => {
    if (!filter) {
      setFilteredRows(rows);
    } else {
      setFilteredRows(filterResults(filter, rows, columns));
    }
    // eslint-disable-next-line
  }, [filter, rows]);

  const search = async () => {
    setLoaded(false);
    try {
      const res = await service.find({
        query: {
          ptsParentID: ptsParentID,
          $limit: 0,
          IsDeleted: false,
        },
      });
      setRows(res.data);
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoaded(true);
    }
  };

  const quickFilter = ev => {
    const val = ev.target.value;
    setFilter(val);
  };

  const handleDelete = async () => {
    if (!window.confirm('Do you want to delete this event sub type?')) return;
    try {
      await service.remove(selection);

      const newRows = rows.filter(el => el.Code !== selection);
      setRows(newRows);
      props.notify('Deleted', 'warning');
      setSelection([]);
    } catch (err) {
      props.handleError(err, 'Error deleting event sub type');
    }
  };

  const handleAdd = () => {
    props.showAddEventSubTypes({ data: { ptsParentID }, type: 'add' });
  };

  const handleEdit = () => {
    const obj = rows.find(row => row.Code === selection);
    props.showAddEventSubTypes({ data: obj, type: 'edit' });
  };

  const renderToolbar = () => {
    return (
      <div className={classes.toolbar}>
        <div className={classes.filterWrap}>
          <TextField
            label="Quick filter"
            type="search"
            className={classes.filter}
            variant="outlined"
            size="small"
            onChange={quickFilter}
            value={filter}
          />
        </div>
        <div className={classes.action}>
          <Tooltip title="Add">
            <IconButton onClick={handleAdd}>
              <AddIcon />
            </IconButton>
          </Tooltip>
          {selection !== null && (
            <>
              <Tooltip title="Edit">
                <IconButton onClick={handleEdit}>
                  <EditIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Delete">
                <IconButton onClick={handleDelete}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={ptsParentID ? '' : classes.wrap}>
      {renderToolbar()}
      <div
        className={classes.gridWrap}
        style={{
          height: `calc(100vh - 480px)`,
        }}>
        <XGrid
          columns={columns}
          rows={filteredRows}
          loading={!loaded}
          rowHeight={38}
          disableMultipleSelection
          getRowId={row => row.Code}
          onSelectionModelChange={newSelection => {
            setSelection(newSelection.selectionModel[0]);
          }}
        />
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    addEventSubTypes: state.dialogs.addEventSubTypes,
  };
};

export default connect(mapStateToProps, {
  showSpinner,
  hideSpinner,
  handleError,
  notify,
  showAddEventSubTypes,
  closeAddEventSubTypes,
})(EventSubTypes);
