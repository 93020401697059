export function getExampleID(row) {
  let ex = '';
  if (row) {
    const list = getOrderList(row);

    const separator = row.Separator;
    const UseSeparator = row.UseSeparator;
    const prefix = row.Prefix;
    const sequenceLength = row.SequenceLength;
    const suffix = row.Suffix;
    const monthOrder = row.MonthOrder;
    const dayOrder = row.DayOrder;
    const yearOrder = row.YearOrder;
    list.forEach((item, index) => {
      if (item.name === 'Prefix') {
        if (separator && UseSeparator && prefix && index !== 0) ex += separator;
        if (prefix) ex += prefix;
      } else if (item.name === 'Sequence') {
        if (separator && UseSeparator && index !== 0) ex += separator;
        for (let i = 0; i < sequenceLength; i++) {
          ex += 'S';
        }
      } else if (item.name === 'Month') {
        if (separator && UseSeparator && monthOrder !== 0 && index !== 0) ex += separator;
        if (monthOrder !== 0) ex += 'MM';
      } else if (item.name === 'Day') {
        if (separator && UseSeparator && dayOrder !== 0 && index !== 0) ex += separator;
        if (dayOrder !== 0) ex += 'DD';
      } else if (item.name === 'Year') {
        if (separator && UseSeparator && yearOrder !== 0 && index !== 0) ex += separator;
        if (yearOrder !== 0) {
          if (row.UseFourDigitYear) ex += 'YYYY';
          else ex += 'YY';
        }
      } else if (item.name === 'Suffix') {
        if (separator && UseSeparator && suffix && index !== 0) ex += separator;
        if (suffix) ex += suffix;
      }
    });
  }
  return ex;
}
export const getOrderList = values => {
  const { SequenceOrder, YearOrder, DayOrder, MonthOrder, SuffixOrder, PrefixOrder } = values;
  const list = [
    {
      name: 'Prefix',
      key: 'PrefixOrder',
      value: PrefixOrder,
    },
    {
      key: 'SequenceOrder',
      name: 'Sequence',
      value: SequenceOrder,
    },
    {
      key: 'MonthOrder',
      name: 'Month',
      value: MonthOrder,
    },
    {
      key: 'DayOrder',
      name: 'Day',
      value: DayOrder,
    },
    {
      key: 'YearOrder',
      name: 'Year',
      value: YearOrder,
    },
    {
      key: 'SuffixOrder',
      name: 'Suffix',
      value: SuffixOrder,
    },
  ];
  const filteredList = list.filter(item => item.value !== 0);
  const sortedList = filteredList.sort((a, b) => a.value - b.value);
  const putVaidNumber = sortedList.map((item, index) => {
    item.value = index + 1;
    return item;
  });
  return putVaidNumber;
};
