import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { handleError } from '../../reducers/ErrorReducer';
import { showSpinner, hideSpinner } from '../../reducers/UiReducer';
import { notify, closeSnackbar } from '../../reducers/NotifierReducer';
import Tooltip from '../../components/Tooltip';
import { removeAllESNs } from '../../reducers/EsnReducer';
import DeleteIcon from '@material-ui/icons/Delete';
import { zonesChanged } from '../../reducers/DataChangeReducer';
import { Alert, AlertTitle } from '@material-ui/lab';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import { validateGeofence } from 'reducers/ZonesReducer';
import { getESNs } from '../../reducers/EsnReducer';
import { pathToStr } from 'utils/mapFunctions';
import { asyncForEach } from 'utils/functions';

const useStyles = makeStyles(theme => ({
  form: {
    margin: `0 -${theme.spacing(0.5)}px`,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  btn: {
    margin: `-${theme.spacing(1)}px ${theme.spacing(0.5)}px 0`,
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
}));

function ImportEsnsOther(props) {
  const [errors, setErrors] = useState([]);
  const classes = useStyles();

  const deleteAll = async () => {
    if (!window.confirm(`Are you sure you want to remove all ESN\'s?`)) return;
    props.showSpinner();
    try {
      await removeAllESNs();
      props.notify(`All ESN\'s deleted.`);
    } catch (err) {
      props.handleError(err, "Error deleting ESN's.");
    }
    props.hideSpinner();
    props.zonesChanged();
  };

  const validatePolygons = async () => {
    const errors = [];
    props.showSpinner();
    try {
      const esns = await getESNs();
      const esnsWithPaths = esns.filter(e => e.path && e.path.length > 0);
      await asyncForEach(esnsWithPaths, async esn => {
        const isOk = await validateGeofence(pathToStr(esn.path));
        if (!isOk) {
          errors.push(
            `Error, Invalid geofence ${esn.ESN}. Check for possible polygon intersections.`
          );
        }
      });
    } catch (err) {
      console.log(err);
      errors.push('Error processing the request');
    }
    props.hideSpinner();
    if (errors.length) {
      props.notify('There were ESNs valildation errors', 'error');
    } else {
      props.notify('No errors in ESN geofences found', 'success');
    }
    setErrors(errors);
  };

  return (
    <>
      <div className={classes.form}>
        <Tooltip title="Delete zones and geofences">
          <span className={classes.btn}>
            <Button color="primary" onClick={deleteAll} variant="outlined">
              <DeleteIcon />
              Delete All ESNs
            </Button>
          </span>
        </Tooltip>
        <Tooltip title="Test ESN geofences for intersections">
          <span className={classes.btn}>
            <Button color="primary" onClick={validatePolygons} variant="outlined">
              <LabelImportantIcon />
              Validate ESN Polygons
            </Button>
          </span>
        </Tooltip>
      </div>
      <br />
      {errors.length > 0 && (
        <Alert severity="error" className={classes.error}>
          <AlertTitle>Error processing the file</AlertTitle>
          {errors.map((err, idx) => (
            <p key={idx}>{err}</p>
          ))}
        </Alert>
      )}
    </>
  );
}

export default connect(null, {
  notify,
  showSpinner,
  hideSpinner,
  handleError,
  closeSnackbar,
  zonesChanged,
})(ImportEsnsOther);
