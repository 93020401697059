import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import CustomAutocomplete from '../../components/CustomAutocomplete';
import { handleError } from '../../reducers/ErrorReducer';
import { showSpinner, hideSpinner } from '../../reducers/UiReducer';
import { notify } from '../../reducers/NotifierReducer';
import Tooltip from '../../components/Tooltip';
import { asyncForEach } from '../../utils/functions';
import { zonesChanged } from '../../reducers/DataChangeReducer';
import { saveZone, getZones } from '../../reducers/ZonesReducer';
import PublishIcon from '@material-ui/icons/Publish';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { removeDuplicatePoints } from 'utils/mapFunctions';
import { Alert, AlertTitle } from '@material-ui/lab';

function getFormStyle(minWidth, maxWidth) {
  return {
    maxWidth: maxWidth,
    flexBasis: minWidth,
    minWidth: minWidth,
    flexGrow: 1,
    margin: `0 4px 12px`,
  };
}

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
  },
  form: {
    margin: `0 -${theme.spacing(0.5)}px`,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginBottom: 16,
  },
  warning: {
    marginBottom: theme.spacing(1),
  },
  error: {
    marginBottom: theme.spacing(1),
  },
  textField: {
    ...getFormStyle(200, 200),
  },
  importBtn: {
    margin: `0 ${theme.spacing(0.5)}px`,
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}));

function RestoreZones(props) {
  const classes = useStyles();
  const { dictionary } = props;
  const [errors, setErrors] = useState([]);
  const [fileLoaded, setFileLoaded] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loadedData, setLoadedData] = useState(null);
  const [zones, setZones] = useState([]);
  const [availAgencies, setAvailAgencies] = useState([]);
  const [targetAgency, setTargetAgency] = useState(null);
  const [sourceAgency, setSourceAgency] = useState(null);
  const [loadedAgencies, setLoadedAgencies] = useState([]);
  const backupFileRef = useRef();
  const validFile = selectedFile?.type === 'application/json';
  const fileName = selectedFile?.name || null;

  useEffect(() => {
    loadZones();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!dictionary) return;
    const agencies = dictionary.Agencies.map(d => ({ ...d, Code: d.AgencyID }));
    setAvailAgencies(agencies);
    // eslint-disable-next-line
  }, [dictionary]);

  const loadZones = async () => {
    try {
      const agencies = [{ Code: 'All' }];
      const zones = await getZones();
      zones.forEach(z => {
        if (agencies.findIndex(el => el.Code === z.AgencyID) === -1)
          agencies.push({ Code: z.AgencyID });
      });
      setZones(zones);
    } catch (err) {
      props.handleError(err);
    }
  };

  const handleChangeBackupFile = ev => {
    const file = ev.target.files[0];
    setSelectedFile(file);
    if (!file || file.type !== 'application/json') return;
    handleBackupLoad(file);
  };

  const handleBackupLoad = file => {
    const reader = new FileReader();
    reader.onload = ev => {
      processBackupFile(ev.target.result);
    };
    reader.onerror = error => {
      props.notify('Error, file not loaded', 'error');
    };
    reader.readAsText(file);
  };

  const processBackupFile = async strData => {
    let errors = false;
    let data;
    try {
      data = JSON.parse(strData);
      data.forEach(row => {
        if (!row.ZoneCode || !row.AgencyID) errors = true;
      });
    } catch (err) {
      errors = true;
    }
    if (errors) {
      if (window.confirm('Some zones contain errors. Continue loading only valid zones?')) {
        data = data.filter(z => z.ZoneCode && z.AgencyID);
        errors = false;
      }
    }
    if (!errors && data.length > 0) {
      setFileLoaded(true);
      setLoadedData(data);
      const agencies = data
        .map(z => z.AgencyID)
        .reduce((res, AgencyID) => (res.indexOf(AgencyID) === -1 ? [...res, AgencyID] : res), [])
        .map(AgencyID => ({ Code: AgencyID }));
      setLoadedAgencies(agencies);
      if (agencies.length === 1) {
        setSourceAgency(agencies[0]);
        const AgencyID = agencies[0].Code;
        const targetAgency = availAgencies.find(a => a.Code === AgencyID);
        if (!targetAgency) return;
        setTargetAgency(targetAgency);
      }
    } else if (errors) {
      props.notify('Processing file error', 'error');
    } else {
      props.notify('Backup file contains no data.', 'warning');
    }
  };

  const importData = async () => {
    const sourceAgencyID = sourceAgency.Code;
    const tartegAgencyID = targetAgency.Code;
    const zones = loadedData
      .filter(z => z.AgencyID === sourceAgencyID)
      .map(p => {
        const newPath = removeDuplicatePoints(p.path);
        return { ...p, path: newPath };
      })
      .map(z => {
        if (!z.path || !z.path.length)
          return { ...z, AgencyID: tartegAgencyID, Code: 'Zone', SurfaceText: null };
        const SurfaceText = z.path.reduce((res, p, idx) => {
          let out = idx ? res + ', ' : res;
          out += `${p.lng} ${p.lat}`;
          return out;
        }, '');
        return { ...z, AgencyID: tartegAgencyID, Code: 'Zone', SurfaceText };
      })
      .map(z => {
        if (!z.SurfaceText) return z;
        return { ...z, SurfaceText: z.SurfaceText + `, ${z.path[0].lng} ${z.path[0].lat}` };
      });

    props.showSpinner();
    const errors = [];
    try {
      await asyncForEach(zones, async zone => {
        const result = await saveZone(zone);
        if (!result.valid) {
          errors.push(
            `Error, Invalid geofence ${zone.ZoneCode} from ${zone.AgencyID}. Check for possible polygon intersections.`
          );
        }
      });
      props.notify('Zones imported', 'success');
      props.zonesChanged();
      loadZones();
    } catch (err) {
      props.handleError(err);
    }
    setErrors(errors);
    props.hideSpinner();
  };

  const onSourceChange = val => {
    if (val) {
      const AgencyID = val.Code;
      const existingAgency = availAgencies.find(a => a.AgencyID === AgencyID);
      if (existingAgency) setTargetAgency(existingAgency);
    }
    setSourceAgency(val);
  };

  const getImportDisabled = () =>
    !sourceAgency ||
    !targetAgency ||
    zones.filter(z => z.AgencyID === targetAgency.Code).length > 0;

  return (
    <div>
      <div className={classes.form}>
        <Tooltip title="Restore geofences">
          <div className={classes.importBtn}>
            <input
              accept=".json"
              className={classes.input}
              id="import-zone-backup-file"
              multiple
              type="file"
              onChange={handleChangeBackupFile}
              ref={backupFileRef}
            />
            <label htmlFor="import-zone-backup-file">
              <Button color="primary" component="span" variant="contained">
                <PublishIcon />
                Choose Backup File
              </Button>
            </label>
          </div>
        </Tooltip>
      </div>
      {Boolean(fileName) && (
        <p>
          {fileName} {!validFile && <span style={{ color: 'red' }}>Invalid file type!</span>}
        </p>
      )}
      {fileLoaded && (
        <div style={{ marginTop: 16 }} className={classes.row}>
          <CustomAutocomplete
            className={classes.textField}
            label="Source Agency"
            options={loadedAgencies}
            value={sourceAgency}
            onChange={onSourceChange}
          />
          <CustomAutocomplete
            className={classes.textField}
            label="Destination Agency"
            options={availAgencies}
            value={targetAgency}
            onChange={setTargetAgency}
          />
          <div>
            <Button
              color="primary"
              disabled={getImportDisabled()}
              variant="contained"
              onClick={importData}>
              <ImportExportIcon />
              Import
            </Button>
          </div>
        </div>
      )}
      {errors.length > 0 && (
        <Alert severity="error" className={classes.error}>
          <AlertTitle>Error processing the file</AlertTitle>
          {errors.map((err, idx) => (
            <p key={idx}>{err}</p>
          ))}
        </Alert>
      )}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    dictionary: state.dictionary,
  };
};

export default connect(mapStateToProps, {
  notify,
  showSpinner,
  hideSpinner,
  handleError,
  zonesChanged,
})(RestoreZones);
