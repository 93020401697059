import { getService } from './service';
import { handleError } from './ErrorReducer';

const GET_DICTIONARY = 'GET_DICTIONARY';

export const getDictionary = () => {
  return async dispatch => {
    try {
      const service = getService('dictionary');
      const data = await service.find({
        query: {
          options: 'settings',
        },
      });

      dispatch({ type: GET_DICTIONARY, payload: data.dictionary });
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

export default function reducer(state = {}, action) {
  switch (action.type) {
    case GET_DICTIONARY:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
