import React, { useState, useRef, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

import { handleError } from '../reducers/ErrorReducer';
import { showSpinner, hideSpinner } from '../reducers/UiReducer';
import { notify } from '../reducers/NotifierReducer';
import { closeNIBRSOffence } from '../reducers/DialogsReducer';
import { Dialog, DialogActions, DialogTitle, Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { DialogContent } from '@mui/material';
import { getService } from 'reducers/service';
import DatePicker2 from 'components/DatePicker2';
import { processPhoneNoWithoutExtra } from 'utils/functions';
import Checkbox2 from 'components/Checkbox2';
import Dictionary from 'components/Dictionary';

const useStyles = makeStyles(theme => ({
  actions: {
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  textField: {
    width: 600,
    maxWidth: '100%',
    marginTop: theme.spacing(1),
  },
  dialog: {
    '& $content': {
      margin: 0,
      padding: 0,
      background: 'red !important',
    },
  },
}));
const formFields = [
  { name: 'FBIGroupCode', Description: 'Group Code', type: 'select', options: 'NIBRSGroupCode' },

  {
    name: 'CrimeAgainst',
    Description: 'Crime Against',
    type: 'select',
    options: 'codeNIBRSCrimesAgainst',
  },
  { name: 'Description', Description: 'Description', type: 'text' },
  { name: 'State', Description: 'State', type: 'select', options: 'States' },
  { name: 'CargoTheft', Description: 'Cargo Theft', type: 'bool' },
  { name: 'OffenderSuspectedOfUsing', Description: 'Offender Suspected Of Using', type: 'bool' },
  { name: 'BiasMotivation', Description: 'Bias Motivation', type: 'bool' },
  { name: 'LocationType', Description: 'Location Type', type: 'bool' },
  { name: 'MethodOfEntry', Description: 'Method Of Entry', type: 'bool' },

  { name: 'TypeWeaponOrForceInvolved', Description: 'Type Weapon Or Force Involved', type: 'bool' },
  { name: 'PropertyLossType', Description: 'Property Required', type: 'bool' },
  {
    name: 'AggravatedAssaultOrHomicide',
    Description: 'Aggravated Assault Or Homicide',
    type: 'bool',
  },
  { name: 'JustifiableHomicide', Description: 'Justifiable Homicide', type: 'bool' },
  { name: 'InjuryType', Description: 'Injury Type', type: 'bool' },

  { name: 'StructureEntered', Description: 'Structure Entered', type: 'bool' },
  { name: 'IsActive', Description: 'IsActive', type: 'bool' },
  {
    name: 'CriminalActivityOrGangInfo',
    Description: 'Criminal Activity Or Gang Info',
    type: 'bool',
  },
  {
    name: 'DVReport',
    Description: 'Domestic Violence Report',
    type: 'bool',
  },
  {
    name: 'AllowedCriminalActivityOrGangInfo',
    Description: 'Allowed Criminal Activity Or GangInfo',
    type: 'select',
    options: 'CriminalActivities',
    multi: true,
    size: 6,
  },
];
function NIBRSOffenceCodeDialog(props) {
  const classes = useStyles();
  const { wsClient, user, data } = props;

  const username = user?.userData?.user?.Username;
  const codeCriminalActivities = useSelector(state => state.dictionary.CriminalActivities || []);
  const service = getService('ptsfbicode');
  const dictionary = useSelector(state => state.dictionary);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (data.ptsFBICodeID) {
      const form = {
        FBIGroupCode: getCodeObject(data.FBIGroupCode, 'NIBRSGroupCode') || null,
        CrimeAgainst: getCodeObject(data.CrimeAgainst, 'codeNIBRSCrimesAgainst') || null,
        Description: data.Description || null,
        State: data.State || null,
        IsActive: data.IsActive || false,
        CargoTheft: data.CargoTheft || false,
        OffenderSuspectedOfUsing: data.OffenderSuspectedOfUsing || false,
        BiasMotivation: data.BiasMotivation || false,
        LocationType: data.LocationType || false,
        MethodOfEntry: data.MethodOfEntry || false,
        CriminalActivityOrGangInfo: data.CriminalActivityOrGangInfo || false,
        TypeWeaponOrForceInvolved: data.TypeWeaponOrForceInvolved || false,
        PropertyLossType: data.PropertyLossType || false,
        AggravatedAssaultOrHomicide: data.AggravatedAssaultOrHomicide || false,
        JustifiableHomicide: data.JustifiableHomicide || false,
        InjuryType: data.InjuryType || false,
        AllowedCriminalActivityOrGangInfo:
          formatCriminalActivities(data.AllowedCriminalActivityOrGangInfo, 'get') || [],
        StructureEntered: data.StructureEntered || false,
        DVReport: data.DVReport || false,
      };

      setFormData(form);
    } else {
      setFormData({ IsActive: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getCodeObject = (code, name) => {
    const list = dictionary[name];

    return list.find(item => item.Code === code);
  };
  const handleFormChange = (name, data) => {
    const newForm = { ...formData };
    newForm[name] = data;
    setFormData({ ...newForm });
  };

  const save = async () => {
    props.showSpinner();
    try {
      //   await addEventNote(text, ptsEventID);
      const newForm = formatSaveData();
      if (data.ptsFBICodeID) {
        const id = data?.ptsFBICodeID;
        newForm['UpdatedBy'] = username;
        const updatedData = await service.patch(id, newForm);
        props.notify('Record Updated', 'success', updatedData);
      } else {
        newForm['CreatedBy'] = username;
        const addedData = await service.create(newForm);
        props.notify('New Record Created', 'success');
      }
    } catch (err) {
      props.handleError(err, 'Error, Record not saved.', err);
    }
    props.hideSpinner();
    close();
  };

  const formatSaveData = () => {
    return {
      ...formData,
      State: formData.State?.Code || null,
      FBIGroupCode: formData.FBIGroupCode?.Code || null,
      CrimeAgainst: formData.CrimeAgainst?.Code || null,
      AllowedCriminalActivityOrGangInfo: formatCriminalActivities(
        formData.AllowedCriminalActivityOrGangInfo,
        'send'
      ),
    };
  };
  const formatCriminalActivities = (data, type) => {
    if (type === 'get') {
      data = JSON.parse(data || '[]');
      if (data && Array.isArray(data)) {
        const newArr = data.map(item => {
          return { Code: item };
        });
        return newArr;
      }
      return [];
    } else {
      if (!data && !Array.isArray(data)) {
        return JSON.stringify([]);
      }
      const newArray = data.map(item => item.Code);
      return JSON.stringify(newArray);
    }
  };

  const close = () => {
    props.closeNIBRSOffence();
  };

  const renderActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={save}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  const renderTextField = item => {
    return (
      <Grid item sm={item.size || 3}>
        <TextField
          //fullWidth
          size="small"
          style={{ width: '100%' }}
          type="text"
          value={formData[item.name] || ''}
          label={item.Description}
          //error={error}
          variant="outlined"
          onChange={e => handleFormChange(item.name, e.target.value)}
        />
      </Grid>
    );
  };
  const renderCheckbox = item => {
    return (
      <Grid item sm={item.size || 3}>
        <Checkbox2
          checked={formData[item.name] || false}
          onChange={e => handleFormChange(item.name, e.target.checked)}
          label={item.Description}
        />
      </Grid>
    );
  };
  const renderDatepicker = item => {
    return (
      <DatePicker2
        onChange={(n, date) => handleFormChange(item.name, date)}
        label={item.Description}
        style={{ width: '100%' }}
        margin="none"
        value={formData[item.name] || null}
        className={classes.field}
      />
    );
  };
  const renderAutoComplete = item => {
    return (
      <Grid item sm={item.size || 3}>
        {item.multi ? (
          <Autocomplete
            key={item.name}
            multiple
            id={item.name}
            options={codeCriminalActivities || []}
            getOptionLabel={option => option.Code || ''}
            value={formData[item.name] || []}
            onChange={(_, newValue) => handleFormChange(item.name, newValue)}
            getOptionSelected={(option, value) => option.Code == value.Code}
            renderOption={option => (
              <span>
                <strong>{option.Code}</strong>
                {!!option.Description && <> - {option.Description}</>}
              </span>
            )}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label={`Select ${item.name}`}
                placeholder={`Select ${item.name}`}
              />
            )}
          />
        ) : (
          <Dictionary
            options={item.options}
            className={classes.item}
            onChange={(ev, val) => handleFormChange(item.name, val)}
            value={formData[item.name]}
            compact
            label={item.Description}
            multiple={item.multi || false}
          />
        )}
      </Grid>
    );
  };

  return (
    <Dialog
      onClose={close}
      title={'Add NIBRS Offense Code'}
      open={true}
      actions={renderActions()}
      //   className={classes.dialog}

      // toolbar={false}
      // draggable={false}
      fullWidth
      maxWidth="lg">
      <DialogTitle>{data?.ptsFBICodeID == 'edit' ? 'Edit' : 'Add'} NIBRS Offense Code</DialogTitle>
      <DialogContent className="py-2">
        <Grid container spacing={2} style={{ width: '100%' }}>
          {formFields.map(item => {
            switch (item.type) {
              case 'text':
                return renderTextField(item);
              case 'bool':
                return renderCheckbox(item);
              case 'select':
                return renderAutoComplete(item);
              case 'date':
                return renderDatepicker(item);
              default:
                return null;
            }
          })}
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className=" mb-1 mr-3">{renderActions()}</div>
      </DialogActions>
    </Dialog>
  );
}
const mapStateToProps = state => {
  return {
    dictionary: state.dictionary,
    wsClient: state.websocket,
    user: state.user,
  };
};
export default connect(mapStateToProps, {
  showSpinner,
  hideSpinner,
  handleError,
  notify,
  closeNIBRSOffence,
})(NIBRSOffenceCodeDialog);
