import React, { useEffect, useState } from 'react';
import Content from '../Content';
import { ColCard, Row, RowInner, formatSaveData } from 'utils/formStyles';
import FormDivider from 'components/FormDivider';
import { Button, TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import { getService } from 'reducers/service';
import SaveIcon from '@material-ui/icons/Save';
import { hideSpinner, showSpinner } from 'reducers/UiReducer';
import { handleError } from 'reducers/ErrorReducer';
import { notify } from 'reducers/NotifierReducer';

function EssentialLinks(props) {
  const cdrService = getService('cdr');

  const [ptsHelpEditMode, setPtsHelpEditMode] = useState(false);
  const [ptsTrainingEditMode, setPtsTrainingEditMode] = useState(false);
  const [ptsHelpLink, setPtsHelpLink] = useState(null);
  const [ptsTrainingLink, setPtsTrainingLink] = useState(null);
  useEffect(() => {
    if (props.wsClient.websocket) {
      searchHelpLink();
      searchTrainingLink();
    }
  }, [props.dictionary, props.wsClient]);
  const searchHelpLink = async () => {
    const data = await cdrService.get({
      Path: 'Settings.Link.Help',
      CDRKey: 'HelpLink',
    });

    if (data && data.length > 0) {
      setPtsHelpEditMode(true);
      setPtsHelpLink(data[0].ValueString || '');
    }
  };
  const searchTrainingLink = async () => {
    const data = await cdrService.get({
      Path: 'Settings.Link.Training',
      CDRKey: 'TrainingLink',
    });

    if (data && data.length > 0) {
      setPtsTrainingEditMode(true);
      setPtsTrainingLink(data[0].ValueString || '');
    }
  };
  const handleTrainingLinkSave = async () => {
    try {
      if (ptsTrainingEditMode) {
        await cdrService.patch(null, {
          Path: 'Settings.Link.Training',
          CDRKey: 'TrainingLink',
          ValueString: ptsTrainingLink,
        });
      } else {
        await cdrService.create({
          Path: 'Settings.Link.Training',
          CDRKey: 'TrainingLink',
          ValueString: ptsTrainingLink,
        });
      }
    } catch (err) {
      props.handleError(err, 'Error Saving Training Link.');
    }
  };
  const handleHelpLinkSave = async () => {
    try {
      if (ptsHelpEditMode) {
        await cdrService.patch(null, {
          Path: 'Settings.Link.Help',
          CDRKey: 'HelpLink',
          ValueString: ptsHelpLink,
        });
      } else {
        await cdrService.create({
          Path: 'Settings.Link.Help',
          CDRKey: 'HelpLink',
          ValueString: ptsHelpLink,
        });
      }
    } catch (err) {
      props.handleError(err, 'Error Saving Help Link.');
    }
  };

  const handleSaveClick = async () => {
    try {
      await handleHelpLinkSave();
      await handleTrainingLinkSave();

      props.notify('Links Saved.', 'success');
    } catch (err) {
      props.handleError(err, 'Error Saving.');
    }
  };

  //ui parts
  const renderPtsHelpLink = () => {
    return (
      <TextField
        label="Help Address Link"
        id="outlined-help-link"
        style={{ width: '100%', maxWidth: '800px' }}
        variant="outlined"
        size="small"
        type="text"
        value={ptsHelpLink || ''}
        onChange={e => {
          setPtsHelpLink(e.target.value || '');
        }}
      />
    );
  };
  const renderPtsTrainingLink = () => {
    return (
      <TextField
        label="Training Address Link"
        id="outlined-training-link"
        style={{ width: '100%', maxWidth: '800px' }}
        variant="outlined"
        size="small"
        type="text"
        value={ptsTrainingLink || ''}
        onChange={e => {
          setPtsTrainingLink(e.target.value || '');
        }}
      />
    );
  };
  const renderSaveBtn = () => {
    return (
      <div className="text-right pb-4">
        <Button onClick={handleSaveClick} variant="contained" color="primary">
          <SaveIcon className="mr-2" /> Save
        </Button>
      </div>
    );
  };
  return (
    <Content>
      <div className="m-4 mt-0">
        {renderSaveBtn()}

        <FormDivider title="PTS Help" />
        <RowInner>{renderPtsHelpLink()}</RowInner>
        <p className="mt-2"></p>
        <FormDivider title="PTS Training Academy" />
        <RowInner>{renderPtsTrainingLink()}</RowInner>
        <p className="mb-2"></p>
      </div>
    </Content>
  );
}
const mapStateToProps = state => {
  return {
    dictionary: state.dictionary,
    wsClient: state.websocket,
  };
};

export default connect(mapStateToProps, {
  handleError,
  notify,
})(EssentialLinks);
