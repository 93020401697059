
    import React from 'react';
    import SvgIcon from '@material-ui/core/SvgIcon';
    
    function icons8caraccident100Icon(props) {
      return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100" height="100"><path d="M24 2c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4zm13.354 3.006a2.5 2.5 0 0 0-2.006 1.223l-2.66 4.5-7.942 1.343c-.33.056-.66.143-.982.278a4.497 4.497 0 0 0-2.416 5.884l3.5 8.375a4.475 4.475 0 0 0 2.047 2.23c0 .004 8.78 4.352 8.78 4.352l2.567 5.99a3.002 3.002 0 0 0 3.94 1.577 3.002 3.002 0 0 0 1.576-3.94l-2.99-6.976a2.984 2.984 0 0 0-1.323-1.47L35.135 26l3.695-1.643 5.682 3.247a2.995 2.995 0 0 0 4.092-1.115 2.997 2.997 0 0 0-1.115-4.092s-7.19-4.104-7.305-4.153a2.995 2.995 0 0 0-2.164-.08l-6.098 2.033-1.545-3.699 4.451-1.064a2.508 2.508 0 0 0 1.574-1.16l3.25-5.5a2.498 2.498 0 0 0-2.299-3.768zM1 11v2h4.018c1.308 0 2.53.638 3.279 1.71L14.082 23H1v19h9.15c.699 3.416 3.733 6 7.35 6 3.617 0 6.651-2.584 7.35-6H27c2.206 0 4-1.794 4-4v-4.893l-.002-.002-5.041-2.5a6.512 6.512 0 0 1-2.953-3.222l-1.377-3.297-5.037-.988-6.652-9.532A6.002 6.002 0 0 0 5.018 11H1zm16.5 24c3.05 0 5.5 2.45 5.5 5.5S20.55 46 17.5 46 12 43.55 12 40.5s2.45-5.5 5.5-5.5z"/></SvgIcon>
      );
    }
  
    export default icons8caraccident100Icon;