import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function WaterIcon(props) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <path d="M 25.769531 0.535156 C 25.390625 0.0742188 24.609375 0.0742188 24.226563 0.535156 C 23.539063 1.371094 7.386719 21.078125 7.386719 32.382813 C 7.386719 42.097656 15.285156 50 25 50 C 34.714844 50 42.613281 42.097656 42.613281 32.386719 C 42.613281 21.078125 26.460938 1.371094 25.769531 0.535156 Z M 25.199219 12.742188 C 25.105469 12.863281 15.742188 25.035156 15.742188 32.191406 C 15.742188 41.214844 23.648438 42.453125 23.984375 42.5 C 24.53125 42.578125 24.910156 43.085938 24.832031 43.632813 C 24.761719 44.132813 24.335938 44.492188 23.84375 44.492188 C 23.796875 44.492188 23.75 44.488281 23.699219 44.480469 C 23.601563 44.464844 13.742188 42.925781 13.742188 32.191406 C 13.742188 24.355469 23.214844 12.039063 23.617188 11.515625 C 23.957031 11.078125 24.585938 11 25.019531 11.339844 C 25.457031 11.679688 25.539063 12.304688 25.199219 12.742188 Z" />
    </SvgIcon>
  );
}

export default WaterIcon;
