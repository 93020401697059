import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Content from '../Content';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { getService } from '../../reducers/service';
import { notify } from '../../reducers/NotifierReducer';

const useStyles = makeStyles(theme => ({}));

function VehiclesDuplicate(props) {
  const service = getService('settings-duplicate-checking-people');

  const [checkForDuplicates, setCheckForDuplicates] = useState(1);
  const [dob, setDob] = useState(0);
  const [firstName, setFirstName] = useState(0);
  const [middleName, setMiddleName] = useState(0);
  const [lastName, setLastName] = useState(0);
  const [nickName, setNickName] = useState(0);
  const [race, setRace] = useState(0);
  const [social, setSocial] = useState(0);
  const [prefix, setPrefix] = useState(0);
  const [suffix, setSuffix] = useState(0);
  const [sex, setSex] = useState(0);
  const [oln, setOln] = useState(0);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    service
      .find()
      .then(res => {
        handleFetchedData(res);
      })
      .catch(err => console.log(err));
  };

  const handleSaveClick = async () => {
    try {
      await service.create({
        Path: 'PTS.Person.DuplicatePerson.CheckForDuplicates',
        ValueBoolean: checkForDuplicates,
      });
      await service.create({
        Path: 'PTS.Person.DuplicatePerson.SearchParameters.DOB',
        ValueBoolean: dob,
      });
      await service.create({
        Path: 'PTS.Person.DuplicatePerson.SearchParameters.FirstName',
        ValueBoolean: firstName,
      });
      await service.create({
        Path: 'PTS.Person.DuplicatePerson.SearchParameters.MiddleName',
        ValueBoolean: middleName,
      });
      await service.create({
        Path: 'PTS.Person.DuplicatePerson.SearchParameters.Race',
        ValueBoolean: race,
      });
      await service.create({
        Path: 'PTS.Person.DuplicatePerson.SearchParameters.Social',
        ValueBoolean: social,
      });
      await service.create({
        Path: 'PTS.Person.DuplicatePerson.SearchParameters.Suffix',
        ValueBoolean: suffix,
      });
      await service.create({
        Path: 'PTS.Person.DuplicatePerson.SearchParameters.LastName',
        ValueBoolean: lastName,
      });
      await service.create({
        Path: 'PTS.Person.DuplicatePerson.SearchParameters.NickName',
        ValueBoolean: nickName,
      });
      await service.create({
        Path: 'PTS.Person.DuplicatePerson.SearchParameters.OLN',
        ValueBoolean: oln,
      });
      await service.create({
        Path: 'PTS.Person.DuplicatePerson.SearchParameters.Prefix',
        ValueBoolean: prefix,
      });
      await service.create({
        Path: 'PTS.Person.DuplicatePerson.SearchParameters.Sex',
        ValueBoolean: sex,
      });
      props.notify('Duplicate Settings Saved!', 'success');
    } catch (err) {
      console.log(err);
    }
  };

  const boolToBit = value => {
    return value === true ? 1 : 0;
  };

  const handleFetchedData = data => {
    data.forEach(d => {
      switch (d.Path) {
        case 'PTS.Person.DuplicatePerson.CheckForDuplicates':
          setCheckForDuplicates(boolToBit(d.ValueBoolean));
          break;
        case 'PTS.Person.DuplicatePerson.SearchParameters.DOB':
          setDob(boolToBit(d.ValueBoolean));
          break;
        case 'PTS.Person.DuplicatePerson.SearchParameters.FirstName':
          setFirstName(boolToBit(d.ValueBoolean));
          break;
        case 'PTS.Person.DuplicatePerson.SearchParameters.MiddleName':
          setMiddleName(boolToBit(d.ValueBoolean));
          break;
        case 'PTS.Person.DuplicatePerson.SearchParameters.Race':
          setRace(boolToBit(d.ValueBoolean));
          break;
        case 'PTS.Person.DuplicatePerson.SearchParameters.Social':
          setSocial(boolToBit(d.ValueBoolean));
          break;
        case 'PTS.Person.DuplicatePerson.SearchParameters.Suffix':
          setSuffix(boolToBit(d.ValueBoolean));
          break;
        case 'PTS.Person.DuplicatePerson.SearchParameters.LastName':
          setLastName(boolToBit(d.ValueBoolean));
          break;
        case 'PTS.Person.DuplicatePerson.SearchParameters.NickName':
          setNickName(boolToBit(d.ValueBoolean));
          break;
        case 'PTS.Person.DuplicatePerson.SearchParameters.OLN':
          setOln(boolToBit(d.ValueBoolean));
          break;
        case 'PTS.Person.DuplicatePerson.SearchParameters.Prefix':
          setPrefix(boolToBit(d.ValueBoolean));
          break;
        case 'PTS.Person.DuplicatePerson.SearchParameters.Sex':
          setSex(boolToBit(d.ValueBoolean));
          break;
        default:
          console.log('no setState available');
          break;
      }
    });
  };

  const toggle = num => {
    return num === 0 ? 1 : 0;
  };

  const isDisabled = () => {
    if (checkForDuplicates === 0) return false;
    let count = 0;
    if (dob === 1) count++;
    if (firstName === 1) count++;
    if (middleName === 1) count++;
    if (lastName === 1) count++;
    if (nickName === 1) count++;
    if (race === 1) count++;
    if (social === 1) count++;
    if (prefix === 1) count++;
    if (suffix === 1) count++;
    if (sex === 1) count++;
    if (oln === 1) count++;
    return count < 2;
  };

  return (
    <Content>
      <div className="ml-4 mt-4">
        <h4>People Duplicate Checking</h4>
        <div className="text-right pr-4">
          <Button
            onClick={handleSaveClick}
            variant="contained"
            color="primary"
            disabled={isDisabled()}>
            <SaveIcon /> Save
          </Button>
        </div>
        <div className="mt-4">
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkForDuplicates === 1}
                  onChange={() => setCheckForDuplicates(toggle(checkForDuplicates))}
                  name="Check"
                  color="primary"
                />
              }
              label="Check for Duplicates"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={firstName === 1}
                  onChange={() => setFirstName(toggle(firstName))}
                  name="firstName"
                  color="primary"
                  disabled={checkForDuplicates === 0}
                />
              }
              label="First Name"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={middleName === 1}
                  onChange={() => setMiddleName(toggle(middleName))}
                  name="MiddleName"
                  color="primary"
                  disabled={checkForDuplicates === 0}
                />
              }
              label="Middle Name"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={lastName === 1}
                  onChange={() => setLastName(toggle(lastName))}
                  name="LastName"
                  color="primary"
                  disabled={checkForDuplicates === 0}
                />
              }
              label="Last Name"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={prefix === 1}
                  onChange={() => setPrefix(toggle(prefix))}
                  name="Prefix"
                  color="primary"
                  disabled={checkForDuplicates === 0}
                />
              }
              label="Prefix"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={suffix === 1}
                  onChange={() => setSuffix(toggle(suffix))}
                  name="Suffix"
                  color="primary"
                  disabled={checkForDuplicates === 0}
                />
              }
              label="Suffix"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={nickName === 1}
                  onChange={() => setNickName(toggle(nickName))}
                  name="NickName"
                  color="primary"
                  disabled={checkForDuplicates === 0}
                />
              }
              label="Nick Name"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={dob === 1}
                  onChange={() => setDob(toggle(dob))}
                  name="dob"
                  color="primary"
                  disabled={checkForDuplicates === 0}
                />
              }
              label="Date of Birth"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={social === 1}
                  onChange={() => setSocial(toggle(social))}
                  name="Social"
                  color="primary"
                  disabled={checkForDuplicates === 0}
                />
              }
              label="Social Security Number"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={oln === 1}
                  onChange={() => setOln(toggle(oln))}
                  name="OLN"
                  color="primary"
                  disabled={checkForDuplicates === 0}
                />
              }
              label="OLN"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={race === 1}
                  onChange={() => setRace(toggle(race))}
                  name="Race"
                  color="primary"
                  disabled={checkForDuplicates === 0}
                />
              }
              label="Race"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={sex === 1}
                  onChange={() => setSex(toggle(sex))}
                  name="Sex"
                  color="primary"
                  disabled={checkForDuplicates === 0}
                />
              }
              label="Sex"
            />
          </div>
        </div>
      </div>
    </Content>
  );
}

export default connect(null, {
  notify,
})(VehiclesDuplicate);
