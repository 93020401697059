import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { handleError } from 'reducers/ErrorReducer';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextFieldPlus from './TextFieldPlus';
import { addSop } from 'reducers/DialogsReducer';
import store from 'config/configureStore';
import { TextField } from '@material-ui/core';

const getService = (serviceName = 'cad') => {
  let service;
  try {
    const state = store.store.getState();
    const client = state.websocket.websocket;
    service = client.service(serviceName);
    service.timeout = 20000;
  } catch (err) {
    throw new Error('Error, service not ready.');
  }
  return service;
};

const useStyles = makeStyles(theme => ({
  textField: {
    margin: '0 4px 8px',
  },
  activeWarrant: {
    background: theme.palette.error.main,
    color: theme.card.bg,
    width: 'calc(100% + 32px)',
    display: 'inline-block',
    padding: '8px 16px',
    margin: '-8px -16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 14,
    '& em': {
      fontStyle: 'normal',
      fontSize: 12,
    },
  },
  option: {
    fontSize: 14,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    '& em': {
      fontStyle: 'normal',
      fontSize: 12,
    },
  },
}));

function SOPsLookup(props) {
  const { onChange } = props;
  const classes = useStyles();
  const [searchMatch, setSearchMatch] = useState([]);
  const [Value, setValue] = useState(null);
  const label = props.label ? props.label : 'SOPs ID';
  const mountedRef = useRef(true);
  const throttleRef = useRef(0);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, []);

  const onSearchSelect = (ev, selection) => {
    setValue(selection);
    onChange && onChange(selection);
  };

  const getOptionSelected = (option, value) => {
    return option.ptsSOPID === value.ptsSOPID;
  };

  const onInputChange = (ev, val) => {
    clearTimeout(throttleRef.current);
    throttleRef.current = setTimeout(() => {
      if (val.length > 2) searchSOPs(val);
    }, 1000);
  };

  const searchSOPs = async search => {
    try {
      const service = getService('ptssops');
      const result = await service.find({
        query: {
          $or: [
            { SOPID: { $like: `%${search}%` } },
            { DispatcherMessage: { $like: `%${search}%` } },
          ],
        },
      });

      if (!mountedRef) return;
      if (result.data) {
        setSearchMatch(result.data);
      }
    } catch (err) {
      console.log('sop lookup error: ', err);
    }
  };

  const renderOption = option => {
    const { SOPID, DispatcherMessage } = option;

    return (
      <span className={classes.option}>
        <b>{SOPID ? SOPID + '' : ''}</b> {DispatcherMessage ? DispatcherMessage + ' ' : ''}
      </span>
    );
  };

  const renderLabel = option => {
    const { SOPID, DispatcherMessage } = option;
    let label = SOPID ? SOPID + ' - ' : '';
    label += DispatcherMessage ? DispatcherMessage + ' ' : '';

    return label;
  };

  return (
    <Autocomplete
      disabled={props.disabled}
      className={props.className}
      fullWidth
      autoHighlight
      options={searchMatch}
      value={Value}
      getOptionLabel={renderLabel}
      size="small"
      onChange={onSearchSelect}
      onInputChange={onInputChange}
      renderInput={params => {
        params.label = label;
        params.variant = 'outlined';
        params.onAdd = () => props.addSop({});
        return <TextField {...params} />;
      }}
      getOptionSelected={getOptionSelected}
      renderOption={renderOption}
    />
  );
}

const mapStateToProps = state => {
  return {
    dataUpdate: state.dataUpdate,
  };
};

export default connect(mapStateToProps, {
  handleError,
  addSop,
})(SOPsLookup);
