
    import React from 'react';
    import SvgIcon from '@material-ui/core/SvgIcon';
    
    function icons8policecar100Icon(props) {
      return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="100" height="100"><path d="M9 3a1 1 0 0 0-1 1v1.36a3.99 3.99 0 0 0-2.146 2.318l-2.06 5.88A4.004 4.004 0 0 0 2 16.905V24a2 2 0 1 0 4 0v-1.262S11.281 23 15 23c3.719 0 9-.262 9-.262V24a2 2 0 1 0 4 0v-7.096a4.008 4.008 0 0 0-1.795-3.345l-2.057-5.88A3.997 3.997 0 0 0 22 5.36V4a1 1 0 0 0-1-1h-4v2h-4V3H9zm.629 4H20.37c.85 0 1.608.538 1.889 1.34l1.144 3.273a.715.715 0 0 1-.787.948C20.657 12.244 18.135 12 15 12s-5.656.244-7.617.56a.715.715 0 0 1-.787-.947L7.74 8.34A2.002 2.002 0 0 1 9.63 7zM6.5 16a1.5 1.5 0 1 1-.001 3.001A1.5 1.5 0 0 1 6.5 16zm5.5 0h6a1 1 0 0 1 0 2h-6a1 1 0 0 1 0-2zm11.5 0a1.5 1.5 0 1 1-.001 3.001A1.5 1.5 0 0 1 23.5 16z"/></SvgIcon>
      );
    }
  
    export default icons8policecar100Icon;