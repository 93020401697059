import React, { useState, useEffect } from 'react';
import XGrid3 from 'components/XGrid3';
import { XGrid } from '@material-ui/x-grid';
import QuickFilter from 'components/QuickFilter';
import Content from 'Settings/Content';
import { makeStyles } from '@material-ui/core';
import { gridStyle } from 'utils/formStyles';
const useStyles = makeStyles(theme => ({
  item: {
    ...gridStyle(200, 800),
  },
  list: {
    width: '100%',
    height: 500,
  },
  wrap: {
    padding: theme.spacing(2),
    '& > h5': {
      marginBottom: 20,
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: `calc(100vh - 540px)`,
    minHeight: '350px !important',
  },
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0, 2),
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
}));

function SelectTargetDialog(props) {
  const classes = useStyles();
  const { sourceID, columns, setTargetID, targetID } = props;
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);

  // console.log('rows', rows);
  useEffect(() => {
    const rows = props.rows.filter(row => row.ptsCityID !== sourceID && !row.IsDeleted);
    setRows(rows);
    // eslint-disable-next-line
  }, []);
  const renderActions = () => {
    //const dltDisabled = selected?.Icon === 0;
    return (
      <div>
        <QuickFilter rows={rows} columns={columns} setFilteredRows={setFilteredRows} />
      </div>
    );
  };
  const handleSelectChange = ({ selectionModel }) => {
    setTargetID(selectionModel[0]);
  };
  return (
    // <Content>
    <div className="m-4 mb-1">
      {renderActions()}
      <div className={classes.gridWrap}>
        <XGrid
          columns={columns}
          rows={filteredRows}
          // loading={!loaded}
          rowHeight={38}
          getRowId={row => row.ptsCityID}
          // checkboxSelection

          onSelectionModelChange={handleSelectChange}
        />
      </div>
    </div>
    // </Content>
  );
}

export default SelectTargetDialog;
