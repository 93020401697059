import React from 'react';

function Child50(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      {...props}
      preserveAspectRatio="xMidYMid meet">
      <g
        transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none">
        <path d="M202 467 c-28 -30 -28 -64 0 -94 18 -19 19 -23 5 -23 -9 0 -46 -21 -82 -47 -49 -35 -65 -52 -63 -68 4 -30 33 -30 73 1 20 15 39 24 42 20 9 -9 -15 -74 -40 -109 -16 -24 -18 -32 -8 -51 16 -29 69 -86 81 -86 20 0 20 22 0 55 -29 47 -14 67 43 63 54 -4 67 -26 37 -65 -21 -29 -21 -53 0 -53 12 0 65 57 81 86 10 19 8 27 -8 51 -25 35 -49 100 -40 109 3 4 22 -5 42 -20 40 -31 69 -31 73 -1 2 15 -14 33 -62 68 -35 26 -72 47 -82 47 -16 0 -15 2 4 23 28 30 28 68 -1 95 -30 28 -68 28 -95 -1z m112 -274 c4 -9 1 -20 -6 -25 -48 -31 -137 -13 -122 25 5 13 19 17 64 17 45 0 59 -4 64 -17z" />
      </g>
    </svg>
  );
}

export default Child50;
