export default {
  mainNavHeight: 54,
  reqThrottlingTime: 150, // [ms] some requests like get events are throttled
  synchronizeData: false, // update events, units ... after some chenges been made
  serviceTimeout: 20000,
  maxTabHeight: 300,      //
  geofenceTypes: [
    { Code: 'Zone', Description: 'Event Zones', geofenceCode: 'zone' },
    { Code: 'ESN',  Description: 'Emergency Service Number', geofenceCode: 'esn' }
  ],
  CapabilityCategories: [ 'Personal', 'Vehicle', 'Item', 'Unit' ]
}
