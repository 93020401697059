import React from 'react';
import { connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { closePlaceAddressEdit } from 'reducers/DialogsReducer';
import Dialog from 'components/Dialog';
import AddressEdit from 'Dialogs/AddressEdit';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';

const useStyles = makeStyles(theme => ({}));

function EditPlaceAddressDialog(props) {
  const classes = useStyles();
  const { ptsAddressID, IsPrimary, ptsPlaceID } = props;

  const close = () => {
    props.notifyDataUpdate({ type: 'edit-place-address' });
    props.closePlaceAddressEdit();
  };

  const renderActions = () => {
    return (
      <>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  return (
    <Dialog toolbar onClose={close} title="Address Edit" actions={renderActions()}>
      <AddressEdit ptsAddressID={ptsAddressID} IsPrimary={IsPrimary} ptsPlaceID={ptsPlaceID} />
    </Dialog>
  );
}

export default connect(null, { closePlaceAddressEdit, notifyDataUpdate })(EditPlaceAddressDialog);
