import { getService } from 'reducers/service';

export const addNewUnitOnRotation = async (Rotation, ptsUnitID, Position, ContactInfo) => {
  const service = getService('cadrotationunits');
  const customServie = getService('rotation-setup');
  const isDeleteRecordExist = await service.find({ query: { Rotation, ptsUnitID } });
  // if previouslt this code was deleteded then just need to update
  if (isDeleteRecordExist?.data[0] && isDeleteRecordExist.data[0].IsDeleted == true) {
    const data = {
      DeletedIP: null,
      DeletedBy: null,
      Deleted: null,
      IsDeleted: false,
      ContactInfo,
      Position: Position,
    };
    await customServie.patch(null, data, {
      query: {
        Rotation,
        ptsUnitID,
        undoDelete: true,
      },
    });

    return;
  }

  const data = {
    Rotation,
    ptsUnitID,
    Position: 0,
    ContactInfo,
  };
  return service.create(data);
};

export const UpdateRotationUnit = async (Rotation, ptsUnitID, Position, ContactInfo) => {
  const customServie = getService('rotation-setup');

  let data = {};
  if (Position) {
    data = {
      ...data,
      Position,
    };
  }
  if (ContactInfo || ContactInfo === '') {
    data = { ...data, ContactInfo };
  }

  return customServie.patch(null, data, {
    query: {
      Rotation,
      ptsUnitID,
    },
  });
};
export const removeUnitFromRotation = (Rotation, ptsUnitID) => {
  if (!Rotation || !ptsUnitID) return;
  const service = getService('cadrotationunits');

  return service.remove(null, {
    query: {
      Rotation,
      ptsUnitID,
    },
  });
};
