import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { formatDate, tableSort, getTableComparator } from '../../utils/functions';
import { updateGroups, removeGroup } from '../../reducers/PagingReducer';
import { showSpinner, hideSpinner } from '../../reducers/UiReducer';
import { handleError } from '../../reducers/ErrorReducer';
import { editPageGroup, closeEditPageGroup } from '../../reducers/DialogsReducer';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 750,
    '& td': {
      paddingTop: 2,
      paddingBottom: 2,
    },
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: `0 ${theme.spacing(2)}px`,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  actions: {
    '& button': {
      opacity: 0,
      transition: 'opacity 0.3s',
    },
  },
  row: {
    '&:hover': {
      '& $actions': {
        '& button': {
          opacity: 1,
        },
      },
    },
  },
}));

const headCells = [
  { id: 'Label', numeric: false, disablePadding: false, label: 'Group'  },
  { id: 'Created',   numeric: false, disablePadding: false, label: 'Created'    },
  { id: 'CreatedBy', numeric: false, disablePadding: false, label: 'Created By' },
  { id: 'Updated',   numeric: false, disablePadding: false, label: 'Updated'    },
  { id: 'UpdatedBy', numeric: false, disablePadding: false, label: 'Updated By' },
];

function CustomTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="center">Actions</TableCell>
      </TableRow>
    </TableHead>
  );
}

function Groups(props) {
  const classes = useStyles();
  const { groups } = props;
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    props.updateGroups();
    // eslint-disable-next-line
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, groups.length - page * rowsPerPage);

  const addGroup = () => {
    props.editPageGroup(true);
  }

  const delGroup = (group) => {
    if (!window.confirm(`Are you sure you want to remove group ${group.Label}?`)) return;
    props.showSpinner();
    removeGroup(group.ptsGroupID)
      .then(() => props.updateGroups())
      .catch(error => props.handleError(error, 'Error, group not deleted.'))
      .finally(() => props.hideSpinner());
  }

  const editGroup = (group) => {
    props.editPageGroup(group.ptsGroupID);
  }

  const renderHeader = () => {
    return (
      <div className={classes.header}>
        <h4>Groups</h4>
        <Fab size="small" color="secondary" onClick={addGroup}>
          <AddIcon />
        </Fab>
      </div>
    );
  }

  const renderTable = () => {
    return (
      <>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
          >
            <CustomTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={groups.length}
            />
            <TableBody>
              {tableSort(groups, getTableComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      hover
                      key={row.ROWGUID}
                      className={classes.row}
                    >
                      <TableCell align="left">{row.Label}</TableCell>
                      <TableCell align="center">{formatDate(row.Created)}</TableCell>
                      <TableCell align="left">{row.CreatedBy}</TableCell>
                      <TableCell align="center">{formatDate(row.Updated)}</TableCell>
                      <TableCell align="center">{row.UpdatedBy}</TableCell>
                      <TableCell align="right" className={classes.actions}>
                        <IconButton onClick={() => editGroup(row)}>
                          <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton onClick={() => delGroup(row)}>
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={groups.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </>
    );
  }
  
  return (
    <div className={classes.root}>
      {renderHeader()}
      {renderTable()}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    groups: state.paging.groups,
  }
}

export default connect(mapStateToProps, { 
  updateGroups, showSpinner, hideSpinner, handleError, editPageGroup, closeEditPageGroup
})(Groups);
