import React from 'react';

function LawCourtIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      {...props}
      preserveAspectRatio="xMidYMid meet">
      <g
        transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none">
        <path d="M130 399 c-109 -86 -143 -120 -106 -106 14 6 16 -8 16 -128 l0 -135 210 0 210 0 0 135 c0 120 2 134 16 128 38 -14 3 21 -106 106 -63 50 -117 91 -120 91 -3 0 -57 -41 -120 -91z m237 -75 c5 -14 -78 -37 -90 -25 -15 15 36 41 72 37 8 0 16 -6 18 -12z m9 -59 c8 -32 5 -34 -40 -42 -32 -5 -36 -3 -36 16 0 11 0 24 -1 29 0 7 33 19 58 21 7 1 15 -10 19 -24z m-96 -30 c0 -8 -17 -19 -37 -24 -120 -31 -134 -34 -146 -22 -18 18 1 28 88 49 84 20 95 19 95 -3z m118 -37 c-5 -15 -65 -31 -86 -23 -28 11 11 31 66 34 14 1 22 -4 20 -11z m10 -100 c3 -16 -6 -18 -72 -18 -57 0 -76 3 -76 13 0 24 13 28 79 25 53 -2 66 -6 69 -20z" />
      </g>
    </svg>
  );
}

export default LawCourtIcon;
