import { getService } from './service';
import { handleError } from './ErrorReducer';

export const GET_CITATION_COURT = 'CITATION/GET_CITATION_COURT';

export const getCitationCourt = () => {
  return async dispatch => {
    try {
      const service = getService('citation-court');
      const data = await service.find();
      dispatch({ type: GET_CITATION_COURT, payload: data.data });
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};
const defaultState = {
  citationCourts: [],
};
export default function citationReducer(state = defaultState, action) {
  switch (action.type) {
    case GET_CITATION_COURT:
      return { ...state, citationCourts: action.payload };
    default:
      return state;
  }
}
