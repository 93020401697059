import React, { useEffect, useState } from 'react';
import { getService } from '../../reducers/service';
import Content from 'Settings/Content';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Grid, TextField, Box, Divider } from '@material-ui/core';
import { notify } from 'reducers/NotifierReducer';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';

import { useDispatch } from 'react-redux';
import Checkbox2 from 'components/Checkbox2';

const intitailJson = {
  MOBILE: { required: false, deviceType: 'ALL' },

  CAD: { required: false, deviceType: 'ALL' },
  RMS: { required: false, deviceType: 'ALL' },
  SETTINGS: { required: false, deviceType: 'ALL' },
};
const TwoFactorAuth = () => {
  const [agencyOptions, setAgencyOptions] = useState([]);
  const [AgencyID, setAgencyID] = useState('');

  const dispatch = useDispatch();
  const [applicationJson, setApplicationJson] = useState(intitailJson);
  const handleSaveClick = async () => {
    try {
      const service = getService('ptsagencies');
      const data = JSON.stringify(applicationJson);
      const result = await service.patch(AgencyID, {
        TwoFactorAuth: data,
      });
      dispatch(notify('Settings Saved.', 'success'));
    } catch (err) {
      dispatch(notify('Could not Saved.', 'error'));
    }

    // console.log('res of patch ', result);
    //setTwoFactorAuth(result?.data?.TwoFactorAuth);
  };
  const onChangeAgency = async AgencyID => {
    const service = getService('ptsagencies');
    const result = await service.get(AgencyID);
    const jsonData = JSON.parse(result?.TwoFactorAuth || '{}');
    // console.log('jsonData', jsonData);
    const newObj = {};

    Object.entries(jsonData).forEach(([key, value]) => {
      if (value.required) {
        newObj[key] = {
          required: true,
          deviceType: value.deviceType,
        };
      } else {
        newObj[key] = {
          required: false,
          deviceType: value.deviceType,
        };
      }
    });

    setApplicationJson({ ...intitailJson, ...newObj });

    setAgencyID(AgencyID);
  };

  useEffect(() => {
    const fetchAgencies = async () => {
      const service = getService('ptsagencies');
      const response = await service.find({ query: { IsDeleted: false } });
      const newArray = response?.data.map(el => el?.AgencyID);
      setAgencyOptions(newArray);
    };
    fetchAgencies();
  }, []);
  const handleCheckbox = (event, key) => {
    const newObj = { ...applicationJson };
    newObj[key].required = event.target.checked;
    setApplicationJson(newObj);
  };
  const handleDeviceType = (key, value) => {
    const newObj = { ...applicationJson };
    newObj[key].deviceType = value;
    setApplicationJson(newObj);
  };
  const renderSave = () => {
    return (
      <div style={{ textAlign: 'right' }} className="mb-2">
        <Button onClick={handleSaveClick} variant="contained" color="primary">
          <SaveIcon className="mr-2" /> Save
        </Button>
      </div>
    );
  };
  // console.log('applicationJson', applicationJson);
  return (
    <Content>
      <div className="m-4">
        <h5 className="mt-2 mb-4">Two Factor Authentication</h5>
        {renderSave()}
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12}>
            <Box>
              <Autocomplete
                size="small"
                options={agencyOptions}
                value={AgencyID}
                renderInput={params => <TextField {...params} label="Agency" variant="outlined" />}
                onChange={(event, selection) => onChangeAgency(selection)}
              />
            </Box>
          </Grid>
          {Object.entries(applicationJson).map(([key, value]) => (
            <Grid item xs={12} sm={12} key={key} style={{ padding: '5px' }}>
              <Grid container spaceing={2}>
                <Grid item xs={12} sm={2}>
                  <Box>
                    <Checkbox2
                      size="small"
                      checked={value.required}
                      onChange={e => handleCheckbox(e, key)}
                      label={key}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box>
                    <Autocomplete
                      size="small"
                      disabled={!value.required}
                      options={['MOBILE', 'ALL']}
                      value={value.deviceType}
                      renderInput={params => (
                        <TextField {...params} label="Device Type" variant="outlined" />
                      )}
                      onChange={(event, selection) => {
                        handleDeviceType(key, selection);
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </div>
    </Content>
  );
};

export default TwoFactorAuth;
