import {
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  Tab,
  Tabs,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { closeDeleteAgencyDialog } from 'reducers/DialogsReducer';
import { getService } from 'reducers/service';

import { handleError } from 'reducers/ErrorReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { notify } from 'reducers/NotifierReducer';
import RelatedItemsListView from './RelatedItemsListView';
import { tabsList } from './columnsNames';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
const useStyles = makeStyles(theme => ({
  wrap: {
    padding: theme.spacing(3),
  },
  content: {},
}));

export const DeleteAgency = props => {
  const classes = useStyles();
  const { AgencyID, mode } = props;
  const [tab, setTab] = useState('users');
  const service = getService('agency-related-records');
  const [recordsCounts, setRecordsCounts] = useState(null);
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    const data = await service.find({
      query: {
        AgencyID,
      },
    });
    if (data) {
      setRecordsCounts(data);
    }
  };
  const close = data => {
    props.closeDeleteAgencyDialog(null);
  };

  const handleDeleteAgency = async (type, apiName) => {
    if (!window.confirm(`Are you sure you want to delete  "${AgencyID}" Agency?`)) return;

    const service = getService(apiName);
    try {
      props.showSpinner();
      const result = await service.remove(AgencyID);
      if (result && result.success === false) {
        throw new Error(result.error);
      }
      props.notify('Agency Deleted', 'success');
      close();
      props.notifyDataUpdate({ type: 'agencies' });
    } catch (err) {
      props.handleError(err, 'Error, cannot not Delete Agency.', err);
    } finally {
      props.hideSpinner();
    }
  };

  const renderLabel = (label, count = 0) => {
    return (
      <Badge badgeContent={count || 0} color="primary">
        <span className="pr-1">{label}</span>
      </Badge>
    );
  };
  const commonProps = {
    AgencyID,
    recordsCounts,
    setRecordsCounts,
  };
  return (
    <Dialog
      open={true}
      toolbar
      onClose={close}
      title={`Delete Agency : ${AgencyID}`}
      maxWidth="xl"
      fullWidth>
      <DialogContent style={{ minHeight: '600px' }}>
        <Paper square>
          <Tabs variant="scrollable" value={tab} onChange={(ev, val) => setTab(val)}>
            <Tab label={renderLabel('Users', recordsCounts?.secUser)} value="users" />
            <Tab label={renderLabel('Roles', recordsCounts?.secGroup)} value="roles" />
            <Tab label={renderLabel('Units', recordsCounts?.cadUnit)} value="units" />
            <Tab
              label={renderLabel('Custom IDs', recordsCounts?.ptsCustomAgencyID)}
              value="custom-ids"
            />

            <Tab
              label={renderLabel('Citation Definations', recordsCounts?.CitationDefinition)}
              value="citationDefinations"
            />
            <Tab label={renderLabel('WorkFlows', recordsCounts?.ptsWFlows)} value="ptsWFlows" />
            <Tab label={renderLabel('Alerts', recordsCounts?.cadAlerts)} value="cadAlerts" />
            <Tab label={renderLabel('ESNs', recordsCounts?.codeESNs)} value="codeESNs" />

            <Tab
              label={renderLabel('Geofences', recordsCounts?.ptsGeofences)}
              value="ptsGeofences"
            />
            <Tab label={renderLabel('Shares', recordsCounts?.ptsShares)} value="ptsShares" />
          </Tabs>
        </Paper>

        {Object.entries(tabsList).map(
          ([key, value]) =>
            key === tab && (
              <RelatedItemsListView
                commonProps={commonProps}
                serviceName={value.serviceName}
                label={value.label}
              />
            )
        )}
      </DialogContent>
      <DialogActions style={{ marginRight: '10px' }}>
        <Button
          onClick={() => handleDeleteAgency('all', 'agency-related-records')}
          color="primary"
          variant="contained">
          Delete With All Records
        </Button>
        <Button
          onClick={() => handleDeleteAgency('single', 'ptsagencies')}
          color="primary"
          variant="contained">
          Delete Agency Only
        </Button>
        <Button onClick={close} color="primary" variant="outlined">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  closeDeleteAgencyDialog,
  showSpinner,
  hideSpinner,
  handleError,
  notify,
  notifyDataUpdate,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAgency);
