import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { gridStyle } from 'utils/formStyles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import QuickFilter from 'components/QuickFilter';
import { getColumnsName } from './columnsNames';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import { XGrid } from '@material-ui/x-grid';
import { getService } from 'reducers/service';
import { notify } from 'reducers/NotifierReducer';
import { handleError } from 'reducers/ErrorReducer';
import { Autocomplete } from '@material-ui/lab';
import MoveItems from './MoveItems';

const useStyles = makeStyles(theme => ({
  item: {
    ...gridStyle(200, 800),
  },
  list: {
    width: '100%',
    height: 500,
  },
  wrap: {
    padding: theme.spacing(2),
    '& > h5': {
      marginBottom: 20,
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: `calc(100vh - 375px)`,
  },
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 0, 2),
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
}));
const isMovable = serviceName => {
  const permitedList = ['secUser', 'secGroup', 'cadUnit'];
  return permitedList.includes(serviceName);
};
export const RelatedItemsListView = props => {
  const classes = useStyles();
  const { serviceName, label, commonProps } = props;
  const { AgencyID, recordsCounts, setRecordsCounts } = commonProps;
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [moveDialogOpen, setMoveDialogOpen] = useState(false);
  const columnsItems = getColumnsName(serviceName);

  const keyName = columnsItems.find(item => item.key === true).field;

  const service = getService('agency-related-records');
  useEffect(() => {
    fetchData();
  }, [serviceName]);

  const fetchData = async () => {
    setLoaded(false);
    const data = await service.get(AgencyID, {
      query: {
        tableName: serviceName,
        columns: columnsItems,
      },
    });

    if (data) {
      const selections = [];

      data.forEach(item => selections.push(item[keyName]));
      setSelection(selections);
      setRows(data);
    }
    setLoaded(true);
  };

  const handleSelectChange = selection => {
    setSelection(selection.selectionModel);
  };
  const onClickMove = () => {
    setMoveDialogOpen(true);
  };
  const onClickEdit = () => {};
  const onClickDelete = async () => {
    if (!window.confirm(`Are you sure you want to delete the selected ${label}?`)) return;
    try {
      setLoaded(false);
      const result = await service.remove(null, {
        query: {
          tableName: serviceName,
          keyName,
          records: selection,
        },
      });
      processDataAfterActions();
      props.notify('Items Deleted!', 'success');
    } catch (error) {
      console.log('error', error);
      props.handleError(error, 'Error, Action Failed', error);
    } finally {
      setLoaded(true);
    }
  };
  const processDataAfterActions = () => {
    const filtered = rows.filter(row => !selection.includes(String(row[keyName])));
    setRows(filtered);
    setRecordsCounts({ ...recordsCounts, [serviceName]: filtered.length });
  };
  const renderActions = () => {
    const disabled = selection.length === 0;

    //const dltDisabled = selected?.Icon === 0;
    return (
      <div className={classes.actions}>
        <div>
          <QuickFilter rows={rows} columns={columnsItems} setFilteredRows={setFilteredRows} />
        </div>
        <div>
          {/* <Fab size="small" color="secondary" onClick={onClickAdd}>
            <AddIcon />
          </Fab>
          <Fab size="small" color="secondary" onClick={onClickEdit} disabled={disabled}>
            <EditIcon />
          </Fab> */}
          {isMovable(serviceName) && (
            <Fab size="small" color="primary" onClick={onClickMove} disabled={disabled}>
              <SwapHorizIcon />
            </Fab>
          )}

          <Fab size="small" color="secondary" onClick={onClickDelete} disabled={disabled}>
            <DeleteIcon />
          </Fab>
        </div>
      </div>
    );
  };
  return (
    <div className="m-4">
      {/* <h4>{label}</h4> */}
      {renderActions()}
      <div className={classes.gridWrap}>
        <XGrid
          columns={columnsItems}
          rows={filteredRows}
          loading={!loaded}
          checkboxSelection={true}
          rowHeight={38}
          getRowId={item => item[keyName]}
          selectionModel={selection}
          onSelectionModelChange={handleSelectChange}
        />
      </div>
      <MoveItems
        AgencyID={AgencyID}
        moveDialogOpen={moveDialogOpen}
        selection={selection}
        setMoveDialogOpen={setMoveDialogOpen}
        serviceName={serviceName}
        keyName={keyName}
        processDataAfterActions={processDataAfterActions}
      />
    </div>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = { notify, handleError };

export default connect(mapStateToProps, mapDispatchToProps)(RelatedItemsListView);
