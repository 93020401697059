import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  getGroupComponentPermission,
  findSecComponents,
  updateGroupComponentPermission2,
  findSecGroupComponents,
  saveGroupBooleans,
} from '../SecFunctions';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import { handleError } from 'reducers/ErrorReducer';
import { notify } from 'reducers/NotifierReducer';
import { Divider } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Fills } from 'utils/formStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { isDevelopment } from 'utils/functions';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { PermissionContext } from '.';

const useStyles = makeStyles(theme => ({
  autoComplete: {
    maxWidth: 300,
  },
  checkStyle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '5px',
  },
  item: {
    flex: '1 1 250px',
    margin: '5px',
  },
  table: {
    minWidth: 650,
  },
  btn: {
    textAlign: 'right',
    marginBottom: '15px',
    marginRight: '5px',
  },
}));

function PermissionsComponent(props) {
  const classes = useStyles();
  const { type, title } = props;
  const [allCoreComponents, setAllCoreComponents] = useState([]);
  const [allCoreBoolComponents, setAllCoreBoolComponents] = useState([]);
  const [currentRoleWithBools, setCurrentRoleWithBools] = useState(null);
  const [existingBoolIds, setExistingBoolIds] = useState([]);
  const { allComponents, allGroupComponents, rolesList, selectedProfile } = useContext(
    PermissionContext
  );
  const [initialLoad, setInitialLoad] = useState(true);

  // const { allComponents, allGroupComponents, rolesList, selectedProfile } = useContext(
  //   PermissionContext
  // );
  useEffect(() => {
    // setAllCoreBoolComponents([]);
    // setAllCoreComponents([]);

    processComponents();
  }, [selectedProfile, rolesList]);

  useEffect(() => {
    if (allCoreBoolComponents.length > 0) renderInitialBools();
  }, [selectedProfile, allCoreBoolComponents, rolesList]);

  const processComponents = async () => {
    props.showSpinner();

    const cores = allComponents.filter(c => c.Type === type);
    const attached = await attachPermissions(cores);
    setAllCoreComponents(attached);
    const coreBools = allComponents.filter(c => c.Type === `${type}.bool`);
    setAllCoreBoolComponents(coreBools);
    if (initialLoad) {
      props.hideSpinner();
      setInitialLoad(false);
    } else {
      setTimeout(() => {
        props.hideSpinner();
      }, 1000);
    }
  };

  const attachPermissions = async components => {
    for (const c of components) {
      const result = allGroupComponents.filter(
        component =>
          rolesList.includes(component.ptsGroupID) && component.ptsComponentID === c.ptsComponentID
      );

      let permission = result.length > 0 ? 0 : -1;
      if (result.length > 0) {
        permission = result.reduce((total, current) => {
          return total | current.Permissions;
        }, 0);
      }
      c.Permission = permission;
    }

    return components;
  };

  const renderInitialBools = async () => {
    const filtered = allGroupComponents.filter(
      el => rolesList.includes(el.ptsGroupID) && el.Permissions !== 0
    );

    const modules = [];
    for (const module of allCoreBoolComponents) {
      const found = filtered.find(el => module.ptsComponentID === el.ptsComponentID);
      if (found) modules.push(module.ptsComponentID);
    }

    const newRole = { ...selectedProfile, CoreBools: modules };
    setCurrentRoleWithBools(newRole);

    const existingComponents = allGroupComponents.filter(el => rolesList.includes(el.ptsGroupID));
    const existingIds = [];
    for (const module of allCoreBoolComponents) {
      const found = existingComponents.find(el => module.ptsComponentID === el.ptsComponentID);
      if (found) existingIds.push(module.ptsComponentID);
    }
    setExistingBoolIds(existingIds);
  };

  const renderBools = module => {
    const handleChecked = () => {
      if (!currentRoleWithBools) return false;
      const currentBoolIds = [...currentRoleWithBools.CoreBools];
      const found = currentBoolIds.find(modId => modId === module.ptsComponentID);
      return Boolean(found);
    };

    const label = isDevelopment ? `${module.Label} (${module.ptsComponentID})` : module.Label;
    return (
      <FormControlLabel
        control={<Checkbox checked={handleChecked()} color="primary" />}
        label={label}
      />
    );
  };

  const isCoreChecked = (permission, shift) => {
    if (permission === -1) return false;
    const mask = 1 << shift;
    return (permission & mask) !== 0;
  };

  const renderCore = () => {
    return (
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Components {isDevelopment && <span>(id)</span>}</TableCell>
              <TableCell align="center">Read</TableCell>
              <TableCell align="center">Create</TableCell>
              <TableCell align="center">Edit</TableCell>
              <TableCell align="center">Delete</TableCell>
              <TableCell align="center">View Deleted</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allCoreComponents.map(c => (
              <TableRow key={c.ptsComponentID}>
                <TableCell component="th" scope="row">
                  {c.Label} {isDevelopment && <span>({c.ptsComponentID})</span>}
                </TableCell>
                <TableCell align="center">
                  {
                    <Checkbox
                      checked={isCoreChecked(c.Permission, 0)}
                      // onChange={() => handleCoreCheckChange(c.Permission, 0, c.ptsComponentID)}
                      color="primary"
                    />
                  }
                </TableCell>
                <TableCell align="center">
                  {
                    <Checkbox
                      checked={isCoreChecked(c.Permission, 1)}
                      // onChange={() => handleCoreCheckChange(c.Permission, 1, c.ptsComponentID)}
                      color="primary"
                    />
                  }
                </TableCell>
                <TableCell align="center">
                  {
                    <Checkbox
                      checked={isCoreChecked(c.Permission, 2)}
                      // onChange={() => handleCoreCheckChange(c.Permission, 2, c.ptsComponentID)}
                      color="primary"
                    />
                  }
                </TableCell>
                <TableCell align="center">
                  {
                    <Checkbox
                      checked={isCoreChecked(c.Permission, 3)}
                      // onChange={() => handleCoreCheckChange(c.Permission, 3, c.ptsComponentID)}
                      color="primary"
                    />
                  }
                </TableCell>
                <TableCell align="center">
                  {
                    <Checkbox
                      checked={isCoreChecked(c.Permission, 7)}
                      // onChange={() => handleCoreCheckChange(c.Permission, 7, c.ptsComponentID)}
                      color="primary"
                    />
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <div>
      {renderCore()}
      <Divider className="mt-4 mb-4" />
      <div>
        <Grid container className="mb-3">
          <Grid item xs={9}>
            <h5>Additional Privileges</h5>
          </Grid>
          {/* <Grid item xs={3}>
            {renderBoolSaveBtn()}
          </Grid> */}
        </Grid>
        <div className={classes.checkStyle}>
          {allCoreBoolComponents.map(m => (
            <span key={m.ptsComponentID} className={classes.item}>
              {renderBools(m)}
            </span>
          ))}
          <Fills className={classes.item} />
        </div>
      </div>
    </div>
  );
}

export default connect(null, { handleError, notify, showSpinner, hideSpinner })(
  PermissionsComponent
);
