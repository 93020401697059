
    import React from 'react';
    import SvgIcon from '@material-ui/core/SvgIcon';
    
    function icons8stopgesture100Icon(props) {
      return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100" height="100"><path d="M37.533 7c-1.721 0-2.505 1.188-2.505 2.531V23a1 1 0 0 1-1 1C33.475 24 33 23.552 33 23V4.479C33.021 3.271 32.272 2 30.521 2s-2.506 1.363-2.506 2.506v17.507a1 1 0 0 1-1 1 1.014 1.014 0 0 1-1.016-.999V2.506C26 1.495 25.219 0 23.51 0c-1.71 0-2.506 1.495-2.506 2.506V23c0 .552-.448 1-1 1S19 23.552 19 23V6.548c0-1.839-1.495-2.506-2.502-2.506-.966 0-2.498.649-2.498 2.39V31a1 1 0 0 1-2 0v-7c0-1.381-1-5-3-5-1.381 0-2 1.119-2 2.5V34c0 10 7 16 16 16h8c4.424 0 9-3.366 9-9V9.583C40 8.208 39.254 7 37.533 7z"/></SvgIcon>
      );
    }
  
    export default icons8stopgesture100Icon;