import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { handleError } from '../reducers/ErrorReducer';
import { makeStyles } from '@material-ui/core/styles';
import { XGrid } from '@material-ui/x-grid';
import settings from '../config/settings';
import CheckIcon from '@material-ui/icons/Check';
import TextField from '@material-ui/core/TextField';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '../components/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import { filterResults } from '../utils/functions';
import { getSops, removeSop, approveSOP } from '../reducers/SopsReducer';
import { addSop, editSop } from '../reducers/DialogsReducer';
import { showSpinner, hideSpinner } from '../reducers/UiReducer';
import { formatDate } from '../utils/functions';
import Content from './Content';

const useStyles = makeStyles(theme => ({
  wrap: {
    background: theme.card.bg,
    height: `calc(100vh - ${settings.mainNavHeight}px)`,
    padding: theme.spacing(2),
    '& h5': {
      padding: '0.75em 0',
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: `calc(100vh - 200px)`,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    minHeight: theme.spacing(6),
    width: '100%',
  },
  filterWrap: {
    flexShrink: 1,
    flexBasis: 400,
  },
  filter: {
    width: '100%',
  },
  action: {
    flexGrow: 0,
    flexShrink: 0,
    width: 'auto',
    marginLeft: theme.spacing(1),
    textAlign: 'right',
  },
}));

const bool = params => (params.value ? <CheckIcon /> : <span></span>);

const columns = [
  { field: 'Title', headerName: 'Title', width: 250 },
  { field: 'DispatcherMessage', headerName: 'Dispatcher Message', width: 250 },
  { field: 'AlertMessage', headerName: 'Alert Message', width: 200 },
  {
    field: 'IsAlertMessageUsed',
    headerName: 'Alert Message Used',
    width: 150,
    renderCell: bool,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'ShowPopupNotification',
    headerName: 'Show Popup Notification',
    width: 150,
    renderCell: bool,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'Created',
    headerName: 'Created',
    width: 120,
    valueFormatter: params => formatDate(params.value),
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 150 },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 120,
    valueFormatter: params => formatDate(params.value),
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 150 },
  {
    field: 'Deleted',
    headerName: 'Deleted',
    width: 120,
    valueFormatter: params => formatDate(params.value),
  },
  { field: 'DeletedBy', headerName: 'Deleted By', width: 150 },
  { field: 'DeletedIP', headerName: 'Deleted IP', width: 150 },
];

function SearchSOPs(props) {
  const classes = useStyles();
  const { addSopDialog, CallerType, ptsParentID, ParentType } = props;
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [selection, setSelection] = useState(null);
  const [filter, setFilter] = useState('');
  useEffect(() => {
    search();
    // eslint-disable-next-line
  }, [addSopDialog]);
  const xgridRef = useRef();

  useEffect(() => {
    if (!filter) {
      setFilteredRows(rows);
    } else {
      setFilteredRows(filterResults(filter, rows, columns));
    }
    // eslint-disable-next-line
  }, [filter, rows]);

  const processData = data => {
    return data.map(row => {
      return { ...row, id: row.ptsSOPID };
    });
  };

  const search = () => {
    setLoaded(false);
    getSops(ptsParentID)
      .then(result => {
        setRows(processData(result));
      })
      .catch(err => {
        props.handleError(err, 'Errorr, cannot get SOPs records.');
      })
      .finally(() => setLoaded(true));
  };

  const quickFilter = ev => {
    const val = ev.target.value;
    setFilter(val);
  };

  const deleteSop = () => {
    if (!window.confirm('Are you sure you want to remove SOP?')) return;
    props.showSpinner();
    removeSop(selection, ptsParentID)
      .then(result => console.log('deleted? ', result))
      .then(() => search())
      .catch(err => props.handleError(err, 'Error, Cannot remove SOP.'))
      .finally(() => props.hideSpinner());
  };

  const addSop = () => {
    props.addSop(ptsParentID);
  };

  const editSop = () => {
    props.editSop(selection);
  };

  const approveSop = () => {
    props.showSpinner();
    approveSOP(selection)
      .then(() => search())
      .catch(err => props.handleError(err, 'Error, Cannot update SOP.'))
      .finally(() => props.hideSpinner());
  };

  const renderToolbar = () => {
    return (
      <div className={classes.toolbar}>
        <div className={classes.filterWrap}>
          <TextField
            label="Quick filter"
            type="search"
            className={classes.filter}
            variant="outlined"
            size="small"
            onChange={quickFilter}
            value={filter}
          />
        </div>
        <div className={classes.action}>
          <Tooltip title="Add">
            <IconButton onClick={addSop}>
              <AddIcon />
            </IconButton>
          </Tooltip>
          {selection !== null && (
            <>
              {CallerType !== 'CadTypes' && (
                <>
                  <Tooltip title="Edit">
                    <IconButton onClick={editSop}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Approve">
                    <IconButton onClick={approveSop}>
                      <CheckIcon />
                    </IconButton>
                  </Tooltip>
                </>
              )}
              <Tooltip title="Delete">
                <IconButton onClick={deleteSop}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
        </div>
      </div>
    );
  };
  const content = () => (
    <div className={CallerType === 'CadTypes' ? '' : classes.wrap}>
      {CallerType !== 'CadTypes' && <h5>SOPs</h5>}
      {renderToolbar()}
      <div
        className={classes.gridWrap}
        style={{
          height: CallerType === 'CadTypes' ? `calc(100vh - 480px)` : `calc(100vh - 200px)`,
        }}>
        <XGrid
          columns={columns}
          rows={filteredRows}
          loading={!loaded}
          rowHeight={38}
          disableMultipleSelection
          onSelectionModelChange={newSelection => {
            setSelection(parseInt(newSelection.selectionModel[0]));
          }}
        />
      </div>
    </div>
  );
  return CallerType === 'CadTypes' ? content() : <Content>{content()}</Content>;
}

const mapStateToProps = state => {
  return {
    addSopDialog: state.dialogs.editSop,
  };
};

export default connect(mapStateToProps, {
  showSpinner,
  hideSpinner,
  handleError,
  addSop,
  editSop,
})(SearchSOPs);
