export const SET_MAP = 'MAP/SET_MAP';
export const SET_MAP_CENTER = 'MAP/SET_MAP_CENTER';
export const SET_MAP_ZOOM = 'MAP/SET_MAP_ZOOM';
export const SET_MAP_BOUNDS = 'MAP/SET_MAP_BOUNDS';
export const SET_EVENT_INFOWINDOW = 'MAP/SET_EVENT_INFOWINDOW';
export const SET_EVENT_INFOWINDOW_TAB = 'MAP/SET_EVENT_INFOWINDOW_TAB';
export const SET_UNIT_INFOWINDOW = 'MAP/SET_UNIT_INFOWINDOW';

export const setMap = (map, units = [], events = []) => {
  return async dispatch => {
    var bounds = new window.google.maps.LatLngBounds(
      new window.google.maps.LatLng(1, -180),
      new window.google.maps.LatLng(-1, 180)
    );

    if (units.length !== 0) {
      units.forEach(unit => {
        if (
          unit.UnitLatitudeSign &&
          unit.UnitLongitudeSign &&
          unit.UnitLatitudeDegree &&
          unit.UnitLongitudeDegree
        ) {
          let latitude = `${unit.UnitLatitudeSign === '-' ? unit.UnitLatitudeSign : ''
            }${unit.UnitLatitudeDegree}`;
          let longitude = `${unit.UnitLongitudeSign === '-' ? unit.UnitLongitudeSign : ''
            }${unit.UnitLongitudeDegree}`;

          let position = {
            lat: parseFloat(latitude),
            lng: parseFloat(longitude)
          };
          bounds.extend(position);
        }
      });

      map.fitBounds(bounds);
    }

    if (events.length !== 0) {
      events.forEach(event => {
        if (
          event.LatitudeSign &&
          event.LongitudeSign &&
          event.LatitudeDegree &&
          event.LongitudeDegree
        ) {
          let latitude = `${event.LatitudeSign === '-' ? event.LatitudeSign : ''
            }${event.LatitudeDegree}`;
          let longitude = `${event.LongitudeSign === '-' ? event.LongitudeSign : ''
            }${event.LongitudeDegree}`;

          let position = {
            lat: parseFloat(latitude),
            lng: parseFloat(longitude)
          };
          bounds.extend(position);
        }
      });

      map.fitBounds(bounds);
    }

    if (events.length === 0 && units.length === 0) map.setZoom(2)

    dispatch({ type: SET_MAP_BOUNDS, payload: bounds });
    dispatch({ type: SET_MAP, payload: map });
  };
};

export const setEventInfowindow = (state, event) => {
  return async dispatch => {
    dispatch({
      type: SET_UNIT_INFOWINDOW,
      payload: { open: false, data: null }
    });
    if (event !== null) {
      if (event.LatitudeDegree !== null && event.LongitudeDegree !== null) {
        const latitude = `${event.LatitudeSign === '-' ? event.LatitudeSign : ''
          }${event.LatitudeDegree}`;
        const longitude = `${event.LongitudeSign === '-' ? event.LongitudeSign : ''
          }${event.LongitudeDegree}`;
        const position = {
          lat: parseFloat(latitude),
          lng: parseFloat(longitude)
        };
        dispatch({
          type: SET_EVENT_INFOWINDOW,
          payload: { open: state, data: event }
        });
        dispatch({ type: SET_MAP_CENTER, payload: position });
        dispatch({ type: SET_MAP_ZOOM, payload: 15 });
      } else {
        dispatch({
          type: SET_EVENT_INFOWINDOW,
          payload: { open: state, data: event }
        });
      }
    } else {
      dispatch({
        type: SET_EVENT_INFOWINDOW,
        payload: { open: state, data: event }
      });
    }
  };
};

export const setEventInfowindowTab = tab => {
  return async dispatch => {
    dispatch({ type: SET_EVENT_INFOWINDOW_TAB, payload: tab });
  };
};

export const setUnitInfowindow = (state, unit) => {
  return async dispatch => {
    dispatch({
      type: SET_EVENT_INFOWINDOW,
      payload: { open: false, data: null }
    });
    if (unit !== null) {
      if (
        unit.UnitLatitudeDegree !== null &&
        unit.UnitLongitudeDegree !== null
      ) {
        const latitude = `${unit.UnitLatitudeSign === '-' ? unit.UnitLatitudeSign : ''
          }${unit.UnitLatitudeDegree}`;
        const longitude = `${unit.UnitLongitudeSign === '-' ? unit.UnitLongitudeSign : ''
          }${unit.UnitLongitudeDegree}`;
        const position = {
          lat: parseFloat(latitude),
          lng: parseFloat(longitude)
        };
        dispatch({
          type: SET_UNIT_INFOWINDOW,
          payload: { open: state, data: unit }
        });
        dispatch({ type: SET_MAP_CENTER, payload: position });
        dispatch({ type: SET_MAP_ZOOM, payload: 15 });
      } else {
        dispatch({
          type: SET_UNIT_INFOWINDOW,
          payload: { open: state, data: unit }
        });
      }
    } else {
      dispatch({
        type: SET_UNIT_INFOWINDOW,
        payload: { open: state, data: unit }
      });
    }
  };
};

export default function reducer(
  state = {
    options: {
      zoomControl: true,
      mapTypeControl: true,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      mapTypeControlOptions: {
        style: 1, // window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR
        position: 11, // window.google.maps.ControlPosition.BOTTOM_CENTER
      },
      zoomControlOptions: {
        position: 11, // window.google.maps.ControlPosition.BOTTOM_CENTER
      },
    },
  },
  action
) {
  switch (action.type) {
    // Map

    default:
      break;
  }
  return state;
}
