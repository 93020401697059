import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import { XGrid } from '@material-ui/x-grid';
import DeleteIcon from '@material-ui/icons/Delete';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import {
  savePersonalCapability,
  getPersonalCapabilities,
  removePersonalCapability,
  getPersonalItems,
  getItemsResources,
  savePersonItem,
  removePersonItem,
} from '../../reducers/EsnReducer';
import { findPartyPerson } from '../../reducers/EventsReducer';
import { handleError } from '../../reducers/ErrorReducer';
import { showSpinner, hideSpinner } from '../../reducers/UiReducer';

const useStyles = makeStyles(theme => ({
  wrap: {
    '& > h5': {
      marginBottom: 20,
    },
  },
  form: {
    margin: '0 -4px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexGrow: 1,
  },
  autocomplete: {
    margin: '0 4px 8px',
    maxWidth: 400,
    flexBasis: 400,
    minWidth: 400,
    flexGrow: 1,
  },
  buttons: {
    // marginBottom: 8,
    display: 'flex',
    alignItems: 'center',
    '& button': {
      marginLeft: theme.spacing(1),
    },
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  search: {
    marginBottom: 10,
  },
  activeWarrant: {
    background: theme.palette.error.main,
    color: theme.card.bg,
    width: 'calc(100% + 32px)',
    display: 'inline-block',
    padding: '8px 16px',
    margin: '-8px -16px',
  },
  textField: {
    margin: '0 4px 8px',
  },
  list: {
    width: '100%',
    boxSizing: 'border-box',
    height: `calc(100vh - 340px)`,
  },
  actions: {
    width: '100%',
    padding: theme.spacing(1, 0, 2),
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
}));

const CapabilityColumns = [
  { field: 'CapabilityType', headerName: 'Type', width: 150 },
  { field: 'ComponentName', headerName: 'Description', width: 300 },
];

const ItemColumns = [
  { field: 'ptsChildID', headerName: 'Item ID', width: 150 },
  { field: 'ComponentName', headerName: 'Description', width: 300 },
];

function PersonItemManagement(props) {
  const classes = useStyles();
  const { dictionary } = props;
  const { CapabilityTypes } = dictionary;
  const [view, setView] = useState('list');
  const [capability, setCapability] = useState(null);
  const [capabilities, setCapabilities] = useState([]);
  const [filteredCapabilities, setFilteredCapabilities] = useState([]);
  const [searchMatch, setSearchMatch] = useState([]);
  const [personData, setPersonData] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selection, setSelection] = useState([]);
  const throttleRef = useRef(0);
  const [selectedBeginningDate, setSelectedBeginningDate] = useState(new Date());
  const [selectedEndingDate, setSelectedEndingDate] = useState(null);
  const [items, setItems] = useState([]);
  const [allItemsData, setAllItemsData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  //   useEffect(() => {
  //       console.log("dictionary: ", dictionary)
  //   }, [dictionary])

  useEffect(() => {
    if (!data) return;
    getCapabilities();
    getItems();
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    getAllItemsData();
  }, []);

  useEffect(() => {
    const filteredCapabilities = CapabilityTypes.filter(
      t => !capabilities.find(c => c.CapabilityType === t.Code) && t.Category === 'Personal'
    );
    setFilteredCapabilities(filteredCapabilities);
    // eslint-disable-next-line
  }, [capabilities]);

  const getAllItemsData = async () => {
    const items = await getItemsResources();
    console.log('items : ', items);
    const processed = process(items);
    //console.log(processed)
    setAllItemsData(processed);
  };

  const process = Items => {
    return Items.map(u => {
      const Item = { ...u };
      Item.id = Item.ptsItemID;
      const { Resources } = Item;
      // const resources = Resources
      //   .filter(res => res.ChildType === 'Item')
      //   .reduce((result, value, idx) => {
      //     if (idx) result += ', ';
      //     result += value.ComponentName;
      //     return result;
      //   }, '');
      const capabilities = Resources.filter(res => res.ChildType === 'ItemCapability').map(cap => {
        const capability = { ...cap };
        capability.CapabilityTypeQuantity =
          cap.CapabilityTypeQuantity !== null ? cap.CapabilityTypeQuantity : 1;
        return capability;
      });
      // Item.Resources = resources;
      Item.Capabilities = capabilities;
      return Item;
    });
  };

  const getCapabilities = async () => {
    const { ptsPersonID } = data;
    setLoading(true);
    try {
      const capabilities = await getPersonalCapabilities(ptsPersonID);
      console.log('setCapabilities for ptsPersonID: ', capabilities);
      setCapabilities(
        capabilities.map(c => {
          return { ...c, id: c.CapabilityType };
        })
      );
    } catch (err) {
      props.handleError(err, 'Error receiving capabilities');
    }
    setLoading(false);
  };

  const getItems = async () => {
    const { ptsPersonID } = data;
    setLoading(true);
    try {
      const items = await getPersonalItems(ptsPersonID);
      console.log('setItems for ptsPersonID: ', items);
      setItems(
        items.map(i => {
          return { ...i, id: i.ptsChildID };
        })
      );
    } catch (err) {
      props.handleError(err, 'Error receiving items');
    }
    setLoading(false);
  };

  const save = async () => {
    const ptsChildID = selectedItem.ptsItemID;
    const ComponentName = selectedItem.Name;
    const { ptsPersonID } = personData;
    props.showSpinner();
    try {
      await savePersonItem({
        ptsPersonID,
        ptsChildID,
        ComponentName,
        selectedBeginningDate,
        selectedEndingDate,
      });
      await getItems();
      setView('list');
    } catch (err) {
      props.handleError(err, 'Error saving item');
    }
    props.hideSpinner();
  };

  const onPersonSearchSelect = (ev, selection) => {
    console.log('on person search select, setPersonData: ', selection);
    if (selection) {
      setPersonData(selection);
      setData({ ...selection });
    } else {
      clearPersonData();
    }
  };

  const onCapabilitySelect = (ev, selection) => {
    setCapability(selection);
  };

  const clearPersonData = () => {
    setPersonData(null);
    setData(null);
    setSelection([]);
    setCapabilities([]);
    setItems([]);
  };

  const searchPerson = text => {
    clearTimeout(throttleRef.current);
    throttleRef.current = setTimeout(() => {
      data && clearPersonData();
      findPartyPerson(text)
        .then(result => setSearchMatch(result))
        .catch(err => console.log(err));
    }, 500);
  };

  const renderPersonSearch = () => {
    return (
      <div className={classes.form}>
        <Autocomplete
          fullWidth
          disabled={view === 'add'}
          options={searchMatch}
          value={personData}
          getOptionLabel={option => option.FullName}
          className={classes.autocomplete}
          size="small"
          onChange={onPersonSearchSelect}
          onInputChange={(ev, val) => searchPerson(val)}
          renderInput={params => <TextField {...params} label="Search Person" variant="outlined" />}
          getOptionSelected={(option, value) => option.ptsPersonID === value.ptsPersonID}
          renderOption={option => (
            <span className={option.ActiveWarrant ? classes.activeWarrant : null}>
              {option.LastName}, {option.FirstName} {option.PersonID}
            </span>
          )}
        />
      </div>
    );
  };

  const handleBeginningDateChange = date => {
    setSelectedBeginningDate(date);
  };

  const handleEndingDateChange = date => {
    setSelectedEndingDate(date);
  };

  const onItemSelect = (ev, selection) => {
    if (selection) {
      setSelectedItem(selection);
    } else {
      setSelectedItem(null);
      setSelection([]);
    }
  };

  const renderAddItem = () => {
    return (
      <>
        <Grid container>
          <Grid item md={12}>
            <div className={classes.form}>
              {/* <Autocomplete
                fullWidth
                options={filteredCapabilities}
                value={capability}
                getOptionLabel={(option) => option.Code ? option.Code : ''}
                className={classes.autocomplete}
                size="small"
                onChange={onCapabilitySelect}
                renderInput={(params) => <TextField {...params} label="Capability" variant="outlined" />}
                getOptionSelected={(option, value) => option.Code === value.Code}
                renderOption={(option) => {
                  const { Code, Description } = option;
                  return (
                  <span><strong>{Code}</strong>{!!Description && <> - {Description}</>}</span>
                )}}
              /> */}
              <Autocomplete
                className={classes.autocomplete}
                options={allItemsData}
                getOptionLabel={option => option.Name || 'undefined'}
                value={selectedItem}
                onChange={onItemSelect}
                fullWidth
                size="small"
                renderInput={params => (
                  <TextField {...params} label="Choose Item" variant="outlined" />
                )}
              />
            </div>
          </Grid>
          <div className={classes.form} style={{ marginLeft: 0, marginTop: -8 }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className="mr-2"
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                size="small"
                format="MM/dd/yyyy"
                margin="normal"
                label="Beginning"
                value={selectedBeginningDate}
                onChange={handleBeginningDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                format="MM/dd/yyyy"
                margin="normal"
                size="small"
                label="Ending"
                value={selectedEndingDate}
                onChange={handleEndingDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className={classes.buttons}>
            <Button
              variant="contained"
              color="primary"
              disabled={!selectedItem || !personData}
              onClick={save}>
              <SaveIcon />
              Save
            </Button>
            <Button
              color="primary"
              onClick={() => {
                setView('list');
              }}>
              <CloseIcon />
              Close
            </Button>
          </div>
        </Grid>
      </>
    );
  };

  const handleSelectChange = ({ selectionModel }) => {
    setSelection(selectionModel);
  };

  const delCapability = async () => {
    if (!window.confirm('Are you sure you want to remove this item?')) return;
    const ptsChildID = selection[0];
    const ptsParentID = data.ptsPersonID;
    setLoading(true);
    try {
      await removePersonItem(ptsChildID, 'Item', ptsParentID);
      await getItems();
    } catch (err) {
      props.handleError(err, 'Error deleting item');
    }
    setLoading(false);
  };

  const addCapability = () => {
    setView('add');
    setCapability(null);
  };

  const renderActions = () => {
    const delDisabled = selection.length === 0;
    return (
      <div className={classes.actions}>
        <Fab size="small" color="secondary" onClick={addCapability} disabled={!data}>
          <AddIcon />
        </Fab>
        <Fab size="small" color="secondary" onClick={delCapability} disabled={delDisabled}>
          <DeleteIcon />
        </Fab>
        {view !== 'list' && (
          <Fab size="small" color="secondary" onClick={() => setView('list')}>
            <CloseIcon />
          </Fab>
        )}
      </div>
    );
  };

  const renderCapabilities = () => {
    return (
      <div className={classes.list}>
        <XGrid
          columns={ItemColumns}
          rows={items}
          loading={loading}
          rowHeight={38}
          onSelectionModelChange={handleSelectChange}
          disableSelectionOnClick={view === 'add'}
          disableMultipleSelection
          hideFooter
        />
      </div>
    );
  };

  return (
    <div className={classes.wrap}>
      {renderPersonSearch()}
      {view === 'add' && renderAddItem()}
      {view === 'list' && renderActions()}
      {renderCapabilities()}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    dictionary: state.dictionary,
  };
};

export default connect(mapStateToProps, {
  handleError,
  showSpinner,
  hideSpinner,
})(PersonItemManagement);
