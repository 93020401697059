import React, { useState, useRef, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

import { handleError } from '../reducers/ErrorReducer';
import { showSpinner, hideSpinner } from '../reducers/UiReducer';
import { notify } from '../reducers/NotifierReducer';
import { closeCitationFile } from '../reducers/DialogsReducer';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { DialogContent } from '@mui/material';
import { getService } from 'reducers/service';
import { getCitationCourt } from 'reducers/CitationReducer';
import { makeAddress } from 'utils/functions';
import formStyles, {
  ColCard,
  Fills,
  Row,
  RowInner,
  gridStyle,
  useError,
  validateEmail,
  validatePhoneNo,
} from 'utils/formStyles';
import Dictionary from 'components/Dictionary';
import DateFnsUtils from '@date-io/date-fns';

import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import FormDivider from 'components/FormDivider';
import { formatSaveDate } from 'reducers/TimeReducer';
import TextField2 from 'components/TextField2';

const useStyles = makeStyles(theme => ({
  ...formStyles,
  wrap: {
    padding: theme.spacing(3),
  },
  item: gridStyle(200, 800),
  addressFiled: gridStyle('100%', '100%'),
  field: gridStyle(210, 400),
  actions: {
    textAlign: 'right',
    marginTop: 8,
    '& button': {
      marginLeft: 8,
      '& svg': {
        marginRight: theme.spacing(1),
      },
    },
  },
}));

function AddPersonDialog(props) {
  const classes = useStyles();
  const {
    wsClient,
    user,
    dictionary,
    setptsPersonID,
    setShowAddPersonDialog,
    ptsPersonID,
    setShowContactDialog,
    showContactDialog,
  } = props;

  const username = user?.userData?.user?.Username;
  const service = getService('mobile-search-people');
  const contactInfoService = getService('ptscontactinfo');

  const [firstname, setFirstname] = useState('');
  const [middlename, setMiddlename] = useState('');
  const [lastname, setLastname] = useState('');

  const [prefix, setPrefix] = useState(null);
  const [suffix, setSuffix] = useState(null);

  const [race, setRace] = useState(null);
  const [sex, setSex] = useState(null);

  const [birthdate, setBirthdate] = useState(null);
  const [ethnicity, setEthnicity] = useState(null);

  const [errors, setErrors] = useState();

  const [contactList, setContactList] = useState({});

  const dispatch = useDispatch();
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const close = () => {
    setShowAddPersonDialog(false);
  };

  const save = async () => {
    const FullName = `${firstname} ` + (middlename ? `${middlename} ` : '') + lastname;
    const contacts = processcontactList();
    let data = {
      person: {
        FirstName: firstname || null,
        MiddleName: middlename || null,
        LastName: lastname || null,
        FullName,
        Prefix: prefix ? prefix.Code : null,
        Suffix: suffix ? suffix.Code : null,
        Race: race ? race.Code : null,
        Sex: sex ? sex.Code : null,
        Ethnicity: ethnicity ? ethnicity.Code : null,
        Born: formatSaveDate(birthdate),
      },
    };

    props.showSpinner();
    try {
      const result = await service.create(data);
      setptsPersonID(result);
      if (contacts && contacts.length > 0) {
        await Promise.all(
          contacts.map(async item => {
            const { ContactMethod, MethodType, Info } = item;
            if (ContactMethod === 'Phone') {
              const parsedNumber = Info ? Info.replace(/\D/g, '') : '';
              if (parsedNumber) {
                const AreaCode = parsedNumber.slice(0, 3);
                const num = parsedNumber.slice(3, 10);
                const Extension = parsedNumber.slice(10);
                item = { ...item, AreaCode, Number: num, Extension };
              }
            }
            if (ContactMethod && MethodType) {
              return contactInfoService.create({
                ...item,
                CreatedBy: username,
                UpdatedBy: username,
                ptsParentID: result,
              });
            }
          })
        );
      }
      props.notify('New Person Created', 'success');
      close();
    } catch (err) {
      console.log('error', err);
      props.notify('Error, Person Creation Failed.', 'error');
    } finally {
      props.hideSpinner();
    }
  };
  const handleContactSave = async () => {
    const contacts = processcontactList();
    if (contacts.length < 0) {
      close();
      return;
    }
    dispatch(showSpinner());

    try {
      const result = await contactInfoService.create({ ...contacts[0], ptsParentID: ptsPersonID });

      dispatch(notify('New Contact Created', 'success'));
      if (showContactDialog === 'Phone') {
        props.updateNumberList(result);
      } else if (showContactDialog === 'Email') {
        props.updateEmailList(result);
      }
      setShowContactDialog(false);
      close();
    } catch (error) {
      dispatch(notify('Error Creatings Contact', 'error'));
      console.log('errr', error);
    } finally {
      dispatch(hideSpinner());
    }
  };

  const processcontactList = () => {
    let contacts = null;
    const contactsItem = Object.entries(contactList);
    if (contactsItem.length > 0) {
      contacts = contactsItem.map(([key, contactInfo]) => {
        const { MethodType } = contactInfo;
        if (!MethodType || !key) {
          return false;
        }
        return {
          ContactMethod: key, // mail, phone
          MethodType: contactInfo.MethodType?.Code || null,
          Info: contactInfo.Info || null,
          IsDefault: contactInfo.IsDefault || false,
          Notes: contactInfo.Notes || null,
          IsPrimary: contactInfo.IsPrimary || false,
          PartyType: 'Person',
          CreatedBy: username,
          UpdatedBy: username,
        };
      });
    }
    return contacts;
  };

  const renderActions = () => {
    return (
      <>
        <Button
          color="primary"
          variant="contained"
          autoFocus
          onClick={showContactDialog ? handleContactSave : save}
          disabled={phoneError || emailError || (!lastname && !showContactDialog)}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };
  const renderPrefix = () => {
    const onChange = (ev, val) => setPrefix(val);
    return (
      <Dictionary
        options="NamePrefixes"
        className={classes.item}
        onChange={onChange}
        value={prefix}
        label="Prefix"
      />
    );
  };

  const renderSuffix = () => {
    const onChange = (ev, val) => setSuffix(val);
    return (
      <Dictionary
        options="NameSuffixes"
        className={classes.item}
        onChange={onChange}
        value={suffix}
        label="Suffix"
      />
    );
  };

  const renderFirstname = () => {
    return (
      <TextField
        label="First Name"
        variant="outlined"
        onChange={e => setFirstname(e.target.value)}
        value={firstname}
        size="small"
        className={classes.item}
      />
    );
  };

  const renderMiddleName = () => {
    return (
      <TextField
        label="Middle Name"
        variant="outlined"
        onChange={e => setMiddlename(e.target.value)}
        value={middlename}
        size="small"
        className={classes.item}
      />
    );
  };

  const renderLastName = () => {
    return (
      <TextField
        label="Last Name"
        variant="outlined"
        onChange={e => setLastname(e.target.value)}
        value={lastname}
        size="small"
        error={!lastname}
        className={classes.item}
      />
    );
  };

  const renderSex = () => {
    const onChange = (ev, val) => setSex(val);
    return (
      <Dictionary
        options="Sex"
        className={classes.item}
        onChange={onChange}
        value={sex}
        label="Sex"
      />
    );
  };

  const renderRace = () => {
    const onChange = (ev, val) => setRace(val);
    return (
      <Dictionary
        options="Race"
        className={classes.item}
        onChange={onChange}
        value={race}
        label="Race"
      />
    );
  };

  const renderBirthDate = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          variant="inline"
          inputVariant="outlined"
          format="MM/dd/yyyy"
          margin="normal"
          label="Birth Date"
          value={birthdate}
          onChange={date => setBirthdate(date)}
          autoOk
          size="small"
          className={classes.item}
        />
        {/* <KeyboardDatePicker
          style={{ marginTop: 0, paddingTop: 0 }}
          key="person_dob"
          variant="inline"
          inputVariant="outlined"
          size="small"
          className={classes.item}
          value={birthdate}
          placeholder="Birth Date"
          onChange={date => setBirthdate(date)}
          format="MM/DD/yyyy"
        /> */}
      </MuiPickersUtilsProvider>
    );
  };
  const renderEthnicity = () => {
    const onChange = (ev, val) => setEthnicity(val);
    return (
      <Dictionary
        options="Ethnicity"
        className={classes.item}
        onChange={onChange}
        value={ethnicity}
        label="Ethnicity"
      />
    );
  };

  //constact

  const renderMethodType = type => {
    return (
      <TextField
        label="Method Type"
        variant="outlined"
        value={type}
        size="small"
        disabled
        className={classes.item}
      />
    );
  };

  const renderContactType = type => {
    return (
      <Dictionary
        options="ContactMethodTypes"
        className={classes.item}
        onChange={(ev, val) => handleContactChange(type, 'MethodType', val || '')}
        value={contactList[type]?.MethodType || ''}
        label="Contact Type"
      />
    );
  };

  const renderInfoPhone = type => {
    let error;
    const handleChange = (type, val) => {
      if (val && !validatePhoneNo(val)) {
        setPhoneError(true);
      } else {
        setPhoneError(false);
      }
      handleContactChange(type, 'Info', val);
    };

    return (
      <TextField2
        className={classes.item}
        label="Info"
        onChange={(ev, val) => handleChange(type, val)}
        value={contactList[type]?.Info || ''}
        max={100}
        error={phoneError ? 'Invalid Phone Number' : ''}
        type={type}
        disabled={!contactList[type]?.MethodType}
      />
    );
  };
  const renderInfoEmail = type => {
    let error;
    const handleChange = (type, val) => {
      if (!validateEmail(val)) {
        setEmailError(true);
      } else {
        setEmailError(false);
      }
      handleContactChange(type, 'Info', val);
    };

    return (
      <TextField2
        className={classes.item}
        label="Info"
        onChange={(ev, val) => handleChange(type, val)}
        value={contactList[type]?.Info || ''}
        max={100}
        error={emailError ? 'Invalid Email Address' : ''}
        type={type}
        disabled={!contactList[type]?.MethodType}
      />
    );
  };

  const renderDefault = type => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            onChange={ev => handleContactChange(type, 'IsDefault', ev.target.checked)}
            checked={contactList[type]?.IsDefault || ''}
            color="primary"
          />
        }
        label="Default"
        className={classes.item}
      />
    );
  };
  const renderIsPrimary = type => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            onChange={ev => handleContactChange(type, 'IsPrimary', ev.target.checked)}
            checked={contactList[type]?.IsPrimary || ''}
            color="primary"
          />
        }
        label="Primary"
        className={classes.item}
      />
    );
  };
  const renderContactNotes = type => {
    return (
      <TextField
        label="Notes"
        variant="outlined"
        onChange={ev => handleContactChange(type, 'Notes', ev.target.value)}
        value={contactList[type]?.Notes || ''}
        size="small"
        className={classes.item}
      />
    );
  };
  const renderContact = type => {
    return (
      <RowInner>
        {renderMethodType(type)}
        {renderContactType(type)}
        {type === 'Phone' && renderInfoPhone(type)}
        {type === 'Email' && renderInfoEmail(type)}

        {renderDefault(type)}
        {renderContactNotes(type)}
        <Fills className={classes.item} />
      </RowInner>
    );
  };

  const handleContactChange = (type, name, val) => {
    const newObj = { ...contactList };
    if (newObj[type]) {
      newObj[type][name] = val;
    } else {
      newObj[type] = {
        [name]: val,
      };
    }
    setContactList(newObj);
  };

  return (
    <Dialog
      onClose={close}
      title={showContactDialog ? 'Add Contact' : 'Add Person'}
      open={true}
      actions={renderActions()}
      //   className={classes.dialog}

      // toolbar={false}
      // draggable={false}
      fullWidth
      maxWidth="lg">
      <DialogTitle>Add Person</DialogTitle>
      <DialogContent className="py-2">
        {!showContactDialog && (
          <>
            <Row>
              <ColCard minWidth={500}>
                <FormDivider title="Name" />
                <RowInner>
                  {renderFirstname()}
                  {renderMiddleName()}
                  {renderLastName()}
                  {renderSuffix()}
                  {renderRace()}
                  {renderSex()}
                  {renderBirthDate()}
                  {renderEthnicity()}
                  <Fills className={classes.item} />
                </RowInner>
              </ColCard>
            </Row>
            <Row>
              <ColCard minWidth={500}>
                <FormDivider title="Contact" />
                {renderContact('Email')}
                {renderContact('Phone')}
              </ColCard>
            </Row>
          </>
        )}
        {showContactDialog && (
          <Row>
            <ColCard minWidth={500}>
              <FormDivider title="Contact" />
              {showContactDialog === 'Email' && renderContact('Email')}
              {showContactDialog === 'Phone' && renderContact('Phone')}
            </ColCard>
          </Row>
        )}
      </DialogContent>
      <DialogActions>
        <div className=" mb-1 mr-3">{renderActions()}</div>
      </DialogActions>
    </Dialog>
  );
}
const mapStateToProps = state => {
  return {
    dictionary: state.dictionary,

    wsClient: state.websocket,
    user: state.user,
  };
};
export default connect(mapStateToProps, {
  showSpinner,
  hideSpinner,
  handleError,
  notify,
  closeCitationFile,
})(AddPersonDialog);
