import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { setMenuState } from '../../reducers/MenuReducer';
import { NavLink, useLocation } from 'react-router-dom';
import mainMenu, { CADSubMenu, rmsSubMenuSorted, nibrsSubMenuSorted } from './Menulist';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  menu: {
    width: '100%',
    '& hr': {
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
    },
    border: '1px solid #d2d2d2',
    paddingBottom: '30px',
  },
  link: {
    '& > span': {
      // fontWeight: 600,
    },
  },
  hasSubmenu: {
    cursor: 'pointer',
    '&:hover': {
      background: theme.colors.grey5,
    },
  },
  submenu: {
    '& > $link': {
      '& span': {
        fontWeight: 400,
        paddingLeft: 10,
        lineHeight: 1,
      },
    },
    '& > $hasSubmenu': {
      '& span': {
        fontWeight: 500,
        paddingLeft: 10,
        lineHeight: 1,
      },
    },
    '& > $submenu': {
      '& > $link': {
        '& span': {
          fontWeight: 600,
          paddingLeft: 20,
          lineHeight: 1,
          fontSize: '0.9em',
        },
      },
    },
  },
  active: {
    color: theme.palette.secondary.main,
  },
}));

function parseMenu(menuDef) {
  let id = 0;
  const state = {};

  const process = menu => {
    if (!menu) return { warning: 'menu not exists' };
    const items = [];
    menu.forEach(i => {
      const key = id++;
      const item = { key, ...i };
      if (item.type === 'menu') {
        item.submenu = process(item.submenu);
        state[key] = false;
      }
      items.push(item);
    });
    return items;
  };

  const items = process(menuDef);
  return { menu: items, state };
}

function LookupPage(props) {
  const classes = useStyles();
  const { menuState, setMenuState, handleClickOnListItem } = props;
  const location = useLocation();
  const pathName = location.pathname;
  const [menuList, setMenuList] = useState(null);
  const [filterText, setFilterText] = useState('');

  useEffect(() => {
    let menus = mainMenu;
    // let states = null;
    if (pathName === '/lookup-codes-cad' || pathName.includes('/lookup-codes-cad')) {
      menus = CADSubMenu;
      //states = state;
    } else if (pathName === '/lookup-codes-rms' || pathName.includes('/lookup-codes-rms')) {
      menus = rmsSubMenuSorted;
    } else if (pathName === '/lookup-codes-ibr' || pathName.includes('/lookup-codes-ibr')) {
      menus = nibrsSubMenuSorted;
    }

    const { menu, state } = parseMenu(menus);
    if (!menuList) setMenuList(menu);
    if (!menuState) setMenuState(state);
    // eslint-disable-next-line
  }, [pathName]);

  if (!menuState) return '';

  const renderItem = item => {
    switch (item.type) {
      case 'link':
        return renderLink(item);

      case 'menu':
        return renderMenu(item);
      default:
        return '';
    }
  };

  const renderLink = item => {
    const { key, link, label } = item;
    const mainPathName = pathName.split('/')[1];
    if (filterText && filterText.length > 0) {
      if (label.toLowerCase().indexOf(filterText.toLowerCase()) === -1) {
        return <></>;
      }
    }

    let pathLink = '';
    if (link === 'event-type-initials') {
      pathLink = '/event-type-initials';
    } else {
      pathLink = `/${mainPathName}/${link}`;
    }
    return (
      <ListItem
        component={NavLink}
        button
        to={pathLink}
        onClick={() => handleClickOnListItem(item)}
        key={key}
        className={classes.link}
        activeClassName={classes.active}>
        <ListItemText primary={label} className={classes.link} />
      </ListItem>
    );
  };

  const renderMenu = item => {
    const { key, submenu, label } = item;
    let open = menuState[key];

    let matched = false;
    if (filterText && filterText.length > 0) {
      submenu.forEach(item => {
        if (item.label.toLowerCase().indexOf(filterText.toLowerCase()) !== -1) {
          matched = true;
          open = true;
          return;
        }
      });
    } else {
      matched = true;
    }
    return (
      <Fragment key={key}>
        {matched && (
          <>
            <ListItem
              onClick={toggleItem(key)}
              className={classes.hasSubmenu}
              activeClassName={classes.active}>
              <ListItemText primary={label} />
              {open && <ExpandLess />}
              {!open && <ExpandMore />}
            </ListItem>
            <div style={{ display: open ? 'block' : 'none' }} className={classes.submenu}>
              {submenu.map(renderItem)}
            </div>
            {renderDivider(item)}
          </>
        )}
      </Fragment>
    );
  };

  const renderDivider = item => <hr key={item.key} />;

  const toggleItem = key => () => {
    const state = { ...menuState };
    const val = !state[key];
    state[key] = val;
    props.setMenuState(state);
  };

  return (
    <div className={classes.menu}>
      <TextField
        id="menu-item-filter"
        label="Filter"
        size="small"
        variant="outlined"
        style={{ width: '100%', padding: '5px', margin: '10px 3px' }}
        value={filterText}
        color="primary"
        onChange={e => {
          setFilterText(e.target.value);
        }}
      />
      <List component="nav" aria-labelledby="nested-list-subheader" dense className={classes.root}>
        {menuList && menuList.map(renderItem)}
      </List>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    menuState: state.menu,
  };
};

export default connect(mapStateToProps, { setMenuState })(LookupPage);
