import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Content from 'Settings/Content';
import SaveIcon from '@material-ui/icons/Save';
import Dialog from 'components/Dialog';

import CloseIcon from '@material-ui/icons/Close';
import { getService } from 'reducers/service';
import { handleError } from 'reducers/ErrorReducer';
import { notify } from 'reducers/NotifierReducer';

import { gridStyle } from 'utils/formStyles';
import { XGrid } from '@material-ui/x-grid';
import {
  Box,
  Button,
  Checkbox,
  DialogContentText,
  Fab,
  FormControlLabel,
  Menu,
  MenuItem,
  Popover,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';

import QuickFilter from 'components/QuickFilter';
import { displayDateTime } from 'reducers/TimeReducer';
import { showAddSecRole, showEditSecRole } from 'reducers/DialogsReducer';
import { deleteSearchGroup, findSearchGroups } from '../../SecFunctions';
import { CheckBox } from '@material-ui/icons';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import { sortObjArr } from 'utils/functions';
const useStyles = makeStyles(theme => ({
  item: {
    ...gridStyle(200, 800),
  },
  list: {
    width: '100%',
    height: 500,
  },
  wrap: {
    padding: theme.spacing(2),
    '& > h5': {
      marginBottom: 20,
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: `calc(100vh - 240px)`,
    [theme.breakpoints.down('lg')]: {
      height: '300px',
    },
  },
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0, 2),
    textAlign: 'right',
    alignItems: 'center',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
}));
const columnsItems = [
  { field: 'Label', headerName: 'Role Name', width: 150 },

  { field: 'Type', headerName: 'Type', width: 150 },
  { field: 'AgencyID', headerName: 'Agency ID', width: 150 },
];
function RolesSection(props) {
  const { wsClient, dataUpdate, profile, allProfiles, profileWithGroups, allProfileGroups } = props;
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [roleFilter, setRoleFilter] = useState('included');
  const [processedRows, setProcessedRows] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    fetchGroups();
  }, []);
  useEffect(() => {
    if (dataUpdate && (dataUpdate.type === 'add-role' || dataUpdate.type === 'edit-role')) {
      fetchGroups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsClient.websocket, dataUpdate]);
  useEffect(() => {
    if (profileWithGroups) {
      setSelection([...profileWithGroups.Groups.map(val => String(val))]);
    }
    if (!profile) setSelection([]);
  }, [profileWithGroups, processedRows]);

  useEffect(() => {
    handleRoleFilterChange(null, roleFilter);
  }, [filteredRows, profileWithGroups]);
  useEffect(() => {
    if (!profile) {
      setRoleFilter('all');
      setProcessedRows(filteredRows);
    } else setRoleFilter('included');
  }, [profile]);

  //handler functions
  const fetchGroups = async () => {
    setLoaded(false);
    const result = await findSearchGroups();
    const sortedData = sortObjArr(result, 'Label');
    setRows(sortedData);

    setLoaded(true);
  };

  const handleRoleFilterChange = (e, newValue) => {
    if (e) setRoleFilter(newValue);
    let roleList = filteredRows;
    if (newValue === 'included') {
      roleList = roleList.filter(item => profileWithGroups.Groups.indexOf(item.ptsGroupID) !== -1);
    } else if (newValue === 'excluded') {
      roleList = roleList.filter(item => {
        return profileWithGroups.Groups.indexOf(item.ptsGroupID) === -1;
      });
    }
    setProcessedRows(roleList);
  };

  const handleSelectChange = params => {
    const { selectionModel } = params;
    if (JSON.stringify(selection) !== JSON.stringify(selectionModel)) {
      setSelection(selectionModel);
    }
  };

  // UI Actions
  const handleShowEditMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseEditMenu = () => {
    setAnchorEl(null);
  };
  const handleEditRoleDetailsClick = () => {
    history.push(`/security-permissions/${selection[0]}`);
  };
  const handleAddSecRole = () => dispatch(showAddSecRole());

  const handleEditSecRole = () => dispatch(showEditSecRole(selection[0]));

  const onClickDelete = async () => {
    const isRoleExistOnProfile = allProfileGroups?.filter(
      item => item.ptsGroupID == selection[0] && item.isDeleted === false
    );

    if (isRoleExistOnProfile.length > 0) {
      const profileNames = isRoleExistOnProfile.reduce((names, curr) => {
        const profileName = allProfiles?.find(item => item.ptsProfileID == curr.ptsProfileID)
          ?.Label;

        return names + profileName + ', ';
      }, '');
      setDeleteConfirmation(profileNames);
    } else {
      setDeleteConfirmation(true);
    }
  };
  const onConfirmDelete = async () => {
    const removedGroup = await deleteSearchGroup(selection[0]);
    const newRows = rows.filter(el => el.ptsGroupID != selection[0]);
    setRows(newRows);
    props.notify('Deleted', 'warning');
    setSelection([]);
    setDeleteConfirmation(false);
  };
  const renderSaveButton = () => {
    return (
      <Fab
        className={classes.ActionIcons}
        size="small"
        color="secondary"
        onClick={() => props.handleSaveClick(selection)}>
        <SaveIcon />
      </Fab>
    );
  };

  //render delete confirmatiion actions
  const renderDeleteRolesActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={onConfirmDelete}>
          <DeleteIcon /> Remove
        </Button>
        <Button color="primary" onClick={() => setDeleteConfirmation(false)}>
          <CloseIcon />
          Close
        </Button>
      </>
    );
  };

  //UI sections
  const renderActions = () => {
    const disabled = selection?.length === 0;

    return (
      <div className={classes.actions}>
        <div>
          <QuickFilter
            minWidth="120px"
            rows={rows}
            marginTop="10px"
            columns={columnsItems}
            setFilteredRows={setFilteredRows}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Box>
            <ToggleButtonGroup
              value={roleFilter}
              exclusive
              size="small"
              onChange={handleRoleFilterChange}
              style={{ width: '100%' }}
              aria-label="Profile Filter">
              <ToggleButton
                value="included"
                disabled={!profile}
                aria-label="included"
                className={classes.toggleItem}>
                Inc
              </ToggleButton>
              <ToggleButton value="all" aria-label="all" fullWidth className={classes.toggleItem}>
                All
              </ToggleButton>
              <ToggleButton
                disabled={!profile}
                value="excluded"
                aria-label="excluded"
                fullWidth
                className={classes.toggleItem}>
                Exc
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Fab
            className={classes.ActionIcons}
            size="small"
            color="secondary"
            onClick={handleAddSecRole}>
            <AddIcon />
          </Fab>
          {profile ? (
            renderSaveButton()
          ) : (
            <>
              <Fab
                size="small"
                className={classes.ActionIcons}
                color="secondary"
                onClick={handleShowEditMenu}
                disabled={disabled}>
                <EditIcon />
              </Fab>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseEditMenu}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}>
                <MenuItem onClick={handleEditSecRole}>Edit Role</MenuItem>
                <MenuItem onClick={handleEditRoleDetailsClick}>Edit Details</MenuItem>
              </Menu>

              <Fab
                size="small"
                className={classes.ActionIcons}
                color="secondary"
                onClick={onClickDelete}
                disabled={disabled}>
                <DeleteIcon />
              </Fab>
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="m-2">
      <h4>
        Roles
        <span style={{ fontSize: '16px' }}> ({props.profile ? props.profile.Label : 'All'})</span>
      </h4>
      {renderActions()}
      <div className={classes.gridWrap}>
        <XGrid
          columns={columnsItems}
          rows={processedRows}
          loading={!loaded || props.rolesLoader}
          rowHeight={38}
          getRowId={row => row.ptsGroupID}
          checkboxSelection={profile ? true : false}
          selectionModel={[...selection]}
          onSelectionModelChange={handleSelectChange}

          // onRowSelected={row => {
          //   console.log('row', row.api?.current?.getSelectedRows());
          //   handleSelectChange(row.data);
          // }}

          // disableSelectionOnClick={profile ? true : false}
        />
      </div>
      {deleteConfirmation && (
        <Dialog
          toolbar
          title="Remove Role"
          actions={renderDeleteRolesActions()}
          maxWidth="md"
          fullWidth>
          <DialogContentText>
            Are you sure you want to remove this Role?
            <br />
            {typeof deleteConfirmation === 'string' && (
              <>
                <p style={{ color: 'red' }}>
                  Deleting the role will also remove it from any profile it is attatched to
                </p>
                Current attatched profiles: <b> {deleteConfirmation}</b>
              </>
            )}
          </DialogContentText>
        </Dialog>
      )}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    wsClient: state.websocket,
    dataUpdate: state.dataUpdate,
  };
};

export default connect(mapStateToProps, {
  handleError,
  notify,
})(RolesSection);
