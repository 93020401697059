import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import QuickFilterSearch from 'components/QuickFilterSearch';
import XGrid3 from 'components/XGrid3';
import Tooltip from 'components/Tooltip';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import {
  getPtsPlace,
  getAgency,
  savePlaceAddress,
  removePlaceAddress,
} from 'Settings/Agencies/AgencyFunctions';
import { showPlaceAddressEdit } from 'reducers/DialogsReducer';
import { deletePlaceEmployment } from 'reducers/PlacesReducer';
import AddressLookup from 'components/AddressLookup';
import Checkbox2 from 'components/Checkbox2';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { notify } from 'reducers/NotifierReducer';
import { handleError } from 'reducers/ErrorReducer';

const useStyles = makeStyles(theme => ({
  wrap: {
    padding: theme.spacing(3),
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  fileInput: {
    display: 'none',
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginBottom: 8,
  },
  addressWidget: {
    width: 550,
    display: 'inline-block',
  },
  address: {
    display: 'flex',
    margin: '24px 0 16px',
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
  },
  addressActions: {
    marginLeft: 8,
    '& button': {
      marginLeft: 8,
    },
    '& svg': {
      marginRight: 8,
    },
  },
}));

const defaultColumns = [
  {
    field: 'IsPrimary',
    headerName: 'IsPrimary',
    format: 'bool',
  },
  { field: 'AddressID', headerName: 'Address ID' },
  { field: 'FullAddressText', headerName: 'Full Address' },
  { field: 'AddressNumber', headerName: 'Address Number' },
  { field: 'StreetName', headerName: 'Street Name' },
  { field: 'StreetType', headerName: 'Street Type' },
  { field: 'City', headerName: 'City' },
  { field: 'State', headerName: 'State' },
  { field: 'PostalCode', headerName: 'Postal Code' },
  { field: 'PostalCodeExtension', headerName: 'Extension' },
  { field: 'Country', headerName: 'Country' },
  { field: 'AddressCategory', headerName: 'Address Category' },
  {
    field: 'Created',
    headerName: 'Created',
    format: 'date',
  },
  { field: 'CreatedBy', headerName: 'Created By' },
  {
    field: 'Updated',
    headerName: 'Updated',
    format: 'date',
  },
  { field: 'UpdatedBy', headerName: 'Updated By' },
];

function AgencyAddresses(props) {
  const classes = useStyles();
  const { agencyId, dataUpdate } = props;
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [columns, setColumns] = useState(defaultColumns);
  const [selection, setSelection] = useState(null);
  const [ptsPlaceID, setPtsPlaceID] = useState(null);
  const [editing, setEditing] = useState(false);
  const [address, setAddress] = useState(null);
  const [IsPrimary, setIsPrimary] = useState(false);

  useEffect(() => {
    search();
  }, []);

  useEffect(() => {
    if (dataUpdate && dataUpdate.type === 'Address') search();
  }, [dataUpdate]);

  const search = async () => {
    const curAgency = await getAgency(agencyId);
    const { ptsPlaceID } = curAgency;
    setPtsPlaceID(ptsPlaceID);
    const curPlace = await getPtsPlace(ptsPlaceID);
    setRows(processData(curPlace.PlaceAddress));
  };

  const processData = addresses => {
    return addresses.map(address => {
      address.Address.id = address.Address.ptsAddressID;
      address.Address.IsPrimary = address.IsPrimary;
      address.Address.City = address.Address?.City?.CityDescription;
      return address.Address;
    });
  };

  const add = () => {
    setEditing(true);
    setAddress(null);
  };

  const edit = () => {
    const selectedItem = rows.find(row => row.ptsAddressID == selection);
    props.showPlaceAddressEdit({
      ptsAddressID: selection,
      IsPrimary: selectedItem.IsPrimary,
      ptsPlaceID,
    });
  };

  const onDelete = async () => {
    if (!window.confirm('Are you sure you want to delete selected address?')) return;
    try {
      await removePlaceAddress({ ptsPlaceID, ptsAddressID: selection });
      search();
      props.notify('Address removed', 'success');
    } catch (err) {
      props.handleError(err, 'Error, Unable to remove address');
    }
  };

  const save = () => {
    const data = {
      ptsPlaceID,
      ptsAddressID: address.ptsAddressID,
      IsPrimary,
    };
    savePlaceAddress(data)
      .then(() => {
        setAddress(false);
        setEditing(false);
        search();
      })
      .catch(props.handleError);
  };

  const onAddAddress = address => {
    setAddress(address);
  };

  const clearPlace = () => {
    setAddress(null);
  };

  const renderIsPrimary = () => {
    const onChange = () => setIsPrimary(!IsPrimary);
    return <Checkbox2 checked={IsPrimary} onChange={onChange} label="Is Primary" />;
  };

  const renderActions = () => {
    return (
      <div className={classes.addressActions}>
        <Button color="primary" variant="contained" autoFocus onClick={save} disabled={!address}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={() => setEditing(false)}>
          <CloseIcon /> Close
        </Button>
      </div>
    );
  };

  return (
    <div className={classes.wrap}>
      <div className={classes.filterWrap}>
        {editing && (
          <>
            <div className={classes.form}>
              <AddressLookup
                className={classes.addressWidget}
                ptsPlaces={false}
                ptsAddresses={true}
                googleAddresses={false}
                onPlaceValueSet={onAddAddress}
                onAddressValueSet={onAddAddress}
                onReset={clearPlace}
                label="Search Address"
                compact
              />
              {renderIsPrimary()}
            </div>
            <div>{renderActions()}</div>
          </>
        )}
        {!editing && (
          <>
            <div className={classes.filterWrap}>
              <QuickFilterSearch rows={rows} columns={columns} setFilteredRows={setFilteredRows} />
            </div>
            <div className={classes.actions}>
              <Tooltip title="Add">
                <Fab size="small" color="secondary" onClick={add}>
                  <AddIcon />
                </Fab>
              </Tooltip>

              {selection !== null && (
                <>
                  <Tooltip title="Edit">
                    <Fab size="small" color="secondary" onClick={edit}>
                      <EditIcon />
                    </Fab>
                  </Tooltip>

                  <Tooltip title="Delete">
                    <Fab size="small" color="secondary" onClick={onDelete}>
                      <DeleteIcon />
                    </Fab>
                  </Tooltip>
                </>
              )}
            </div>
          </>
        )}
      </div>
      <XGrid3
        columns={columns}
        setColumns={setColumns}
        rows={filteredRows}
        setSelection={setSelection}
        xgridHeight={250}
      />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    dataUpdate: state.dataUpdate,
  };
};

export default connect(mapStateToProps, { showPlaceAddressEdit, notify, handleError })(
  AgencyAddresses
);
