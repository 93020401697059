import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { findSecComponents, findSecGroupComponents, saveGroupBooleans } from '../SecFunctions';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import { handleError } from 'reducers/ErrorReducer';
import { notify } from 'reducers/NotifierReducer';
import { Fills } from 'utils/formStyles';
import { isDevelopment } from 'utils/functions';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import FormDivider from 'components/FormDivider';
import { MainPermissionContext } from '.';

const useStyles = makeStyles(theme => ({
  checkStyle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '5px',
  },
  checkStyleForpPlus: {
    border: `1px solid ${theme.colors.border}`,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '5px',
    borderRadius: '15px',
    marginBottom: '10px',
  },
  item: {
    flex: '1 1 250px',
    margin: '5px',
  },
  saveBtn: {
    textAlign: 'right',
  },
}));
export const sortPPlusModules = data => {
  const mappedValues = { cad: 0, mobile: 1, rms: 2, settings: 3, 'incident archive': 4 };
  const newArr = new Array(data.length).fill(0);
  data.forEach(item => {
    const position = mappedValues[item.Label.toLowerCase()];
    newArr[position] = item;
  });
  const processedData = newArr.filter(item => Boolean(item));
  return processedData;
};
function PermissionsApps(props) {
  const classes = useStyles();
  const {
    role,
    allComponents = [],
    allGroupComponents = [],
    refreshComponets,
    setRefreshComponent,
  } = useContext(MainPermissionContext);
  const [allModules, setAllModules] = useState([]);
  const [platinumPlusModules, setPlatinumPlusComponents] = useState([]);
  const [currentRoleWithModules, setCurrentRoleWithModules] = useState(null);
  const [existingModuleIds, setExistingModuleIds] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    fetchAndProcessData();
  }, [role]);

  const fetchAndProcessData = async () => {
    props.showSpinner();
    //fetch all components  and filterd by type modules

    const filteredComponets = allComponents.filter(c => c.Type === 'Modules');
    const p_plusModules = [];
    const other_modules = [];
    (filteredComponets || []).forEach(component => {
      if (component?.Flags?.trim() === 'P+') {
        p_plusModules.push(component);
      } else {
        other_modules.push(component);
      }
    });
    setAllModules(other_modules);
    setPlatinumPlusComponents(sortPPlusModules(p_plusModules));

    //fetch all the permission groups and check if speficif component has permission checked or not

    const filtered = allGroupComponents.filter(
      el => el.ptsGroupID === role.ptsGroupID && el.Permissions === 1
    );
    //console.log('all group components ', allGroupComponents);
    const modules = [];
    for (const module of filteredComponets) {
      const found = filtered.find(el => module.ptsComponentID === el.ptsComponentID);
      if (found) modules.push(module.ptsComponentID);
    }

    const newRole = { ...role, Applications: modules };
    setCurrentRoleWithModules(newRole);

    const existingComponents = allGroupComponents.filter(el => el.ptsGroupID === role.ptsGroupID);
    const existingIds = [];
    for (const module of filteredComponets) {
      const found = existingComponents.find(el => module.ptsComponentID === el.ptsComponentID);
      if (found) existingIds.push(module.ptsComponentID);
    }
    setExistingModuleIds(existingIds);
    if (initialLoad) {
      props.hideSpinner();
      setInitialLoad(false);
    } else {
      setTimeout(() => {
        props.hideSpinner();
      }, 1000);
    }
  };

  const handleSaveClick = async () => {
    const data = {
      GroupId: role.ptsGroupID,
      ExistingComponents: existingModuleIds,
      CurrentComponents: currentRoleWithModules.Applications,
    };
    try {
      await saveGroupBooleans(data);
      setRefreshComponent(!refreshComponets);
      props.notify('Application Permissions Updated', 'success');
    } catch (err) {
      props.handleError(err, 'Error Updating Application Permissions');
    }
  };

  const handleChange = module => {
    const newModulesIds = [...currentRoleWithModules.Applications];
    const foundIdx = newModulesIds.indexOf(module.ptsComponentID);
    if (foundIdx === -1) {
      newModulesIds.push(module.ptsComponentID);
    } else newModulesIds.splice(foundIdx, 1);
    const newRoleWithModules = { ...currentRoleWithModules, Applications: newModulesIds };
    setCurrentRoleWithModules(newRoleWithModules);
  };

  const handleChecked = module => {
    if (!currentRoleWithModules) return false;
    const currentModulesIds = [...currentRoleWithModules.Applications];
    const found = currentModulesIds.find(modId => modId === module.ptsComponentID);
    return Boolean(found);
  };
  const renderModule = module => {
    const label = isDevelopment ? `${module.Label} (${module.ptsComponentID})` : module.Label;
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={handleChecked(module)}
            onChange={() => handleChange(module)}
            color="primary"
          />
        }
        label={label}
      />
    );
  };

  const renderSaveButton = () => {
    return (
      <div className={classes.saveBtn}>
        <Button onClick={handleSaveClick} variant="contained" color="primary">
          <SaveIcon className="mr-2" /> Save
        </Button>
      </div>
    );
  };

  return (
    <div>
      {renderSaveButton()}
      <FormDivider title="Platinum Plus" textOnly={true} />
      <div className={classes.checkStyleForpPlus}>
        {platinumPlusModules.map(m => (
          <span key={m.ptsComponentID} className={classes.item}>
            {renderModule(m)}
          </span>
        ))}
        <Fills className={classes.item} />
      </div>
      <FormDivider title="Others" textOnly={true} />

      <div className={classes.checkStyle}>
        {allModules.map(m => (
          <span key={m.ptsComponentID} className={classes.item}>
            {renderModule(m)}
          </span>
        ))}
        <Fills className={classes.item} />
      </div>
    </div>
  );
}

export default connect(null, { handleError, notify, showSpinner, hideSpinner })(PermissionsApps);
