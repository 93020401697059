import React, { useEffect, useState } from 'react';

import CheckIcon from '@material-ui/icons/Check';
import { makeStyles } from '@material-ui/core';

import { XGrid } from '@material-ui/x-grid';
import TopCard from './TopCard';
import AttatchmentsActions from './AttatchmentsActions';

const useStyles = makeStyles(theme => ({
  gridWrap: {
    width: '100%',
    // boxSizing: 'border-box',
    height: '450px',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    minHeight: theme.spacing(6),
    width: '100%',
  },
  filterWrap: {
    flexShrink: 1,
    flexBasis: 400,
  },
  filter: {
    width: '100%',
  },
  actions: {
    flexGrow: 0,
    flexShrink: 0,

    width: '100%',
    marginLeft: theme.spacing(1),
    textAlign: 'right',
    paddingRight: '15px',
    paddingBottom: '5px',
  },
}));

const bool = params => (params.value ? <CheckIcon /> : <span></span>);

const columns = [
  { field: 'AddressID', headerName: 'Address ID', width: 150, hide: false },
  { field: 'FullAddressText', headerName: 'Full Address', width: 200, hide: false },
  {
    field: 'IsPrimary',
    headerName: 'Is Primary',
    width: 100,
    format: 'bool',
    renderCell: bool,
  },
  {
    field: 'Country',
    headerName: 'Country',
    width: 125,
  },
  {
    field: 'State',
    headerName: 'State',
    width: 100,
  },
  {
    field: 'StreetName',
    headerName: 'StreetName',
    width: 150,
  },
  {
    field: 'BldgType',
    headerName: 'BldgType',
    width: 150,
  },
  {
    field: 'BldgIdentifier',
    headerName: 'Bldg Identifier',
    width: 150,
  },
  {
    field: 'AddressNumberPrefix',
    headerName: 'Prefix',
    width: 150,
  },
  {
    field: 'AddressNumber',
    headerName: 'Number',
    width: 150,
  },
  {
    field: 'AddressNumberSuffix',
    headerName: 'Suffix',
    width: 150,
  },
  {
    field: 'PreDirection',
    headerName: 'Pre Direction',
    width: 150,
  },
  {
    field: 'City',
    headerName: 'City',
    width: 150,
  },
  {
    field: 'PostalCode',
    headerName: 'Postal Code',
    width: 150,
  },
  {
    field: 'PostalCodeExtension',
    headerName: 'Extension',
    width: 150,
  },
  {
    field: 'FloorType',
    headerName: 'Floor Type',
    width: 150,
  },
  {
    field: 'FloorIdentifier',
    headerName: 'Floor Identifier',
    width: 150,
  },
  {
    field: 'UnitType',
    headerName: 'Unit Type',
    width: 150,
  },
  {
    field: 'Unit Identifier',
    headerName: 'Unit Identifier',
    width: 150,
  },
];
const AddressTab = props => {
  const { addressList, ptsSOPID } = props;
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const [selection, setSelection] = useState(null);
  useEffect(() => {
    setRows(addressList);
  }, [addressList]);

  return (
    <div>
      <TopCard parent="Address" ptsSOPID={ptsSOPID} />
      <h5>Addresses</h5>
      {/* {renderToolbar()} */}
      <AttatchmentsActions ptsSOPID={ptsSOPID} ParentType={'Address'} selection={selection} />
      <div className={classes.gridWrap}>
        <XGrid
          columns={columns}
          rows={rows}
          loading={!loaded}
          rowHeight={38}
          disableMultipleSelection
          getRowId={row => row.ptsAddressID}
          onSelectionModelChange={newSelection => {
            setSelection(parseInt(newSelection.selectionModel[0]));
          }}
        />
      </div>
    </div>
  );
};

export default AddressTab;
