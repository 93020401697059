import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Content from './Content';
import Autocomplete2 from 'components/Autocomplete2';
import { handleError } from 'reducers/ErrorReducer';
import FormDivider from 'components/FormDivider';
import { gridStyle, Row, RowInner, ColCard, Fills } from 'utils/formStyles';
import SaveIcon from '@material-ui/icons/Save';
import { getService } from 'reducers/service';
import Button from '@material-ui/core/Button';
import { notify } from 'reducers/NotifierReducer';
import PlaceLookup from 'components/PlaceLookup';
import { Box, Fab, InputAdornment, Menu, TextField, Typography } from '@material-ui/core';
import { SketchPicker } from 'react-color';
import { hideSpinner, showSpinner } from 'reducers/UiReducer';

const useStyles = makeStyles(theme => ({
  item: {
    ...gridStyle(200, 300),
  },
  colorTitle: {
    fontWeight: 'bold',
    fontSize: '18px',
    marginBottom: '0px',
  },
}));
const defaultEventActionColor = '#FFCCCCCC';
const EventCategoryType = [
  'Pending',
  'OnHold',
  'Dispatched',
  'Enroute',
  'Arrived',
  'Completed',
  'Controlled',
  'Closed',
  'Scheduled',
];
function Defaults(props) {
  const classes = useStyles();
  const service = getService('settings-defaults');
  const vehiclePlaceService = getService('settings-add-vehicle-default-place');
  const evenColorService = getService('codecolors');
  const cdrService = getService('cdr');
  const [place, setPlace] = useState(null);
  const [ptsPlaceID, setPlaceID] = useState(null);
  const { CredentialTypes, UnitActions, CallTypes, PartyRelationships } = props.dictionary;
  const [olnCode, setOlnCode] = useState(null);
  const [ssnCode, setSsnCode] = useState(null);
  const [employeeNumber, setEmployeeNumber] = useState(null);
  const [dispatched, setDispatched] = useState(null);
  const [enroute, setEnroute] = useState(null);
  const [arrived, setArrived] = useState(null);
  const [completed, setCompleted] = useState(null);
  const [offduty, setOffduty] = useState(null);
  const [inservice, setInservice] = useState(null);
  const [queued, setQueued] = useState(null);
  const [cancelled, setCancelled] = useState(null);
  const [unitInitiated, setUnitInitiated] = useState(null);
  const [assignedOfficer, setAssignedOfficer] = useState(null);
  const [assistingOfficer, setAssistingOfficer] = useState(null);
  const [receivedBy, setReceivedBy] = useState(null);
  const [relDispatcher, setRelDispatcher] = useState(null);
  const [caller, setCaller] = useState(null);
  const [location, setLocation] = useState(null);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [eventColors, setEventColors] = useState(null);
  const [modifiedEventColors, setModifiedEvntColors] = useState(null);
  const [pickerAnchor, setPickerAnchor] = useState(null);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [inactiveTimeLimit, setInactiveTimeLimit] = useState(null);
  const [inactiveTimeEditMode, setInactiveTimeEditMode] = useState(false);
  const user = useSelector(state => state.user);
  const userName = user?.userData?.user?.Username || '';
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.wsClient.websocket) {
      search();
      fetchDefaultPlace();
      searchInactiveTimeData();
    }
  }, [props.dictionary, props.wsClient]);

  const search = async () => {
    const data = await service.find();
    const { settings, eventColors } = data;
    setInitialData(settings);

    setEventColors(eventColors);
  };
  const fetchDefaultPlace = async () => {
    const data = await vehiclePlaceService.get(null);
    if (data && data.ptsPlaceID) {
      setPlace(data);
      setPlaceID(data.ptsPlaceID);
    }
  };
  const searchInactiveTimeData = async () => {
    const data = await cdrService.get({
      Path: 'Unit.InactivationTime',
      CDRKey: 'TimeLimit',
    });

    if (data && data.length > 0) {
      setInactiveTimeEditMode(true);
      setInactiveTimeLimit(data[0].ValueNumeric || 0);
    }
  };

  const findValInDict = (table, value) => {
    switch (table) {
      case 'CredentialTypes':
        return (
          CredentialTypes?.find(type => type.Code === value || type.Description === value) || null
        );
      case 'UnitActions':
        return UnitActions?.find(type => type.Code === value || type.Description === value) || null;
      case 'CallTypes':
        return CallTypes?.find(type => type.Code === value || type.Description === value) || null;
      case 'PartyRelationships':
        return (
          PartyRelationships?.find(type => type.Code === value || type.Description === value) ||
          null
        );
      default:
        return null;
    }
  };
  const handelEventColorChange = ColorValue => {
    setEventColors({
      Events: {
        ...(eventColors?.Events || {}),
        [currentCategory]: ColorValue,
      },
    });
    const newObj = { ...(modifiedEventColors || {}) };
    newObj[currentCategory] = ColorValue;
    setModifiedEvntColors(newObj);
  };

  const setInitialData = data => {
    for (const row of data) {
      let cur;
      switch (row.Path) {
        case 'CAD.DefaultCodes.CredentialTypes.OLN':
          cur = findValInDict('CredentialTypes', row.ValueString);
          setOlnCode(cur);
          break;
        case 'CAD.DefaultCodes.CredentialTypes.Employee':
          cur = findValInDict('CredentialTypes', row.ValueString);
          setEmployeeNumber(cur);
          break;
        case 'CAD.DefaultCodes.CredentialTypes.SSN':
          cur = findValInDict('CredentialTypes', row.ValueString);
          setSsnCode(cur);
          break;
        case 'CAD.DefaultCodes.EventType.UnitInitiated':
          cur = findValInDict('CallTypes', row.ValueString);
          setUnitInitiated(cur);
          break;
        case 'CAD.DefaultCodes.Relationships.Assisting':
          cur = findValInDict('PartyRelationships', row.ValueString);
          setAssistingOfficer(cur);
          break;
        case 'CAD.DefaultCodes.Relationships.AssignedOfficer':
          cur = findValInDict('PartyRelationships', row.ValueString);
          setAssignedOfficer(cur);
          break;
        case 'CAD.DefaultCodes.Relationships.Caller':
          cur = findValInDict('PartyRelationships', row.ValueString);
          setCaller(cur);
          break;
        case 'CAD.DefaultCodes.Relationships.Dispatcher':
          cur = findValInDict('PartyRelationships', row.ValueString);
          setRelDispatcher(cur);
          break;
        case 'CAD.DefaultCodes.Relationships.Location':
          cur = findValInDict('PartyRelationships', row.ValueString);
          setLocation(cur);
          break;
        case 'CAD.DefaultCodes.Relationships.ReceivedBy':
          cur = findValInDict('PartyRelationships', row.ValueString);
          setReceivedBy(cur);
          break;
        case 'CAD.DefaultCodes.UnitActions.Arrived':
          cur = findValInDict('UnitActions', row.ValueString);
          setArrived(cur);
          break;
        case 'CAD.DefaultCodes.UnitActions.Cancelled':
          cur = findValInDict('UnitActions', row.ValueString);
          setCancelled(cur);
          break;
        case 'CAD.DefaultCodes.UnitActions.Completed':
          cur = findValInDict('UnitActions', row.ValueString);
          setCompleted(cur);
          break;
        case 'CAD.DefaultCodes.UnitActions.Dispatch':
          cur = findValInDict('UnitActions', row.ValueString);
          setDispatched(cur);
          break;
        case 'CAD.DefaultCodes.UnitActions.Enroute':
          cur = findValInDict('UnitActions', row.ValueString);
          setEnroute(cur);
          break;
        case 'CAD.DefaultCodes.UnitActions.Inservice':
          cur = findValInDict('UnitActions', row.ValueString);
          setInservice(cur);
          break;
        case 'CAD.DefaultCodes.UnitActions.Outservice':
          cur = findValInDict('UnitActions', row.ValueString);
          setOffduty(cur);
          break;
        case 'CAD.DefaultCodes.UnitActions.Queued':
          cur = findValInDict('UnitActions', row.ValueString);
          setQueued(cur);
          break;
        default:
      }
    }
  };

  //handlers functions

  const handleSaveClick = async () => {
    const data = formatSaveData();
    try {
      await service.create(data);
      handleVehiclePlaceSaveClick();
      handleInactiveUnitTimeSave();

      if (modifiedEventColors) {
        handleEventColorChange();
      }

      props.notify('Defaults Saved.', 'success');
    } catch (err) {
      props.handleError(err, 'Error Saving Defaults.');
    }
  };

  const handleVehiclePlaceSaveClick = async () => {
    const ptsPlaceID = place ? place.ptsPlaceID : null;
    try {
      await vehiclePlaceService.create({ ptsPlaceID });
      // props.notify('Default Place Updated.', 'success');
    } catch (err) {
      props.handleError(err, 'Error Updating Default Place');
    }
  };
  const handleInactiveUnitTimeSave = async () => {
    // const data = formatSaveData();
    try {
      if (inactiveTimeEditMode) {
        await cdrService.patch(null, {
          Path: 'Unit.InactivationTime',
          CDRKey: 'TimeLimit',
          ValueNumeric: Number(inactiveTimeLimit),
        });
      } else {
        await cdrService.create({
          Path: 'Unit.InactivationTime',
          CDRKey: 'TimeLimit',
          ValueNumeric: Number(inactiveTimeLimit),
        });
      }
    } catch (err) {
      props.handleError(err, 'Error Saving Time.');
    }
  };

  const handleEventColorChange = async () => {
    dispatch(showSpinner());
    try {
      const results = await Promise.all(
        Object.entries(modifiedEventColors).map(([ColorKey, ColorValue]) => {
          return evenColorService.patch(
            null,
            { ColorValue, UpdatedBy: userName },
            {
              query: {
                Category: 'EVENT',
                ColorKey,
              },
            }
          );
        })
      );
      // dispatch(notify('Updated', 'success'));
      setModifiedEvntColors(null);
    } catch (error) {
      dispatch(notify('Error Updating Color', 'error'));
      console.log('errr', error);
    } finally {
      dispatch(hideSpinner());
    }
  };
  const renderSaveBtnForColors = () => {
    return (
      <Button
        onClick={handleEventColorChange}
        variant="contained"
        disabled={!modifiedEventColors}
        color="primary">
        <SaveIcon className="mr-2" /> Save
      </Button>
    );
  };

  const formatSaveData = () => {
    return [
      {
        Path: 'CAD.DefaultCodes.CredentialTypes.OLN',
        ValueString: olnCode?.Code || null,
      },
      {
        Path: 'CAD.DefaultCodes.CredentialTypes.Employee',
        ValueString: employeeNumber?.Code || null,
      },
      {
        Path: 'CAD.DefaultCodes.CredentialTypes.SSN',
        ValueString: ssnCode?.Code || null,
      },
      {
        Path: 'CAD.DefaultCodes.EventType.UnitInitiated',
        ValueString: unitInitiated?.Code || null,
      },
      {
        Path: 'CAD.DefaultCodes.Relationships.Assisting',
        ValueString: assistingOfficer?.Code || null,
      },
      {
        Path: 'CAD.DefaultCodes.Relationships.AssignedOfficer',
        ValueString: assignedOfficer?.Code || null,
      },
      {
        Path: 'CAD.DefaultCodes.Relationships.Caller',
        ValueString: caller?.Code || null,
      },
      {
        Path: 'CAD.DefaultCodes.Relationships.Dispatcher',
        ValueString: relDispatcher?.Code || null,
      },
      {
        Path: 'CAD.DefaultCodes.Relationships.Location',
        ValueString: location?.Code || null,
      },
      {
        Path: 'CAD.DefaultCodes.Relationships.ReceivedBy',
        ValueString: receivedBy?.Code || null,
      },
      {
        Path: 'CAD.DefaultCodes.UnitActions.Arrived',
        ValueString: arrived?.Code || null,
      },
      {
        Path: 'CAD.DefaultCodes.UnitActions.Cancelled',
        ValueString: cancelled?.Code || null,
      },
      {
        Path: 'CAD.DefaultCodes.UnitActions.Completed',
        ValueString: completed?.Code || null,
      },
      {
        Path: 'CAD.DefaultCodes.UnitActions.Dispatch',
        ValueString: dispatched?.Code || null,
      },
      {
        Path: 'CAD.DefaultCodes.UnitActions.Enroute',
        ValueString: enroute?.Code || null,
      },
      {
        Path: 'CAD.DefaultCodes.UnitActions.Inservice',
        ValueString: inservice?.Code || null,
      },
      {
        Path: 'CAD.DefaultCodes.UnitActions.Outservice',
        ValueString: offduty?.Code || null,
      },
      {
        Path: 'CAD.DefaultCodes.UnitActions.Queued',
        ValueString: queued?.Code || null,
      },
    ];
  };

  //render componets
  const renderCredentialCodes = () => {
    return (
      <div>
        <FormDivider title="Default Credential Codes" />
        <RowInner>
          <Autocomplete2
            options={CredentialTypes}
            className={classes.item}
            onChange={(ev, val) => setOlnCode(val)}
            value={olnCode}
            label="OLN Code"
          />
          <Autocomplete2
            options={CredentialTypes}
            className={classes.item}
            onChange={(ev, val) => setSsnCode(val)}
            value={ssnCode}
            label="SSN Code"
          />
          <Autocomplete2
            options={CredentialTypes}
            className={classes.item}
            onChange={(ev, val) => setEmployeeNumber(val)}
            value={employeeNumber}
            label="Employee Number Code"
          />
        </RowInner>
      </div>
    );
  };

  const matchActionsByCat = cat => {
    return UnitActions?.filter(action => action.Category?.toLowerCase() === cat) || [];
  };

  const renderUnitActions = () => {
    return (
      <Row>
        <ColCard>
          <FormDivider title="Default Unit Actions" />

          <RowInner>
            <Autocomplete2
              options={matchActionsByCat('dispatch')}
              className={classes.item}
              onChange={(ev, val) => setDispatched(val)}
              value={dispatched}
              label="Dispatched"
            />
            <Autocomplete2
              options={matchActionsByCat('enroute')}
              className={classes.item}
              onChange={(ev, val) => setEnroute(val)}
              value={enroute}
              label="Enroute"
            />
            <Autocomplete2
              options={matchActionsByCat('arrived')}
              className={classes.item}
              onChange={(ev, val) => setArrived(val)}
              value={arrived}
              label="Arrived"
            />
            <Autocomplete2
              options={matchActionsByCat('completed')}
              className={classes.item}
              onChange={(ev, val) => setCompleted(val)}
              value={completed}
              label="Completed"
            />
            <Autocomplete2
              options={matchActionsByCat('outservice')}
              className={classes.item}
              onChange={(ev, val) => setOffduty(val)}
              value={offduty}
              label="Off Duty"
            />
            <Autocomplete2
              options={matchActionsByCat('inservice')}
              className={classes.item}
              onChange={(ev, val) => setInservice(val)}
              value={inservice}
              label="In Service"
            />
            <Autocomplete2
              options={matchActionsByCat('queued')}
              className={classes.item}
              onChange={(ev, val) => setQueued(val)}
              value={queued}
              label="Queued"
            />
            <Autocomplete2
              options={matchActionsByCat('cancelled')}
              className={classes.item}
              onChange={(ev, val) => setCancelled(val)}
              value={cancelled}
              label="Cancelled"
            />
            <Fills className={classes.item} />
          </RowInner>
        </ColCard>
      </Row>
    );
  };

  const renderEventTypes = () => {
    return (
      <div>
        <FormDivider title="Default Event Type" />
        <Autocomplete2
          options={CallTypes}
          className={classes.item}
          onChange={(ev, val) => setUnitInitiated(val)}
          value={unitInitiated}
          label="Unit Initiated"
        />
      </div>
    );
  };

  const renderRelationshipCodes = () => {
    return (
      <div>
        <FormDivider title="Default Relationship Codes" />
        <RowInner>
          <Autocomplete2
            options={PartyRelationships}
            className={classes.item}
            onChange={(ev, val) => setAssignedOfficer(val)}
            value={assignedOfficer}
            label="Assigned Officer"
          />
          <Autocomplete2
            options={PartyRelationships}
            className={classes.item}
            onChange={(ev, val) => setAssistingOfficer(val)}
            value={assistingOfficer}
            label="Assisting Officer"
          />
          <Autocomplete2
            options={PartyRelationships}
            className={classes.item}
            onChange={(ev, val) => setReceivedBy(val)}
            value={receivedBy}
            label="Received By"
          />
          <Autocomplete2
            options={PartyRelationships}
            className={classes.item}
            onChange={(ev, val) => setRelDispatcher(val)}
            value={relDispatcher}
            label="Dispatcher"
          />
          <Autocomplete2
            options={PartyRelationships}
            className={classes.item}
            onChange={(ev, val) => setCaller(val)}
            value={caller}
            label="Caller"
          />
          <Autocomplete2
            options={PartyRelationships}
            className={classes.item}
            onChange={(ev, val) => setLocation(val)}
            value={location}
            label="Location"
          />
          <Fills className={classes.item} />
        </RowInner>
      </div>
    );
  };

  const renderVehiclePlaceSaveBtn = () => {
    return (
      <Button onClick={handleSaveClick} variant="contained" className="mt-4" color="primary">
        <SaveIcon className="mr-2" /> Save
      </Button>
    );
  };

  const renderPlace = () => {
    const handleChange = value => {
      setPlace(value);
    };
    return (
      <PlaceLookup
        onChange={handleChange}
        className={classes.item}
        label="Place"
        value={place}
        ptsPlaceID={ptsPlaceID}
      />
    );
  };
  const renderInactiveTime = () => {
    return (
      <TextField
        label="Time Limit"
        id="outlined-inactive-time"
        style={{ width: '250px' }}
        variant="outlined"
        size="small"
        type="number"
        onKeyPress={e => {
          if (e.key === '-' || e.key === '+' || e.key === '.') {
            e.preventDefault();
          }
        }}
        value={inactiveTimeLimit || ''}
        onChange={e => {
          setInactiveTimeLimit(e.target.value || 0);
        }}
        InputProps={{
          inputProps: { min: 0 },
          endAdornment: <InputAdornment position="start">Minutes</InputAdornment>,
        }}
      />
    );
  };

  const renderSaveBtn = () => {
    return (
      <div className="text-right pb-4">
        <Button onClick={handleSaveClick} variant="contained" color="primary">
          <SaveIcon className="mr-2" /> Save
        </Button>
      </div>
    );
  };

  // const renderSaveBtnForPlace = () => {
  //   return (
  //     <Button
  //       onClick={handleVehiclePlaceSaveClick}
  //       variant="contained"
  //       disabled={!place}
  //       color="primary">
  //       <SaveIcon className="mr-2" /> Save
  //     </Button>
  //   );
  // };

  return (
    <Content>
      <div className="m-4 mt-0">
        {renderSaveBtn()}
        <Row>
          <ColCard>
            <Row>
              <ColCard minWidth={500}>
                {renderCredentialCodes()}
                {renderRelationshipCodes()}
              </ColCard>
              <ColCard minWidth={500}>
                {renderEventTypes()}
                {renderUnitActions()}
              </ColCard>
            </Row>
          </ColCard>
        </Row>

        <Row className="mt-4">
          <ColCard minWidth={500}>
            <FormDivider title="Add Vehicle Default Place" />
            <RowInner>{renderPlace()}</RowInner>
            <p className="mt-2"></p>
            <FormDivider title="Inactivation Time Limit" />
            <RowInner>
              <div
                style={{ paddingTop: '15px', display: 'flex', gap: '15px', alignItems: 'center' }}>
                <Typography variant="body1" className="mb-2 pl-1">
                  Do not display unit on the map if inactive for
                </Typography>
                {renderInactiveTime()}
              </div>
            </RowInner>
          </ColCard>
          <ColCard minWidth={500}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                paddingRight: '15px',
                paddingBottom: '15px',
              }}>
              <FormDivider title="Default Colors" />
            </div>

            <RowInner style={{ gap: '20px', alignItems: 'center' }}>
              {EventCategoryType.map(category => (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '10px',
                    alignItems: 'center',
                    width: '45%',
                    minWidth: '300px',

                    marginBottom: '15px',
                    flexWrap: 'nowrap',
                    border: '1px solid #f0f0f0',
                    textAlign: 'left',
                  }}>
                  <p className={classes.colorTitle}>{category}</p>
                  <p style={{ marginBottom: '0px' }}>
                    {eventColors?.Events[category] || defaultEventActionColor}
                  </p>
                  <Fab
                    title="Pick a color"
                    className="ml-2 mr-2"
                    size="small"
                    style={{
                      backgroundColor: eventColors?.Events[category] || defaultEventActionColor,
                      borderRadius: '4px',
                      minWidth: '35px',
                    }}
                    onClick={ev => {
                      setDisplayColorPicker(true);
                      setPickerAnchor(ev.currentTarget);
                      setCurrentCategory(category);
                    }}
                  />

                  <Menu
                    id="basic-menu"
                    anchorEl={pickerAnchor}
                    open={displayColorPicker}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    onClose={() => {
                      setDisplayColorPicker(false);
                    }}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}>
                    <SketchPicker
                      color={eventColors?.Events[category] || defaultEventActionColor}
                      onChange={color => handelEventColorChange(color.hex)}
                    />
                  </Menu>
                </div>
              ))}
            </RowInner>
          </ColCard>
        </Row>
      </div>
    </Content>
  );
}

const mapStateToProps = state => {
  return {
    dictionary: state.dictionary,
    wsClient: state.websocket,
  };
};

export default connect(mapStateToProps, {
  handleError,
  notify,
})(Defaults);
