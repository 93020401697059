import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  getGroupComponentPermission,
  findSecComponents,
  saveCodePermissions,
  findSecGroupComponents,
} from '../SecFunctions';
import Checkbox from '@mui/material/Checkbox';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import { handleError } from 'reducers/ErrorReducer';
import { notify } from 'reducers/NotifierReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';
import { PermissionContext } from '.';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  autoComplete: {
    maxWidth: 300,
    marginBottom: 10,
  },
  list: {
    width: '100%',
    height: 400,
  },
  saveBtn: {
    marginRight: 10,
    marginBottom: 20,
    textAlign: 'right',
  },
  paperColor: {
    backgroundColor: 'inherit',
  },
  tableCell: {
    color: 'inherit !important',
  },
}));

function Row(props) {
  const { row, allRows, setAllRows, filterText } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  let matched = true;
  let matchOpen = false;
  let filteredChildren = row.Children;

  if (filterText && filterText.length > 0) {
    filteredChildren = row.Children.filter(c =>
      c.Label.toLowerCase().includes(filterText.toLowerCase())
    );
    if (filteredChildren.length > 0) {
      matched = true;
      matchOpen = true;
    } else {
      matched = false;
      matchOpen = false;
    }
  } else {
    matched = true;
    filteredChildren = row.Children;
    matchOpen = false;
  }

  // const handleCoreCheckChange = (permission, shift, ptsComponentID) => {
  //   if (permission === -1) permission = 0;
  //   const mask = 1 << shift;
  //   permission ^= mask;
  //   const rowsClone = [...allRows];
  //   for (const parent of rowsClone) {
  //     if (parent.ptsComponentID === row.ptsComponentID) {
  //       for (const child of parent.Children) {
  //         if (child.ptsComponentID === ptsComponentID) {
  //           child.Permission = permission;
  //           break;
  //         }
  //       }
  //       break;
  //     }
  //   }
  //   setAllRows(rowsClone);
  // };

  const isCoreChecked = (permission, shift) => {
    if (permission === -1) return false;
    const mask = 1 << shift;
    return (permission & mask) !== 0;
  };

  return (
    <React.Fragment>
      {matched && (
        <>
          <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell className={classes.tableCell}>
              <IconButton
                aria-label="expand row"
                size="small"
                color="default"
                onClick={() => {
                  setOpen(!open);
                  matchOpen = false;
                }}>
                {open || matchOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            <TableCell className={classes.tableCell} component="th" scope="row">
              {row.Label}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              className={classes.tableCell}
              style={{ paddingBottom: 0, paddingTop: 0 }}
              colSpan={10}>
              <Collapse in={open || matchOpen} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableCell}>Components</TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          Read
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          Create
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          Edit
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          Delete
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          View Deleted
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredChildren.map(c => (
                        <TableRow key={c.ptsComponentID}>
                          <TableCell className={classes.tableCell} component="th" scope="row">
                            {c.Label}
                          </TableCell>
                          <TableCell className={classes.tableCell} align="center">
                            {
                              <Checkbox
                                checked={isCoreChecked(c.Permission, 0)}
                                // onChange={() =>
                                //   handleCoreCheckChange(c.Permission, 0, c.ptsComponentID)
                                // }
                                color="primary"
                              />
                            }
                          </TableCell>
                          <TableCell className={classes.tableCell} align="center">
                            {
                              <Checkbox
                                checked={isCoreChecked(c.Permission, 1)}
                                // onChange={() =>
                                //   handleCoreCheckChange(c.Permission, 1, c.ptsComponentID)
                                // }
                                color="primary"
                              />
                            }
                          </TableCell>
                          <TableCell className={classes.tableCell} align="center">
                            {
                              <Checkbox
                                checked={isCoreChecked(c.Permission, 2)}
                                // onChange={() =>
                                //   handleCoreCheckChange(c.Permission, 2, c.ptsComponentID)
                                // }
                                color="primary"
                              />
                            }
                          </TableCell>
                          <TableCell className={classes.tableCell} align="center">
                            {
                              <Checkbox
                                checked={isCoreChecked(c.Permission, 3)}
                                // onChange={() =>
                                //   handleCoreCheckChange(c.Permission, 3, c.ptsComponentID)
                                // }
                                color="primary"
                              />
                            }
                          </TableCell>
                          <TableCell className={classes.tableCell} align="center">
                            {
                              <Checkbox
                                checked={isCoreChecked(c.Permission, 7)}
                                // onChange={() =>
                                //   handleCoreCheckChange(c.Permission, 7, c.ptsComponentID)
                                // }
                                color="primary"
                              />
                            }
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </>
      )}
    </React.Fragment>
  );
}

function SecurityCodes(props) {
  const classes = useStyles();

  const { allComponents, allGroupComponents, rolesList, selectedProfile } = useContext(
    PermissionContext
  );
  const [allRows, setAllRows] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    // setAllRows([]);
    fetchData();
  }, [selectedProfile, rolesList]);

  const fetchData = async () => {
    props.showSpinner();

    const parents = allComponents.filter(c => c.Type === 'ParentCode');
    for (const parent of parents) {
      const children = allComponents.filter(c => c.Type === `${parent.Label}Code`);
      const withPermissions = await attachPermissions(children);
      parent.Children = withPermissions;
    }
    setAllRows(processData(parents));
    if (initialLoad) {
      props.hideSpinner();
      setInitialLoad(false);
    } else {
      setTimeout(() => {
        props.hideSpinner();
      }, 1000);
    }
  };

  const attachPermissions = async components => {
    for (const c of components) {
      const result = allGroupComponents.filter(
        component =>
          rolesList.includes(component.ptsGroupID) && component.ptsComponentID === c.ptsComponentID
      );

      let permission = result.length > 0 ? 0 : -1;
      if (result.length > 0) {
        permission = result.reduce((total, current) => {
          return total | current.Permissions;
        }, 0);
      }
      c.Permission = permission;
    }
    return components;
  };

  const processData = data => {
    return data.map(row => {
      return {
        ...row,
        id: row.ptsComponentID,
      };
    });
  };

  const renderCollapsibleTable = () => {
    return (
      <TableContainer>
        <Table aria-label="collapsible table" size="small" className={classes.paperColor}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell} style={{ width: '50px' }} />
              <TableCell className={classes.tableCell} align="left">
                Components
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allRows.map(row => (
              <Row
                key={row.ptsComponentID}
                row={row}
                allRows={allRows}
                setAllRows={setAllRows}
                filterText={filterText}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          id="roles-filter"
          label="Filter"
          variant="outlined"
          size="small"
          style={{
            flex: 1, // Take up available space
            marginRight: '5px',
            marginBottom: 20,
          }}
          value={filterText}
          onChange={e => {
            setFilterText(e.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {filterText && (
                  <IconButton onClick={() => setFilterText('')} edge="end">
                    <ClearIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
        {/* {renderSaveButton()} */}
      </div>
      {renderCollapsibleTable(filterText)}
    </>
  );
}

export default connect(null, { handleError, notify, showSpinner, hideSpinner })(SecurityCodes);
