import { getService } from '../reducers/service';
import store from 'config/configureStore';

/** returns { lat, lng } when provided address string */
export const geocodeAddress = address => {
  return new Promise((resolve, reject) => {
    let geocoder;
    try {
      geocoder = new window.google.maps.Geocoder();
    } catch (err) {
      return Promise.reject(err);
    }
    geocoder.geocode({ address }, (results, status) => {
      if (status === 'OK') {
        const location = results[0].geometry.location;
        const lat = location.lat();
        const lng = location.lng();
        resolve({ lat, lng });
      } else {
        reject(status);
      }
    });
  });
};

/** returns { lat, lng } when provided location object */
export const geocodeLocation = (location, dictionary) => {
  const AddressNumber = location.AddressNumber ? location.AddressNumber : '';
  const PreDirection = location.PreDirection ? ` ${location.PreDirection}` : '';
  const StreenName = location.StreetName ? ` ${location.StreetName}` : '';
  const PostDirection = location.PostDirection ? ` ${location.PostDirection}` : '';
  const cityObj = dictionary?.Cities?.find(city => city.ptsCityID === location.ptsCityID);
  const City = cityObj ? `, ${cityObj.Code}` : '';
  const State = location.State ? `, ${location.State}` : '';
  const PostalCode = location.PostalCode ? ` ${location.PostalCode}` : '';
  const Address =
    AddressNumber + PreDirection + StreenName + PostDirection + City + State + PostalCode;
  return geocodeAddress(Address);
};

/** Returns location object with coordinates added */
export const addCoordsToLocation = (location, dictionary) => {
  const { ptsCoordinateID } = location;
  if (ptsCoordinateID) {
    return new Promise((resolve, reject) => {
      getCoordinates(ptsCoordinateID)
        .then(coords => {
          resolve({ ...location, ...coords });
        })
        .catch(err => reject(err));
    });
  } else {
    return new Promise((resolve, reject) => {
      geocodeLocation(location, dictionary)
        .then(coords => {
          const lat = coords ? coords.lat : null;
          const lng = coords ? coords.lng : null;
          resolve({ ...location, lat: lat, lng });
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

/** returns { lat, lng } - center point of the path */
export const getPathCenter = path => {
  let latMin = 999;
  let latMax = -999;
  let lngMin = 999;
  let lngMax = -999;
  path.forEach(point => {
    const { lat, lng } = point;
    latMin = Math.min(lat, latMin);
    latMax = Math.max(lat, latMax);
    lngMin = Math.min(lng, lngMin);
    lngMax = Math.max(lng, lngMax);
  });
  const center = {
    lat: (latMax + latMin) / 2,
    lng: (lngMax + lngMin) / 2,
  };
  return center;
};

/** convert Google Maps polygon to path [{ lat, lng }] */
export const getPathFromPolygon = polygon => {
  const vertices = polygon.getPath();
  if (!vertices) return null;
  const path = [];
  const len = vertices.getLength();
  for (var i = 0; i < len; i++) {
    const point = vertices.getAt(i);
    const lat = point.lat();
    const lng = point.lng();
    path.push({ lat, lng });
  }
  return removeDuplicatePoints(path);
};

export const removeDuplicatePoints = path => {
  if (!path) return null;
  const newPath = [];
  path.forEach((point, idx) => {
    if (idx === 0) {
      newPath.push(point);
    } else {
      const prevPoint = path[idx - 1];
      if (point.lat !== prevPoint.lat || point.lng !== prevPoint.lng) newPath.push(point);
    }
  });
  return newPath;
};

/** returns path as string that could be saved to DB */
export const pathToStr = path => {
  let pathStr = '';
  const isArrayPoint = !path[0].lat;
  const getLat = point => (isArrayPoint ? point[1] : point.lat);
  const getLng = point => (isArrayPoint ? point[0] : point.lng);
  path.forEach((point, idx) => {
    if (idx > 0) pathStr += ', ';
    pathStr += `${getLng(point)} ${getLat(point)}`;
  });
  const needClosing =
    getLat(path[0]) !== getLat(path[path.length - 1]) ||
    getLng(path[0]) !== getLng(path[path.length - 1]);
  if (needClosing) pathStr += `, ${getLng(path[0])} ${getLat(path[0])}`;
  return pathStr;
};

export const offsetPoint = (point, no) => {
  const offset = 0.0000000001;
  const isArrPoint = point[0];
  let lat = isArrPoint ? parseFloat(point[1]) : point.lat;
  let lng = isArrPoint ? parseFloat(point[0]) : point.lng;
  switch (no) {
    case 1:
      lat += offset;
      break;
    case 2:
      lat -= offset;
      break;
    case 3:
      lng += offset;
      break;
    case 4:
      lng -= offset;
  }
  if (isArrPoint) return [lng, lat];
  return { lat, lng };
};

/** converts string received from DB to path obj */
export const strToPath = str => {
  if (!str) return null;
  let pathStr = str;
  if (str.substr(0, 7) === 'POLYGON') pathStr = str.substr(10, str.length - 12);
  const arr = pathStr.split(',').map(point => {
    const objArr = point.trim().split(' ');
    return {
      lat: Number(objArr[1]),
      lng: Number(objArr[0]),
    };
  });
  if (arr[0].lat === arr[arr.length - 1].lat && arr[0].lng === arr[arr.length - 1].lng) {
    arr.pop();
  }
  return arr;
};

/** Returns path with points aligned to other paths */
export const alignToPaths = (path, geofences) => {
  const accuracy = 10;
  const newPath = [];
  path.forEach(point => {
    const matches = []; // points within the range
    geofences.forEach(geofence => {
      geofence.path &&
        geofence.path.forEach((point2, idx) => {
          const distance = calculateDistance(point, point2);
          if (distance < accuracy) matches.push({ ...point2, distance, idx, point });
        });
    });
    let newPoint = point;
    let distance = 999999999;
    matches.forEach(point => {
      if (point.distance < distance) {
        distance = point.distance;
        newPoint = point;
      }
    });
    newPath.push(newPoint);
  });
  return newPath;
};

export const calculateDistance = (p1, p2) => {
  const rad = x => (x * Math.PI) / 180;
  const R = 6378137;
  var dLat = rad(p2.lat - p1.lat);
  var dLong = rad(p2.lng - p1.lng);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d;
};

export const isIntersecting = (p1, p2, p3, p4) => {
  // in progress - don't use in production
  function CCW(p1, p2, p3) {
    return (p3.lat - p1.lat) * (p2.lng - p1.lng) > (p2.lat - p1.lat) * (p3.lng - p1.lng);
  }
  return CCW(p1, p3, p4) != CCW(p2, p3, p4) && CCW(p1, p2, p3) != CCW(p1, p2, p4);
};

function intersects(p1, p2, p3, p4) {
  // in progress - don't use in production
  const { a, b } = p1;
  const { c, d } = p2;
  const { p, q } = p3;
  const { r, s } = p4;
  let det, gamma, lambda;
  det = (c - a) * (s - q) - (r - p) * (d - b);
  if (det === 0) {
    return false;
  } else {
    lambda = ((s - q) * (r - a) + (p - r) * (s - b)) / det;
    gamma = ((b - d) * (r - a) + (c - a) * (s - b)) / det;
    return 0 < lambda && lambda < 1 && 0 < gamma && gamma < 1;
  }
}

export const isGeofenceValid = path => {
  // in progress - don't use in production
  if (!path.length) return true;
  let valid = true;
  for (let i = 0; i < path.length; ++i) {
    for (let j = 0; j < path.length; ++j) {
      if (i === j) continue;
      const p1 = path[i];
      const p2 = i === path.length - 1 ? path[0] : path[i + 1];
      const p3 = path[j];
      const p4 = j === path.length - 1 ? path[0] : path[j + 1];
      if (intersects(p1, p2, p3, p4)) {
        valid = false;
        console.log(p1, p2, p3, p4);
        // break;
      }
    }
  }
  return valid;
};

export const removeIntersections = path => {
  // in progress - don't use in production
  const invalidPoints = [];
  for (let i = 0; i < path.length; ++i) {
    for (let j = 0; j < path.length; ++j) {
      if (i === j) continue;
      const p1 = path[i];
      const p2 = i === path.length - 1 ? path[0] : path[i + 1];
      const p3 = path[j];
      const p4 = j === path.length - 1 ? path[0] : path[j + 1];
      if (isIntersecting(p1, p2, p3, p4) && invalidPoints.indexOf(i) === -1) {
        invalidPoints.push(i);
      }
    }
  }
  console.log('invalid points', invalidPoints);
  console.log(
    'returning: ',
    path.filter((p, idx) => invalidPoints.indexOf(idx) === -1)
  );
  return path.filter((p, idx) => invalidPoints.indexOf(idx) === -1);
};

export const getCoordinates = ptsCoordinateID => {
  const service = getService('ptscoordinates');
  return new Promise((resolve, reject) => {
    service
      .get(ptsCoordinateID)
      .then(result => {
        const coords = {
          lat: result.LatitudeDegree * (result.LatitudeSign === '+' ? 1 : -1),
          lng: result.LongitudeDegree * (result.LongitudeSign === '+' ? 1 : -1),
        };
        resolve(coords);
      })
      .catch(err => reject(err));
  });
};

/** Returns full text address location */
export const getAddressFromLocation = location => {
  const state = store.store.getState();
  const { dictionary } = state;
  const AddressNumberPrefix = location.AddressNumberPrefix
    ? `${location.AddressNumberPrefix} `
    : '';
  const AddressNumberSuffix = location.AddressNumberSuffix
    ? ` ${location.AddressNumberPrefix}`
    : '';
  const AddressNumber = location.AddressNumber ? location.AddressNumber : '';
  const PreDirection = location.PreDirection ? ` ${location.PreDirection}` : '';
  const StreenName = location.StreetName ? ` ${location.StreetName}` : '';
  const StreetType = location.StreetType ? ` ${location.StreetType}` : '';
  const PostDirection = location.PostDirection ? ` ${location.PostDirection}` : '';
  const cityObj = dictionary.Cities.find(city => city.ptsCityID === location.ptsCityID);
  const City = cityObj ? `, ${cityObj.Code}` : '';
  const State = location.State ? `, ${location.State}` : '';
  const PostalCode = location.PostalCode ? ` ${location.PostalCode}` : '';
  const Unit =
    location.UnitType && location.UnitIdentifier
      ? `, ${location.UnitType} ${location.UnitIdentifier}`
      : '';
  return (
    AddressNumberPrefix +
    AddressNumber +
    AddressNumberSuffix +
    PreDirection +
    StreenName +
    StreetType +
    PostDirection +
    Unit +
    City +
    State +
    PostalCode
  );
};
