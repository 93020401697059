import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  wrap: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(255, 255, 255, 0.4)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
    '& svg': {
      color: '#0153a3'
    }
  }
}));

function Spinner() {
  const classes = useStyles();

  return (
    <div className={classes.wrap}>
      <CircularProgress />
    </div>
  );
}

export default Spinner;

