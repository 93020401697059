import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControlLabel, Checkbox } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  checkbox: {
    margin: '0 8px 8px 0',
  },
  checkboxError: {
    margin: '0 8px 0px 0',
    color: theme.palette.error.main,
    '& svg': {
      color: theme.palette.error.main,
    },
  },
}));

function Checkbox2(props) {
  const classes = useStyles();
  const { error, name, checked, label, disabled } = props;
  const className = error ? classes.checkboxError : classes.checkbox;

  const onChange = (ev) => {
    if (props.onChange) props.onChange(ev, ev.target.checked);
  }

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={onChange}
          name={name}
          disabled={disabled}
        />
      }
      label={label}
      className={className}
    />
  );
}

export default Checkbox2;
