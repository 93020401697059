import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

import { handleError } from '../reducers/ErrorReducer';
import { showSpinner, hideSpinner } from '../reducers/UiReducer';
import { notify } from '../reducers/NotifierReducer';
import { closeAgencyBasedUnitSelection } from '../reducers/DialogsReducer';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { DialogContent } from '@mui/material';
import { getService } from 'reducers/service';

const useStyles = makeStyles(theme => ({
  actions: {
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  textField: {
    width: 600,
    maxWidth: '100%',
    marginTop: theme.spacing(1),
  },
  dialog: {
    '& $content': {
      margin: 0,
      padding: 0,
      background: 'red !important',
    },
  },
}));

function AddAgencyUnitSelection(props) {
  const classes = useStyles();
  const { wsClient, user } = props;
  const { type } = props.data;
  const username = user?.userData?.user?.Username;
  const service = getService('cdr');
  const Agencies = props.dictionary.Agencies || [];

  const [formData, setFormData] = useState({});

  useEffect(() => {
    const info = props.data.info;
    if (type == 'edit' && info?.CDRKey) {
      const form = {
        CDRKey: info.CDRKey || '',
        ValueBoolean: info.ValueBoolean || '',

        CreatedBy: info.CreatedBy || '',
        UpdatedBy: user || '',
      };

      setFormData(form);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.info, type]);
  //   useEffect(() => {
  //     setTimeout(() => textFieldRef.current.focus(), 0);
  //     getDetails();
  //     // eslint-disable-next-line
  //   }, []);

  //   const getDetails = async () => {
  //     props.showSpinner();
  //     try {
  //       const event = await getEventDetails(ptsEventID);
  //       setEvent(event);
  //     } catch (err) {
  //       props.handleError(err, '|Error, Unable to retrieve event details');
  //     }
  //     props.hideSpinner();
  //   };

  const close = () => {
    props.closeAgencyBasedUnitSelection();
  };

  const save = async () => {
    props.showSpinner();
    try {
      //   await addEventNote(text, ptsEventID);
      if (type == 'edit') {
        const CDRKey = props.data?.info?.CDRKey;
        const updatedData = await service.patch(null, {
          ...formData,
          Path: 'AgencyBasedUnitSetting',
          CDRKey,
          UpdatedBy: username,
        });
        props.notify('File Updated', 'success');
      } else {
        const addedData = await service.create({
          ...formData,
          Path: 'AgencyBasedUnitSetting',
          CreatedBy: username,
          UpdatedBy: username,
        });
        props.notify('Created', 'success');
      }
    } catch (err) {
      props.handleError(err, 'Error, cannot not saved.', err);
    }
    props.hideSpinner();
    close();
  };
  const handleFormChange = (name, data) => {
    const newForm = { ...formData };
    newForm[name] = data;
    setFormData({ ...newForm });
  };
  const renderActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={save}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  return (
    <Dialog
      onClose={close}
      title={'Add Citation File'}
      open={true}
      actions={renderActions()}
      //   className={classes.dialog}

      // toolbar={false}
      // draggable={false}
      fullWidth
      maxWidth="lg">
      <DialogTitle>{type == 'edit' ? 'Edit' : 'Add'} Unit Selection</DialogTitle>
      <DialogContent className="py-2">
        <Grid container spacing={2} style={{ width: '100%' }}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="combo-box-demo"
              size="small"
              disabled={type == 'edit'}
              autoHighlight
              autoSelect
              value={{ AgencyID: formData.CDRKey || '' }}
              onChange={(event, newValue) => {
                handleFormChange('CDRKey', newValue?.AgencyID || '');
              }}
              options={[{ AgencyID: '' }, ...Agencies]}
              getOptionLabel={option => option.AgencyID || ''}
              renderOption={option => option.AgencyID || '-'}
              getOptionSelected={() => true}
              style={{ width: '100%' }}
              renderInput={params => <TextField {...params} variant="outlined" label="Agency" />}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.ValueBoolean || false}
                  onChange={e => handleFormChange('ValueBoolean', e.target.checked)}
                />
              }
              label="Unit Selection"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className=" mb-1 mr-3">{renderActions()}</div>
      </DialogActions>
    </Dialog>
  );
}
const mapStateToProps = state => {
  return {
    dictionary: state.dictionary,
    wsClient: state.websocket,
    user: state.user,
  };
};
export default connect(mapStateToProps, {
  showSpinner,
  hideSpinner,
  handleError,
  notify,
  closeAgencyBasedUnitSelection,
})(AddAgencyUnitSelection);
