import React from 'react';
import { connect } from 'react-redux';
import Content from 'Settings/Content';

export const Configuration = props => {
  return <Content>Configuration</Content>;
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Configuration);
