import React from 'react';

function HighRiskIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      {...props}
      preserveAspectRatio="xMidYMid meet">
      <g
        transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none">
        <path d="M121 264 c-62 -108 -111 -203 -109 -212 3 -15 27 -17 238 -17 211 0 235 2 238 17 5 26 -217 408 -238 408 -12 0 -51 -59 -129 -196z m149 -24 c0 -73 -2 -80 -20 -80 -18 0 -20 7 -20 80 0 73 2 80 20 80 18 0 20 -7 20 -80z m0 -130 c0 -13 -7 -20 -20 -20 -13 0 -20 7 -20 20 0 13 7 20 20 20 13 0 20 -7 20 -20z" />
        <path d="M80 433 c1 -15 49 -67 55 -61 8 7 -35 68 -47 68 -4 0 -8 -3 -8 -7z" />
        <path d="M381 409 c-13 -17 -20 -33 -16 -37 6 -6 54 46 55 61 0 16 -18 5 -39 -24z" />
        <path d="M20 362 c0 -7 63 -42 75 -42 17 0 -1 19 -30 33 -41 19 -45 20 -45 9z" />
        <path d="M433 353 c-28 -14 -44 -33 -28 -33 12 0 75 35 75 42 0 11 -10 9 -47 -9z" />
        <path d="M0 270 c0 -5 18 -10 40 -10 22 0 40 5 40 10 0 6 -18 10 -40 10 -22 0 -40 -4 -40 -10z" />
        <path d="M420 270 c0 -5 18 -10 40 -10 22 0 40 5 40 10 0 6 -18 10 -40 10 -22 0 -40 -4 -40 -10z" />
      </g>
    </svg>
  );
}

export default HighRiskIcon;
