
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { saveSettings, getSettings } from '../../reducers/ConfigReducer';
import { showSpinner, hideSpinner } from '../../reducers/UiReducer';
import { notify } from '../../reducers/NotifierReducer';
import { handleError } from '../../reducers/ErrorReducer';

const useStyles = makeStyles(theme => ({
  wrap: {
    padding: theme.spacing(2),
    '& > h5': {
      marginBottom: 20,
    },
  },
  form: {
    margin: '0 -4px 4px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexGrow: 1,
  },
  textField: {
    margin: '0 4px 8px',
    maxWidth: 320,
    flexBasis: 320,
    minWidth: 320,
    flexGrow: 1
  },
  buttons: {
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
}));

function Recommendations(props) {
  const classes = useStyles();
  const { Recommendations } = props;
  const [inservice, setInservice] = useState(1);
  const [notOffDuty, setNotOffDuty] = useState(1);
  const [esnMatch, setEsnMatch] = useState(1);
  const [routingMatch, setRoutingMatch] = useState(1);
  const [zoneMatch, setZoneMatch] = useState(1);
  const [proximity, setProximity] = useState(1);
  const [capability, setCapability] = useState(1);

  const [inserviceValidationError, setInserviceValidationError] = useState(false);
  const [notOffDutyValidationError, setNotOffDutyValidationError] = useState(false);
  const [esnMatchValidationError, setEsnMatchValidationError] = useState(false);
  const [routingMatchValidationError, setRoutingMatchValidationError] = useState(false);
  const [zoneMatchValidationError, setZoneMatchValidationError] = useState(false);
  const [proximityValidationError, setProximityValidationError] = useState(false);
  const [capabilityValidationError, setCapabilityValidationError] = useState(false);

  // const disabled =  Recommendations &&
  //                   inservice === Recommendations.inservice &&
  //                   notOffDuty === Recommendations.notOffDuty &&
  //                   esnMatch === Recommendations.esnMatch &&
  //                   routingMatch === Recommendations.routingMatch &&
  //                   zoneMatch === Recommendations.zoneMatch &&
  //                   proximity === Recommendations.proximity &&
  //                   capability === Recommendations.capability;
  
  useEffect(() => {
    if (!Recommendations) return;
    const {
      inservice, notOffDuty, esnMatch, routingMatch, zoneMatch, proximity, capability
    } = Recommendations;
    setInservice(inservice);
    setNotOffDuty(notOffDuty);
    setEsnMatch(esnMatch);
    setRoutingMatch(routingMatch);
    setZoneMatch(zoneMatch);
    setProximity(proximity);
    setCapability(capability);
    // eslint-disable-next-line
  }, [Recommendations]);

  const save = async () => {
    props.showSpinner();
    const data = { 
      inservice: inservice ? inservice : 0,
      notOffDuty: notOffDuty ? notOffDuty : 0,
      esnMatch: esnMatch ? esnMatch : 0,
      routingMatch: routingMatch ? routingMatch : 0,
      zoneMatch: zoneMatch ? zoneMatch: 0,
      proximity: proximity ? proximity : 0,
      capability: capability ? capability: 0
    };

    try {
        await saveSettings('Recommendations', data);
        props.notify('Settings saved', 'success');
        props.getSettings();
    } catch (err) {
      props.handleError(err, 'Error saving settings');
    }
    props.hideSpinner();
  }

  const handleInserviceChange = ev => {
    const targetValue = parseInt(ev.target.value);
    setInservice(targetValue);
    (targetValue<0 || targetValue>10) ? setInserviceValidationError(true) : setInserviceValidationError(false);
  }
  const handleNotOffDutyChange = ev => {
    const targetValue = parseInt(ev.target.value);
    setNotOffDuty(targetValue);
    (targetValue<0 || targetValue>10) ? setNotOffDutyValidationError(true) : setNotOffDutyValidationError(false);
  }
  const handleEsnMatchChange = ev => {
    const targetValue = parseInt(ev.target.value);
    setEsnMatch(targetValue);
    (targetValue<0 || targetValue>10) ? setEsnMatchValidationError(true) : setEsnMatchValidationError(false);
  }
  const handleRoutingMatchChange = ev => {
    const targetValue = parseInt(ev.target.value);
    setRoutingMatch(targetValue);
    (targetValue<0 || targetValue>10) ? setRoutingMatchValidationError(true) : setRoutingMatchValidationError(false);
  }
  const handleZoneMatchChange = ev => {
    const targetValue = parseInt(ev.target.value);
    setZoneMatch(targetValue);
    (targetValue<0 || targetValue>10) ? setZoneMatchValidationError(true) : setZoneMatchValidationError(false);
  }
  const handleProximityChange = ev => {
    const targetValue = parseInt(ev.target.value);
    setProximity(targetValue);
    (targetValue<0 || targetValue>10) ? setProximityValidationError(true) : setProximityValidationError(false);
  }
  const handleCapabilityChange = ev => {
    const targetValue = parseInt(ev.target.value);
    setCapability(targetValue);
    (targetValue<0 || targetValue>10) ? setCapabilityValidationError(true) : setCapabilityValidationError(false);
  }

  return (
    <div className={classes.wrap}>
      <div className={classes.info}>
        <p>Select numbers from 0 to 10 depending on parameter importance for Dispatch Recommendations.</p>
      </div>
      <div className={classes.form}>
        <TextField
          error = {inservice<0 || inservice>10}
          helperText={(inservice<0 || inservice>10) ? "* status should be between 0 and 10" : ''}
          className={classes.textField}
          label="In-Service status"
          variant="outlined"
          value={inservice}
          onChange={handleInserviceChange}
          size="small"
          type="number"
          InputProps={{ inputProps: { min: 0, max: 10 }}}
        />
      </div>
      <div className={classes.form}>
        <TextField
          error = {notOffDuty<0 || notOffDuty>10}
          helperText={(notOffDuty<0 || notOffDuty>10) ? "* notOffDuty should be between 0 and 10" : ''}
          className={classes.textField}
          label="Status other than Off-Duty"
          variant="outlined"
          value={notOffDuty}
          onChange={handleNotOffDutyChange}
          size="small"
          type="number"
          InputProps={{ inputProps: { min: 0, max: 10 }}}
        />
      </div>
      <div className={classes.form}>
        <TextField
          error = {esnMatch<0 || esnMatch>10}
          helperText={(esnMatch<0 || esnMatch>10) ? "* esnMatch should be between 0 and 10" : ''}
          className={classes.textField}
          label="Unit within ESN zone but not recommeded"
          variant="outlined"
          value={esnMatch}
          onChange={handleEsnMatchChange}
          size="small"
          type="number"
          InputProps={{ inputProps: { min: 0, max: 10 }}}
        />
      </div>
      <div className={classes.form}>
        <TextField
          error = {routingMatch<0 || routingMatch>10}
          helperText={(routingMatch<0 || routingMatch>10) ? "* routingMatch should be between 0 and 10" : ''}
          className={classes.textField}
          label="Recommended agency"
          variant="outlined"
          value={routingMatch}
          onChange={handleRoutingMatchChange}
          size="small"
          type="number"
          InputProps={{ inputProps: { min: 0, max: 10 }}}
        />
      </div>
      <div className={classes.form}>
        <TextField
          error = {zoneMatch<0 || zoneMatch>10}
          helperText={(zoneMatch<0 || zoneMatch>10) ? "* zoneMatch should be between 0 and 10" : ''}
          className={classes.textField}
          label="Unit Zone Match"
          variant="outlined"
          value={zoneMatch}
          onChange={handleZoneMatchChange}
          size="small"
          type="number"
          InputProps={{ inputProps: { min: 0, max: 10 }}}
        />
      </div>
      <div className={classes.form}>
        <TextField
          error = {proximity<0 || proximity>10}
          helperText={(proximity<0 || proximity>10) ? "* proximity should be between 0 and 10" : ''}
          className={classes.textField}
          label="Distance from an event"
          variant="outlined"
          value={proximity}
          onChange={handleProximityChange}
          size="small"
          type="number"
          InputProps={{ inputProps: { min: 0, max: 10 }}}
        />
      </div>
      <div className={classes.form}>
        <TextField
          error = {capability<0 || capability>10}
          helperText={(capability<0 || capability>10) ? "* capability should be between 0 and 10" : ''}
          className={classes.textField}
          label="Unit Capabilities"
          variant="outlined"
          value={capability}
          onChange={handleCapabilityChange}
          size="small"
          type="number"
          InputProps={{ inputProps: { min: 0, max: 10 }}}
        />
      </div>
      <div className={classes.buttons}>
        <Button 
          variant="contained"
          color="primary"
          disabled={inserviceValidationError||notOffDutyValidationError||esnMatchValidationError||proximityValidationError||zoneMatchValidationError||capabilityValidationError||routingMatchValidationError}
          onClick={save}
        >
          <SaveIcon />
          Save
        </Button>
      </div>
  </div>
  );
}

const mapStateToProps = state => {
  return {
    Recommendations: state.config.options.Recommendations
  }
}

export default connect(mapStateToProps, {
  showSpinner, hideSpinner, handleError, getSettings, notify
})(Recommendations);