import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Autocomplete } from '@material-ui/lab';
import { ColCard, Row, RowInner } from 'utils/formStyles';
import FormDivider from 'components/FormDivider';
import UnitManagementForRotation from './UnitManagementForRotation';
import { closeRotationSeup } from 'reducers/DialogsReducer';
import { getService } from 'reducers/service';
import { formatRotationCodes } from 'Settings/LookupCodes/CodeObjectFormatter';
import { handleError } from 'reducers/ErrorReducer';
import { notify } from 'reducers/NotifierReducer';
const useStyles = makeStyles(theme => ({
  filter: {
    width: '100%',
    marginTop: '10px',
  },
  datePicker: {
    width: '100%',
    marginTop: '10px',
  },
}));
const obj = {
  Code: '',
  Description: '',
  Category: '',
  IsDefault: false,
  IsActive: false,
  Code2: '',
  Description2: '',
  Code3: '',
  Description3: '',
  Code4: '',
  Description4: '',
  SkipType: '',
  IsSkipReasonRequired: '',
  IncludeSkipOnEvent: '',
  OwnerType: '',
  IsOwnerReasonRequired: '',
  IncludeOwnerRequestOnEvent: '',
};
const skipTypeOptions = ['Remain', 'Last', 'Choose'];
const ownerTypeOptions = ['Remain', 'Last', 'Choose'];
export const RotationSetupDialog = props => {
  const { mode = 'add', info } = props?.data || {};

  const [values, setValues] = useState(obj);
  const service = getService('coderotation');
  const dispatch = useDispatch();

  useEffect(() => {
    if (mode === 'edit' && info.Code) {
      setValues({ ...values, ...info });
    }
  }, []);

  const handleChange = (key, value) => {
    setValues(prevState => ({ ...prevState, [key]: value }));
  };
  const close = () => {
    dispatch(closeRotationSeup());
  };

  const handleSubmit = async () => {
    try {
      if (mode === 'add') {
        await handleAddCodes();
      } else {
        await handleUpdateCodes();
      }
    } catch (error) {
      dispatch(handleError(error, `Could not save`));
    } finally {
      close();
    }
  };
  /* handler functions -> end */

  /* APi Calls -> start */
  const checkDuplication = async query => {
    const isAlreadyExist = await service.find({ query });
    if (isAlreadyExist.data.length) {
      dispatch(notify('Item Already Exist', 'error'));
      return true;
    }
    return false;
  };
  const handleAddCodes = async () => {
    const isExists = await checkDuplication({ Code: values.Code, IsDeleted: false });
    if (isExists) return;
    await service.create(formatRotationCodes(values));
  };
  const handleUpdateCodes = async () => {
    await service.patch(values.Code, formatRotationCodes(values));
  };

  return (
    <Dialog open={true} onClose={close} maxWidth={mode === 'add' ? 'lg' : 'xl'} fullWidth={true}>
      <DialogTitle style={{ background: '#1976d2', color: 'white' }}>
        {<Typography variant="h5">Rotation Setup</Typography>}
      </DialogTitle>
      <DialogContent>
        <div style={{ padding: 10 }}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container>
                <Grid container spacing={1} item xs={12} style={{ marginTop: 10 }}>
                  <Grid item xs={3}>
                    <Box>
                      <TextField
                        size="sm"
                        //fullWidth
                        disabled={mode === 'edit'}
                        style={{ width: '100%' }}
                        type={'text'}
                        value={values.Code}
                        label={'Code/Description'}
                        //error={error}
                        variant={'outlined'}
                        onChange={e => handleChange('Code', e.target.value)}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={3}>
                    <Box>
                      <TextField
                        size="sm"
                        //fullWidth
                        style={{ width: '100%' }}
                        type={'text'}
                        value={values.Description}
                        //onChange={handleChange}
                        onChange={e => handleChange('Description', e.target.value)}
                        helperText={''}
                        label={'Description'}
                        //error={error}
                        variant={'outlined'}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={2}>
                    <Box>
                      <TextField
                        size="sm"
                        //fullWidth
                        style={{ width: '100%' }}
                        type={'text'}
                        value={values.Code2}
                        label={'Code 2/Description 2'}
                        //error={error}
                        variant={'outlined'}
                        onChange={e => handleChange('Code2', e.target.value)}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <TextField
                        size="sm"
                        //fullWidth
                        style={{ width: '100%' }}
                        type={'text'}
                        value={values.Description2}
                        //onChange={handleChange}
                        onChange={e => handleChange('Description2', e.target.value)}
                        helperText={''}
                        label={'Description 2'}
                        //error={error}
                        variant={'outlined'}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={1}>
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.IsDefault}
                            onChange={e => handleChange('IsDefault', e.target.checked)}
                          />
                        }
                        label="Default"
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={3}>
                    <Box>
                      <TextField
                        size="sm"
                        //fullWidth
                        style={{ width: '100%' }}
                        type={'text'}
                        value={values.Code3}
                        label={'Code 3/ Description 3'}
                        //error={error}
                        variant={'outlined'}
                        onChange={e => handleChange('Code3', e.target.value)}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <TextField
                        size="sm"
                        //fullWidth
                        style={{ width: '100%' }}
                        type={'text'}
                        value={values.Description3}
                        //onChange={handleChange}
                        onChange={e => handleChange('Description3', e.target.value)}
                        helperText={''}
                        label={'Description 3'}
                        //error={error}
                        variant={'outlined'}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={2}>
                    <Box>
                      <TextField
                        size="sm"
                        //fullWidth
                        style={{ width: '100%' }}
                        type={'text'}
                        value={values.Code4}
                        label={'Code 4/ Description 4'}
                        //error={error}
                        variant={'outlined'}
                        onChange={e => handleChange('Code4', e.target.value)}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <TextField
                        size="sm"
                        //fullWidth
                        style={{ width: '100%' }}
                        type={'text'}
                        value={values.Description4}
                        //onChange={handleChange}
                        onChange={e => handleChange('Description4', e.target.value)}
                        helperText={''}
                        label={'Description 4'}
                        //error={error}
                        variant={'outlined'}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={1}>
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.IsActive}
                            onChange={e => handleChange('IsActive', e.target.checked)}
                          />
                        }
                        label="Active"
                      />
                    </Box>
                  </Grid>
                </Grid>

                <Row className="mt-2">
                  <ColCard minWidth={500}>
                    <FormDivider title="Skip Settings" />
                    <RowInner>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.IsSkipReasonRequired}
                            onChange={e => handleChange('IsSkipReasonRequired', e.target.checked)}
                          />
                        }
                        label="Skip Reason Required"
                      />

                      <Autocomplete
                        id="SkipType-codes"
                        size="small"
                        autoHighlight
                        autoSelect
                        value={values.SkipType}
                        onChange={(event, newValue) => {
                          handleChange('SkipType', newValue || '');
                        }}
                        options={skipTypeOptions}
                        getOptionLabel={option => option || ''}
                        style={{ marginRight: '10px', width: '40%' }}
                        renderInput={params => (
                          <TextField size="sm" {...params} variant="outlined" label="Skip Type" />
                        )}
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.IncludeSkipOnEvent}
                            onChange={e => handleChange('IncludeSkipOnEvent', e.target.checked)}
                          />
                        }
                        label="Include Skip Unit Action On Event"
                      />
                    </RowInner>
                  </ColCard>
                  <ColCard minWidth={500}>
                    <div>
                      <FormDivider title="Owner Settings" />
                      <RowInner>
                        <Box>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.IsOwnerReasonRequired}
                                onChange={e =>
                                  handleChange('IsOwnerReasonRequired', e.target.checked)
                                }
                              />
                            }
                            label="Owner Reason Required"
                          />
                        </Box>

                        <Autocomplete
                          id="OwnerType-codes"
                          size="small"
                          autoHighlight
                          autoSelect
                          value={values.OwnerType}
                          onChange={(event, newValue) => {
                            handleChange('OwnerType', newValue || '');
                          }}
                          options={ownerTypeOptions}
                          getOptionLabel={option => option || ''}
                          style={{ marginRight: '10px', width: '30%' }}
                          renderInput={params => (
                            <TextField
                              size="sm"
                              {...params}
                              variant="outlined"
                              label="Owner Type"
                            />
                          )}
                        />

                        <Box>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.IncludeOwnerRequestOnEvent}
                                onChange={e =>
                                  handleChange('IncludeOwnerRequestOnEvent', e.target.checked)
                                }
                              />
                            }
                            label="Include Owner's Request Unit Action On Event"
                          />
                        </Box>
                      </RowInner>
                    </div>
                  </ColCard>
                </Row>

                {mode === 'edit' && <UnitManagementForRotation parentCode={values.Code} />}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <div className=" mb-1">
          <Button className="ml-2" variant="contained" color="primary" onClick={handleSubmit}>
            {mode === 'edit' ? 'Update' : 'Save'}
          </Button>
          <Button onClick={close}>Cancel</Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RotationSetupDialog);
