import React from 'react';
import PoliceIcon from './iconagencytypepoliceIcon';
import MedicalIcon from './iconsagencytypemedicalIcon';
import SheriffIcon from './iconsagencytypesheriffIcon';
import FireIcon from './fireDEPIcon';
import Police1Icon from './police1Icon';
import Police2Icon from './police2Icon';
//import ShootingIcon from '../EventTypeIcons/ShootingIcon';
import HelicopterIcon from './HelicopterIcon';
import HorseIcon from './HorseIcon';
import ScalesIcon from './ScalesIcon';
import TexasIcon from './TexasIcon';
import TowTruckIcon from './TowTruckIcon';
import WaterIcon from './WaterIcon';
import ElectricityIcon from './ElectricityIcon';

function EmptyIcon() {
  return <span>-</span>;
}

export default {
  0: EmptyIcon,
  1: PoliceIcon,
  2: MedicalIcon,
  3: SheriffIcon,
  4: Police1Icon,
  5: Police2Icon,
  6: FireIcon,
  7: ElectricityIcon,
  8: HelicopterIcon,
  9: HorseIcon,
  10: ScalesIcon,
  11: TexasIcon,
  12: TowTruckIcon,
  13: WaterIcon,
};
