
    import React from 'react';
    import SvgIcon from '@material-ui/core/SvgIcon';
    
    function icons8firinggun100Icon(props) {
      return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="100" height="100"><path d="M35.07 8.797a1.504 1.504 0 0 0-1.852-1.035l-.515.146-.32-1.129-4.116 1.165.32 1.129-18.006 5.097a1.5 1.5 0 0 0-1.044 1.819l.129.498c.13.487.152.977.102 1.447-1.413-1.074-3.28-1.495-5.069-.988a1.5 1.5 0 1 0 .817 2.887 2.78 2.78 0 0 1 2.567.574 2.665 2.665 0 0 1-.329.117l-.585.153c-1.413.378-3.265 1.876-2.577 4.455l.339 1.265c-1.5 1.787-4.785 6.951-2.396 15.891l.312 1.159a2.576 2.576 0 0 0 3.145 1.816l8.281-2.21a2.571 2.571 0 0 0 1.819-3.145l-.284-1.063c-1.148-4.297-1.375-6.246-1.397-7.072l1.676-.461.004.015.001.002.303 1.069a2.545 2.545 0 0 0 1.218 1.544 2.54 2.54 0 0 0 1.952.23l3.46-.98a2.568 2.568 0 0 0 1.808-1.902l1.157-5.079 1.244-.359a1.5 1.5 0 0 0 1.027-1.85l-1.033-3.646 8.847-2.504A1.501 1.501 0 0 0 37.11 16l-2.04-7.203zM22.691 27.181l-.728 3.194-2.801.793-.186-.657v-.002l-.595-2.1 1.469-.424 2.841-.804zm23.56-17.777a1.505 1.505 0 0 0-1.852-1.035l-4.27 1.208a1.5 1.5 0 1 0 .816 2.887l4.27-1.208a1.5 1.5 0 0 0 1.036-1.852zm-1.25 6.659-2.442-1.365a1.5 1.5 0 1 0-1.463 2.619l2.442 1.365a1.5 1.5 0 0 0 1.463-2.619zm-4.53-8.738 1.365-2.443a1.5 1.5 0 0 0-2.619-1.464l-1.365 2.443a1.5 1.5 0 0 0 2.619 1.464z"/></SvgIcon>
      );
    }
  
    export default icons8firinggun100Icon;