
    import React from 'react';
    import SvgIcon from '@material-ui/core/SvgIcon';
    
    function icons8spy100Icon(props) {
      return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100" height="100"><path d="M18.688 0c-5.165 0-7.481 9.027-9.313 16.563C3.152 17.977 0 19.968 0 22.5c0 2.418 3.04 4.273 7.594 5.531a4.418 4.418 0 0 0-.188 1.282c0 .554.114 1.164.313 1.812C5.739 32.215 2.895 34.27.187 38c-.156.219-.23.484-.187.75.043.266.219.504.438.656l8.906 6.313-2.125 2.656c-.239.3-.29.715-.125 1.063.164.343.523.562.906.562h34.031a.997.997 0 0 0 .657-1.75l-2.032-2.531 8.907-6.313c.218-.156.394-.394.437-.656a1.012 1.012 0 0 0-.188-.75c-2.707-3.734-5.554-5.785-7.53-6.875.202-.672.312-1.34.312-1.938 0-.48-.063-.847-.157-1.187C46.974 26.742 50 24.91 50 22.5c0-2.531-3.148-4.523-9.375-5.938l-.094-.343c-.27-1.063-.554-2.145-.843-3.219v-.094h-.032C37.883 6.32 35.668 0 31.313 0c-1.489 0-2.594.578-3.563 1.094-.902.48-1.688.906-2.75.906-1.063 0-1.848-.426-2.75-.906C21.281.578 20.176 0 18.687 0ZM11.75 15.219C14.242 17.109 19.773 18 25 18c5.2 0 10.668-.875 13.219-2.719.12.477.258.946.375 1.406l.281 1.063C38.457 18.973 33.265 21 25 21c-8.258 0-13.418-2.027-13.844-3.25l.031-.125c.184-.75.348-1.559.563-2.406ZM9.531 28.5a54.23 54.23 0 0 0 4.469.781v.313c0 2.523 1.727 5.285 4.938 5.406.109.008.234 0 .343 0 1.211 0 2.332-.336 3.157-.969.714-.55 1.562-1.61 1.562-3.437V30c.336.004.664 0 1 0 .332 0 .668.004 1 0v.594C26 33.64 28.367 35 30.719 35c.11 0 .215.008.312 0C34.254 34.879 36 32.117 36 29.594v-.313c1.59-.215 3.09-.468 4.5-.781.055.184.094.383.094.688 0 .535-.133 1.152-.344 1.718-.434 1.172-1.227 2.223-2.063 2.313l-.593.062-.219.532C34.531 40.87 30.859 48.105 25 48c-6.133-.094-9.992-8.066-12.375-14.063l-.219-.562-.594-.063c-.734-.082-1.593-1.187-2.062-2.406-.207-.539-.344-1.09-.344-1.593 0-.364.055-.63.125-.813Z"/></SvgIcon>
      );
    }
  
    export default icons8spy100Icon;