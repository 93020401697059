import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Radio, FormControlLabel } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  checkbox: {
    margin: '0 8px 0px 0',
  },
  checkboxError: {
    margin: '0 8px 0px 0',
    color: theme.palette.error.main,
    '& svg': {
      color: theme.palette.error.main,
    },
  },
}));

function Radio2(props) {
  const classes = useStyles();
  const { error, value, label } = props;
  const className = error ? classes.checkboxError : classes.checkbox;

  return (
    <FormControlLabel
      value={value}
      control={<Radio />}
      label={label}
      className={className}
    />
  );
}

export default Radio2;
