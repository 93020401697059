import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Recipients from './Recipients';
import Groups from './Groups';
import Templates from './Templates';
import SMTPSettings from './SMTPSettings';

const useStyles = makeStyles(theme => ({
  tabs: {
    width: '100%',
    '& button': {
      '& > span': {
        textTransform: 'none',
      },
    },
  },
  settings: {
    height: '100%',
    overflowY: 'auto',
  },
}));

function PagingSetup(props) {
  const { callerName } = props;
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(
    callerName === 'notification-settings' ? 'Settings' : 'Groups'
  );

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div className={classes.settings}>
      {callerName !== 'notification-settings' && (
        <Tabs
          variant="scrollable"
          value={tabValue}
          onChange={handleTabChange}
          aria-label="Vertical tabs example"
          className={classes.tabs}>
          <Tab label="Groups" value="Groups" />
          <Tab label="Recipients" value="Recipients" />
          <Tab label="Templates" value="Templates" />
        </Tabs>
      )}
      <div role="tabpanel" className={classes.tabPanel}>
        <Box p={3}>
          {tabValue === 'Recipients' && <Recipients />}
          {tabValue === 'Groups' && <Groups />}
          {tabValue === 'Templates' && <Templates />}
          {tabValue === 'Settings' && <SMTPSettings />}
        </Box>
      </div>
    </div>
  );
}

export default PagingSetup;
