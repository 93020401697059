import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { getService } from '../reducers/service';

import { notify } from '../reducers/NotifierReducer';
import CustomIDGenerator from 'global-components/CustomIDGenerator';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& h4': {
      marginBottom: theme.spacing(2),
    },
  },
  form: {
    margin: '0 -4px 4px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexGrow: 1,
  },
  formControl: {
    minWidth: '210px',
  },
  selectEmpty: {
    height: '40px',
  },
}));

function CustomStructure(props) {
  const classes = useStyles();
  const service = getService('pts-custom-agency-id');
  const initialData = {
    Application: 'RMS',
    AgencyID: '',
    EntityName: 'Structure',
  };
  const dataForNewItem = {
    Application: 'RMS',
    AgencyID: '',
    EntityName: 'Structure',
    Sequence: 0,
    SequenceLength: 9,
    PrefixOrder: 1,
    SuffixOrder: 0,
    MonthOrder: 0,
    DayOrder: 0,
    YearOrder: 0,
    SequenceOrder: 2,
    SequenceResetType: 'N',
    UseSeparator: false,
    IsDeleted: false,
    Prefix: '',
    Suffix: '',
    UseFourDigitYear: false,
    Sequencer: 0,
    Separator: '',
  };
  // states

  return (
    <CustomIDGenerator
      initialData={initialData}
      dataForNewItem={dataForNewItem}
      showAgency={false}
    />
  );
}

// export default CustomStructure;
export default connect(null, {
  notify,
})(CustomStructure);
