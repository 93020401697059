/* UnitActionsCodes
CAD.DefaultCodes.UnitActions.Arrived	ARRIVED
CAD.DefaultCodes.UnitActions.Cancelled	CANCELLED
CAD.DefaultCodes.UnitActions.Completed	COMPLETED
CAD.DefaultCodes.UnitActions.Dispatch	DISPATCH
CAD.DefaultCodes.UnitActions.Enroute	ENROUTE
CAD.DefaultCodes.UnitActions.Inservice	INSERVICE
CAD.DefaultCodes.UnitActions.Outservice	OFFDUTY
CAD.DefaultCodes.UnitActions.Queued	QUEUED
*/

export const getEventColor = status => {
  switch (status) {
    case 'Pending':
      return '#e79468';
    case 'Dispatched':
      return '#ecb743';
    case 'OnHold':
      return '#ecb743';
    case 'Enroute':
      return '#c7c11e';
    case 'Arrived':
      return '#ed474b';
    case 'Completed':
      return '#a0b0c3';
    default:
      return '#a0b0c3';
  }
};

export const getUnitColor = status => {
  switch (status) {
    case 'CAD.DefaultCodes.UnitActions.Inservice':
      return '#e79468';
    case 'CAD.DefaultCodes.UnitActions.Dispatch':
      return '#ecb743';
    case 'CAD.DefaultCodes.UnitActions.Queued':
      return '#EC2418';
    case 'CAD.DefaultCodes.UnitActions.Outservice':
      return '#ff0000';
    case 'CAD.DefaultCodes.UnitActions.Enroute':
      return '#c7c11e';
    case 'CAD.DefaultCodes.UnitActions.Arrived':
      return '#ed474b';
    case 'CAD.DefaultCodes.UnitActions.Completed':
      return '#a0b0c3';
    default:
      return '#a0b0c3';
  }
};
