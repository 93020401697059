import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { getFormStyle } from 'utils/functions';
import TextField2 from 'components/TextField2';
import { RowInner } from 'utils/formStyles';
import Button from '@material-ui/core/Button';
import { handleError } from 'reducers/ErrorReducer';
import { passValidationErrors } from 'utils/functions';
import { changePass } from 'reducers/UserReducer';
import { CircularProgress } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { notify } from 'reducers/NotifierReducer';
// import { hidePassExpiration } from 'reducers/UserReducer';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: 16,
  },
  form: {
    margin: `0 -${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  w150x250: {
    ...getFormStyle(150, 250),
  },
  alert: {
    marginBottom: 16,
    '& ul': {
      padding: 0,
    },
  },
}));

function ChangeUserPass(props) {
  const classes = useStyles();
  const { Username } = props;
  const [oldPass, setOldPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [confirmNewPass, setConfirmNewPass] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errors, setErrors] = useState([]);
  const disabled = Boolean(error || !oldPass || !newPass || loading || newPass !== confirmNewPass);
  const repeatPassErr = newPass === confirmNewPass ? false : 'Passwords don\'t match';

  useEffect(() => {
    const validationErrors = passValidationErrors(Username, newPass);
    if (newPass && validationErrors.length) {
      setError(validationErrors[0]);
    } else {
      if (error) setError(false);
    }
  }, [newPass]);

  const changePassword = async () => {
    setLoading(true);
    try {
      await changePass(oldPass, newPass);
      props.notify('Password updated!', 'success');
      setOldPass('');
      setNewPass('');
      setConfirmNewPass('');
      setErrors([]);
      // props.hidePassExpiration();
    } catch (err) {
      if (err.errors && err.errors.length) {
        setErrors(err.errors);
      } else {
        const message = err.message ? err.message : 'Error updating password';
        setErrors([message]);
      }
    }
    setLoading(false);
  }

  return (
    <>
      <h4 className={classes.title}>Change password</h4>
      {errors.length > 0 && <Alert severity="error" className={classes.alert}>
        <AlertTitle>Password validation errors</AlertTitle>
        <ul>
          {errors.map((err, idx) => <li key={idx}>{err}</li>)}
        </ul>
      </Alert>}
      <RowInner>
        <TextField2
          className={classes.w150x250}
          label="Previous Password"
          value={oldPass}
          onChange={(ev, val) => setOldPass(val)}
          type="mask"
        />
      </RowInner>
      <RowInner>
        <TextField2
          className={classes.w150x250}
          label="New Password"
          value={newPass}
          onChange={(ev, val) => setNewPass(val)}
          type="mask"
          error={error}
          disabled={!oldPass}
        />
      </RowInner>
      <RowInner>
        <TextField2
          className={classes.w150x250}
          label="Repeat New Password"
          value={confirmNewPass}
          onChange={(ev, val) => setConfirmNewPass(val)}
          type="mask"
          error={repeatPassErr}
          disabled={!oldPass}
        />
      </RowInner>
      <RowInner>
        <Button
          className={classes.w150x250}
          color="secondary"
          variant="contained"
          autoFocus
          onClick={changePassword}
          disabled={disabled}>
          Change Password
        </Button>
        {loading && <CircularProgress style={{ marginLeft: 8 }} />}
      </RowInner>
    </>
  );
}

const mapStateToProps = (state) => ({
  Username: state.user.userData.user.Username
});

export default connect(mapStateToProps, {
  handleError, notify, 
  // hidePassExpiration,
})(ChangeUserPass);