import { Box, Button, TextField, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import { Autocomplete } from '@material-ui/lab';
import { findSearchProfiles } from 'Settings/Security/SecFunctions';
import Dialog from 'components/Dialog';
import { getService } from 'reducers/service';
// import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
const useStyles = makeStyles(theme => ({
  autocomplete: {
    margin: '0 4px 12px',
    maxWidth: 400,
    flexBasis: 400,
    minWidth: 400,
    flexGrow: 1,
  },
}));
export default function UserProfileChangeConfirmation(props) {
  const classes = useStyles();
  const { profile: selectedProfile, confirmationDialogData } = props;
  const service = getService('settings-sec-users');
  const [allProfiles, setAllProfiles] = useState([]);
  const [profile, setProfile] = useState(null);
  useEffect(() => {
    fetchProfiles();
  }, []);

  const close = () => {
    props.setConfirmationDialogData(null);
  };

  const handleChange = (e, newValue) => setProfile(newValue);
  const fetchProfiles = async () => {
    let result = await findSearchProfiles();
    result = result.filter(profile => profile.ptsProfileID !== selectedProfile.ptsProfileID);
    setAllProfiles(result);
  };
  const handleChangeProfile = async () => {
    const ptsProfileID =
      confirmationDialogData.type === 'change-profile'
        ? profile?.ptsProfileID
        : selectedProfile.ptsProfileID;
    const { ptsUserID, Username } = confirmationDialogData.user;

    const result = await service.patch(ptsUserID, {
      ptsProfileID: ptsProfileID,
      UpdatedBy: Username,
    });
    close();
    props.fetchUsers();
  };
  const renderActions = () => {
    const profileId =
      confirmationDialogData.type === 'change-profile'
        ? profile?.ptsProfileID
        : selectedProfile.ptsProfileID;
    return (
      <>
        <Button
          color="primary"
          variant="contained"
          onClick={handleChangeProfile}
          autoFocus
          disabled={!profileId}>
          Change Profile
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon />
          Close
        </Button>
      </>
    );
  };
  return (
    <Dialog
      open={true}
      onClose={close}
      title={'User Profile Change Window'}
      actions={renderActions()}
      maxWidth="sm"
      fullWidth={true}
      toolbar>
      <div style={{ minHeight: '100px' }}>
        {confirmationDialogData && confirmationDialogData.type === 'change-profile' ? (
          <Box>
            <Autocomplete
              options={allProfiles}
              className={classes.autoComplete}
              renderInput={params => (
                <TextField {...params} label="Select Alternative Profile" variant="outlined" />
              )}
              onChange={handleChange}
              getOptionLabel={option => option.Label}
              renderOption={option => `${option.Label} ${option.Type ? -option.Type : ''}`}
              size="small"
              value={profile}
            />
          </Box>
        ) : (
          <div>
            Are you sure you want to move <b>{confirmationDialogData.user?.Username}</b> to
            <b> {selectedProfile.Label}</b> profile ?
          </div>
        )}
      </div>
    </Dialog>
  );
}
