import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

import { handleError } from '../reducers/ErrorReducer';
import { showSpinner, hideSpinner } from '../reducers/UiReducer';
import { notify } from '../reducers/NotifierReducer';
import { closeCitationCourt } from '../reducers/DialogsReducer';
import { Dialog, DialogActions, DialogTitle, Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { DialogContent } from '@mui/material';
import { getService } from 'reducers/service';
import DatePicker2 from 'components/DatePicker2';
import { processPhoneNoWithoutExtra } from 'utils/functions';

const useStyles = makeStyles(theme => ({
  actions: {
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  textField: {
    width: 600,
    maxWidth: '100%',
    marginTop: theme.spacing(1),
  },
  dialog: {
    '& $content': {
      margin: 0,
      padding: 0,
      background: 'red !important',
    },
  },
}));

function AddCitationCourt(props) {
  const classes = useStyles();
  const { wsClient, user } = props;
  const { type } = props.data;
  const username = user?.userData?.user?.Username;
  const ptsUserID = user?.userData?.user?.ptsUserID;
  const service = getService('citation-court');
  const Agencies = props.dictionary.Agencies || [];
  const Cities = props.dictionary.Cities || [];
  const States = props.dictionary.States || [];
  const [text, setText] = useState('');
  const [event, setEvent] = useState({});
  const textFieldRef = useRef(null);
  const [cityCode, setCityCode] = useState('');
  const [formData, setFormData] = useState({});
  useEffect(() => {
    const info = props.data.info;
    if (type == 'edit' && info?.Court_Name) {
      const form = {
        Court_Name: info.Court_Name || null,
        Bank_Code: info.Bank_Code || null,
        Street_Address: info.Street_Address || null,
        Mailing_Address: info.Mailing_Address || null,
        City: info.City || null,
        State: info.State || null,
        Zip_Code: info.Zip_Code || null,
        // Area_Code: info.Area_Code || null,
        Phone_Number: processPhoneNoWithoutExtra(info.Area_Code + info.Phone_Number) || null,
        NextDateTime: info.NextDateTime || null,

        Court_type: info.Court_type || null,
        Law_Code_name: info.Law_Code_name || null,
        court_state: info.court_state || null,
        court_location: info.court_location || null,
        DefaultDays: info.DefaultDays || null,
        Operator: info.Operator || null,
        OperatorID: info.OperatorID || null,
        UpdateDateTime: info.UpdateDateTime || null,
        Notes: info.Notes || null,
      };
      setCityCode(info.City);

      setFormData(form);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.info, type]);
  //   useEffect(() => {
  //     setTimeout(() => textFieldRef.current.focus(), 0);
  //     getDetails();
  //     // eslint-disable-next-line
  //   }, []);

  //   const getDetails = async () => {
  //     props.showSpinner();
  //     try {
  //       const event = await getEventDetails(ptsEventID);
  //       setEvent(event);
  //     } catch (err) {
  //       props.handleError(err, '|Error, Unable to retrieve event details');
  //     }
  //     props.hideSpinner();
  //   };

  const close = () => {
    props.closeCitationCourt();
  };
  const save = async () => {
    props.showSpinner();
    try {
      //   await addEventNote(text, ptsEventID);
      const newForm = { ...formData };
      newForm['Operator'] = username || '';
      newForm['OperatorID'] = ptsUserID || '';
      if (newForm.Phone_Number) {
        const formatedNUmber = newForm.Phone_Number.replace(/\D/g, '');
        const areaCode = formatedNUmber.slice(0, 3);
        const phn = formatedNUmber.slice(3);
        newForm.Area_Code = areaCode || '';
        newForm.Phone_Number = phn;
      }
      if (type == 'edit') {
        const id = props.data?.info?.SQLSysID;

        const updatedData = await service.patch(id, newForm);
        props.notify('File Updated', 'success');
      } else {
        const addedData = await service.create(newForm);
        props.notify('New File Created', 'success');
      }
    } catch (err) {
      props.handleError(err, 'Error, File not saved.', err);
    }
    props.hideSpinner();
    close();
  };
  const handleFormChange = (name, data) => {
    const newForm = { ...formData };
    newForm[name] = data;
    setFormData({ ...newForm });
  };

  const onPhoneNoChange = ev => {
    const inputVal = ev.target.value;
    const outputVal = processPhoneNoWithoutExtra(inputVal);
    if (outputVal !== false) handleFormChange('Phone_Number', outputVal);
  };
  const renderActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={save}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  return (
    <Dialog
      onClose={close}
      title={'Add Citation Court'}
      open={true}
      actions={renderActions()}
      //   className={classes.dialog}

      // toolbar={false}
      // draggable={false}
      fullWidth
      maxWidth="lg">
      <DialogTitle>{type == 'edit' ? 'Edit' : 'Add'} Citation Court</DialogTitle>
      <DialogContent className="py-2">
        <Grid container spacing={2} style={{ width: '100%' }}>
          <Grid item xs={12} sm={6}>
            <TextField
              //fullWidth
              size="small"
              style={{ width: '100%' }}
              type={'text'}
              value={formData.Court_Name || ''}
              label={'Court Name'}
              //error={error}
              variant={'outlined'}
              onChange={e => handleFormChange('Court_Name', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              //fullWidth
              inputProps={{
                maxLength: 10,
              }}
              size="small"
              style={{ width: '100%' }}
              type={'text'}
              value={formData.Bank_Code || ''}
              label="Bank Code"
              //error={error}
              variant={'outlined'}
              onChange={e => handleFormChange('Bank_Code', e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              //fullWidth
              size="small"
              style={{ width: '100%' }}
              type={'text'}
              value={formData.Street_Address || ''}
              label="Street Address"
              //error={error}
              variant="outlined"
              onChange={e => handleFormChange('Street_Address', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              //fullWidth
              size="small"
              style={{ width: '100%' }}
              type={'text'}
              value={formData.Mailing_Address || ''}
              label="Mailing Address"
              //error={error}
              variant="outlined"
              onChange={e => handleFormChange('Mailing_Address', e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="combo-box-demo"
              size="small"
              autoHighlight
              autoSelect
              value={{ CityDescription: formData.City || '' }}
              onChange={(event, newValue) => {
                setCityCode(newValue?.Code || '');
                handleFormChange('City', newValue?.CityDescription || '');
              }}
              options={[{ Code: '', CityDescription: '' }, ...Cities]}
              getOptionLabel={option => option.CityDescription || ''}
              renderOption={option =>
                option.CityDescription ? (
                  <span>
                    {option.CityDescription}
                    <strong>{option.State ? ' - ' + option.State : ''}</strong>
                  </span>
                ) : (
                  '-'
                )
              }
              getOptionSelected={(option, value) =>
                (option.CityDescription || '').toUpperCase() ===
                (value.CityDescription || '').toUpperCase()
              }
              style={{ width: '100%' }}
              renderInput={params => <TextField {...params} variant="outlined" label="City" />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="combo-box-demo"
              size="small"
              autoHighlight
              autoSelect
              value={{ Code: formData.State || '' }}
              onChange={(event, newValue) => {
                handleFormChange('State', newValue?.Code || '');
              }}
              options={[{ Code: '', Description: '' }, ...States]}
              getOptionLabel={option => option.Code || ''}
              renderOption={option => option.Code || '-'}
              getOptionSelected={(option, value) => option.Code === value.Code}
              style={{ width: '100%' }}
              renderInput={params => <TextField {...params} variant="outlined" label="State" />}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              //fullWidth
              inputProps={{
                maxLength: 10,
              }}
              size="small"
              style={{ width: '100%' }}
              type="text"
              value={formData.Zip_Code || ''}
              label="Zip"
              //error={error}
              variant="outlined"
              onChange={e => handleFormChange('Zip_Code', e.target.value)}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              style={{ width: '100%' }}
              type="text"
              value={formData.Area_Code || ''}
              label="Area Code"
              inputProps={{
                maxLength: 3,
              }}
              variant="outlined"
              onChange={e => handleFormChange('Area_Code', e.target.value)}
            />
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <TextField
              //fullWidth

              size="small"
              style={{ width: '100%' }}
              type={'text'}
              value={formData.Phone_Number || ''}
              label={'Phone'}
              error={formData.Phone_Number && formData.Phone_Number.length < 14}
              variant={'outlined'}
              onChange={onPhoneNoChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker2
              onChange={(n, date) => handleFormChange('NextDateTime', date)}
              label="Next Date Time"
              style={{ width: '100%' }}
              margin="none"
              value={formData.NextDateTime || null}
              className={classes.field}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              style={{ width: '100%' }}
              type="text"
              value={formData.Court_type || ''}
              label="Court Type"
              variant="outlined"
              onChange={e => handleFormChange('Court_type', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              style={{ width: '100%' }}
              type="text"
              value={formData.Law_Code_name || ''}
              label="Law Code Name"
              variant="outlined"
              onChange={e => handleFormChange('Law_Code_name', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="combo-box-demo"
              size="small"
              autoHighlight
              autoSelect
              value={{ Code: formData.court_state || '' }}
              onChange={(event, newValue) => {
                handleFormChange('court_state', newValue?.Code || '');
              }}
              options={[{ Code: '', Description: '' }, ...States]}
              getOptionLabel={option => option.Code || ''}
              renderOption={option => option.Code || '-'}
              getOptionSelected={(option, value) => option.Code === value.Code}
              style={{ width: '100%' }}
              renderInput={params => (
                <TextField {...params} variant="outlined" label="Court State" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              style={{ width: '100%' }}
              type="text"
              value={formData.court_location || ''}
              label="Court Location"
              variant="outlined"
              onChange={e => handleFormChange('court_location', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              style={{ width: '100%' }}
              value={formData.DefaultDays || null}
              label="Default Days"
              variant="outlined"
              type="number"
              error={Number(formData.DefaultDays) > 255}
              helperText="Max value 255"
              onChange={e => handleFormChange('DefaultDays', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              id="outlined-multiline-static"
              style={{ width: '100%' }}
              label="Notes"
              variant="outlined"
              multiline
              rows={4}
              value={formData.Notes || ''}
              onChange={e => handleFormChange('Notes', e.target.value)}
            />
            {/* <TextField
              //fullWidth
              size="small"
              type={'text'}
              value={formData.JsonDefinition}
              label="Citation Definition"
              //error={error}
              multiline
              rows={4}
              variant={'outlined'}
            /> */}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className=" mb-1 mr-3">{renderActions()}</div>
      </DialogActions>
    </Dialog>
  );
}
const mapStateToProps = state => {
  return {
    dictionary: state.dictionary,
    wsClient: state.websocket,
    user: state.user,
  };
};
export default connect(mapStateToProps, {
  showSpinner,
  hideSpinner,
  handleError,
  notify,
  closeCitationCourt,
})(AddCitationCourt);
