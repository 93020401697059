
    import React from 'react';
    import SvgIcon from '@material-ui/core/SvgIcon';
    
    function icons8firestation100Icon(props) {
      return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100" height="100"><path d="m48.62 19.21-23-18a1.02 1.02 0 0 0-1.24 0l-23 18c-.43.34-.51.97-.17 1.41.34.43.97.51 1.41.17L4 19.71V46c0 .55.45 1 1 1h40c.55 0 1-.45 1-1V19.71l1.38 1.08c.19.14.4.21.62.21.3 0 .59-.13.79-.38.34-.44.26-1.07-.17-1.41zM28.48 39s2.1-2.38 2.1-4.25c0-2.62-1.97-3.87-2.76-6.75-1.18 1.25-.92 3.25-.92 3.25S24.14 29 25.72 22.5c0 0-4.86 3.25-4.86 8.75 0 4.5 2.36 7.75 2.36 7.75S16 37.25 16 31.25C16 21.62 28.08 16 28.08 16c-.39 2.5 0 4.75 1.19 6.25.65-.5 1.44-1.38 2.36-1.62-.39 3.37 2.36 4.74 2.36 10.62.14 3.38-2.23 6.62-5.51 7.75z"/></SvgIcon>
      );
    }
  
    export default icons8firestation100Icon;