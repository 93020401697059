import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PersonLookup from 'components/PersonLookup';
import { gridStyle } from 'utils/formStyles';
import {
  findSearchProfiles,
  findSearchClearanceLevels,
  findContactList,
} from 'Settings/Security/SecFunctions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getPartyPerson } from 'reducers/EventsReducer';
import AddPersonDialog from 'Dialogs/AddPersonDialog';
import TextField2 from 'components/TextField2';
import SendIcon from '@mui/icons-material/Send';
import OutboxIcon from '@mui/icons-material/Outbox';
import { processInputPhoneNo } from 'utils/functions';
import { AddIcon } from 'evergreen-ui';
const useStyles = makeStyles(theme => ({
  item: gridStyle(200, 320),
  autoComplete: gridStyle(200, 300),
  disabledText: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
  checkedBox: {
    width: '100%',
  },
}));
const btnStyle = {
  margin: '1px 8px 1px 2px',
  padding: 0,
};
function AddUser(props) {
  const classes = useStyles();
  const [username, setUsername] = useState('');
  const [person, setPerson] = useState(null);
  const [profile, setProfile] = useState(null);
  const [clearance, setClearance] = useState(null);
  const [email, setEmail] = useState('');
  const [allProfiles, setAllProfiles] = useState([]);
  const [allClearanceLevels, setAllClearanceLevels] = useState([]);
  const [isLockedOut, setIsLockedOut] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [defaultAgency, setDefaultAgency] = useState('');
  const [NCICUsername, setNCICUsername] = useState('');
  const [ori, setOri] = useState(null);
  const [rid, setRid] = useState(null);
  const [forcePW, setForcePW] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [ApplicationName, setApplicationName] = useState(null);
  const [showAddPersonDialog, setShowAddPersonDialog] = useState();
  const [userNameError, setUserNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [ptsPersonID, setptsPersonID] = useState(null);
  const [PhoneNumber, setPhoneNumber] = useState('');
  const { setNewUser, newUserData, selectedUser } = props;
  const [credentialAnchorEl, setCredentialAnchorEl] = React.useState(null);
  const openPopup = Boolean(credentialAnchorEl);
  const [showContactDialog, setShowContactDialog] = React.useState(null);
  const agencies = useSelector(state => state.dictionary.Agencies);
  const [numberList, setNumberList] = React.useState([]);
  const [emailList, setEmailList] = React.useState([]);
  const [NCICEmail, setNCICEmail] = useState('');
  useEffect(() => {
    fetchProfiles();
    fetchClearanceLevels();
  }, []);
  useEffect(() => {
    if (props.selectedProfile) {
      handleChange(
        'ptsProfileLookupId',
        props.selectedProfile,
        setProfile,
        props.selectedProfile?.ptsProfileID || null
      );
    }
  }, [props.selectedProfile]);
  useEffect(() => {
    if (person) {
      setNewUser(user => {
        return { ...user, ptsPersonId: person.ptsPersonID };
      });
    } else {
      setNewUser(user => {
        return { ...user, ptsPersonId: null };
      });
    }
  }, [person]);

  useEffect(() => {
    if (allProfiles && allClearanceLevels && selectedUser) {
      const { ptsProfileID, ptsClearanceLevelID } = selectedUser;
      const currentProfile = allProfiles.find(profile => profile.ptsProfileID === ptsProfileID);
      const currentClearance = allClearanceLevels.find(
        c => c.ptsClearanceLevelID === ptsClearanceLevelID
      );
      const currentAgency = (agencies || []).find(agn => agn.AgencyID === selectedUser.AgencyID);

      getPartyPerson(selectedUser.ptsPersonID).then(person => {
        setSelectedPerson(person);
        setPerson(person);
        setNewUser(user => {
          return { ...user, ptsPersonId: selectedUser.ptsPersonID };
        });
      });

      setProfile(currentProfile);
      setClearance(currentClearance);
      setUsername(selectedUser.Username);
      setIsLockedOut(selectedUser.IsLockedOut);
      setIsDisabled(selectedUser.IsDisabled);
      setEmail(selectedUser.PasswordEmail);
      setDefaultAgency(currentAgency);
      setNCICUsername(selectedUser.NCICUsername);
      setApplicationName(selectedUser.ApplicationName);
      setPhoneNumber(selectedUser.PhoneNumber);
      setRid(selectedUser.RID);
      setOri(selectedUser.ORI);
      setNCICEmail(selectedUser.NCICEmail || '');
    }
  }, [allProfiles, allClearanceLevels]);

  useEffect(() => {
    if (person && person?.ptsPersonID) {
      fetchContactList(person?.ptsPersonID);
    } else {
      setEmailList([]);
      setNumberList([]);
    }
  }, [person && person?.ptsPersonID]);

  const fetchContactList = async id => {
    const list = await findContactList(id);
    if (list && list.length > 0) {
      const filterList = list.filter(contact => contact.Info);
      const phones = filterList.filter(contact => contact.ContactMethod === 'Phone');
      const emails = filterList.filter(contact => contact.ContactMethod === 'Email');
      setNumberList(phones);
      setEmailList(emails);
    }
  };

  const handleShowPopupClick = event => {
    setCredentialAnchorEl(event.currentTarget);
  };
  const handleClosePopup = () => {
    setCredentialAnchorEl(null);
  };

  const handleChangeWithValidation = (key, value, setState, validateInput, setError) => {
    setState(value);
    setNewUser(user => {
      return { ...user, [key]: value };
    });
    if (validateInput(value)) setError(false);
    else setError(true);
  };

  const handleChange = (key, value, setState, stateValue) => {
    setState(value);
    setNewUser(user => {
      return { ...user, [key]: stateValue };
    });
  };

  const validateUserName = value => {
    if (!/^[a-zA-Z0-9_@.-]+$/.test(value)) {
      return false;
    } else {
      return true;
    }
  };

  const validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const fetchProfiles = async () => {
    const result = await findSearchProfiles();
    setAllProfiles(result);
  };

  const fetchClearanceLevels = async () => {
    const result = await findSearchClearanceLevels();
    setAllClearanceLevels(result);
  };
  const handleSendUserName = medium => {
    props.saveUser({ type: 'username', medium });
    handleClosePopup();
  };
  const renderUsername = () => {
    return (
      <Box style={{ marginLeft: 10, display: 'flex', justifyContent: 'left', gap: '4px' }}>
        <TextField
          label="Username*"
          variant="outlined"
          value={username}
          disabled={selectedUser?.Username}
          size="small"
          onChange={e =>
            handleChangeWithValidation(
              'userName',
              e.target.value,
              setUsername,
              validateUserName,
              setUserNameError
            )
          }
          fullWidth
          className={classes.item}
          error={userNameError}
          style={{ flex: selectedUser?.Username ? 95 : 100 }}
          helperText={userNameError ? 'Provide a valid Username.' : ''}
        />
        {selectedUser?.Username ? (
          <Tooltip title="Send Username">
            <IconButton style={{ flex: 5, marginTop: '-10px' }} onClick={handleShowPopupClick}>
              <OutboxIcon fontSize="large" />
            </IconButton>
          </Tooltip>
        ) : null}
        <Menu
          id="basic-menu"
          anchorEl={credentialAnchorEl}
          open={openPopup}
          onClose={handleClosePopup}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}>
          {newUserData.email ? (
            <MenuItem onClick={() => handleSendUserName('EMAIL')}>Email</MenuItem>
          ) : (
            <div style={{ padding: '10px', textAlign: 'center', width: '100%' }}>
              <Tooltip title="Please Add a Email Address">
                <span className={classes.disabledText}>Email</span>
              </Tooltip>
            </div>
          )}

          {newUserData.PhoneNumber ? (
            <MenuItem onClick={() => handleSendUserName('SMS')}>SMS</MenuItem>
          ) : (
            <div style={{ padding: '10px', textAlign: 'center', width: '100%' }}>
              <Tooltip title="Please Add a Phone Number">
                <span className={classes.disabledText}>SMS</span>
              </Tooltip>
            </div>
          )}
        </Menu>
      </Box>
    );
  };
  const updateNumberList = item => {
    handleChange('PhoneNumber', item.Info || '', setPhoneNumber, item.Info || '');
    setNumberList([item, ...numberList]);
  };
  const updateEmailList = item => {
    handleChange('email', item.Info, setEmail, item.Info);
    setEmailList([item, ...emailList]);
  };
  const renderEmail = () => {
    return (
      <Box style={{ marginLeft: 10 }}>
        <Autocomplete
          disabled={props.disabled}
          className={classes.item}
          fullWidth
          autoHighlight
          options={emailList || []}
          value={{ Info: email }}
          getOptionLabel={option => {
            return option?.Info || '';
          }}
          size="small"
          onChange={(ev, val) => {
            setEmail(val?.Info || '');
            handleChange('email', val?.Info, setEmail, val?.Info);
            // handleChangeWithValidation('email', val?.Info, setEmail, validateEmail, setEmailError);
          }}
          renderOption={option => {
            return (
              <span>
                <b>{option.MethodType || ''}</b> - {option.Info || ''}
              </span>
            );
          }}
          // getOptionSelected={(option, value) => option.Info === value.Info}
          renderInput={params => {
            return (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  autoComplete: 'new-password',

                  startAdornment: (
                    <IconButton
                      style={btnStyle}
                      disabled={!person?.ptsPersonID}
                      onClick={() => {
                        setShowContactDialog('Email');
                        setShowAddPersonDialog(true);
                      }}>
                      <AddIcon fontSize="small" />
                    </IconButton>
                  ),
                }}
                size="small"
                variant="outlined"
                label="Email"
              />
            );
          }}
        />
      </Box>
    );
  };

  const renderPhoneNumber = () => {
    return (
      <Box style={{ marginLeft: 10 }}>
        <Autocomplete
          disabled={props.disabled}
          className={props.className}
          fullWidth
          autoHighlight
          options={numberList || []}
          value={{ Info: PhoneNumber }}
          // error={PhoneNumber?.length > 0 && PhoneNumber?.length < 14}
          getOptionLabel={option => option.Info || ''}
          size="small"
          onChange={(ev, val) => {
            handleChange('PhoneNumber', val?.Info || '', setPhoneNumber, val?.Info || '');
          }}
          renderOption={option => {
            return (
              <span>
                <b>{option.MethodType || ''}</b> - {option.Info || ''}
              </span>
            );
          }}
          renderInput={params => {
            return (
              <TextField
                {...params}
                className={classes.item}
                InputProps={{
                  ...params.InputProps,
                  autoComplete: 'new-password',

                  startAdornment: (
                    <IconButton
                      style={btnStyle}
                      disabled={!person?.ptsPersonID}
                      onClick={() => {
                        setShowContactDialog('Phone');
                        setShowAddPersonDialog(true);
                      }}>
                      <AddIcon fontSize="small" />
                    </IconButton>
                  ),
                }}
                size="small"
                variant="outlined"
                label="Phone Number"
              />
            );
          }}
        />
      </Box>
    );
  };
  const renderPersonLookup = () => {
    return (
      <Box style={{ marginLeft: 10 }}>
        <PersonLookup
          className={classes.item}
          onPersonChange={setPerson}
          label="Person Lookup*"
          addEnabled={true}
          selectedPerson={selectedPerson}
          ptsPersonID={ptsPersonID}
          setShowAddPersonDialog={setShowAddPersonDialog}
          fullWidth
        />
      </Box>
    );
  };

  const renderProfileLookup = () => {
    return (
      <Box style={{ marginLeft: 10 }}>
        <Autocomplete
          disablePortal
          options={allProfiles || []}
          className={classes.item}
          renderInput={params => (
            <TextField {...params} label="Profile Lookup*" variant="outlined" />
          )}
          onChange={(e, value) =>
            handleChange('ptsProfileLookupId', value, setProfile, value?.ptsProfileID || null)
          }
          getOptionLabel={option => option.Label}
          renderOption={option => `${option.Label} ${option.Type ? '- ' + option.Type : ''}`}
          getOptionSelected={(option, value) => option.ptsProfileID === value?.ptsProfileID}
          size="small"
          value={profile}
        />
      </Box>
    );
  };

  const renderClearanceLevel = () => {
    return (
      <Box style={{ marginLeft: 10 }}>
        <Autocomplete
          disablePortal
          options={allClearanceLevels || []}
          className={classes.item}
          renderInput={params => (
            <TextField {...params} label="Clearance Levels Lookup" variant="outlined" />
          )}
          onChange={(e, value) =>
            handleChange(
              'ptsClearanceLevelId',
              value,
              setClearance,
              value?.ptsClearanceLevelID || null
            )
          }
          getOptionLabel={option => option.Level}
          renderOption={option =>
            `${option.Level} ${option.Description ? '- ' + option.Description : ''}`
          }
          size="small"
          value={clearance}
        />
      </Box>
    );
  };

  const renderLockedOut = () => {
    return (
      <Box style={{ marginLeft: 10 }}>
        <FormControlLabel
          className={classes.checkedBox}
          control={
            <Checkbox
              checked={isLockedOut}
              onChange={() =>
                handleChange('isLockedOut', !isLockedOut, setIsLockedOut, !isLockedOut)
              }
            />
          }
          label="Locked Out"
        />
      </Box>
    );
  };

  const renderDisabled = () => {
    return (
      <Box style={{ marginLeft: 20 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isDisabled}
              onChange={() => handleChange('isDisable', !isDisabled, setIsDisabled, !isDisabled)}
            />
          }
          label="Disable"
        />
      </Box>
    );
  };

  const renderForcePW = () => {
    return (
      <Box style={{ marginLeft: 20 }}>
        <FormControlLabel
          className={classes.checkedBox}
          control={
            <Checkbox
              checked={forcePW}
              onChange={() => handleChange('forcePWChange', !forcePW, setForcePW, !forcePW)}
            />
          }
          label="Force Password Change"
        />
      </Box>
    );
  };

  const renderDefaultAgency = () => {
    return (
      <Box style={{ marginLeft: 10 }}>
        <Autocomplete
          disablePortal
          options={agencies || []}
          className={classes.item}
          onChange={(e, value) =>
            handleChange('defaultAgencyId', value, setDefaultAgency, value?.AgencyID || null)
          }
          getOptionLabel={option => option.AgencyID}
          renderInput={params => (
            <TextField {...params} label="Default Agency" variant="outlined" />
          )}
          renderOption={option =>
            `${option.AgencyID} ${option.Description ? '- ' + option.Description : ''}`
          }
          size="small"
          fullWidth
          value={defaultAgency}
        />
      </Box>
    );
  };

  const renderNCICUsername = () => {
    return (
      <Box style={{ marginLeft: 10 }}>
        <TextField
          label="NCIC Username"
          variant="outlined"
          placeholder="max length 30"
          inputProps={{ maxLength: 30 }}
          value={NCICUsername}
          size="small"
          onChange={e =>
            handleChange('NCICUserName', e.target.value, setNCICUsername, e.target.value)
          }
          fullWidth
          className={classes.item}
        />
      </Box>
    );
  };

  const renderNCICEmail = () => {
    return (
      <Box style={{ marginLeft: 10 }}>
        <TextField
          label="NCIC Email"
          variant="outlined"
          value={NCICEmail}
          fullWidth
          size="small"
          className={classes.item}
          type="email"
          error={emailError}
          helperText={emailError ? 'Please enter a valid email address' : ''}
          onChange={e =>
            handleChangeWithValidation(
              'NCICEmail',
              e.target.value,
              setNCICEmail,
              validateEmail,
              setEmailError
            )
          }
        />
      </Box>
    );
  };
  const renderORI = () => {
    return (
      <Box style={{ marginLeft: 10 }}>
        <TextField
          label="ORI"
          variant="outlined"
          value={ori}
          size="small"
          onChange={e => handleChange('ori', e.target.value, setOri, e.target.value)}
          fullWidth
          className={classes.item}
        />
      </Box>
    );
  };

  const renderRID = () => {
    return (
      <Box style={{ marginLeft: 10 }}>
        <TextField
          label="RID"
          variant="outlined"
          value={rid}
          size="small"
          onChange={e => handleChange('rid', e.target.value, setRid, e.target.value)}
          fullWidth
          className={classes.item}
        />
      </Box>
    );
  };

  const renderApplication = () => {
    const applicationList = [
      { name: 'RMS', index: 1 },
      { name: 'CAD', index: 2 },
      { name: 'CAD-Mobile', index: 3 },
    ];

    return (
      <Box style={{ marginLeft: 10 }}>
        <Autocomplete
          style={{ padding: 5, paddingBottom: 20 }}
          options={applicationList || []}
          // disabled={selectedUser?.Username}
          disablePortal
          getOptionLabel={option => option.name}
          renderInput={params => (
            <TextField {...params} label="Default Application" variant="outlined" />
          )}
          renderOption={option => `${option.name}`}
          size="small"
          value={{ name: ApplicationName }}
          onChange={(e, value) => {
            setApplicationName(value);
            handleChange(
              'ApplicationName',
              value?.name || '',
              setApplicationName,
              value?.name || null
            );
          }}
          getOptionSelected={(option, value) => {
            return option.name === value.name;
          }}
        />
      </Box>
    );
  };

  return (
    <div>
      {showAddPersonDialog && (
        <AddPersonDialog
          setptsPersonID={setptsPersonID}
          setShowAddPersonDialog={setShowAddPersonDialog}
          showContactDialog={showContactDialog}
          ptsPersonID={person?.ptsPersonID}
          setShowContactDialog={setShowContactDialog}
          updateNumberList={updateNumberList}
          updateEmailList={updateEmailList}
        />
      )}
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={12} style={{ width: '100%' }}>
          {renderApplication()}
        </Grid>

        <Grid item xs={6} style={{ width: '100%' }}>
          {renderUsername()}
        </Grid>
        <Grid item xs={6} style={{ width: '100%' }}>
          {renderProfileLookup()}
        </Grid>
      </Grid>

      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          {renderPersonLookup()}
        </Grid>
        <Grid item xs={6} style={{ width: '100%' }}>
          {renderClearanceLevel()}
        </Grid>
      </Grid>

      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          {renderEmail()}
        </Grid>
        <Grid item xs={6} style={{ width: '100%' }}>
          {renderPhoneNumber()}
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          {renderDefaultAgency()}
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          {renderNCICUsername()}
        </Grid>
        <Grid item xs={6} style={{ width: '100%' }}>
          {renderNCICEmail()}
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          {renderRID()}
        </Grid>
        <Grid item xs={6} style={{ width: '100%' }}>
          {renderORI()}
        </Grid>
      </Grid>

      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={4} style={{ width: '100%' }}>
          {renderLockedOut()}
        </Grid>
        <Grid item xs={3} style={{ width: '100%' }}>
          {renderDisabled()}
        </Grid>
        <Grid item xs={5} style={{ width: '100%' }}>
          {renderForcePW()}
        </Grid>
      </Grid>
    </div>
  );
}

export default AddUser;
