//react
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
//components
import { Button } from '@material-ui/core';
import { gridStyle } from 'utils/formStyles';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from 'components/Dialog';
import Tooltip from 'components/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CircularProgress from '@material-ui/core/CircularProgress';
//reducers
import { notify } from 'reducers/NotifierReducer';
import { handleError } from 'reducers/ErrorReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { closeAddEditDefaultEmails, closeAddSecRole } from 'reducers/DialogsReducer';
//services
import { getService } from 'reducers/service';
import { validateEmail } from 'utils/functions';

const useStyles = makeStyles(theme => ({
  item: {
    ...gridStyle(200, 800),
    margin: 0,
  },
  wrap: {
    minHeight: 300,
    minWidth: 400,
    overflowY: 'auto',
  },
  row: {
    padding: '5px 15px 5px',
    borderBottom: `1px solid ${theme.card.hr}`,
    '& p': {
      fontSize: 13,
    },
    '&:hover': {
      '& $rowActions': {
        opacity: 1,
      },
    },
  },
  actions: {
    padding: '5px 15px',
    textAlign: 'right',
    borderBottom: `1px solid ${theme.card.hr}`,
    '& label': {
      marginBottom: 0,
    },
  },
  typography: {
    borderBottom: `1px solid ${theme.card.hr}`,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h4': {
      fontSize: '1em',
      minWidth: 130,
      marginBottom: 0,
    },
  },
  left: {
    display: 'flex',
    alignItems: 'center',
  },
  rowActions: {
    textAlign: 'right',
    opacity: 0,
    transition: 'opacity 0.3s',
    '& label': {
      marginBottom: 0,
    },
  },
  loading: {
    textAlign: 'center',
  },
}));
const AddSecRoleDialog = props => {
  const classes = useStyles();
  const { data } = props;
  const service = getService('settings-agency-cad');
  const [defaultEmails, setDefaultEmails] = useState([]);
  const [defaultEmail, setDefaultEmail] = useState('');
  const [showEmailField, setShowEmailField] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  useEffect(() => {
    if (!data?.defaultEmails) return;
    const stringToArray = data.defaultEmails.split(',');
    setDefaultEmails(stringToArray);
  }, [data]);

  const onAddEmail = () => {
    setShowEmailField(true);
    setIsEditing(false);
  };

  const onEditEmail = email => {
    setIsEditing(true);
    const index = defaultEmails.findIndex(item => item === email);
    setEditingIndex(index);
    setDefaultEmail(email);
    setShowEmailField(true);
  };

  const onRemoveEmail = async email => {
    const tempArray = [...defaultEmails];
    const filteredArray = tempArray.filter(item => item !== email);
    const defaultEmailsString = filteredArray.join(',');
    await handleSaveEmails(defaultEmailsString, 'remove');
  };

  const onDiscard = () => {
    setDefaultEmail('');
    setShowEmailField(false);
  };

  const onSave = async () => {
    let defaultEmailsString = '';
    if (isEditing) defaultEmailsString = updateDefaultEmails();
    else defaultEmailsString = addDefaultEmail();
    await handleSaveEmails(defaultEmailsString);
  };

  const addDefaultEmail = () => {
    const tempArray = [...defaultEmails];
    tempArray.push(defaultEmail);
    const defaultEmailsString = tempArray.join(',');
    return defaultEmailsString;
  };

  const updateDefaultEmails = () => {
    const tempArray = [...defaultEmails];
    tempArray[editingIndex] = defaultEmail;
    const defaultEmailsString = tempArray.join(',');
    return defaultEmailsString;
  };

  const handleSaveEmails = async (defaultEmailsString, opType = '') => {
    setLoading(true);
    try {
      await service.create([
        {
          Path: `${data.agencyId}.CAD.Event.EmailReportOnEventClose.Email`,
          CDRKey: 'CAD Event',
          ValueString: defaultEmailsString,
        },
      ]);
      notifyUser(opType);
      setDefaultEmail('');
      setIsEditing(false);
      setDefaultEmails(defaultEmailsString ? defaultEmailsString.split(',') : []);
    } catch (err) {
      props.handleError(err, 'Error Saving Agency CAD Settings.');
    }
    setLoading(false);
  };

  const notifyUser = operationType => {
    if (operationType === 'remove') props.notify('Agency Default Email Removed', 'error');
    else props.notify('Agency Default Emails Saved', 'success');
  };

  const close = () => {
    props.notifyDataUpdate({ type: 'default-emails', data: null });
    props.closeAddEditDefaultEmails();
  };

  const renderDialogActions = () => {
    return (
      <>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  const renderActions = () => {
    return (
      <div className={classes.actions}>
        <Tooltip title="Add Email">
          <span>
            <IconButton color="primary" size="small" onClick={onAddEmail} disabled={showEmailField}>
              <AddIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Save changes">
          <IconButton
            color="primary"
            size="small"
            onClick={onSave}
            disabled={!defaultEmail || !validateEmail(defaultEmail)}>
            <SaveIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Discard changes">
          <IconButton color="primary" size="small" onClick={onDiscard} disabled={!defaultEmail}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  const renderDefaultEmails = () => {
    return (
      <div style={{ marginTop: '20px' }}>
        {defaultEmails.length ? (
          defaultEmails.map((email, idx) => (
            <div key={idx} className={classes.row}>
              <div className={classes.header}>
                <div className={classes.left}>
                  <h4>{email}</h4>
                </div>
                <div className={classes.rowActions}>
                  <Tooltip title="Edit email">
                    <span>
                      <IconButton
                        color="primary"
                        size="small"
                        component="span"
                        onClick={() => onEditEmail(email)}>
                        <EditIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title="Delete email">
                    <span>
                      <IconButton
                        color="primary"
                        size="small"
                        component="span"
                        onClick={() => onRemoveEmail(email)}>
                        <DeleteIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No Record Found</p>
        )}
      </div>
    );
  };

  const renderAddEmail = () => {
    return (
      <TextField
        label="Default Email"
        size="small"
        variant={'outlined'}
        fullWidth
        onChange={e => setDefaultEmail(e.target.value)}
        //disabled={!emailReportOnEventClose}
        error={defaultEmail && !validateEmail(defaultEmail)}
        helperText={
          defaultEmail
            ? validateEmail(defaultEmail)
              ? ''
              : 'Please enter a valid email address'
            : ''
        }
        value={defaultEmail}
      />
    );
  };

  return (
    <Dialog onClose={close} title="Default Emails" maxWidth={'lg'} actions={renderDialogActions()}>
      <div className={classes.wrap}>
        <PerfectScrollbar>
          {renderActions()}
          {showEmailField && renderAddEmail()}
          {renderDefaultEmails()}
          {loading && (
            <div className={classes.loading}>
              <CircularProgress />
            </div>
          )}
        </PerfectScrollbar>
      </div>
    </Dialog>
  );
};

const mapStateToProps = state => {
  return {
    dictionary: state.dictionary,
  };
};

export default connect(mapStateToProps, {
  closeAddSecRole,
  handleError,
  notify,
  notifyDataUpdate,
  closeAddEditDefaultEmails,
})(AddSecRoleDialog);
