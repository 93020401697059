
    import React from 'react';
    import SvgIcon from '@material-ui/core/SvgIcon';
    
    function icons8robbery100Icon(props) {
      return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="100" height="100"><path d="M17.5 5A3.5 3.5 0 0 0 14 8.5V9c0 1.933 1.5 4 3.5 4S21 10.933 21 9v-.5A3.5 3.5 0 0 0 17.5 5zm27 6a3.5 3.5 0 0 0-3.5 3.5v.5c0 1.933 1.5 4 3.5 4s3.5-2.067 3.5-4v-.5a3.5 3.5 0 0 0-3.5-3.5zm-32.531 3.361c-.883 0-1.751.434-2.278 1.23-1.437 2.177-3.35 6.54-3.617 14.548a5.685 5.685 0 0 0 1.002 3.41l6.565 9.496 1.129 12.049c.049.514.482.906.998.906H20v-1.547a1 1 0 0 0-.473-.85l-1.011-.626.005-11.366c0-.589-.13-1.172-.38-1.705l-3.832-8.138c0-2.246.28-3.91.629-5.114l1.88 2.377c.485.612 1.35.77 2.02.367l4.7-2.82 1.583-.22a1.542 1.542 0 0 0 1.111-.739l.842-1.412.158-.924 1.204-.191.377-.399L31 22.67v-1.248h-.332L30.451 21h-6.369l-.937 1.148.642.873-.408.16-4.436 2.022-2.488-6.396c-.563-1.906-1.818-3.171-2.951-3.961a2.68 2.68 0 0 0-1.535-.485zm21.336.026c-.29.012-.647.082-.918.312-.621.53-.34 1.403-.34 1.403s-.204.958.117 1.373c.293.378 1.621 1.894 1.857 2.164l1.045 7.435a.817.817 0 0 0 .514.65l.572.221a.824.824 0 0 0 .51.03l2.203-.592L39.25 33H30a2 2 0 0 0-2 2v2h29v-4h-6.424l.211-6.01 3.166 1.961a.874.874 0 0 0 .848.041l.502-.25a.874.874 0 0 0 .463-.588l1.927-8.46s1.584-1.806 1.905-2.221c.32-.415.119-1.371.119-1.371s.285-.874-.336-1.403c-.542-.46-1.434-.28-1.434-.28l-1.365 1.655s-.016-.63-.752-.642c0 0-.361 1.07-.394 1.373l.08 1.191-1.955 5.863-2.4-1.361s-2.278-1.217-4.163-1.496c0 0-.714 4.287-1.937 8.133L45 22h-1.273l-1.075 6.117c-.626-3.303-.654-7.115-.654-7.115-2.468 0-4.717 2.266-4.717 2.266l-1.029-5.272.078-1.191c-.033-.303-.394-1.373-.394-1.373-.736.013-.752.642-.752.642l-1.366-1.654s-.223-.046-.513-.033zM6.875 36.67l.078 7.316-2.404 9.797a.998.998 0 0 0 .347 1.02L6.396 56H10v-1.348a1 1 0 0 0-.314-.728l-1.002-.942 2.937-8.412-.842-2.107-3.904-5.793zM31 39v17h26V40l-26-1z"/></SvgIcon>
      );
    }
  
    export default icons8robbery100Icon;