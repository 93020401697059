import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Content from 'Settings/Content';

import { getService } from 'reducers/service';
import { handleError } from 'reducers/ErrorReducer';
import { notify } from 'reducers/NotifierReducer';

import { gridStyle } from 'utils/formStyles';
import { XGrid } from '@material-ui/x-grid';
import { Fab } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { showNIBRSOffence } from 'reducers/DialogsReducer';
import QuickFilter from 'components/QuickFilter';
const useStyles = makeStyles(theme => ({
  item: {
    ...gridStyle(200, 800),
  },
  list: {
    width: '100%',
    height: 500,
  },
  wrap: {
    padding: theme.spacing(2),
    '& > h5': {
      marginBottom: 20,
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: `calc(100vh - 240px)`,
  },
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0, 2),
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
}));
const columnsItems = [
  { field: 'FBICode', headerName: 'FBICode', width: 150 },
  { field: 'FBIGroupCode', headerName: 'FBIGroupCode', width: 150 },
  { field: 'CrimeAgainst', headerName: 'CrimeAgainst', width: 150 },
  { field: 'Description', headerName: 'Description', width: 150 },
  { field: 'State', headerName: 'State', width: 150 },

  { field: 'ptsFBICodeID', headerName: 'ptsFBICodeID', width: 150 },
  { field: 'FBICodeID', headerName: 'FBICodeID', width: 150 },
  { field: 'CargoTheft', headerName: 'CargoTheft', width: 150 },
  { field: 'OffenderSuspectedOfUsing', headerName: 'OffenderSuspectedOfUsing', width: 150 },
  { field: 'BiasMotivation', headerName: 'BiasMotivation', width: 150 },
  { field: 'LocationType', headerName: 'LocationType', width: 150 },
  { field: 'MethodOfEntry', headerName: 'MethodOfEntry', width: 150 },
  { field: 'CriminalActivityOrGangInfo', headerName: 'CriminalActivityOrGangInfo', width: 150 },
  { field: 'TypeWeaponOrForceInvolved', headerName: 'TypeWeaponOrForceInvolved', width: 150 },
  { field: 'PropertyLossType', headerName: 'Property Required', width: 150 },
  { field: 'AggravatedAssaultOrHomicide', headerName: 'AggravatedAssaultOrHomicide', width: 150 },
  { field: 'JustifiableHomicide', headerName: 'JustifiableHomicide', width: 150 },
  { field: 'InjuryType', headerName: 'InjuryType', width: 150 },
  {
    field: 'AllowedCriminalActivityOrGangInfo',
    headerName: 'AllowedCriminalActivityOrGangInfo',
    width: 150,
  },
  { field: 'StructureEntered', headerName: 'StructureEntered', width: 150 },
  { field: 'DVReport', headerName: 'DVReport', width: 150 },
  { field: 'IsActive', headerName: 'IsActive', width: 150 },

  { field: 'Updated', headerName: 'Updated', width: 150 },
  { field: 'UpdatedBy', headerName: 'UpdatedBy', width: 150 },
];
function NIBRSOffenceCode(props) {
  const { wsClient, editNibrsOffence } = props;
  const classes = useStyles();
  const service = getService('ptsfbicode');
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    const search = async () => {
      service.timeout = 20000;
      try {
        const formDetails = await service.find({
          query: {
            IsDeleted: false,
            $limit: 0,
            $sort: {
              ptsFBICodeID: -1,
            },
          },
        });

        setRows(formDetails.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoaded(true);
      }
    };
    if (editNibrsOffence == null) {
      search();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsClient.websocket, editNibrsOffence]);

  const handleSelectChange = ({ selectionModel }) => {
    setSelection(selectionModel);
  };

  const onClickAdd = () => {
    dispatch(showNIBRSOffence({ type: 'add' }));
  };
  const onClickEdit = () => {
    const obj = rows.find(row => row.ptsFBICodeID == selection[0]);

    dispatch(showNIBRSOffence(obj));
  };
  const onClickDelete = async () => {
    const removeAgency = await service.remove(selection[0]);
    const newRows = rows.filter(el => el.ptsFBICodeID !== removeAgency.ptsFBICodeID);
    setRows(newRows);
    props.notify('Deleted', 'warning');
    setSelection([]);
  };
  const renderActions = () => {
    const disabled = selection.length === 0;
    // const selected = rows.find(a => a.id === selection[0]);
    //const dltDisabled = selected?.Icon === 0;
    return (
      <div className={classes.actions}>
        <div>
          <QuickFilter rows={rows} columns={columnsItems} setFilteredRows={setFilteredRows} />
        </div>
        <div>
          <Fab size="small" color="secondary" onClick={onClickAdd}>
            <AddIcon />
          </Fab>
          <Fab size="small" color="secondary" onClick={onClickEdit} disabled={disabled}>
            <EditIcon />
          </Fab>
          <Fab size="small" color="secondary" onClick={onClickDelete} disabled={disabled}>
            <DeleteIcon />
          </Fab>
        </div>
      </div>
    );
  };
  return (
    <Content>
      <div className="m-4">
        <h4>NIBRS Offense Codes</h4>
        {renderActions()}
        <div className={classes.gridWrap}>
          <XGrid
            columns={columnsItems}
            rows={filteredRows}
            loading={!loaded}
            rowHeight={38}
            getRowId={row => row.ptsFBICodeID}
            // checkboxSelection
            onSelectionModelChange={handleSelectChange}
          />
        </div>
      </div>
    </Content>
  );
}

const mapStateToProps = state => {
  return {
    wsClient: state.websocket,
    editNibrsOffence: state.dialogs.editNibrsOffence,
  };
};

export default connect(mapStateToProps, {
  handleError,
  notify,
})(NIBRSOffenceCode);
