import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import { XGrid } from '@material-ui/x-grid';
import DeleteIcon from '@material-ui/icons/Delete';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import { removeCapability, saveUnitCapability, getUnitsResources } from '../../reducers/EsnReducer';
import {
  saveUnitPerson,
  removePerson,
  saveUnitVehicle,
  getVehiclesResources,
  removeVehicle,
} from '../../reducers/EsnReducer';
import { handleError } from '../../reducers/ErrorReducer';
import { showSpinner, hideSpinner } from '../../reducers/UiReducer';
import { filterResults } from '../../utils/functions';
import { findPartyPerson } from '../../reducers/EventsReducer';

const useStyles = makeStyles(theme => ({
  wrap: {
    '& > h5': {
      marginBottom: 20,
    },
  },
  edit: {
    padding: theme.spacing(2),
    paddingBottom: 12,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  form: {
    margin: '0 -4px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexGrow: 1,
  },
  textField: {
    margin: '0 4px 12px',
    maxWidth: 400,
    flexBasis: 400,
    minWidth: 400,
    flexGrow: 1,
  },
  quantity: {
    margin: '0 4px 8px',
    maxWidth: 100,
    flexBasis: 100,
    minWidth: 100,
    flexGrow: 1,
  },
  autocomplete: {
    margin: '0 4px 8px',
    maxWidth: 400,
    flexBasis: 400,
    minWidth: 400,
    flexGrow: 1,
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      marginLeft: theme.spacing(1),
    },
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  search: {
    marginBottom: 10,
  },
  checkbox: {
    margin: '0 4px 4px',
  },
  activeWarrant: {
    background: theme.palette.error.main,
    color: theme.card.bg,
    width: 'calc(100% + 32px)',
    display: 'inline-block',
    padding: '8px 16px',
    margin: '-8px -16px',
  },
  addressWrap: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100%',
    marginBottom: theme.spacing(1),
  },
  address: {
    width: 400,
  },
  Number: {
    maxWidth: 100,
    flexBasis: 100,
    minWidth: 100,
    flexGrow: 1,
  },
  list: {
    width: '100%',
    boxSizing: 'border-box',
    height: `calc(100vh - 340px)`,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 -4px',
  },
  actions: {
    width: '100%',
    padding: theme.spacing(1, 0, 2),
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  actions2: {
    padding: theme.spacing(1, 0, 2),
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  data: {
    fontSize: '1.25em',
    fontWeight: 600,
    '& span': {
      fontWeight: 400,
    },
  },
}));

const formatCapabilities = params => {
  return params.value.reduce((result, value, idx) => {
    if (idx) result += ', ';
    result += `${value.CapabilityType} (${value.CapabilityTypeQuantity})`;
    return result;
  }, '');
};

const formatResources = params => {
  return params.value.reduce((result, value, idx) => {
    if (idx) result += ', ';
    result += `${value.Name}`;
    return result;
  }, '');
};

const columns = [
  { field: 'Unit', headerName: 'Unit', width: 120 },
  { field: 'Division', headerName: 'Division', width: 120 },
  { field: 'AgencyID', headerName: 'Agency ID', width: 120 },
  { field: 'Resources', headerName: 'Resources', width: 300, valueFormatter: formatResources },
  {
    field: 'Capabilities',
    headerName: 'Capabilities',
    width: 300,
    valueFormatter: formatCapabilities,
  },
];

const PersonColumns = [
  { field: 'id', headerName: 'Person ID', width: 250 },
  { field: 'FullName', headerName: 'Full Name', width: 300 },
];

const VehicleColumns = [
  { field: 'id', headerName: 'Vehicle ID', width: 250 },
  { field: 'Make', headerName: 'Vehicle Make', width: 300 },
];

function UnitManagement(props) {
  const classes = useStyles();
  const { dictionary } = props;
  const { CapabilityTypes } = dictionary;
  const [view, setView] = useState('list'); // edit, add-capability
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [capability, setCapability] = useState(null);
  const [capabilities, setCapabilities] = useState([]);
  const [filteredCapabilities, setFilteredCapabilities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selection, setSelection] = useState([]);
  const [editSelection, setEditSelection] = useState([]);
  const [quickFilter, setQuickFilter] = useState('');
  const [edited, setEdited] = useState(null);
  const [editedCapability, setEditedCapability] = useState(false);
  const id = selection.length ? parseInt(selection[0]) : null;
  const [selectedBeginningDate, setSelectedBeginningDate] = useState(new Date());
  const [selectedEndingDate, setSelectedEndingDate] = useState(null);
  const { assignment } = props;
  const [resources, setResources] = useState([]);
  const [searchMatch, setSearchMatch] = useState([]);
  const [personData, setPersonData] = useState(null);
  const throttleRef = useRef(0);
  const [searchPersonData, setSearchPersonData] = useState(null);
  const [editedVehicles, setEditedVehicles] = useState([]);
  const [allVehiclesData, setAllVehiclesData] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState(null);

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const filteredData = filterResults(quickFilter, data, columns);
    setFilteredData(filteredData);
    // eslint-disable-next-line
  }, [data, quickFilter]);

  useEffect(() => {
    if (!edited) return;
    const capabilities = edited.Capabilities.map(cap => {
      const capability = { ...cap };
      capability.id = cap.CapabilityType;
      return capability;
    });
    setCapabilities(capabilities);
    const filteredCapabilities = CapabilityTypes.filter(
      t => !capabilities.find(c => c.CapabilityType === t.Code) && t.Category === 'Unit'
    );
    setFilteredCapabilities(filteredCapabilities);
    // eslint-disable-next-line
  }, [edited]);

  useEffect(() => {
    if (!edited) return;
    const resources = edited.Resources.map(res => {
      const resource = { FullName: res.Name, id: res.ptsPersonID };
      return resource;
    });
    setResources(resources);
    // eslint-disable-next-line
  }, [edited]);

  useEffect(() => {
    if (!edited) return;
    const vehicles = edited.Vehicles.map(v => {
      const vehicle = {
        Make: v.Make,
      };
      vehicle.id = v.ptsVehicleID;
      return vehicle;
    });
    setEditedVehicles(vehicles);
    getVehicles();
    // eslint-disable-next-line
  }, [edited]);

  const getVehicles = async () => {
    const allVehicles = await getVehiclesResources();
    setAllVehiclesData(allVehicles);
  };

  const getData = async () => {
    props.showSpinner();
    try {
      const units = await getUnitsResources();
      const processedUnits = processUnits(units);
      setData(processedUnits);
      if (edited) {
        const newEdited = processedUnits.find(unit => unit.ptsUnitID === edited.ptsUnitID);
        setEdited(newEdited);
      }
    } catch (err) {
      props.handleError(err, 'Error loading units data');
    }
    props.hideSpinner();
  };

  const processUnits = units => {
    return units.map(u => {
      const unit = { ...u };
      unit.id = unit.ptsUnitID;
      return unit;
    });
  };

  const savePerson = async () => {
    const ComponentName = personData.FullName;
    const ptsChildID = personData.ptsPersonID;
    const { ptsUnitID } = edited;
    props.showSpinner();
    try {
      const result = await saveUnitPerson({
        ptsUnitID,
        ComponentName,
        ptsChildID,
        selectedBeginningDate,
        selectedEndingDate,
      });
      getData();
      setCapability(null);
      setView('edit');
    } catch (err) {
      props.handleError(err, 'Error saving capability');
    }
    props.hideSpinner();
  };

  const saveVehicle = async () => {
    const ComponentName = selectedVehicle.Make;
    const ptsChildID = selectedVehicle.ptsVehicleID;
    const { ptsUnitID } = edited;
    props.showSpinner();
    try {
      const result = await saveUnitVehicle({
        ptsUnitID,
        ComponentName,
        ptsChildID,
        selectedBeginningDate,
        selectedEndingDate,
      });

      getData();
      setCapability(null);
      setView('edit');
    } catch (err) {
      props.handleError(err, 'Error saving vehicle');
    }
    props.hideSpinner();
  };

  const renderQuickFilter = () => {
    return (
      <div className={classes.form}>
        <TextField
          label="Quick Filter"
          variant="outlined"
          value={quickFilter}
          onChange={ev => setQuickFilter(ev.target.value)}
          size="small"
          className={classes.textField}
          type="search"
        />
      </div>
    );
  };

  const handleSelectChange = ({ selectionModel }) => {
    setSelection(selectionModel);
  };

  const handleEditSelectChange = ({ selectionModel }) => {
    setEditSelection(selectionModel);
  };

  const delCapability = async () => {
    if (!window.confirm('Are you sure you want to remove this capability?')) return;
    const CapabilityType = editSelection[0];
    setLoading(true);
    try {
      await removeCapability(CapabilityType, 'UnitCapability', edited.id);
      getData();
      setEditSelection([]);
    } catch (err) {
      props.handleError(err, 'Error deleting capability');
    }
    setLoading(false);
  };
  const delPerson = async () => {
    if (!window.confirm('Are you sure you want to remove this person?')) return;
    const id = editSelection[0];
    setLoading(true);
    try {
      await removePerson(id, 'Person', edited.id);
      getData();
      setEditSelection([]);
    } catch (err) {
      props.handleError(err, 'Error deleting person');
    }
    setLoading(false);
  };

  const delVehicle = async () => {
    if (!window.confirm('Are you sure you want to remove this vehicle?')) return;
    const id = editSelection[0];
    setLoading(true);
    try {
      await removeVehicle(id, 'Vehicle', edited.id);
      getData();
      setEditSelection([]);
    } catch (err) {
      props.handleError(err, 'Error deleting vehicle');
    }
    setLoading(false);
  };

  const edit = () => {
    if (!id) return;
    const edited = data.find(d => d.id === id);
    setEdited(edited);
    setView('edit');
  };

  const renderCapabilityActions = () => {
    const disabled = selection.length === 0;
    return (
      <div className={classes.actions}>
        <Fab size="small" color="secondary" onClick={edit} disabled={disabled}>
          <EditIcon />
        </Fab>
      </div>
    );
  };

  const closeEdit = () => {
    setView('list');
    setSelection([]);
    setEditSelection([]);
  };

  const addCapability = () => {
    setCapability(null);
    setView('add');
    //setQuantity(1);
  };

  const renderActions = () => {
    const disabled = editSelection.length === 0;
    return (
      <div className={classes.actions2}>
        <Fab size="small" color="secondary" onClick={addCapability} disabled={!data}>
          <AddIcon />
        </Fab>
        <Fab
          size="small"
          color="secondary"
          onClick={assignment === 'Person' ? delPerson : delVehicle}
          disabled={disabled}>
          <DeleteIcon />
        </Fab>
        {view !== 'list' && (
          <Fab size="small" color="secondary" onClick={closeEdit}>
            <CloseIcon />
          </Fab>
        )}
      </div>
    );
  };

  const renderUnits = () => {
    return (
      <div className={classes.list}>
        <XGrid
          columns={columns}
          rows={filteredData}
          loading={loading}
          rowHeight={38}
          onSelectionModelChange={handleSelectChange}
          disableSelectionOnClick={view === 'add'}
          disableMultipleSelection
          hideFooter
        />
      </div>
    );
  };

  const renderHeader = () => {
    const { Unit } = edited;
    return (
      <div className={classes.header}>
        <TextField
          label="Unit"
          value={Unit}
          size="small"
          className={classes.textField}
          variant="outlined"
          disabled
        />
        {renderActions()}
      </div>
    );
  };

  const onCapabilitySelect = (ev, selection) => {
    setCapability(selection);
  };

  const handleBeginningDateChange = date => {
    setSelectedBeginningDate(date);
  };

  const handleEndingDateChange = date => {
    setSelectedEndingDate(date);
  };

  const onPersonSearchSelect = (ev, selection) => {
    if (selection) {
      setPersonData(selection);
      setSearchPersonData({ ...selection });
    } else {
      clearPersonData();
    }
  };

  const clearPersonData = () => {
    setPersonData(null);
    setSearchPersonData(null);
    setSelection([]);
    setCapabilities([]);
  };

  const searchPerson = text => {
    clearTimeout(throttleRef.current);
    throttleRef.current = setTimeout(() => {
      searchPersonData && clearPersonData();
      findPartyPerson(text)
        .then(result => setSearchMatch(result))
        .catch(err => console.log(err));
    }, 500);
  };

  const onVehicleSelect = (ev, selection) => {
    if (selection) {
      setSelectedVehicle(selection);
    } else {
      setSelectedVehicle(null);
      setSelection([]);
    }
  };

  const renderAddCapability = () => {
    const { Unit } = edited;
    // const disabled = !capability;
    const disabled =
      (assignment === 'Person' && !personData) || (assignment === 'Vehicle' && !selectedVehicle);
    return (
      <>
        <Grid container>
          <div className={classes.form}>
            <TextField
              label="Unit"
              value={Unit}
              size="small"
              className={classes.textField}
              variant="outlined"
              disabled
            />
          </div>
          <Grid item md={12}>
            <div className={classes.form}>
              {assignment === 'Person' && (
                <Autocomplete
                  fullWidth
                  disabled={editedCapability}
                  options={searchMatch}
                  value={personData}
                  getOptionLabel={option => option.FullName}
                  className={classes.autocomplete}
                  size="small"
                  onChange={onPersonSearchSelect}
                  onInputChange={(ev, val) => searchPerson(val)}
                  renderInput={params => (
                    <TextField {...params} label="Search Person" variant="outlined" />
                  )}
                  getOptionSelected={(option, value) => option.ptsPersonID === value.ptsPersonID}
                  renderOption={option => (
                    <span className={option.ActiveWarrant ? classes.activeWarrant : null}>
                      {option.LastName}, {option.FirstName} {option.PersonID}
                    </span>
                  )}
                />
              )}
              {assignment === 'Vehicle' && (
                <Autocomplete
                  className={classes.autocomplete}
                  options={allVehiclesData}
                  getOptionLabel={option => option.Make || '-'}
                  value={selectedVehicle}
                  onChange={onVehicleSelect}
                  fullWidth
                  size="small"
                  renderInput={params => (
                    <TextField {...params} label="Choose Vehicle" variant="outlined" />
                  )}
                />
              )}
            </div>
          </Grid>
          <div className={classes.form} style={{ marginLeft: 0, marginTop: -8 }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className="mr-2"
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                size="small"
                format="MM/dd/yyyy"
                margin="normal"
                label="Beginning"
                value={selectedBeginningDate}
                onChange={handleBeginningDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                format="MM/dd/yyyy"
                margin="normal"
                size="small"
                label="Ending"
                value={selectedEndingDate}
                onChange={handleEndingDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className={classes.buttons}>
            <Button
              variant="contained"
              color="primary"
              disabled={disabled}
              onClick={assignment === 'Person' ? savePerson : saveVehicle}>
              <SaveIcon />
              Save
            </Button>
            <Button
              color="primary"
              onClick={() => {
                setView('edit');
              }}>
              <CloseIcon />
              Close
            </Button>
          </div>
        </Grid>
      </>
    );
  };

  const renderCapabilities = () => {
    return (
      <div className={classes.list}>
        {assignment === 'Person' && (
          <XGrid
            columns={PersonColumns}
            rows={resources}
            loading={loading}
            rowHeight={38}
            onSelectionModelChange={handleEditSelectChange}
            disableSelectionOnClick={view === 'add'}
            disableMultipleSelection
            hideFooter
          />
        )}
        {assignment === 'Vehicle' && (
          <XGrid
            columns={VehicleColumns}
            rows={editedVehicles}
            loading={loading}
            rowHeight={38}
            onSelectionModelChange={handleEditSelectChange}
            disableSelectionOnClick={view === 'add'}
            disableMultipleSelection
            hideFooter
          />
        )}
      </div>
    );
  };

  return (
    <div className={classes.wrap}>
      {view === 'list' && renderQuickFilter()}
      {view === 'list' && renderCapabilityActions()}
      {view === 'list' && renderUnits()}
      {view === 'edit' && renderHeader()}
      {view === 'add' && renderAddCapability()}
      {(view === 'add' || view === 'edit') && renderCapabilities()}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    dictionary: state.dictionary,
  };
};

export default connect(mapStateToProps, {
  handleError,
  showSpinner,
  hideSpinner,
})(UnitManagement);
