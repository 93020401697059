import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { processInputPhoneNo } from '../utils/functions';

function PhoneTextField(props) {
  const { short } = props;
  const [PhoneNo, setPhoneNo] = useState(props.value);
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    props.isValid && props.isValid(validate(props.value));
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (props.mergedValue) {
      onPhoneNoChange(props.mergedValue);
    }
  }, [props.mergedValue]);

  const onPhoneNoChange = ev => {
    const val = ev.target ? ev.target.value : ev;
    const carrier = val.split('@')[1];
    const no = processInputPhoneNo(val);
    let formatedVal = no;
    if (no !== false) {
      const num = !short ? no : no.substr(0, 14);
      formatedVal = carrier ? num + '@' + carrier : num;
      setPhoneNo(formatedVal);
    }
    !touched && setTouched(true);
    props.isValid && props.isValid(validate(no));
    props.onChange && props.onChange(formatedVal);
  };

  const validate = no => {
    return no.split('@')[0].length > 13;
  };

  return (
    <TextField
      error={!validate(PhoneNo) && touched}
      value={PhoneNo}
      onChange={onPhoneNoChange}
      label={props.label}
      className={props.className}
      variant={props.variant}
      size={props.size || 'small'}
    />
  );
}

export default PhoneTextField;