import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { closeImportEsns } from '../../reducers/DialogsReducer';
import { handleError } from '../../reducers/ErrorReducer';
import { showSpinner, hideSpinner } from '../../reducers/UiReducer';
import { notify, closeSnackbar } from '../../reducers/NotifierReducer';
import CSVIcon from '@material-ui/icons/Description';
import Tooltip from '../../components/Tooltip';
import TextField from '@material-ui/core/TextField';
import { Alert, AlertTitle } from '@material-ui/lab';
import { asyncForEach } from '../../utils/functions';
import { zonesChanged } from '../../reducers/DataChangeReducer';
import { parseString } from 'xml2js';
import { getPathCenter, strToPath, pathToStr, offsetPoint } from 'utils/mapFunctions';
import { saveZone, validateGeofence, getZones } from 'reducers/ZonesReducer';

function getFormStyle(minWidth, maxWidth) {
  return {
    maxWidth: maxWidth,
    flexBasis: minWidth,
    minWidth: minWidth,
    flexGrow: 1,
    margin: `0 4px 12px`,
  };
}

const useStyles = makeStyles(theme => ({
  warning: {
    marginBottom: theme.spacing(1),
  },
  error: {
    marginBottom: theme.spacing(1),
  },
  form: {
    margin: `0 -${theme.spacing(0.5)}px`,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  agency: {
    ...getFormStyle(200, 300),
  },
  importBtn: {
    margin: `0 ${theme.spacing(0.5)}px`,
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  actions: {
    textAlign: 'right',
    marginBottom: 20,
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}));

function ImportKlmZones(props) {
  const classes = useStyles();
  const { dictionary } = props;
  const [lawAgencies, setLawAgencies] = useState([]);
  const [fireAgencies, setFireAgencies] = useState([]);
  const [emsAgencies, setEmsAgencies] = useState([]);
  const [rawData, setRawData] = useState([]);
  const [agencies, setAgencies] = useState(false);
  const [mode, setMode] = useState('import'); // import, edit
  const [errors, setErrors] = useState([]);
  const [warnings, setWarnings] = useState([]);

  const kmlFileRef = useRef(null);

  useEffect(() => {
    const { Agencies } = dictionary;
    if (!Agencies) return;
    setLawAgencies(
      Agencies.filter(agency => agency.AgencyType === 1).map(agency => agency.AgencyID)
    );
    setFireAgencies(
      Agencies.filter(agency => agency.AgencyType === 2).map(agency => agency.AgencyID)
    );
    setEmsAgencies(
      Agencies.filter(agency => agency.AgencyType === 4).map(agency => agency.AgencyID)
    );
    // eslint-disable-next-line
  }, []);

  const handleChangeKMLFile = ev => {
    const file = ev?.target?.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = ev => {
      parseKLMString(ev.target.result);
    };
    reader.onerror = () => {
      props.notify('Error, file not loaded', 'error');
    };
    reader.readAsText(file);
  };

  const removeEmptyPaths = paths =>
    paths.reduce((paths, path) => (getArea(path) > 0 ? [...paths, path] : paths), []);

  const removeLastPointFromPaths = paths => paths.forEach(p => p.pop());

  const getArea = polygon => {
    let total = 0;
    for (let i = 0; i < polygon.length; i++) {
      const addX = polygon[i][0];
      const addY = polygon[i === polygon.length - 1 ? 0 : i + 1][1];
      const subX = polygon[i === polygon.length - 1 ? 0 : i + 1][0];
      const subY = polygon[i][1];
      total += addX * addY * 0.5 - subX * subY * 0.5;
    }
    return Math.floor(Math.abs(total * 10000000));
  };

  const getDistance = (p1, p2) => Math.hypot(p2[0] - p1[0], p2[1] - p1[1]);

  const mergePolygons = async (polygon1, polygon2, pointIdx1, pointIdx2) => {
    const mergedPolygon = [];

    polygon1.forEach((point1, idx1) => {
      if (idx1 !== pointIdx1) {
        mergedPolygon.push(point1);
      } else {
        mergedPolygon.push(point1);
        for (let idx = pointIdx2; idx < polygon2.length; idx++) {
          mergedPolygon.push(polygon2[idx]);
        }
        for (let idx = 0; idx <= pointIdx2; idx++) {
          mergedPolygon.push(polygon2[idx]);
        }
        mergedPolygon.push(point1);
      }
    });
    // Apply microadjustments to connection points to make path valid
    let polyValid = false;
    for (let i = 0; i < 5; i++) {
      mergedPolygon[pointIdx1] = offsetPoint(polygon1[pointIdx1], i);
      for (let j = 1; j < 5; j++) {
        mergedPolygon[pointIdx1 + 1] = offsetPoint(polygon2[pointIdx2], j);
        polyValid = await arePolygonsValid([mergedPolygon]);
        if (polyValid) break;
      }
      if (polyValid) break;
    }
    return mergedPolygon;
  };

  const findClosesPoints = (path1, paths) => {
    let point1Idx = 0; // index of point in path1
    let point2Idx = 0; // index of point in path2
    let path2Idx = 0; //  index of path2 in paths array
    let distance = getDistance(path1[0], paths[0][0]);
    path1.forEach((point1, idx1) => {
      paths.forEach((path2, pathIdx2) => {
        path2.forEach((point2, idx2) => {
          const dist = getDistance(point1, point2);
          if (dist < distance) {
            distance = dist;
            point1Idx = idx1;
            path2Idx = pathIdx2;
            point2Idx = idx2;
          }
        });
      });
    });
    return { point1Idx, point2Idx, path2Idx, distance };
  };

  const arePolygonsValid = async paths => {
    let result = true;
    await asyncForEach(paths, async path => {
      const strPath = pathToStr(path);
      try {
        const isOk = await validateGeofence(strPath);
        if (!isOk) result = false;
      } catch (err) {
        props.handleError(err);
      }
    });
    return result;
  };

  const strToPath2 = pathStr => {
    const path = pathStr.split(',').map(c => c.split(' '));
    path.pop();
    return path;
  };

  const pathToStr2 = path => {
    const path2 = [...path];
    path2.push([...path[0]]);
    return path2.map(c => c.join(' ')).join(',');
  };

  const joinPolygons = async polygons => {
    const allPaths = polygons.map(strToPath2);
    const paths = removeEmptyPaths(allPaths).map(p => removeDuplicatePoints(p));
    const validPaths = await arePolygonsValid(paths);
    removeLastPointFromPaths(paths);
    let joinedPath = paths.shift();
    while (paths.length) {
      const { point1Idx, point2Idx, path2Idx } = findClosesPoints(joinedPath, paths);
      joinedPath = await mergePolygons(joinedPath, paths[path2Idx], point1Idx, point2Idx);
      paths.splice(path2Idx, 1);
    }
    return validPaths ? pathToStr2(joinedPath) : false;
  };

  const removeDuplicatePoints = path => {
    if (!path) return null;
    const newPath = [];
    path.forEach((point, idx) => {
      if (idx === 0) {
        newPath.push(point);
      } else {
        const prevPoint = path[idx - 1];
        const distance = getDistance(point, prevPoint);
        if (distance > 0.00000001) newPath.push(point);
      }
    });
    return newPath;
  };

  const parseKLMString = kml => {
    const parseInfo = data => {
      const info = {};
      data.forEach(i => {
        const name = i.$.name;
        const val = i._;
        info[name] = val;
      });
      return info;
    };

    const parsePolygon = data => {
      const polygon = data
        .trim()
        .split(' ')
        .map(c => {
          const arr = c.split(',').map(c => c.trim());
          return `${arr[0]} ${arr[1]}`;
        });
      const unique = [];
      polygon.forEach((el, idx) => {
        if (idx === 0 || el !== polygon[idx - 1]) unique.push(el);
      });
      const result = unique.join(',');
      return result;
    };

    const parseMultiGeometry = async el => {
      const polygons = el.MultiGeometry[0].Polygon.map(p =>
        parsePolygon(p.outerBoundaryIs[0].LinearRing[0].coordinates[0])
      );
      return await joinPolygons(polygons);
    };

    const getDataErrors = esns => {
      const errors = [];
      const mandatoryProps = ['EMS', 'ESN', 'Fire', 'Law', 'polygon'];
      const errMsg = prop => `${prop} property is missing`;
      esns.forEach(e => {
        const keys = Object.keys(e);
        mandatoryProps.forEach(key => {
          if (keys.indexOf(key) === -1 && errors.indexOf(errMsg(key)) === -1)
            errors.push(errMsg(key));
        });
      });
      return errors;
    };

    const prepSaveData = async esns => {
      // Merge multiple ESNs as single path
      const uniqueEsns = [];
      await asyncForEach(esns, async esn => {
        if (!uniqueEsns.find(e => e.ESN === esn.ESN)) {
          const duplEsns = esns.filter(e => e.ESN === esn.ESN);
          if (duplEsns.length === 1) {
            uniqueEsns.push(esn);
          } else {
            const polygon = await joinPolygons(duplEsns.map(e => e.polygon));
            if (polygon) {
              uniqueEsns.push({ ...esn, polygon });
            } else {
              props.notify(
                `Polygons for ${esn.ESN} were not merged propersly. Please check for possible intersections.`
              );
            }
          }
        }
      });
      // Format
      const saveData = uniqueEsns.map(esn => ({
        ESN: esn.ESN,
        ems: esn.EMS,
        fire: esn.Fire,
        law: esn.Law,
        SurfaceText: esn.polygon,
        Color: esn.PolyColor ? esn.PolyColor : 'Blue',
      }));
      return saveData;
    };

    const getAgencyDef = esns => {
      const recExists = (type, AgencyID) =>
        agencies.find(a => a.type === type && a.sourceAgencyId === AgencyID);
      const agencies = [];
      esns.forEach(esn => {
        const { ems, law, fire } = esn;
        if (!recExists('ems', ems)) {
          const exists = emsAgencies.indexOf(ems) !== -1;
          agencies.push({ type: 'ems', sourceAgencyId: ems, AgencyID: exists ? ems : null });
        }
        if (!recExists('fire', fire)) {
          const exists = fireAgencies.indexOf(fire) !== -1;
          agencies.push({ type: 'fire', sourceAgencyId: fire, AgencyID: exists ? fire : null });
        }
        if (!recExists('law', law)) {
          const exists = lawAgencies.indexOf(law) !== -1;
          agencies.push({ type: 'law', sourceAgencyId: law, AgencyID: exists ? law : null });
        }
      });
      return agencies;
    };

    parseString(kml, async (err, result) => {
      props.showSpinner();
      const esns = [];
      const getPolygon = async el => {
        if (el.Polygon)
          return parsePolygon(el.Polygon[0].outerBoundaryIs[0].LinearRing[0].coordinates[0]);
        if (el.MultiGeometry) return await parseMultiGeometry(el);
        return [];
      };
      try {
        await asyncForEach(result.kml.Document[0].Folder[0].Placemark, async el => {
          const entry = parseInfo(el.ExtendedData[0].SchemaData[0].SimpleData);
          entry.polygon = await getPolygon(el);
          if (entry.polygon) {
            esns.push(entry);
          } else {
            props.notify(
              `Polygons for ${entry.ESN} were not merged propersly. Please check for possible intersections.`
            );
          }
        });
      } catch (err) {
        props.notify('Error parsing KML data', 'error');
        return;
      }
      const dataErrors = getDataErrors(esns);
      setErrors(dataErrors);
      if (dataErrors.length) return;
      const saveData = await prepSaveData(esns);
      setRawData(saveData);
      setAgencies(getAgencyDef(saveData));
      setMode('edit');
      props.hideSpinner();
    });
  };

  const cleanLoadData = () => {
    setMode('import');
    setWarnings([]);
    setAgencies([]);
    setRawData([]);
  };

  const convertEsnsToZones = esns => {
    const zones = [];
    esns.forEach(esn => {
      const {
        ESN,
        Color,
        SurfaceText,
        ems,
        fire,
        law,
        lawAgencyID,
        emsAgenciID,
        fireAgencyID,
      } = esn;
      const path = strToPath(SurfaceText);
      const center = getPathCenter(path);
      const rec = {
        Color,
        AttachToEvent: true,
        Description: ESN,
        SurfaceText,
        Code: 'Zone',
        center,
        path,
      };
      zones.push({ ...rec, AgencyID: lawAgencyID, ZoneCode: law });
      zones.push({ ...rec, AgencyID: emsAgenciID, ZoneCode: ems });
      zones.push({ ...rec, AgencyID: fireAgencyID, ZoneCode: fire });
    });
    const filteredZones = zones.filter(zone => zone.AgencyID);
    return filteredZones;
  };

  const removeNameDuplicates = async zones => {
    const getNoStr = no => {
      const str = '00' + no;
      return '_' + str.substr(-2);
    };
    const getName = (name, no) => {
      const noStr = getNoStr(no);
      return name.substr(0, 17) + noStr;
    };
    const currentZones = (await getAllZones()).map(z => z.ZoneCode);
    zones.forEach(z => {
      const { ZoneCode } = z;
      let no = 1;
      if (currentZones.indexOf(ZoneCode) !== -1 || no > 99) {
        let newZoneCode = ZoneCode;
        while (currentZones.indexOf(newZoneCode) !== -1) {
          newZoneCode = getName(ZoneCode, no++);
        }
        z.ZoneCode = newZoneCode;
      }
      currentZones.push(z.ZoneCode);
    });
  };

  const getAllZones = async () => {
    let zoneCodes = [];
    try {
      zoneCodes = await getZones();
    } catch (err) {
      props.handleError(err);
    }
    return zoneCodes;
  };

  const importData = async esns => {
    props.showSpinner();
    const zones = convertEsnsToZones(esns);
    await removeNameDuplicates(zones);
    const errors = [];
    try {
      await asyncForEach(zones, async zone => {
        const result = await saveZone(zone);
        const isOk = result?.valid;
        if (!isOk) {
          errors.push(
            `Error, Invalid geofence ${zone.ZoneCode} from ${zone.AgencyID}. Check for possible polygon intersections.`
          );
        }
      });
      props.notify('Import successful', 'success');
    } catch (err) {
      props.handleError(err, 'Import file error.');
    }
    setErrors(errors);
    props.hideSpinner();
    props.zonesChanged();
    cleanLoadData();
  };

  const renderInputOptions = () => {
    return (
      <div className={classes.form}>
        <Tooltip title="Import KML ESNs">
          <div className={classes.importBtn}>
            <input
              accept=".kml"
              className={classes.input}
              id="import-esn-kml-file"
              multiple
              type="file"
              onChange={handleChangeKMLFile}
              ref={kmlFileRef}
            />
            <label htmlFor="import-esn-kml-file">
              <Button color="primary" component="span" variant="outlined">
                <CSVIcon />
                Import KML
              </Button>
            </label>
          </div>
        </Tooltip>
      </div>
    );
  };

  const renderEditAgencies = () => {
    const renderSettings = type => {
      let options = lawAgencies;
      switch (type) {
        case 'ems':
          options = emsAgencies;
          break;
        case 'fire':
          options = fireAgencies;
      }

      const onChange = (a, AgencyID) => {
        const newAgencies = [...agencies];
        const agency = agencies.find(
          b => b.sourceAgencyId === a.sourceAgencyId && b.type === a.type
        );
        agency.AgencyID = AgencyID;
        setAgencies(newAgencies);
      };

      return (
        <div className={classes.form}>
          {agencies
            .filter(a => a.type === type)
            .map(a => (
              <Autocomplete
                className={classes.agency}
                options={options}
                value={a.AgencyID}
                onChange={(ev, AgencyID) => onChange(a, AgencyID)}
                key={a.sourceAgencyId}
                renderInput={params => (
                  <TextField {...params} label={a.sourceAgencyId} variant="outlined" size="small" />
                )}
              />
            ))}
          <div className={classes.agency}></div>
          <div className={classes.agency}></div>
          <div className={classes.agency}></div>
        </div>
      );
    };

    const findAgencyId = (el, sourceAgencyId) =>
      agencies.find(a => a.sourceAgencyId === el[sourceAgencyId]).AgencyID;

    const importEdited = () => {
      const data = rawData.map(el => {
        const ems = findAgencyId(el, 'ems');
        const fire = findAgencyId(el, 'fire');
        const law = findAgencyId(el, 'law');
        el.ems = el.ems.substr(0, 20);
        el.fire = el.fire.substr(0, 20);
        el.law = el.law.substr(0, 20);
        el.emsAgenciID = ems;
        el.fireAgencyID = fire;
        el.lawAgencyID = law;
        return el;
      });
      importData(data);
    };

    // const importValid = () => agencies.reduce((res, val) => (val.AgencyID ? res : false), true);
    return (
      <>
        <h6>Law Enforcement Settings</h6>
        {renderSettings('law')}
        <h6>Fire Department Settings</h6>
        {renderSettings('fire')}
        <h6>EMS Settings</h6>
        {renderSettings('ems')}
        <div className={classes.actions}>
          <Button
            color="primary"
            variant="outlined"
            onClick={importEdited}
            // disabled={!importValid()}
          >
            Import
          </Button>
          <Button color="primary" variant="contained" onClick={cleanLoadData}>
            Cancel
          </Button>
        </div>
      </>
    );
  };

  return (
    <>
      {mode === 'import' && renderInputOptions()}
      {mode === 'edit' && renderEditAgencies()}
      {errors.length > 0 && (
        <Alert severity="error" className={classes.error}>
          <AlertTitle>Error processing the file</AlertTitle>
          {errors.map((err, idx) => (
            <p key={idx}>{err}</p>
          ))}
        </Alert>
      )}
      {warnings.length > 0 && (
        <Alert severity="warning" className={classes.warning}>
          <AlertTitle>Warning:</AlertTitle>
          {warnings.map((err, idx) => (
            <p key={idx}>{err}</p>
          ))}
        </Alert>
      )}
    </>
  );
}

const mapStateToProps = state => {
  return {
    dictionary: state.dictionary,
  };
};

export default connect(mapStateToProps, {
  closeImportEsns,
  notify,
  showSpinner,
  hideSpinner,
  handleError,
  closeSnackbar,
  zonesChanged,
})(ImportKlmZones);
