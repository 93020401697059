import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Content from 'Settings/Content';

import { getService } from 'reducers/service';
import { handleError } from 'reducers/ErrorReducer';
import { notify } from 'reducers/NotifierReducer';

import { gridStyle } from 'utils/formStyles';
import { XGrid } from '@material-ui/x-grid';
import { Fab } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { showCitationFile } from 'reducers/DialogsReducer';
import QuickFilter from 'components/QuickFilter';
const useStyles = makeStyles(theme => ({
  item: {
    ...gridStyle(200, 800),
  },
  list: {
    width: '100%',
    height: 500,
  },
  wrap: {
    padding: theme.spacing(2),
    '& > h5': {
      marginBottom: 20,
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: `calc(100vh - 240px)`,
  },
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0, 2),
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
}));
const columnsItems = [
  { field: 'CitationDefinitionID', headerName: 'ID', width: 150, type: 'string' },
  { field: 'ApplicationName', headerName: 'Application', width: 150, type: 'string' },
  { field: 'AgencyID', headerName: 'Agency ID', width: 150 },
  { field: 'FileName', headerName: 'FileName', width: 150 },
  { field: 'IsDefault', headerName: 'Default', width: 150, type: 'boolean' },
  { field: 'Description', headerName: 'Description', width: 150 },
  { field: 'NumberOfViolation', headerName: 'Number Of Violations', width: 150 },
  { field: 'IsWarning', headerName: 'Warning Allowed', width: 150, type: 'boolean' },
  { field: 'NumberOfWarning', headerName: 'Number Of Warnings', width: 150 },
  { field: 'FormType', headerName: 'Form Type', width: 150 },
  { field: 'Court_Name', headerName: 'Court Name', width: 150 },

  { field: 'County', headerName: 'County', width: 150 },
  { field: 'City', headerName: 'City', width: 150 },
  {
    field: 'JsonDefinition',
    headerName: 'Citation Definitaion',
    width: 150,
    type: 'boolean',
  },
];
function CitationFileInfo(props) {
  const { wsClient, addCitationFile } = props;
  const classes = useStyles();
  const service = getService('default-agency');
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [open, setOpen] = useState(false);

  const [mode, setMode] = useState('');
  const [editableRow, setEditableRow] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    const search = async () => {
      service.timeout = 20000;
      try {
        const formDetails = await service.find({ query: { agencyID: null } });
        if (formDetails) {
          setRows(formDetails);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoaded(true);
      }
    };
    if (addCitationFile == null && wsClient.websocket) {
      search();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsClient.websocket, addCitationFile]);

  const handleSelectChange = ({ selectionModel }) => {
    setSelection(selectionModel);
  };

  const onClickAdd = () => {
    dispatch(showCitationFile({ type: 'add' }));
    setMode('add');
    setOpen(true);
  };
  const onClickEdit = () => {
    const obj = rows.find(row => row.CitationDefinitionID == selection[0]);
    // console.log('obj', obj, selection);
    dispatch(showCitationFile({ type: 'edit', info: obj }));
    setMode('edit');
    setOpen(true);
  };
  const onClickDelete = async () => {
    const removeAgency = await service.remove(selection[0]);
    const newRows = rows.filter(
      el => el.CitationDefinitionID !== removeAgency.CitationDefinitionID
    );
    setRows(newRows);
    props.notify('Deleted', 'warning');
    setSelection([]);
  };
  const renderActions = () => {
    const disabled = selection.length === 0;
    const selected = rows.find(a => a.id === selection[0]);
    //const dltDisabled = selected?.Icon === 0;
    return (
      <div className={classes.actions}>
        <div>
          <QuickFilter rows={rows} columns={columnsItems} setFilteredRows={setFilteredRows} />
        </div>
        <div>
          <Fab size="small" color="secondary" onClick={onClickAdd}>
            <AddIcon />
          </Fab>
          <Fab size="small" color="secondary" onClick={onClickEdit} disabled={disabled}>
            <EditIcon />
          </Fab>
          <Fab size="small" color="secondary" onClick={onClickDelete} disabled={disabled}>
            <DeleteIcon />
          </Fab>
        </div>
      </div>
    );
  };
  return (
    <Content>
      <div className="m-4">
        <h4>Citation Files</h4>
        {renderActions()}
        <div className={classes.gridWrap}>
          <XGrid
            columns={columnsItems}
            rows={filteredRows}
            loading={!loaded}
            rowHeight={38}
            getRowId={row => row.CitationDefinitionID}
            // checkboxSelection
            onSelectionModelChange={handleSelectChange}
          />
        </div>
      </div>
    </Content>
  );
}

const mapStateToProps = state => {
  return {
    wsClient: state.websocket,
    addCitationFile: state.dialogs.addCitationFile,
  };
};

export default connect(mapStateToProps, {
  handleError,
  notify,
})(CitationFileInfo);
