
    import React from 'react';
    import SvgIcon from '@material-ui/core/SvgIcon';
    
    function icons8carservice100Icon(props) {
      return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="100" height="100"><path d="M8 3a6 6 0 0 0-6 6 5.993 5.993 0 0 0 3 5.191V24a3 3 0 1 0 6 0v-9.809a5.993 5.993 0 0 0 2.467-7.658L10 10H7V7l3.467-3.467A5.977 5.977 0 0 0 8 3zm7.732 8a8.032 8.032 0 0 1-.826 2h1.951c1.005 0 1.939.499 2.497 1.336l1.132 1.697c-.066-.01-.135-.024-.2-.033H13l.05 8h7.305c.567 1.175 1.762 2 3.145 2 1.542 0 2.847-1.025 3.309-2.42L28 23v-3.904c0-.938-.65-1.752-1.566-1.953-.864-.19-2.015-.424-3.239-.653l-2.175-3.263A4.99 4.99 0 0 0 16.86 11h-1.128zM23.5 21c.84 0 1.5.66 1.5 1.5s-.66 1.5-1.5 1.5-1.5-.66-1.5-1.5.66-1.5 1.5-1.5zM8 23a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/></SvgIcon>
      );
    }
  
    export default icons8carservice100Icon;