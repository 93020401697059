import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import { XGrid } from '@material-ui/x-grid';
import DeleteIcon from '@material-ui/icons/Delete';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import {
  removeCapability,
  saveVehicleCapability,
  getVehiclesResources,
  getVehiclesForItems,
  getItemsResources,
  saveVehicleItem,
  removeVehicleItem,
} from '../../reducers/EsnReducer';
import { handleError } from '../../reducers/ErrorReducer';
import { showSpinner, hideSpinner } from '../../reducers/UiReducer';
import { filterResults } from '../../utils/functions';

const useStyles = makeStyles(theme => ({
  wrap: {
    '& > h5': {
      marginBottom: 20,
    },
  },
  edit: {
    padding: theme.spacing(2),
    paddingBottom: 12,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  form: {
    margin: '0 -4px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexGrow: 1,
  },
  textField: {
    margin: '0 4px 12px',
    maxWidth: 400,
    flexBasis: 400,
    minWidth: 400,
    flexGrow: 1,
  },
  quantity: {
    margin: '0 4px 8px',
    maxWidth: 100,
    flexBasis: 100,
    minWidth: 100,
    flexGrow: 1,
  },
  autocomplete: {
    margin: '0 4px 8px',
    maxWidth: 400,
    flexBasis: 400,
    minWidth: 400,
    flexGrow: 1,
  },
  buttons: {
    // marginBottom: 8,
    display: 'flex',
    alignItems: 'center',
    '& button': {
      marginLeft: theme.spacing(1),
    },
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  search: {
    marginBottom: 10,
  },
  checkbox: {
    margin: '0 4px 4px',
  },
  activeWarrant: {
    background: theme.palette.error.main,
    color: theme.card.bg,
    width: 'calc(100% + 32px)',
    display: 'inline-block',
    padding: '8px 16px',
    margin: '-8px -16px',
  },
  addressWrap: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100%',
    marginBottom: theme.spacing(1),
  },
  address: {
    width: 400,
  },
  Number: {
    maxWidth: 100,
    flexBasis: 100,
    minWidth: 100,
    flexGrow: 1,
  },
  list: {
    width: '100%',
    boxSizing: 'border-box',
    height: `calc(100vh - 340px)`,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 -4px',
  },
  actions: {
    width: '100%',
    padding: theme.spacing(1, 0, 2),
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  actions2: {
    padding: theme.spacing(1, 0, 2),
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  data: {
    fontSize: '1.25em',
    fontWeight: 600,
    '& span': {
      fontWeight: 400,
    },
  },
}));

const formatCapabilities = params => {
  return params.value.reduce((result, value, idx) => {
    if (idx) result += ', ';
    result += `${value.CapabilityType} (${value.CapabilityTypeQuantity})`;
    return result;
  }, '');
};

const formatResources = params => {
  return params.value.reduce((result, value, idx) => {
    if (idx) result += ', ';
    result += `${value.ComponentName}`;
    return result;
  }, '');
};

const columns = [
  { field: 'Make', headerName: 'Make', width: 100 },
  { field: 'Model', headerName: 'Model', width: 100 },
  { field: 'PrimaryColor', headerName: 'Color', width: 100 },
  { field: 'VehicleID', headerName: 'Vehicle ID', width: 160 },
  { field: 'VIN', headerName: 'VIN', width: 160 },
  { field: 'Year', headerName: 'Year', width: 100 },
  { field: 'Resources', headerName: 'Items', width: 400, valueFormatter: formatResources },
  { field: 'Capabilities', headerName: 'Roles', width: 300, valueFormatter: formatCapabilities },
];

const CapabilityColumns = [
  { field: 'CapabilityType', headerName: 'Type', width: 150 },
  { field: 'ComponentName', headerName: 'Description', width: 300 },
  { field: 'CapabilityTypeQuantity', headerName: 'Quantity', width: 150 },
];

const ItemColumns = [
  { field: 'ptsChildID', headerName: 'Item ID', width: 150 },
  { field: 'ComponentName', headerName: 'Description', width: 300 },
];

function VehicleCapabilities(props) {
  const classes = useStyles();
  const { dictionary } = props;
  const { CapabilityTypes } = dictionary;
  const [view, setView] = useState('list'); // edit, add-capability
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [capability, setCapability] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [capabilities, setCapabilities] = useState([]);
  const [filteredCapabilities, setFilteredCapabilities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selection, setSelection] = useState([]);
  const [editSelection, setEditSelection] = useState([]);
  const [quickFilter, setQuickFilter] = useState('');
  const [edited, setEdited] = useState(null);
  const [editedCapability, setEditedCapability] = useState(false);
  const [selectedBeginningDate, setSelectedBeginningDate] = useState(new Date());
  const [selectedEndingDate, setSelectedEndingDate] = useState(null);
  const id = selection.length ? parseInt(selection[0]) : null;
  const [items, setItems] = useState([]);
  const [allItemsData, setAllItemsData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const filteredData = filterResults(quickFilter, data, columns);
    //console.log("filter data: ", filteredData);
    setFilteredData(filteredData);
    // eslint-disable-next-line
  }, [data, quickFilter]);

  useEffect(() => {
    if (!edited) return;
    //console.log("edited inside vehicle effect: ", edited)
    const capabilities = edited.Capabilities.map(cap => {
      const capability = { ...cap };
      capability.id = cap.CapabilityType;
      return capability;
    });
    setCapabilities(capabilities);
    const filteredCapabilities = CapabilityTypes.filter(
      t => !capabilities.find(c => c.CapabilityType === t.Code) && t.Category === 'Vehicle'
    );
    setFilteredCapabilities(filteredCapabilities);
    // eslint-disable-next-line
  }, [edited]);

  useEffect(() => {
    if (!edited) return;
    //console.log("edited inside vehicle effect: ", edited)
    const items = edited.Resources.map(i => {
      const item = { ...i };
      item.id = i.ptsChildID;
      return item;
    });
    setItems(items);
    getAllItemsData();
    // const filteredCapabilities = CapabilityTypes
    //   .filter(t => !capabilities.find(c => c.CapabilityType === t.Code) && t.Category === 'Vehicle');
    // setFilteredCapabilities(filteredCapabilities);
    // eslint-disable-next-line
  }, [edited]);

  const getAllItemsData = async () => {
    const items = await getItemsResources();
    //console.log(items)
    const processed = processItems(items);
    //console.log("all items data: ", processed)
    setAllItemsData(processed);
  };

  const processItems = Items => {
    return Items.map(u => {
      const Item = { ...u };
      Item.id = Item.ptsItemID;
      const { Resources } = Item;
      // const resources = Resources
      //   .filter(res => res.ChildType === 'Item')
      //   .reduce((result, value, idx) => {
      //     if (idx) result += ', ';
      //     result += value.ComponentName;
      //     return result;
      //   }, '');
      const capabilities = Resources.filter(res => res.ChildType === 'ItemCapability').map(cap => {
        const capability = { ...cap };
        capability.CapabilityTypeQuantity =
          cap.CapabilityTypeQuantity !== null ? cap.CapabilityTypeQuantity : 1;
        return capability;
      });
      // Item.Resources = resources;
      Item.Capabilities = capabilities;
      return Item;
    });
  };

  const getData = async () => {
    props.showSpinner();
    try {
      const data = await getVehiclesForItems();
      //console.log("data before process: ", data)
      const processed = process(data);
      //console.log("data after process: ", processed)
      setData(processed);
      if (edited) {
        const newEdited = processed.find(vehicle => vehicle.ptsVehicleID === edited.ptsVehicleID);
        setEdited(newEdited);
      }
    } catch (err) {
      props.handleError(err, 'Error loading vehicle data');
    }
    props.hideSpinner();
  };

  const process = vehicles => {
    return vehicles.map(u => {
      const vehicle = { ...u };
      vehicle.id = vehicle.ptsVehicleID;
      const { Resources } = vehicle;
      const resources = Resources.filter(res => res.ChildType === 'Item');
      //   .reduce((result, value, idx) => {
      //     if (idx) result += ', ';
      //     result += value.ComponentName;
      //     return result;
      //   }, '');
      const capabilities = Resources.filter(res => res.ChildType === 'VehicleCapability').map(
        cap => {
          const capability = { ...cap };
          capability.CapabilityTypeQuantity =
            cap.CapabilityTypeQuantity !== null ? cap.CapabilityTypeQuantity : 1;
          return capability;
        }
      );
      vehicle.Resources = resources;
      vehicle.Capabilities = capabilities;
      return vehicle;
    });
  };

  const save = async () => {
    // console.log("selected item inside save: ", selectedItem,
    // "ptsVEhicleID: ", edited.ptsVehicleID)
    const ptsChildID = selectedItem.ptsItemID;
    const ComponentName = selectedItem.Name;
    const { ptsVehicleID } = edited;
    props.showSpinner();
    try {
      await saveVehicleItem({
        ptsVehicleID,
        ptsChildID,
        ComponentName,
        selectedBeginningDate,
        selectedEndingDate,
      });
      getData();
      setCapability(null);
      setEditedCapability(false);
      setView('edit');
    } catch (err) {
      props.handleError(err, 'Error saving item');
    }
    props.hideSpinner();
  };

  const renderQuickFilter = () => {
    return (
      <div className={classes.form}>
        <TextField
          label="Quick Filter"
          variant="outlined"
          value={quickFilter}
          onChange={ev => setQuickFilter(ev.target.value)}
          size="small"
          className={classes.textField}
          type="search"
        />
      </div>
    );
  };

  const handleSelectChange = ({ selectionModel }) => {
    setSelection(selectionModel);
  };

  const handleEditSelectChange = ({ selectionModel }) => {
    setEditSelection(selectionModel);
  };

  const delItem = async () => {
    if (!window.confirm('Are you sure you want to remove this item?')) return;
    const ptsChildID = editSelection[0];
    //console.log(ptsChildID, edited.id);
    setLoading(true);
    try {
      removeVehicleItem(ptsChildID, 'Item', edited.id, 'Vehicle');
      getData();
      setEditSelection([]);
    } catch (err) {
      props.handleError(err, 'Error deleting item');
    }
    setLoading(false);
  };

  const edit = () => {
    if (!id) return;
    const edited = data.find(d => d.id === id);
    setEdited(edited);
    setView('edit');
  };

  const renderCapabilityActions = () => {
    const disabled = selection.length === 0;
    return (
      <div className={classes.actions}>
        <Fab size="small" color="secondary" onClick={edit} disabled={disabled}>
          <EditIcon />
        </Fab>
      </div>
    );
  };

  const closeEdit = () => {
    setView('list');
    setSelection([]);
    setEditSelection([]);
  };

  const closeEdited = () => {
    setView('edit');
    setEditedCapability(false);
  };

  const addCapability = () => {
    setCapability(null);
    setView('add');
    setQuantity(1);
  };

  const editCapability = () => {
    const capability = CapabilityTypes.find(c => c.Code === editSelection[0]);
    const editedCapability = edited.Capabilities.find(c => c.CapabilityType === editSelection[0]);
    const quantity = editedCapability.CapabilityTypeQuantity;
    setCapability(capability);
    setView('add');
    setQuantity(quantity);
    setEditedCapability(true);
    const newCapabilities = [...filteredCapabilities];
    newCapabilities.push(capability);
    setFilteredCapabilities(newCapabilities);
  };

  const renderActions = () => {
    const disabled = editSelection.length === 0;
    return (
      <div className={classes.actions2}>
        <Fab size="small" color="secondary" onClick={addCapability} disabled={!data}>
          <AddIcon />
        </Fab>
        {/* <Fab size="small" color="secondary" onClick={editCapability} disabled={disabled}>
          <EditIcon />
        </Fab> */}
        <Fab size="small" color="secondary" onClick={delItem} disabled={disabled}>
          <DeleteIcon />
        </Fab>
        {view !== 'list' && (
          <Fab size="small" color="secondary" onClick={closeEdit}>
            <CloseIcon />
          </Fab>
        )}
      </div>
    );
  };

  const renderUnits = () => {
    return (
      <div className={classes.list}>
        <XGrid
          columns={columns}
          rows={filteredData}
          loading={loading}
          rowHeight={38}
          onSelectionModelChange={handleSelectChange}
          disableSelectionOnClick={view === 'add'}
          disableMultipleSelection
          hideFooter
        />
      </div>
    );
  };

  const renderHeader = () => {
    const { Make, Model, Year, VIN } = edited;
    const label = `${Make} ${Model} ${Year} VIN: ${VIN}`;
    return (
      <div className={classes.header}>
        <TextField
          label="Vehicle"
          value={label}
          size="small"
          className={classes.textField}
          variant="outlined"
          disabled
        />
        {renderActions()}
      </div>
    );
  };

  const onCapabilitySelect = (ev, selection) => {
    setCapability(selection);
  };

  const handleBeginningDateChange = date => {
    setSelectedBeginningDate(date);
  };

  const handleEndingDateChange = date => {
    setSelectedEndingDate(date);
  };

  const onItemSelect = (ev, selection) => {
    if (selection) {
      setSelectedItem(selection);
    } else {
      setSelectedItem(null);
      setSelection([]);
    }
  };

  const renderAddCapability = () => {
    const { Make, Model, Year, VIN } = edited;
    const label = `${Make} ${Model} ${Year} VIN: ${VIN}`;
    const disabled = !selectedItem;
    return (
      <>
        <Grid container>
          <Grid item>
            <div className={classes.form}>
              <TextField
                label="Vehicle"
                value={label}
                size="small"
                className={classes.textField}
                variant="outlined"
                disabled
              />
            </div>
          </Grid>
          <Grid item md={12}>
            <div className={classes.form}>
              <Autocomplete
                className={classes.autocomplete}
                options={allItemsData}
                getOptionLabel={option => option.Name || 'undefined'}
                value={selectedItem}
                onChange={onItemSelect}
                fullWidth
                size="small"
                renderInput={params => (
                  <TextField {...params} label="Choose Item" variant="outlined" />
                )}
              />
            </div>
          </Grid>
          <div className={classes.form} style={{ marginLeft: 0, marginTop: -8 }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className="mr-2"
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                size="small"
                format="MM/dd/yyyy"
                margin="normal"
                label="Beginning"
                value={selectedBeginningDate}
                onChange={handleBeginningDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                format="MM/dd/yyyy"
                margin="normal"
                size="small"
                label="Ending"
                value={selectedEndingDate}
                onChange={handleEndingDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className={classes.buttons}>
            <Button variant="contained" color="primary" disabled={disabled} onClick={save}>
              <SaveIcon />
              Save
            </Button>
            <Button color="primary" onClick={closeEdited}>
              <CloseIcon />
              Close
            </Button>
          </div>
        </Grid>
      </>
    );
  };

  const renderCapabilities = () => {
    return (
      <div className={classes.list}>
        <XGrid
          columns={ItemColumns}
          rows={items}
          loading={loading}
          rowHeight={38}
          onSelectionModelChange={handleEditSelectChange}
          disableSelectionOnClick={view === 'add'}
          disableMultipleSelection
          hideFooter
        />
      </div>
    );
  };

  return (
    <div className={classes.wrap}>
      {view === 'list' && renderQuickFilter()}
      {view === 'list' && renderCapabilityActions()}
      {view === 'list' && renderUnits()}
      {view === 'edit' && renderHeader()}
      {view === 'add' && renderAddCapability()}
      {(view === 'add' || view === 'edit') && renderCapabilities()}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    dictionary: state.dictionary,
  };
};

export default connect(mapStateToProps, {
  handleError,
  showSpinner,
  hideSpinner,
})(VehicleCapabilities);
