
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

function Markdown(props) {
  const { code, className } = props;
  return (
    <ReactMarkdown
      className={className}
      children={code}
      components={{
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || '')
          return !inline && match ? (
            <SyntaxHighlighter
              children={String(children).replace(/\n$/, '')}
              style={a11yDark}
              language={match[1]}
              PreTag="div"
              {...props}
            />
          ) : (
            <div {...props} className="mono-space">
              {children}
            </div>
          )
        }
      }}
    />
  );
}

export default Markdown;