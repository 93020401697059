import React from 'react';

function CrimeIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      {...props}
      preserveAspectRatio="xMidYMid meet">
      <g
        transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none">
        <path d="M77 403 c-16 -8 -35 -30 -43 -48 -7 -18 -19 -35 -26 -37 -17 -6 8 -38 28 -38 19 0 17 -26 -6 -88 -24 -63 -25 -100 -4 -130 12 -18 25 -22 69 -22 75 0 86 14 97 120 3 21 9 26 47 31 47 6 71 28 71 66 0 22 4 23 69 23 60 0 71 3 76 19 3 11 12 22 20 25 9 3 15 19 15 41 0 41 20 37 -201 35 -103 -1 -159 2 -164 9 -8 13 -12 13 -48 -6z m213 -139 c0 -34 -20 -54 -55 -54 -28 0 -35 4 -35 19 0 28 27 51 61 51 20 0 29 -5 29 -16z" />
        <path d="M220 255 c-20 -24 4 -45 26 -24 13 14 13 17 1 27 -11 10 -17 9 -27 -3z" />
      </g>
    </svg>
  );
}

export default CrimeIcon;
