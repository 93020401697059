
    import React from 'react';
    import SvgIcon from '@material-ui/core/SvgIcon';
    
    function icons8findusermale100Icon(props) {
      return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100" height="100"><path d="M19.875.406c-.46.008-.883.02-1.313.063L18.25.5c-.32.04-.637.098-.938.156-.203.04-.398.078-.593.125-.168.04-.34.078-.5.125-1.86.531-3.313 1.485-4.344 2.844-1.934 2.547-2.285 6.395-1.063 11.469-.449.55-.789 1.386-.656 2.5.266 2.203 1.121 3.11 1.844 3.468.344 1.731 1.297 3.676 2.219 4.594v.469c.008 1.016.004 1.895-.094 3.031-.613 1.407-2.648 2.23-5 3.157-1.934.761-4.07 1.628-5.813 3.03-.019.016-.042.016-.062.032C1.5 36.93.184 38.906 0 41.938L-.063 43h28.938A9.964 9.964 0 0 0 36 46c2.395 0 4.59-.84 6.313-2.25l5.968 5.969 1.438-1.438-5.969-5.968A9.922 9.922 0 0 0 46 36c0-5.516-4.484-10-10-10-3.625 0-6.777 1.95-8.531 4.844-.426-.262-.809-.532-1.094-.844a2.565 2.565 0 0 1-.406-.563c.02-.03.043-.062.062-.093-.011-.02-.05-.04-.062-.063-.098-1.133-.07-1.988-.063-3v-.5c.899-.918 1.82-2.86 2.157-4.593.074-.04.144-.106.218-.157.094-.062.188-.133.282-.218.562-.504 1.144-1.426 1.343-3.094.133-1.09-.187-1.918-.625-2.469.578-1.98 1.77-7.105-.281-10.406-.762-1.227-1.875-2.063-3.313-2.469-.183-.055-.37-.086-.562-.125-.48-.61-1.195-1.063-2.094-1.375-.199-.07-.406-.164-.625-.219-.02-.004-.043.004-.062 0C22.12.602 21.898.54 21.656.5c-.02-.004-.043.004-.062 0a11.72 11.72 0 0 0-1.657-.094h-.062ZM36 28c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8Z"/></SvgIcon>
      );
    }
  
    export default icons8findusermale100Icon;