import React, { useState, useRef, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

import { handleError } from '../../reducers/ErrorReducer';
import { showSpinner, hideSpinner } from '../../reducers/UiReducer';
import { notify } from '../../reducers/NotifierReducer';
import { closeCitationFile, closeMergeCities } from '../../reducers/DialogsReducer';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  Grid,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { DialogContent } from '@mui/material';
import { getService } from 'reducers/service';
import { getCitationCourt } from 'reducers/CitationReducer';
import { makeAddress } from 'utils/functions';
import SelectTargetDialog from './SelectTarget';
import { codeCitiesColumns } from 'Settings/LookupCodes/CodeTableColumns';
import ConfigureMerge from './ConfigureMerge';
import Review from './Review';
// import Review from './Review';

const useStyles = makeStyles(theme => ({
  actions: {
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  textField: {
    width: 600,
    maxWidth: '100%',
    marginTop: theme.spacing(1),
  },
  dialog: {
    '& $content': {
      margin: 0,
      padding: 0,
      background: 'red !important',
    },
  },
  wrap: {
    width: '100%',
    maxHeight: '450px',
  },
  steps: {
    width: '100%',
    marginBottom: 16,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  nav: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
}));

function getSteps() {
  return ['Select target address', 'Configure merge', 'Confirmation'];
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return 'Please select the target address for merging. Source address will be deleted and the target will be updated.';
    case 1:
      return 'Select fields you want to use to in the target address';
    case 2:
      return 'Please confirm that all data is correct. Use the back button to return to merge options.';
    default:
      return 'Unknown stepIndex';
  }
}
function MergeCities(props) {
  const classes = useStyles();
  const steps = getSteps();
  const [activeStep, setActiveStep] = useState(0);

  const { data: sourceData, rows } = props;

  const service = getService('default-agency');
  const [loading, setLoading] = useState(false);
  const [targetID, setTargetID] = useState(null);

  const [source, setSource] = useState(sourceData);
  const [target, setTarget] = useState(null);
  const [data, setData] = useState([]);
  const [cityDetails, setCityDetails] = useState(null);

  const [deleteSource, setDeleteSource] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (targetID) {
      const targetData = rows.filter(row => row.ptsCityID == targetID);
      setTarget(targetData[0]);
    } else {
      setTarget(null);
    }
  }, [targetID]);

  const isNextActive = () => {
    if (loading) return false;
    switch (activeStep) {
      case 0:
        return targetID !== null;
      default:
        return true;
    }
  };
  const close = () => {
    dispatch(closeMergeCities());
  };

  const save = async () => {};
  const handleFormChange = (name, data, prepared = false) => {};
  const handleCourtSelection = court => {};
  const renderActions = () => {
    return (
      <div>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </div>
    );
  };
  //merge functions
  const merge = async () => {
    if (!window.confirm(`Are you sure you want to merge?`)) return;
    try {
      const service = getService('city-merge');
      props.showSpinner();
      const data = await service.patch(source.ptsCityID, {
        cityDetails: cityDetails,
        sourceID: source.ptsCityID,
        targetID: target.ptsCityID,
        chooseToBeDelete: deleteSource,
      });

      if (data && data.success == false) {
        throw Error(data.error);
      }
      props.notify('Merging Completed', 'success');
    } catch (error) {
      console.log('error', error);
      props.handleError(error, 'Error, Action Failed', error);
      handleBack();
    } finally {
      props.hideSpinner();
    }
  };
  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    if (activeStep === 2) merge();
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };
  const renderSteps = () => {
    return (
      <div className={classes.steps}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>
                All steps completed - City merged
              </Typography>
            </div>
          ) : (
            <div className={classes.nav}>
              <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
              <div>
                <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}
                  disabled={!isNextActive()}>
                  {activeStep === steps.length - 1 ? 'Merge Cities' : 'Next'}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <Dialog
      onClose={close}
      title={'Merge Cities'}
      open={true}
      actions={renderActions()}
      //   className={classes.dialog}

      // toolbar={false}
      // draggable={false}
      fullWidth
      maxWidth="xl">
      <DialogTitle>Merge Cities</DialogTitle>
      <DialogContent style={{ minHeight: '500px' }}>
        <div className={classes.wrap}>
          {renderSteps()}
          {activeStep === 0 && (
            <SelectTargetDialog
              rows={rows}
              sourceID={sourceData.ptsCityID}
              columns={codeCitiesColumns}
              setTargetID={setTargetID}
              targetID={targetID}
            />
          )}

          {activeStep === 1 && (
            <ConfigureMerge
              rows={rows}
              sourceID={sourceData.ptsCityID}
              targetID={targetID}
              source={source}
              setSource={setSource}
              target={target}
              setTarget={setTarget}
              data={data}
              setData={setData}
              loading={loading}
              setLoading={setLoading}
            />
          )}

          {activeStep === 2 && (
            <Review
              source={source}
              target={target}
              data={data}
              loading={loading}
              setLoading={setLoading}
              cityDetails={cityDetails}
              setCityDetails={setCityDetails}
              deleteSource={deleteSource}
              setDeleteSource={setDeleteSource}
            />
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <div className=" mb-1 mr-3">{renderActions()}</div>{' '}
      </DialogActions>
    </Dialog>
  );
}
const mapStateToProps = state => {
  return {
    dictionary: state.dictionary,

    wsClient: state.websocket,
    user: state.user,
  };
};
export default connect(mapStateToProps, {
  showSpinner,
  hideSpinner,
  handleError,
  notify,
})(MergeCities);
