import React, { createContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '../../components/Dialog';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { getFormStyle } from '../../utils/functions';
import CloseIcon from '@material-ui/icons/Close';
import FilterGroup from './FilterGroup';
import AddIcon from '@material-ui/icons/Add';
// import Card from '@material-ui/core/Card';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import SOPActions from './SOPActions';
import { saveSop } from '../../reducers/SopsReducer';
import { showSpinner, hideSpinner } from '../../reducers/UiReducer';
import { handleError } from '../../reducers/ErrorReducer';
import { getSops, getSop, decodeDispatcherQuestions } from '../../reducers/SopsReducer';
import { closeEditSop } from '../../reducers/DialogsReducer';
import { notify } from '../../reducers/NotifierReducer';
import { Badge, Box, Tab, Tabs, Typography } from '@material-ui/core';
import AddressTab from './AddressTab';
import PersonTab from './PersonTab';
import PlaceTab from './PlaceTab';
import ZoneTab from './ZoneTab';
import CadTypesTab from './CadTypesTab';
import SOPsLookup from 'components/SOPsLookup';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  form: {
    margin: '0 -4px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexGrow: 1,
  },
  dialogContent: {
    width: '100%',
    height: '450px',
    minWidth: '600px',
  },
  textField: {
    margin: `0 ${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
  },
  w100x200: {
    ...getFormStyle(100, 200),
  },
  w170x400: {
    ...getFormStyle(170, 400),
  },
  w200x300: {
    ...getFormStyle(200, 300),
  },
  w120x180: {
    ...getFormStyle(120, 180),
  },
  w100pr: {
    width: '100%',
    margin: `0 4px 8px`,
  },
  trigger: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    margin: '1em 0',
  },
}));
export const ReloadContext = createContext();
function AddSOP(props) {
  const classes = useStyles();
  const { dictionary, ptsSOPID, ptsParentID } = props;
  const [title, setTitle] = useState('');
  const [groups, setGroups] = useState([]);
  const [valid, setValid] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [dispatcherMessage, setDispatcherMessage] = useState('');
  const [actions, setActions] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [addressList, setAddressList] = useState();
  const [peopleList, setPeopleList] = useState();
  const [placeList, setPlaceList] = useState();
  const [zoneList, setZoneList] = useState();
  const [eventList, setEventList] = useState();
  const [reload, setReload] = useState(false);
  const [searchedSopID, setSearchedSopID] = useState(null);

  useEffect(() => {
    if (ptsSOPID !== true || reload) {
      loadSop(ptsSOPID);
    }
  }, [reload]);

  useEffect(() => {
    validate();
    // eslint-disable-next-line
  }, [groups, title, showNotification, dispatcherMessage, actions]);

  const loadSop = ptsSOPID => {
    props.showSpinner();
    getSop(ptsSOPID)
      .then(parseData)
      .catch(err => props.handleError(err, 'Error, SOP not loaded.'))
      .finally(() => props.hideSpinner());
  };

  const parseData = rawData => {
    if (!rawData.Source) {
      props.notify(
        'Cannot edit this SOP as it was created with previous version of CAD.',
        'warning'
      );
      return props.closeEditSop();
    }
    const source = JSON.parse(rawData.Source);
    setTitle(rawData.SOPID);
    setGroups(source);
    setDispatcherMessage(rawData.DispatcherMessage);
    setShowNotification(rawData.ShowToast);
    setActions(decodeDispatcherQuestions(rawData.DispatcherQuestions));

    setPeopleList(parseList(rawData.personList));

    setAddressList(parseList(rawData.addressList));

    setPlaceList(parseList(rawData.placeList));
    setZoneList(parseList(rawData.zonesList));
    setEventList(parseList(rawData.eventList));
  };
  const parseList = data => {
    return JSON.parse(data || '[]');
  };

  const close = () => {
    props.closeEditSop();
  };

  const updateGroup = no => group => {
    const newGroups = [...groups];
    newGroups[no] = group;
    setGroups(newGroups);
  };

  const addGroup = () => {
    const newGroups = [...groups];
    const operator = groups.length > 0 ? groups[0].operator : 'And';
    newGroups.push({ operator, filters: [], logicOperator: 'And' });
    setGroups(newGroups);
  };

  const removeGroup = no => () => {
    const newGroups = [...groups];
    newGroups.splice(no, 1);
    setGroups(newGroups);
  };

  const updateGroupOperator = operator => {
    const newGroups = groups.map(data => {
      return { ...data, operator };
    });
    setGroups(newGroups);
  };

  const save = () => {
    const data = {
      ptsSOPID,
      SOPID: title,
      TriggerOptions: groups,
      ShowToast: showNotification,
      DispatcherMessage: dispatcherMessage,
      DispatcherQuestions: actions,
    };
    props.showSpinner();
    saveSop(data, ptsParentID, searchedSopID)
      .then(() => close())
      .catch(err => props.handleError(err, 'Error, SOP not saved.'))
      .finally(() => props.hideSpinner());
  };

  const validate = () => {
    let valid = true;
    if (!title) valid = false;
    groups.forEach(group => {
      if (group.filters.length === 0) valid = false;
      group.filters.forEach(filter => {
        const val = filter.actionValue;
        if (!val || val.length === 0) valid = false;
        if (Array.isArray(val)) {
          val.forEach(v => {
            if (v === '') valid = false;
          });
        }
      });
    });
    if (showNotification && !dispatcherMessage) valid = false;
    actions.forEach(action => {
      if (!action.text) valid = false;
    });
    setValid(valid);
  };

  const renderActions = () => {
    return (
      <>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={save}
          disabled={!valid && !searchedSopID}>
          <SaveIcon /> Save
        </Button>
        <Button onClick={close} color="primary" size="small">
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  const handleTitleChange = ev => {
    const targetValue = ev.target.value;
    if (targetValue.length > 25) return;
    setTitle(targetValue);
  };

  const renderTitleForm = () => {
    return (
      <div className={classes.form}>
        <TextField
          className={classes.w100pr}
          label="Title"
          value={title || ''}
          onChange={handleTitleChange}
          variant="outlined"
          size="small"
        />
      </div>
    );
  };

  const renderTriggerOptions = () => {
    return (
      <>
        <h5 className={classes.title}>Trigger options</h5>
        <div className={classes.triggers}>
          {groups.map((group, no) => (
            <FilterGroup
              key={no}
              no={no}
              data={group}
              removeGroup={removeGroup(no)}
              updateGroup={updateGroup(no)}
              dictionary={dictionary}
              updateGroupOperator={updateGroupOperator}
            />
          ))}
        </div>
        <Button onClick={addGroup}>
          <AddIcon /> Add Filter Group
        </Button>
        {/* <Card style={{padding: 16}} variant="outlined">
          <p>Data</p>
          <pre style={{fontFamily: 'monospace'}}>{JSON.stringify(groups, null, 2)}</pre>
        </Card> */}
      </>
    );
  };
  const renderSopLookup = () => {
    const onChange = data => {
      if (!data || !data.ptsSOPID) {
        setSearchedSopID(null);
        return;
      }

      setSearchedSopID(data.ptsSOPID);
    };
    return <SOPsLookup className={classes.item} onChange={onChange} />;
  };
  const renderNotificationOptions = () => {
    return (
      <>
        <h5 className={classes.title}>Notification Text</h5>
        <FormControlLabel
          size="small"
          control={
            <Checkbox
              checked={showNotification}
              onChange={ev => setShowNotification(ev.target.checked)}
              color="primary"
            />
          }
          label="Shop Popup Notification"
        />
        <div className={classes.form}>
          <TextField
            label="Dispatcher Message"
            variant="outlined"
            value={dispatcherMessage || ''}
            onChange={ev => setDispatcherMessage(ev.target.value)}
            error={showNotification && !dispatcherMessage}
            disabled={!showNotification}
            className={classes.w100pr}
            size="small"
          />
        </div>
      </>
    );
  };
  const renderLabel = (label, count = 0) => {
    return (
      <Badge badgeContent={count} color="primary">
        <span className="pr-1">{label}</span>
      </Badge>
    );
  };

  const generalTabContetn = () => {
    return (
      <div className={classes.dialogContent}>
        {ptsParentID && (
          <>
            {renderSopLookup()}
            <hr />
          </>
        )}

        {renderTitleForm()}
        <hr />
        {renderTriggerOptions()}
        <hr />
        {renderNotificationOptions()}
        <hr />
        <SOPActions actions={actions} setActions={setActions} />
      </div>
    );
  };
  return (
    <Dialog
      onClose={close}
      title="Add SOP"
      actions={renderActions()}
      maxWidth="lg"
      fullWidth={true}>
      {ptsSOPID !== true ? (
        <>
          <Tabs
            variant="scrollable"
            value={tabValue}
            onChange={(ev, val) => setTabValue(val)}
            aria-label="Vertical tabs example"
            style={{ width: '100%' }}
            className={classes.tabs}>
            <Tab label="General" {...a11yProps(0)} />

            <Tab label={renderLabel('Address', addressList?.length)} {...a11yProps(1)} />
            <Tab label={renderLabel('Person', peopleList?.length)} {...a11yProps(2)} />
            <Tab label={renderLabel('Place', placeList?.length)} {...a11yProps(3)} />
            <Tab label={renderLabel('Zones', zoneList?.length)} {...a11yProps(4)} />
            <Tab label={renderLabel('Cad Types', eventList?.length)} {...a11yProps(5)} />
          </Tabs>
          <ReloadContext.Provider value={{ reload, setReload }}>
            <TabPanel value={tabValue} index={0}>
              {generalTabContetn()}
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
              <div className={classes.dialogContent}>
                <AddressTab addressList={addressList} ptsSOPID={ptsSOPID} />
              </div>
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <div className={classes.dialogContent}>
                <PersonTab personList={peopleList} ptsSOPID={ptsSOPID} />
              </div>
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
              <div className={classes.dialogContent}>
                <PlaceTab placeList={placeList} ptsSOPID={ptsSOPID} />
              </div>
            </TabPanel>
            <TabPanel value={tabValue} index={4}>
              <div className={classes.dialogContent}>
                <ZoneTab zoneList={zoneList} ptsSOPID={ptsSOPID} />
              </div>
            </TabPanel>
            <TabPanel value={tabValue} index={5}>
              <div className={classes.dialogContent}>
                <CadTypesTab cadTypes={eventList} ptsSOPID={ptsSOPID} />
              </div>
            </TabPanel>
          </ReloadContext.Provider>
        </>
      ) : (
        generalTabContetn()
      )}
    </Dialog>
  );
}

const mapStateToProps = state => {
  return {
    dictionary: state.dictionary,
  };
};

export default connect(mapStateToProps, {
  showSpinner,
  hideSpinner,
  handleError,
  getSops,
  closeEditSop,
  notify,
})(AddSOP);
