import React from 'react';
import { connect } from 'react-redux';
import Notifications from 'react-notification-system-redux';
import { colorDefinitions } from '../theme';

function getColors(themeMode) {
  const colorScheme = {};
  Object.entries(colorDefinitions).forEach(([name, values]) => {
    colorScheme[name] = values[themeMode];
  });
  return colorScheme;
}

const colorSchemeLight = getColors('light');
const colorSchemeDark = getColors('dark');

const notificationStyles = colors => {
  return {
    NotificationItem: {
      DefaultStyle: {
        margin: '10px 5px 2px 1px',
        borderRadius: '0.35rem',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
        background: colors.paper,
        color: colors.text1,
      }, 
      success: {
        borderTopColor: colors.success,
      },
      info: {
        borderTopColor: colors.info,
      },
      warning: {
        borderTopColor: colors.warning,
      },
      error: {
        borderTopColor: colors.error,
      },
    },

    Title: {
      DefaultStyle: {
        textTransform: 'uppercase',
      },
      success: {
        color: colors.success,
      },
      error: {
        color: colors.error,
      },
      warning: {
        color: colors.warning,
      },
      info: {
        color: colors.info,
      }
    },

    Action: {
      DefaultStyle: {
        textTransform: 'uppercase',
        borderRadius: '0.35rem'
      },
  
      success: {
        backgroundColor: colors.success,
        color: '#ffffff'
      },
  
      error: {
        backgroundColor: colors.error,
        color: '#ffffff'
      },
  
      warning: {
        backgroundColor: colors.warning,
        color: '#ffffff'
      },
  
      info: {
        backgroundColor: colors.info,
        color: '#ffffff'
      }
    },

    Dismiss: {
      DefaultStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        fontFamily: 'Arial',
        fontSize: 24,
        position: 'absolute',
        top: 0,
        right: 0,
        lineHeight: 1,
        backgroundColor: 'transparent',
        color: colors.grey3,
        width: 40,
        height: 40,
        fontWeight: 400,
      },
    },

  }
}

const notificationStylesDay = notificationStyles(colorSchemeLight);
const notificationStylesNight = notificationStyles(colorSchemeDark);

function NotificationPanel(props) {
  const { notifications, themeMode } = props;

  return (
    <>
      {themeMode === 'day' && <Notifications notifications={notifications} style={notificationStylesDay}/>}
      {themeMode === 'night' && <Notifications notifications={notifications} style={notificationStylesNight}/>}
    </>
  )
}

const mapStateToProps = state => {
  return {
    themeMode: state.theme.mode,
    notifications: state.notifications,
  }
};

export default connect(mapStateToProps)(NotificationPanel);
