import { getService } from './service';
import { notify } from './NotifierReducer';
import { handleError } from './ErrorReducer';
import { updateEvents } from './EventsReducer';

export const saveSop = (data, ptsParentID, ptsSOPID) => {
  const sopData = { ...data };
  const DispatcherQuestions = parseDispatcherQuestions(data);
  sopData.DispatcherQuestions = `<DispatcherQuestions>
${DispatcherQuestions}
</DispatcherQuestions>`;
  const service = getService('settings-sops');
  if (data.ptsSOPID !== true) {
    return service.patch(data.ptsSOPID, sopData);
  } else {
    return service.create(sopData, { query: { ptsParentID, ptsSOPID } });
  }
};

export const updateSOPs = rawSops => {
  const data = encodeSOPs(rawSops);
  return async dispatch => {
    try {
      const service = getService();
      await service.patch(0, { type: 'update-event-sops', data });
      dispatch(notify('SOPs updated', 'success'));
      dispatch(updateEvents());
    } catch (error) {
      dispatch(handleError(error, 'Error, sops not saved'));
    }
  };
};

export const approveSOP = ptsSOPID => {
  const service = getService('settings-sops');
  return service.patch(ptsSOPID, { type: 'approve-sop' });
};

export const getSops = async ptsParentID => {
  const service = getService('settings-sops');
  return service.find({ query: { ptsParentID } });
};

export const getSop = async ptsSOPID => {
  const service = getService('settings-sops');
  return service.get(ptsSOPID);
};

export const removeSop = (ptsSOPID, ptsParentID) => {
  const service = getService('settings-sops');
  return service.remove(ptsSOPID, { type: 'remove-sop', data: ptsSOPID, query: { ptsParentID } });
};

export function processSOPs(newEvent) {
  return newEvent.sops.map(sop => parseXmlSOP(sop));
}

export function parseXmlSOP(sop) {
  const { Actions, ptsSOPID, SOPID } = sop;
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(Actions, 'text/xml');
  const getContent = name => xmlDoc.getElementsByTagName(name)[0].textContent;
  const getCheckboxVal = val => (val === 'True' ? true : false);
  const DispatcherMessage = getContent('Message');
  const ParentType = getContent('ParentType');
  const ParentName = getContent('ParentName');
  const Notified = {};
  const NotifiedObj = xmlDoc.getElementsByTagName('Notified')[0];
  if (NotifiedObj) {
    Notified.Name = NotifiedObj.textContent;
    Notified.Time = NotifiedObj.getAttribute('Time');
  }
  const DispatcherQuestions = [];
  const DispatcherQuestionsNodes = xmlDoc.getElementsByTagName('DispatcherQuestions')[0].childNodes;
  for (let i = 0; i < DispatcherQuestionsNodes.length; i++) {
    const node = DispatcherQuestionsNodes[i];
    if (!node.tagName) continue;
    // node.tagName === 'CheckBox' && console.log(node.tagName, node.textContent);
    DispatcherQuestions.push({
      type: node.tagName,
      name: node.getAttribute('Name'),
      text: node.getAttribute('Text'),
      value: node.tagName === 'CheckBox' ? getCheckboxVal(node.textContent) : node.textContent,
    });
  }
  return {
    DispatcherMessage,
    ParentType,
    ParentName,
    Notified,
    DispatcherQuestions,
    ptsSOPID,
    SOPID,
  };
}

export const decodeDispatcherQuestions = xml => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xml, 'text/xml');
  const getCheckboxVal = val => (val === 'True' ? true : false);
  const DispatcherQuestions = [];
  const DispatcherQuestionsNodes = xmlDoc.getElementsByTagName('DispatcherQuestions')[0].childNodes;
  for (let i = 0; i < DispatcherQuestionsNodes.length; i++) {
    const node = DispatcherQuestionsNodes[i];
    if (!node.tagName) continue;
    DispatcherQuestions.push({
      type: node.tagName,
      name: node.getAttribute('Name'),
      text: node.getAttribute('Text'),
      value: node.tagName === 'CheckBox' ? getCheckboxVal(node.textContent) : node.textContent,
    });
  }
  return DispatcherQuestions;
};

/* Encode during updating answers */
export function encodeSOPs(rawSops) {
  return rawSops.map(sop => {
    const { ptsEventID, ptsSOPID } = sop;
    const Actions = encodeSOP(sop);
    return { Actions, ptsEventID, ptsSOPID };
  });
}

/* Encode new SOP */
export function encodeSOP(sop) {
  const { DispatcherMessage, Notified, ParentName, ParentType } = sop;
  const notified =
    Notified && Notified.Name
      ? `<Notified Time="${Notified.Time}">${Notified.Name}</Notified>\n`
      : '';
  const DispatcherQuestions = parseDispatcherQuestions(sop);
  const Actions = `<Actions>
  <DispatcherMessage>
    <Message>${DispatcherMessage ? DispatcherMessage : ''}</Message>
  </DispatcherMessage>
  <DispatcherQuestions>
${DispatcherQuestions}
  </DispatcherQuestions>
  <ParentType>${ParentName ? ParentName : ''}</ParentType>
  <ParentName>${ParentType ? ParentType : ''}</ParentName>
  ${notified}
</Actions>`;
  return Actions;
}

const escapeEOL = str => str.replace(/(?:\r\n|\r|\n)/g, '&#13;');

const parseDispatcherQuestions = sop => {
  const getTextStr = q => `    <Text Name="${q.name}" Text="${escapeEOL(q.text)}"/>\n`;
  const getCheckboxStr = q =>
    `    <CheckBox Name="${q.name}" Text="${q.text}">${q.value ? 'True' : ''}</CheckBox>\n`;
  const getQuestionStr = q =>
    `    <Question Name="${q.name}" Text="${q.text}">${q.value}</Question>\n`;
  const DispatcherQuestions = sop.DispatcherQuestions.map(item => {
    switch (item.type) {
      case 'Text':
        return getTextStr(item);
      case 'Question':
        return getQuestionStr(item);
      case 'CheckBox':
        return getCheckboxStr(item);
      default:
        return '';
    }
  }).reduce((res, val) => res + val, '');
  return DispatcherQuestions;
};
