export default function htmlForPrinting(content, title) {
  return `
  <!DOCTYPE html>
  <html lang="en">
    <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap" rel="stylesheet"></link>
    <style>
    @media print {
      body {
        font-family: 'Roboto', sans-serif;
      }
      h4 {
        text-align: center;
      }
      table {
        border-collapse: collapse;
      }
      th, td {
        text-align: left;
        border: 0.1pt solid #666;
        padding: 2pt 5pt;
        font-size: 8pt;
      }
      table table th,
      table table td {
        border: none;
      }
      v-table th {
        white-space: nowrap;
      }
      button {
        display: none;
      }
    }
    </style>
    <title>${title}</title>
  </head>
  <body>${content}</body>
  </html>
  `;
}
