import PagingSetup from 'Settings/PagingSetup';
import React from 'react';
import Content from 'Settings/Content';

function NotificationSettings() {
  return (
    <Content>
      <PagingSetup callerName="notification-settings" />;
    </Content>
  );
}

export default NotificationSettings;
