import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ImportEsnsOther from './ImportEsnsOther';
import ImportCsvEsns from './ImportCsvEsns';
import ImportKmlEsns from './ImportKmlEsns';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Dialog from '../../components/Dialog';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { closeImportEsns } from '../../reducers/DialogsReducer';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(() => ({
  tabs: {
    margin: '-24px -22px 20px -22px',
  },
  wrap: {
    width: 860,
    maxWidth: '100%',
    minHeight: 500,
  },
}));

function ImportEsns(props) {
  const classes = useStyles();
  const [tab, setTab] = useState('csv'); // csv, kml, other

  const close = () => {
    props.closeImportEsns();
  };

  const renderActions = () => {
    return (
      <>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  return (
    <Dialog open={true} onClose={close} title="Import ESNs" actions={renderActions()} toolbar>
      <Tabs value={tab} onChange={(ev, val) => setTab(val)} className={classes.tabs}>
        <Tab label="CSV" value="csv" />
        <Tab label="KML" value="kml" />
        <Tab label="Other" value="other" />
      </Tabs>
      <div className={classes.wrap}>
        {tab === 'other' && <ImportEsnsOther />}
        {tab === 'csv' && <ImportCsvEsns />}
        {tab === 'kml' && <ImportKmlEsns />}
      </div>
    </Dialog>
  );
}

export default connect(null, {
  closeImportEsns,
})(ImportEsns);
