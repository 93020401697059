import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { filterResults } from 'utils/functions';
const minWidth = 548;
const useStyles = makeStyles(theme => ({
  filter: {
    minWidth: minWidth,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
}));

function QuickFilter(props) {
  const classes = useStyles();
  const { rows, columns, setFilteredRows, parentFilter, currentService } = props;
  const [filter, setFilter] = useState('');

  useEffect(() => {
    if (!filter && !parentFilter) {
      setFilteredRows(rows);
    } else {
      let result = rows;
      if (filter) {
        result = filterResults(filter || '', rows, columns);
      }
      if (parentFilter && currentService === 'codetagtypes') {
        result = result.filter(row => row.Parent === parentFilter);
      }
      setFilteredRows(result);
    }
    // eslint-disable-next-line
  }, [filter, rows, parentFilter]);
  useEffect(() => {
    setFilter('');
  }, [currentService]);

  const quickFilter = ev => {
    const val = ev.target.value;
    setFilter(val);
  };

  return (
    <TextField
      label="Quick filter"
      type="search"
      className={classes.filter}
      variant="outlined"
      size="small"
      style={{
        minWidth: props.minWidth || minWidth,
        marginTop: props.marginTop ? props.marginTop : '24px',
      }}
      onChange={quickFilter}
      value={filter}
    />
  );
}

export default QuickFilter;
