import React, { useEffect, useState } from 'react';

import CheckIcon from '@material-ui/icons/Check';
import { makeStyles } from '@material-ui/core';

import { XGrid } from '@material-ui/x-grid';
import TopCard from './TopCard';
import AttatchmentsActions from './AttatchmentsActions';
import { displayDateTime } from 'reducers/TimeReducer';

const useStyles = makeStyles(theme => ({
  gridWrap: {
    width: '100%',
    // boxSizing: 'border-box',
    height: '450px',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    minHeight: theme.spacing(6),
    width: '100%',
  },
  filterWrap: {
    flexShrink: 1,
    flexBasis: 400,
  },
  filter: {
    width: '100%',
  },
  action: {
    flexGrow: 0,
    flexShrink: 0,
    width: 'auto',
    marginLeft: theme.spacing(1),
    textAlign: 'right',
  },
}));

const bool = params => (params.value ? <CheckIcon /> : <span></span>);

const columns = [
  { field: 'PlaceID', headerName: 'Place ID', width: 120 },
  { field: 'PlaceName', headerName: 'Place Name', width: 150 },
  { field: 'PlaceType', headerName: 'Place Type', width: 150 },
  { field: 'PlaceORI', headerName: 'Place ORI', width: 150 },
  { field: 'PlaceDepartmentID', headerName: 'Place Department ID', width: 180 },
  { field: 'FullAddressText', headerName: 'Full Address', width: 200 },
  {
    field: 'Created',
    headerName: 'Created',
    width: 150,
    valueFormatter: params => displayDateTime(params.value),
    format: 'date',
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 150 },
  {
    field: 'Updated',
    headerName: 'Updated',
    valueFormatter: params => displayDateTime(params.value),
    format: 'date',
    width: 150,
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 150 },
  { field: 'IsDeleted', headerName: 'Is Deleted', width: 120 },
  { field: 'Deleted', headerName: 'Deleted', width: 120 },
  { field: 'DeletedBy', headerName: 'Deleted By', width: 150 },
  { field: 'DeletedIP', headerName: 'Deleted IP', width: 150 },
];
const PlaceTab = props => {
  const { placeList, ptsSOPID } = props;
  const classes = useStyles();
  const [rows, setRows] = useState(placeList);
  const [selection, setSelection] = useState(null);
  useEffect(() => {
    setRows(placeList);
  }, [placeList]);
  return (
    <div>
      <TopCard parent="Place" ptsSOPID={ptsSOPID} />
      <h5>Places</h5>
      <AttatchmentsActions ptsSOPID={ptsSOPID} ParentType={'Place'} selection={selection} />

      <div className={classes.gridWrap}>
        <XGrid
          columns={columns}
          rows={rows}
          rowHeight={38}
          disableMultipleSelection
          getRowId={row => row.ptsPlaceID}
          onSelectionModelChange={newSelection => {
            setSelection(parseInt(newSelection.selectionModel[0]));
          }}
        />
      </div>
    </div>
  );
};

export default PlaceTab;
