import { getService } from './service';
import { strToPath, getPathCenter } from '../utils/mapFunctions';
import { decBin } from '../utils/functions';

/** Return full zone info with geofences */
export const getESNs = AgencyID => {
  return new Promise((resolve, reject) => {
    const service = getService();
    service
      .get({ type: 'esns', data: AgencyID })
      .then(result => {
        resolve(
          result.map(zone => {
            const path = strToPath(zone.Polygon);
            delete zone.Polygon;
            const center = path ? getPathCenter(path) : null;
            return { ...zone, path, center };
          })
        );
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const importESNs = (AgencyID, data) => {
  const service = getService();
  return service.patch(AgencyID, { type: 'import-esns', data });
};

export const removeESN = data => {
  const service = getService();
  return service.patch(data.ROWGUID, { type: 'remove-esn', data });
};

export const removeAllESNs = () => {
  const service = getService();
  return service.patch(1, { type: 'remove-all-esns' });
};

export const saveESN = data => {
  const service = getService();
  if (data.ROWGUID) {
    return service.patch(data.ROWGUID, { type: 'update-esn', data });
  } else {
    return service.create({ type: 'save-esn', data });
  }
};

export const getEventESNData = ptsEventID => {
  const service = getService();
  return service.get({ type: 'event-esns', data: ptsEventID });
};

const getEsn = AgencyType => {
  const binStr = decBin(AgencyType, 3);
  return {
    LawEnforcementAgencyID: parseInt(binStr[2]) ? true : false,
    FireResponseAgencyID: parseInt(binStr[1]) ? true : false,
    EMSResponseAgencyID: parseInt(binStr[0]) ? true : false,
  };
};

export const getRoutingRecommendations = async (ptsEventID, dictionary) => {
  const { CallTypes, Agencies } = dictionary;
  const esnAgencies = [];
  const data = await getEventESNData(ptsEventID);
  const CallType = CallTypes.find(c => c.Code === data.event.CallType);
  const esn = getEsn(CallType.AgencyType);
  const recommendations = Agencies.filter(Agency => {
    const { AgencyID } = Agency;
    let match = false;
    data.esnMatch.forEach(e => {
      if (esnAgencies.indexOf(e.FireResponseAgencyID) === -1)
        esnAgencies.push(e.FireResponseAgencyID);
      if (esnAgencies.indexOf(e.EMSResponseAgencyID) === -1)
        esnAgencies.push(e.EMSResponseAgencyID);
      if (esnAgencies.indexOf(e.LawEnforcementAgencyID) === -1)
        esnAgencies.push(e.LawEnforcementAgencyID);
      if (
        (esn.FireResponseAgencyID && e.FireResponseAgencyID === AgencyID) ||
        (esn.EMSResponseAgencyID && e.EMSResponseAgencyID === AgencyID) ||
        (esn.LawEnforcementAgencyID && e.LawEnforcementAgencyID === AgencyID)
      ) {
        match = true;
      }
    });
    return match;
  });
  return recommendations;
};

export const getCapabilities = () => {
  const service = getService('settings-resources');
  return service.get({ type: 'capabilities' });
};

export const getCapabilityTypes = () => {
  const service = getService();
  return service.get({ type: 'capability-types' });
};

export const getVehiclesResources = () => {
  const service = getService('settings-resources');
  return service.get({ type: 'vehicles-resources' });
};

export const getUnitsResources = async () => {
  const service = getService('settings-resources');
  const result = await service.get({ type: 'units-resources' });
  return result;
  //return service.get({ type: 'units-resources' });
};

export const getItemsResources = () => {
  const service = getService('settings-resources');
  return service.get({ type: 'items-resources' });
};

export const saveCallTypeCapability = data => {
  const service = getService('settings-resources');
  return service.patch(data.CallType, { type: 'calltype-capability', data });
};

export const saveCapability = data => {
  const service = getService('settings-resources');
  return service.patch(data.Code, { type: 'capability', data });
};

export const removeCapabilityType = data => {
  const service = getService('settings-resources');
  return service.patch(data.Code, { type: 'remove-capability-type', data });
};

export const removeCapability = (CapabilityType, ChildType, ptsParentID) => {
  const data = { CapabilityType, ChildType, ptsParentID };
  const service = getService('settings-resources');
  return service.patch(CapabilityType, { type: 'remove-capability', data });
};

export const getPersonalCapabilities = ptsPersonID => {
  const service = getService('settings-resources');
  return service.get({ type: 'personal-capabilities', data: ptsPersonID });
};

export const getUnitCapabilities = ptsUnitID => {
  const service = getService('settings-resources');
  return service.get({ type: 'unit-capabilities', data: ptsUnitID });
};

export const getVehicleCapabilities = ptsVehicleID => {
  const service = getService('settings-resources');
  return service.get({ type: 'vehicle-capabilities', data: ptsVehicleID });
};

export const getItemCapabilities = ptsItemID => {
  const service = getService('settings-resources');
  return service.get({ type: 'item-capabilities', data: ptsItemID });
};

export const saveUnitCapability = data => {
  const service = getService('settings-resources');
  return service.patch(data.ptsUnitID, { type: 'unit-capability', data });
};

export const saveVehicleCapability = data => {
  const service = getService('settings-resources');
  return service.patch(data.ptsVehicleID, { type: 'vehicle-capability', data });
};

export const saveItemCapability = data => {
  const service = getService('settings-resources');
  return service.patch(data.ptsItemID, { type: 'item-capability', data });
};

export const savePersonalCapability = data => {
  const service = getService('settings-resources');
  return service.patch(data.ptsPersonID, { type: 'personal-capability', data });
};

export const removeCallTypeCapability = data => {
  const service = getService('settings-resources');
  return service.patch(data.CallType, { type: 'remove-calltype-capability', data });
};

export const removePersonalCapability = data => {
  const service = getService('settings-resources');
  return service.patch(data.ptsPersonID, { type: 'remove-personal-capability', data });
};

export const getAllRequiredCapabilities = () => {
  const service = getService('settings-resources');
  return service.get({ type: 'required-capabilities-all' });
};

export const getRequiredCapabilities = CallType => {
  const service = getService('settings-resources');
  return service.get({ type: 'required-capabilities', data: CallType });
};

export const saveUnitPerson = data => {
  const service = getService('settings-resources');
  return service.patch(data.ptsUnitID, { type: 'unit-person', data });
};

export const removePerson = (id, ChildType, ptsParentID) => {
  const data = { id, ChildType, ptsParentID };
  const service = getService('settings-resources');
  return service.patch(id, { type: 'remove-person', data });
};

export const saveUnitVehicle = data => {
  const service = getService('settings-resources');
  return service.patch(data.ptsUnitID, { type: 'unit-vehicle', data });
};

export const removeVehicle = (id, ChildType, ptsParentID) => {
  const data = { id, ChildType, ptsParentID };
  const service = getService('settings-resources');
  return service.patch(id, { type: 'remove-vehicle', data });
};

export const getPersonalItems = ptsPersonID => {
  const service = getService('settings-resources');
  return service.get({ type: 'personal-items', data: ptsPersonID });
};

export const savePersonItem = data => {
  const service = getService('settings-resources');
  return service.patch(data.ptsPersonID, { type: 'person-item', data });
};

export const removePersonItem = (ptsChildID, ChildType, ptsParentID) => {
  const data = { ptsChildID, ChildType, ptsParentID };
  const service = getService('settings-resources');
  return service.patch(ptsChildID, { type: 'remove-person-item', data });
};

export const getVehiclesForItems = () => {
  const service = getService('settings-resources');
  return service.get({ type: 'vehicles-for-items' });
};

export const saveVehicleItem = data => {
  const service = getService('settings-resources');
  return service.patch(data.ptsVehicleID, { type: 'vehicle-item', data });
};

export const removeVehicleItem = (ptsChildID, ChildType, ptsParentID, ParentType) => {
  const data = { ptsChildID, ChildType, ptsParentID, ParentType };
  const service = getService('settings-resources');
  return service.patch(ptsChildID, { type: 'remove-vehicle-item', data });
};
