import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Content from './Content';
import { getService } from '../reducers/service';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { notify } from '../reducers/NotifierReducer';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Autocomplete2 from 'components/Autocomplete2';
import { gridStyle } from 'utils/formStyles';
import CustomIDGenerator from 'global-components/CustomIDGenerator';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& h4': {
      marginBottom: theme.spacing(2),
    },
  },
  form: {
    margin: '0 -4px 4px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexGrow: 1,
  },
  formControl: {
    minWidth: '210px',
  },
  selectEmpty: {
    height: '40px',
  },
  item: {
    ...gridStyle(200, 300),
    margin: 0,
  },
}));

function CustomCases(props) {
  const classes = useStyles();

  const service = getService('pts-custom-agency-id');

  // states
  const initialData = {
    Application: 'Global',
    AgencyID: '',
    EntityName: 'Cases (Agency Specific)',
  };
  const dataForNewItem = {
    Application: 'Global',

    EntityName: 'Cases (Agency Specific)',
    Sequence: 0,
    SequenceLength: 9,
    PrefixOrder: 1,
    SuffixOrder: 0,
    MonthOrder: 0,
    DayOrder: 0,
    YearOrder: 0,
    SequenceOrder: 2,
    SequenceResetType: 'N',
    UseSeparator: false,
    IsDeleted: false,
    Prefix: '',
    Suffix: '',
    UseFourDigitYear: false,
    Sequencer: 0,
    Separator: '',
  };
  // states

  return (
    <CustomIDGenerator
      initialData={initialData}
      dataForNewItem={dataForNewItem}
      showAgency={true}
    />
  );
}

const mapStateToProps = state => {
  return {
    dictionary: state.dictionary,
  };
};

export default connect(mapStateToProps, {
  notify,
})(CustomCases);
