import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';

import Content from '../Content';
import { ColCard, Fills, Row, RowInner } from 'utils/formStyles';
import FormDivider from 'components/FormDivider';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { getService } from 'reducers/service';
import RenderRow from './RenderRow';
import { connect } from 'react-redux';
import { handleError } from 'reducers/ErrorReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { notify } from 'reducers/NotifierReducer';
import { getFields, handleSaveE911Setup, processData } from './helperFunctions';
import ConnectionSetupSection from './ConnectionSetupSection';
import MessageParser from './MessageParser';
const useStyles = makeStyles(theme => ({
  item: {
    width: '100%',
    flex: '40%',
  },
  checkboxItem: {
    width: '100%',
    flex: '10%',
  },
}));

const E911Setup = props => {
  const { notify, hideSpinner, showSpinner, handleError, user, wsClient } = props;
  const username = user?.userData?.user?.Username;
  const classes = useStyles();
  const service = getService('e911-setup');
  const [fileName, setFileName] = useState(null);
  const [prevValues, setPrevValues] = useState([]);
  const [values, setValues] = useState({});
  const [modifiedValues, setModifiedValues] = useState({});
  const [fileds, setFileds] = useState([]);
  const [resourceType, setResourceType] = useState(0);
  const [showMessageParser, setShowMessageParser] = useState(false);
  useEffect(() => {
    if (wsClient.websocket) {
      handleFetchData();
    }
  }, [wsClient]);
  useEffect(() => {
    if (
      values['E911Collector.ReadSourceType'] &&
      values['E911Collector.ReadSourceType']['ConnectionSetup']
    ) {
      setResourceType(values['E911Collector.ReadSourceType']['ConnectionSetup']);
    }
  }, [
    values['E911Collector.ReadSourceType'],
    values['E911Collector.ReadSourceType'] &&
      values['E911Collector.ReadSourceType']['ConnectionSetup'],
  ]);

  useEffect(() => {
    const allFields = getFields(resourceType);
    setFileds(allFields);
  }, [resourceType]);
  const handleFetchData = async () => {
    const service = getService('e911-setup');
    const data = await service.find({});
    setPrevValues(data || []);
    const processed = processData(data);

    setValues(processed);
  };

  const handleChange = (Path, CDRKey, val, fromPicker) => {
    const obj = JSON.parse(JSON.stringify(values));
    const modifiedObj = JSON.parse(JSON.stringify(modifiedValues));
    if (fromPicker) {
      obj[Path] = fromPicker;
      setValues(obj);
      modifiedObj[Path] = fromPicker;
      setModifiedValues(modifiedObj);
      return;
    }

    if (obj[Path]) {
      obj[Path][CDRKey] = val;
    } else {
      obj[Path] = {
        [CDRKey]: val,
      };
    }

    if (modifiedObj[Path]) {
      modifiedObj[Path][CDRKey] = val;
    } else {
      modifiedObj[Path] = {
        [CDRKey]: val,
      };
    }
    setValues(obj);
    setModifiedValues(modifiedObj);
  };

  const hanldeSave = async () => {
    showSpinner();
    try {
      //   await addEventNote(text, ptsEventID);

      const addedData = await handleSaveE911Setup(modifiedValues, prevValues, username);
      handleFetchData();
      setModifiedValues({});
      notify('New File Created', 'success');
    } catch (err) {
      handleError(err, 'Error, File not saved.', err);
    } finally {
      hideSpinner();
    }
  };
  const renderActions = () => {
    return (
      <div style={{ width: '100%', textAlign: 'right', padding: '10px 15px' }}>
        <Button
          color="primary"
          variant="contained"
          className="mr-2"
          onClick={() => setShowMessageParser(true)}>
          Parser
        </Button>

        <Button color="primary" variant="contained" onClick={hanldeSave}>
          <SaveIcon /> Save
        </Button>
      </div>
    );
  };
  return (
    <Content>
      <div className="m-4">
        <h1>E911Setup</h1>
        {showMessageParser && (
          <MessageParser
            values={values}
            handleChange={handleChange}
            setShowMessageParser={setShowMessageParser}
          />
        )}
        {renderActions()}
        <ConnectionSetupSection
          values={values}
          handleChange={handleChange}
          resourceType={resourceType}
        />
        {fileds.map(field => {
          return (
            <RenderRow
              field={field}
              handleChange={handleChange}
              values={values}
              resourceType={resourceType}
            />
          );
        })}
      </div>
    </Content>
  );
};
const mapStateToProps = state => {
  return {
    dictionary: state.dictionary,
    wsClient: state.websocket,
    user: state.user,
  };
};
export default connect(mapStateToProps, {
  showSpinner,
  hideSpinner,
  handleError,
  notify,
})(E911Setup);
