import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useRef, useState } from 'react';
import { getFields } from './helperFunctions';
import { parseString } from 'xml2js';
import xmlFormat from 'xml-formatter';
import { notify } from 'reducers/NotifierReducer';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  selectionArea: {
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '10px',
    height: '380px',
  },
}));
const SelectPosition = props => {
  const classes = useStyles();
  const { inputText, handleChange, typeTCP, parserType } = props;
  const [startPosition, setStartPosition] = useState(null);
  const [endPosition, setEndPosition] = useState(null);
  const [selectedPath, setSelectedPath] = useState(null);
  const [text, setText] = useState();
  const [formatedXml, setFormatedXml] = useState(null);
  const [xmlNode, setXmlNode] = useState(null);
  const textFieldRef = useRef();
  const [xmlError, setXmlError] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    setText(inputText);
  }, [inputText]);

  useEffect(() => {
    if (text && typeTCP) {
      try {
        const xml = xmlFormat(text, {
          indentation: '  ',

          collapseContent: true,
          lineSeparator: '\n',
        });
        setXmlError(false);
        setFormatedXml(xml);
      } catch (error) {
        // dispatch(notify('Invalid Xml', 'error'));
        setXmlError(true);
      }
    }
  }, [text, typeTCP]);

  const handleMouseUp = e => {
    e.preventDefault();
    if (typeTCP) {
      const searchValue = window.getSelection().toString();
      setXmlNode(searchValue);
    } else {
      const selObj = window.getSelection();

      const { baseOffset: start, extentOffset: end } = selObj;
      if (start == end) {
        setStartPosition(null);
        setEndPosition(null);
        return;
      }

      setStartPosition(start < end ? start : end);
      setEndPosition(start > end ? start - 1 : end - 1);
    }
  };

  const handleSetPosition = () => {
    if (typeTCP) {
      handleChange(selectedPath.Path, 'XmlNode', xmlNode);
      setXmlNode(null);
    } else {
      handleChange(selectedPath.Path, null, null, {
        StartPosition: String(startPosition),
        EndPosition: String(endPosition),
      });

      setEndPosition(null);
      setStartPosition(null);
    }

    setSelectedPath(null);
  };
  const renderPosition = () => {
    return typeTCP ? (
      <Grid item xs={12}>
        <TextField
          style={{ width: '100%' }}
          variant="outlined"
          label="Node"
          value={xmlNode || ''}
          size="small"
        />
      </Grid>
    ) : (
      <>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            label="Start Position"
            value={startPosition || startPosition === 0 ? startPosition + 1 : 0}
            type="number"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            size="small"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            label="End Position"
            value={endPosition ? endPosition + 1 : 0}
            size="small"
            type="number"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          />
        </Grid>
      </>
    );
  };
  const isDisabled = typeTCP ? Boolean(xmlNode) : Boolean(endPosition && String(startPosition));
  return (
    <>
      <Grid item xs={8}>
        <Typography
          //   onContextMenu={handleContextClick}
          onMouseUp={handleMouseUp}
          className={classes.selectionArea}
          ref={textFieldRef}
          style={{ whiteSpace: 'pre-wrap' }}>
          {typeTCP ? (
            xmlError ? (
              <span style={{ color: 'red' }}> Please Give a Valid XML</span>
            ) : (
              formatedXml
            )
          ) : (
            text
          )}
        </Typography>
      </Grid>

      <Grid item xs={4}>
        <Grid container spacing={2} justify="center">
          {renderPosition()}
          <Grid item xs={12}>
            <Autocomplete
              id="combo-box-demo"
              size="small"
              autoHighlight
              autoSelect
              value={selectedPath}
              onChange={(event, newValue) => {
                setSelectedPath(newValue || null);
              }}
              options={getFields(typeTCP ? '2' : '1', true) || []}
              getOptionLabel={option => option.label || ''}
              renderOption={option => option.label || '-'}
              style={{ width: '100%' }}
              renderInput={params => <TextField {...params} variant="outlined" label="Path" />}
            />
          </Grid>

          <Grid item xs={6} style={{ textAlign: 'center' }}>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSetPosition}
              disabled={!selectedPath?.Path || !isDisabled}>
              Set Position
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SelectPosition;
