import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

import { handleError } from '../reducers/ErrorReducer';
import { showSpinner, hideSpinner } from '../reducers/UiReducer';
import { notify } from '../reducers/NotifierReducer';
import { closeAddEditClearanceLevel } from '../reducers/DialogsReducer';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { DialogContent } from '@mui/material';
import { getService } from 'reducers/service';
import DatePicker2 from 'components/DatePicker2';
import { processPhoneNoWithoutExtra } from 'utils/functions';

const useStyles = makeStyles(theme => ({
  actions: {
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  textField: {
    width: 600,
    maxWidth: '100%',
    marginTop: theme.spacing(1),
  },
  dialog: {
    '& $content': {
      margin: 0,
      padding: 0,
      background: 'red !important',
    },
  },
}));

function AddEditClearanceLevel(props) {
  const classes = useStyles();
  const { wsClient, user } = props;
  const { type } = props.data;
  const username = user?.userData?.user?.Username;
  const ptsUserID = user?.userData?.user?.ptsUserID;
  const service = getService('settings-sec-clearance-level');

  const [formData, setFormData] = useState({});
  useEffect(() => {
    const info = props.data.info;
    if (type == 'edit' && info?.ptsClearanceLevelID) {
      const form = {
        Level: info.Level || '',
        Description: info.Description || '',
        Sequence: info.Sequence || null,
      };

      setFormData(form);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.info, type]);

  const close = () => {
    props.closeAddEditClearanceLevel();
  };
  const save = async () => {
    props.showSpinner();
    try {
      //   await addEventNote(text, ptsEventID);
      const newForm = { ...formData };

      if (type == 'edit') {
        const id = props.data?.info?.ptsClearanceLevelID;

        newForm['UpdatedBy'] = username;
        const updatedData = await service.patch(id, newForm);
        props.notify('File Updated', 'success');
      } else {
        newForm['CreatedBy'] = username;
        newForm['UpdatedBy'] = username;
        const addedData = await service.create(newForm, { query: { parent: 'settings' } });
        props.notify('New File Created', 'success');
      }
    } catch (err) {
      if (err.message === 'SequelizeUniqueConstraintError: Validation error') {
        props.handleError(err, 'Error, Sequence Number Must Unique', err);
      } else {
        props.handleError(err, 'Error, File not saved.', err);
      }
    }
    props.hideSpinner();
    close();
  };
  const handleFormChange = (name, data) => {
    const newForm = { ...formData };
    newForm[name] = data;
    setFormData({ ...newForm });
  };

  const renderActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={save}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };
  const handleSequenceChange = ev => {
    const val = ev.target.value;
    handleFormChange('Sequence', val ? Number(val) : '');
    // if (!val) {
    //   handleFormChange('Sequence', '');
    // }
  };
  return (
    <Dialog
      onClose={close}
      title={type === 'edit' ? 'Edit' : 'Add' + 'Clearance Level'}
      open={true}
      actions={renderActions()}
      //   className={classes.dialog}

      // toolbar={false}
      // draggable={false}
      fullWidth
      maxWidth="lg">
      <DialogTitle>{type == 'edit' ? 'Edit' : 'Add'} Clearance Level</DialogTitle>
      <DialogContent className="py-2">
        <Grid container spacing={2} style={{ width: '100%' }}>
          <Grid item xs={12} sm={6}>
            <TextField
              //fullWidth
              size="small"
              style={{ width: '100%' }}
              type="text"
              value={formData.Level || ''}
              label="Level"
              //error={error}
              variant="outlined"
              onChange={e => handleFormChange('Level', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              //fullWidth

              size="small"
              style={{ width: '100%' }}
              type="text"
              value={formData.Description || ''}
              label="Description"
              //error={error}
              variant="outlined"
              onChange={e => handleFormChange('Description', e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
              <InputLabel htmlFor="sequence_for_clearance" className={classes.selectLabel}>
                Sequence
              </InputLabel>
              <Select
                value={formData.Sequence}
                onChange={ev => handleFormChange('Sequence', ev.target.value)}
                label="Sequence"
                inputProps={{ id: 'sequence_for_clearance' }}>
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {[...Array(100)].map((_, index) => (
                  <MenuItem value={index + 1}>{index + 1}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className=" mb-1 mr-3">{renderActions()}</div>
      </DialogActions>
    </Dialog>
  );
}
const mapStateToProps = state => {
  return {
    dictionary: state.dictionary,
    wsClient: state.websocket,
    user: state.user,
  };
};
export default connect(mapStateToProps, {
  showSpinner,
  hideSpinner,
  handleError,
  notify,
  closeAddEditClearanceLevel,
})(AddEditClearanceLevel);
