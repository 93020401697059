import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

import { handleError } from '../reducers/ErrorReducer';
import { showSpinner, hideSpinner } from '../reducers/UiReducer';
import { notify } from '../reducers/NotifierReducer';
import { closeAddEditEventTypeInitials } from '../reducers/DialogsReducer';
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  Fab,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import { Autocomplete } from '@material-ui/lab';
import { DialogContent } from '@mui/material';
import { getService } from 'reducers/service';
import DatePicker2 from 'components/DatePicker2';
import { processPhoneNoWithoutExtra } from 'utils/functions';
import options from 'components/EventTypeIcons';
import Checkbox2 from 'components/Checkbox2';
import { SketchPicker } from 'react-color';
import Dictionary from 'components/Dictionary';
import SOPs from 'Settings/SOPs';
import EventSubTypes from 'Settings/EventSubTypes';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  actions: {
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  textField: {
    width: 600,
    maxWidth: '100%',
    marginTop: theme.spacing(1),
  },
  dialog: {
    '& $content': {
      margin: 0,
      padding: 0,
      background: 'red !important',
    },
  },
  popover: {
    position: 'absolute',
    zIndex: '999',
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
}));
const ITEM_HEIGHT = 48;
const DEFAULT_COLOR = '#dedfe3';
function AddEditEventTypeInitials(props) {
  const classes = useStyles();
  const { wsClient, user, dictionary } = props;

  const { type } = props.data;
  const username = user?.userData?.user?.Username;
  const ptsUserID = user?.userData?.user?.ptsUserID;
  const service = getService('settings-event-type-initials');
  const [Code, setCode] = useState('');
  const [saveError, setSaveError] = useState(null);
  const [initial, setInitial] = useState('');
  const [selectedIconIndex, setSelectedIconIndex] = useState(0);
  const [filterText, setFilterText] = useState('');
  const [Description, setDescription] = useState('');
  const [IsActive, setIsActive] = useState(false);
  const [AgencyType, setAgencyType] = useState(0);
  const [AgencyTypePolice, setAgencyTypePolice] = useState(null);
  const [AgencyTypeFire, setAgencyTypeFire] = useState(null);
  const [AgencyTypeEMS, setAgencyTypeEMS] = useState(null);
  const [Priority, setPriority] = useState(null);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    const info = props.data.info;
    if (type == 'edit' && info?.Code) {
      setCode(info.Code);
      setInitial(info.Initial);
      setSelectedIconIndex(info.Icon);
      setDescription(info.Description);
      setIsActive(info.IsActive);
      setAgencyType(info.AgencyType);
      setPriority(info.Priority);
      // setColor(info.Color || DEFAULT_COLOR);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.info, type]);
  useEffect(() => {
    if (AgencyType === 1) setAgencyTypePolice(true);
    else if (AgencyType === 2) setAgencyTypeFire(true);
    else if (AgencyType === 4) setAgencyTypeEMS(true);
    else if (AgencyType === 3) {
      setAgencyTypePolice(true);
      setAgencyTypeFire(true);
    } else if (AgencyType === 5) {
      setAgencyTypePolice(true);
      setAgencyTypeEMS(true);
    } else if (AgencyType === 6) {
      setAgencyTypeFire(true);
      setAgencyTypeEMS(true);
    } else if (AgencyType === 7) {
      setAgencyTypePolice(true);
      setAgencyTypeFire(true);
      setAgencyTypeEMS(true);
    }
  }, [AgencyType]);
  const close = () => {
    props.closeAddEditEventTypeInitials();
  };

  const save = async () => {
    props.showSpinner();

    let result;

    try {
      //   await addEventNote(text, ptsEventID);
      const newObj = {
        CDRKey: Code,
        ValueString: initial,
        ValueNumeric: selectedIconIndex,
        IsActive,
        Description,
        Priority,
        AgencyType: processAgencyType(),
      };

      if (type == 'add') {
        const id = props.data?.info?.id;

        result = await service.create(newObj);
        props.notify('New File Created', 'success');
      } else {
        result = await service.patch(Code, newObj);
        props.notify('File Updated', 'success');
      }
    } catch (err) {
      props.handleError(err, 'Error, File not saved.', err);
    }
    props.hideSpinner();
    close();
  };

  const processAgencyType = () => {
    let AgencyType = 0;
    if (AgencyTypePolice && AgencyTypeFire && AgencyTypeEMS) AgencyType = 7;
    else if (AgencyTypePolice && AgencyTypeFire) AgencyType = 3;
    else if (AgencyTypePolice && AgencyTypeEMS) AgencyType = 5;
    else if (AgencyTypeFire && AgencyTypeEMS) AgencyType = 6;
    else if (AgencyTypePolice) AgencyType = 1;
    else if (AgencyTypeFire) AgencyType = 2;
    else if (AgencyTypeEMS) AgencyType = 4;
    return AgencyType;
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleIconOptionsClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleIconOptionsClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (ev, idx) => {
    setSelectedIconIndex(idx);
    setAnchorEl(null);
  };

  const renderActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={save}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  const CodeErrorHelpertext = () => {
    if (Code.length > 10) return '* Code should be 10 characters max!';
    if (saveError) return `* ${saveError}`;
  };

  const MenuIcon = options[selectedIconIndex];
  return (
    <Dialog
      onClose={close}
      title={type === 'edit' ? 'Edit' : 'Add' + 'Event Type Initials and Icons'}
      open={true}
      actions={renderActions()}
      //   className={classes.dialog}

      // toolbar={false}
      // draggable={false}
      fullWidth
      maxWidth="lg">
      <DialogTitle>{type == 'edit' ? 'Edit' : 'Add'} Event Type Initials and Icons</DialogTitle>
      <DialogContent className="py-2">
        <Grid container spacing={2} style={{ width: '100%', alignItems: 'top' }}>
          <Grid item xs={12} sm={2}>
            <TextField
              className={classes.textField}
              error={Code.length > 10 || typeof saveError === 'string'}
              helperText={CodeErrorHelpertext()}
              label="Event Type"
              inputProps={{ maxLength: 10 }}
              value={Code}
              onChange={ev => setCode(ev.target.value)}
              variant="outlined"
              size="small"
              disabled={type === 'edit'}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              error={initial.length > 3}
              helperText={initial.length <= 3 ? '' : '* Max 3 Chars!'}
              className={classes.textField}
              inputProps={{ maxLength: 3 }}
              label="Initials"
              value={initial}
              onChange={ev => setInitial(ev.target.value)}
              variant="outlined"
              size="small"
            />
          </Grid>

          <Grid item xs={12} sm={5}>
            <TextField
              className={classes.textField}
              label="Description"
              value={Description}
              onChange={ev => setDescription(ev.target.value)}
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={1} className="pt-3">
            <FormControlLabel
              label="Active"
              control={
                <Checkbox
                  checked={IsActive}
                  onChange={ev => setIsActive(ev.target.checked)}
                  label="Active"
                />
              }
            />
          </Grid>
          <Grid item xs={12} sm={2} className="pt-3">
            <Fab
              className="ml-2 mr-2"
              size="small"
              color="primary"
              onClick={handleIconOptionsClick}>
              <MenuIcon />
            </Fab>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleIconOptionsClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 10,
                  width: '48ch',
                },
              }}>
              <Grid container>
                {Object.entries(options)
                  .filter(option => {
                    const iconName = option[1].name;
                    return iconName.toLowerCase().includes(filterText.toLowerCase());
                  })
                  .map(([key, Icon]) => (
                    <span
                      className="m-3"
                      key={key}
                      onClick={event => handleMenuItemClick(event, key)}>
                      <Fab size="small" color="primary">
                        <Icon color="secondary" style={{ width: '30px', height: '30px' }} />
                      </Fab>
                    </span>
                  ))}
              </Grid>
            </Menu>
          </Grid>

          <Grid item xs={12} sm={2}>
            <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
              <InputLabel htmlFor="sequence_for_Priority" className={classes.selectLabel}>
                Priority
              </InputLabel>
              <Select
                value={Priority}
                onChange={ev => setPriority(ev.target.value)}
                label="Sequence"
                inputProps={{ id: 'sequence_for_Priority' }}>
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {[...Array(10)].map((_, index) => (
                  <MenuItem value={index + 1}>{index + 1}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={1}>
            <FormControlLabel
              label="Police"
              control={
                <Checkbox
                  checked={AgencyTypePolice}
                  onChange={ev => setAgencyTypePolice(ev.target.checked)}
                  label="Police"
                />
              }
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <FormControlLabel
              label="Fire"
              control={
                <Checkbox
                  checked={AgencyTypeFire}
                  onChange={ev => setAgencyTypeFire(ev.target.checked)}
                  label="Fire"
                />
              }
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <FormControlLabel
              label="EMS"
              control={
                <Checkbox
                  checked={AgencyTypeEMS}
                  onChange={ev => setAgencyTypeEMS(ev.target.checked)}
                  label="EMS"
                />
              }
            />
          </Grid>
        </Grid>

        {type === 'edit' && props.data?.info?.IntCode && (
          <>
            <Tabs
              variant="scrollable"
              value={tabValue}
              onChange={(ev, val) => setTabValue(val)}
              aria-label="Vertical tabs example"
              style={{ width: '100%' }}
              className={classes.tabs}>
              <Tab label="SOPs" {...a11yProps(0)} />
              <Tab label="Event Sub Types" {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={tabValue} index={0}>
              <SOPs
                CallerType="CadTypes"
                ptsParentID={props.data?.info?.IntCode}
                ParentType="CADType"
              />
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
              <div className={classes.dialogContent}>
                <EventSubTypes ptsParentID={props.data.info.IntCode} />
              </div>
            </TabPanel>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <div className=" mb-1 mr-3">{renderActions()}</div>
      </DialogActions>
    </Dialog>
  );
}
const mapStateToProps = state => {
  return {
    dictionary: state.dictionary,
    wsClient: state.websocket,
    user: state.user,
  };
};
export default connect(mapStateToProps, {
  showSpinner,
  hideSpinner,
  handleError,
  notify,
  closeAddEditEventTypeInitials,
})(AddEditEventTypeInitials);
