
    import React from 'react';
    import SvgIcon from '@material-ui/core/SvgIcon';
    
    function icons8cannabis100Icon(props) {
      return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100" height="100"><path d="M25 0s-5.602 5.492-5.5 19.594v.812C12.7 11.508 4 9 4 9s.293 9.27 6.656 18.75C4.086 26.059 0 27.906 0 27.906s2.707 6.696 11.406 9.594c-2.398 1.398-3.804 2.898-3.906 3L5 43c1.703.547 2.754 1 9 1 1.7 0 2.938-.195 4.438-.594 2.398-.64 4.261-1.418 5.625-2.312.332 4.968 2.062 8.375 2.062 8.375a1 1 0 1 0 1.75-.938s-1.457-2.914-1.781-7.343c1.347.851 3.16 1.605 5.468 2.218 1.5.399 2.739.594 4.438.594 6.246 0 7.297-.453 9-1l-2.5-2.5c-.102-.102-1.508-1.602-3.906-3C47.293 34.602 50 27.906 50 27.906s-4.086-1.847-10.656-.156C45.707 18.27 46 9 46 9s-8.7 2.508-15.5 11.406v-.812C30.602 5.492 25 0 25 0Z"/></SvgIcon>
      );
    }
  
    export default icons8cannabis100Icon;