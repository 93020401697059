import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import { handleError } from '../reducers/ErrorReducer';
import { showSpinner, hideSpinner } from '../reducers/UiReducer';
import { notify } from '../reducers/NotifierReducer';
import { closeAddEventSubTypes } from '../reducers/DialogsReducer';
import { connect } from 'react-redux';
import { formatCommonCodes } from 'Settings/LookupCodes/CodeObjectFormatter';
import { getService } from 'reducers/service';
const obj = {
  Code: '',
  Description: '',
  Category: '',
  IsDefault: false,
  IsActive: false,
  Code2: '',
  Description2: '',
  Code3: '',
  Description3: '',
  Code4: '',
  Description4: '',
};

const AddEventSubType = props => {
  const [values, setValues] = useState({});
  const { data, type, user } = props;
  const service = getService('codecadsubtypes');
  const username = user?.userData?.user?.Username;

  useEffect(() => {
    if (type === 'edit') {
      setValues(props.data);
    } else {
      setValues(obj);
    }
  }, [type, data]);
  const handleChange = (key, value) => {
    setValues(prevState => ({ ...prevState, [key]: value }));
  };

  const close = () => {
    props.closeAddEventSubTypes();
  };
  const save = async () => {
    const processedValues = formatCommonCodes(values);

    try {
      props.showSpinner();
      let res;
      if (type === 'edit') {
        res = await service.patch(processedValues.Code, {
          ...processedValues,
          UpdatedBy: username,
        });
        props.notify('File Updated', 'success');
      } else {
        res = await service.create({
          ...processedValues,
          ptsParentID: data.ptsParentID,
          CreatedBy: username,
          UpdatedBy: username,
        });
        props.notify('New File Created', 'success');
      }

      props.closeAddEventSubTypes();
    } catch (err) {
      console.log('err', err);
      props.notify('File Not Created', 'error');
    } finally {
      props.hideSpinner();
    }
  };
  const renderActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={save}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };
  return (
    <Dialog
      onClose={close}
      title={type === 'edit' ? 'Edit' : 'Add' + 'Event Sub Type '}
      open={true}
      actions={renderActions()}
      fullWidth
      maxWidth="lg">
      <DialogTitle>{type == 'edit' ? 'Edit' : 'Add'} Event Sub Type</DialogTitle>
      <DialogContent className="py-2">
        <Grid container item xs={12} style={{ marginTop: 10 }}>
          <Grid item xs={6} style={{ width: '100%' }}>
            <Box style={{ marginLeft: 10 }}>
              <TextField
                //fullWidth
                disabled={type === 'edit'}
                style={{ width: '100%' }}
                type={'text'}
                value={values.Code}
                label={'Code/Description'}
                //error={error}
                variant={'outlined'}
                onChange={e => handleChange('Code', e.target.value)}
              />
            </Box>
          </Grid>

          <Grid item xs={6} style={{ width: '100%' }}>
            <Box style={{ marginLeft: 10 }}>
              <TextField
                //fullWidth
                style={{ width: '100%' }}
                type={'text'}
                value={values.Description}
                //onChange={handleChange}
                onChange={e => handleChange('Description', e.target.value)}
                helperText={''}
                label={'Description'}
                //error={error}
                variant={'outlined'}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container item xs={12} style={{ marginTop: 10 }}>
          <Grid item xs={6} style={{ width: '100%' }}>
            <Box style={{ marginLeft: 10 }}>
              <TextField
                //fullWidth
                style={{ width: '100%' }}
                type={'text'}
                value={values.Code2}
                label={'Code 2/Description 2'}
                //error={error}
                variant={'outlined'}
                onChange={e => handleChange('Code2', e.target.value)}
              />
            </Box>
          </Grid>
          <Grid item xs={6} style={{ width: '100%' }}>
            <Box style={{ marginLeft: 10 }}>
              <TextField
                //fullWidth
                style={{ width: '100%' }}
                type={'text'}
                value={values.Description2}
                //onChange={handleChange}
                onChange={e => handleChange('Description2', e.target.value)}
                helperText={''}
                label={'Description 2'}
                //error={error}
                variant={'outlined'}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container item xs={12} style={{ marginTop: 10 }}>
          <Grid item xs={6} style={{ width: '100%' }}>
            <Box style={{ marginLeft: 10 }}>
              <TextField
                //fullWidth
                style={{ width: '100%' }}
                type={'text'}
                value={values.Code3}
                label={'Code 3/ Description 3'}
                //error={error}
                variant={'outlined'}
                onChange={e => handleChange('Code3', e.target.value)}
              />
            </Box>
          </Grid>
          <Grid item xs={6} style={{ width: '100%' }}>
            <Box style={{ marginLeft: 10 }}>
              <TextField
                //fullWidth
                style={{ width: '100%' }}
                type={'text'}
                value={values.Description3}
                //onChange={handleChange}
                onChange={e => handleChange('Description3', e.target.value)}
                helperText={''}
                label={'Description 3'}
                //error={error}
                variant={'outlined'}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container item xs={12} style={{ marginTop: 10 }}>
          <Grid item xs={6} style={{ width: '100%' }}>
            <Box style={{ marginLeft: 10 }}>
              <TextField
                //fullWidth
                style={{ width: '100%' }}
                type={'text'}
                value={values.Code4}
                label={'Code 4/ Description 4'}
                //error={error}
                variant={'outlined'}
                onChange={e => handleChange('Code4', e.target.value)}
              />
            </Box>
          </Grid>
          <Grid item xs={6} style={{ width: '100%' }}>
            <Box style={{ marginLeft: 10 }}>
              <TextField
                //fullWidth
                style={{ width: '100%' }}
                type={'text'}
                value={values.Description4}
                //onChange={handleChange}
                onChange={e => handleChange('Description4', e.target.value)}
                helperText={''}
                label={'Description 4'}
                //error={error}
                variant={'outlined'}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container item xs={12} style={{ marginTop: 10 }}>
          <Grid item xs={6} style={{ width: '100%' }}>
            <Box style={{ marginLeft: 10 }}>
              <TextField
                //fullWidth
                style={{ width: '100%' }}
                type={'text'}
                value={values.Category}
                label={'Category'}
                //error={error}
                variant={'outlined'}
                onChange={e => handleChange('Category', e.target.value)}
              />
            </Box>
          </Grid>

          <Grid item xs={2}>
            <Box style={{ marginLeft: 10 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.IsDefault}
                    onChange={e => handleChange('IsDefault', e.target.checked)}
                  />
                }
                label="Default"
              />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box style={{ marginLeft: 10 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.IsActive}
                    onChange={e => handleChange('IsActive', e.target.checked)}
                  />
                }
                label="Active"
              />
            </Box>
          </Grid>
        </Grid>
        <DialogActions>
          <div className=" mb-1 mr-3">{renderActions()}</div>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
const mapStateToProps = state => {
  return {
    dictionary: state.dictionary,
    wsClient: state.websocket,
    user: state.user,
  };
};
export default connect(mapStateToProps, {
  showSpinner,
  hideSpinner,
  handleError,
  notify,
  closeAddEventSubTypes,
})(AddEventSubType);
