import React from 'react';

function ScheduleIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      preserveAspectRatio="xMidYMid meet"
      {...props}>
      <g
        transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none">
        <path d="M93 493 c-28 -11 -10 -19 42 -19 49 1 58 -3 87 -32 28 -28 33 -39 33 -82 0 -72 -43 -115 -115 -115 -42 0 -54 5 -80 30 -35 36 -47 87 -30 130 7 17 14 24 17 18 9 -24 23 -13 23 17 0 27 -3 30 -30 30 -17 0 -30 -4 -30 -10 0 -5 4 -10 9 -10 6 0 3 -16 -5 -35 -19 -47 -18 -84 6 -122 16 -27 20 -54 22 -150 l3 -118 215 0 215 0 3 163 2 162 -100 0 c-60 0 -100 4 -100 10 0 6 40 10 101 10 l100 0 -3 43 c-3 39 -5 42 -34 45 -22 2 -34 9 -37 23 -7 26 -47 25 -54 -1 -5 -17 -14 -20 -59 -20 -36 0 -64 7 -86 20 -31 19 -87 25 -115 13z m297 -43 c0 -16 -4 -30 -10 -30 -5 0 -10 14 -10 30 0 17 5 30 10 30 6 0 10 -13 10 -30z m-70 -185 c0 -20 -5 -25 -25 -25 -20 0 -25 5 -25 25 0 20 5 25 25 25 20 0 25 -5 25 -25z m70 0 c0 -20 -5 -25 -25 -25 -20 0 -25 5 -25 25 0 20 5 25 25 25 20 0 25 -5 25 -25z m-210 -70 c0 -20 -5 -25 -25 -25 -20 0 -25 5 -25 25 0 20 5 25 25 25 20 0 25 -5 25 -25z m70 0 c0 -20 -5 -25 -25 -25 -20 0 -25 5 -25 25 0 20 5 25 25 25 20 0 25 -5 25 -25z m70 0 c0 -20 -5 -25 -25 -25 -20 0 -25 5 -25 25 0 20 5 25 25 25 20 0 25 -5 25 -25z m70 0 c0 -20 -5 -25 -25 -25 -20 0 -25 5 -25 25 0 20 5 25 25 25 20 0 25 -5 25 -25z m-210 -70 c0 -20 -5 -25 -25 -25 -20 0 -25 5 -25 25 0 20 5 25 25 25 20 0 25 -5 25 -25z m70 0 c0 -20 -5 -25 -25 -25 -20 0 -25 5 -25 25 0 20 5 25 25 25 20 0 25 -5 25 -25z m70 0 c0 -20 -5 -25 -25 -25 -20 0 -25 5 -25 25 0 20 5 25 25 25 20 0 25 -5 25 -25z m70 0 c0 -20 -5 -25 -25 -25 -20 0 -25 5 -25 25 0 20 5 25 25 25 20 0 25 -5 25 -25z" />
        <path d="M130 401 c0 -41 4 -51 26 -66 14 -9 29 -13 32 -10 4 4 -3 14 -15 22 -18 12 -23 25 -23 59 0 24 -4 44 -10 44 -5 0 -10 -22 -10 -49z" />
      </g>
    </svg>
  );
}

export default ScheduleIcon;
