import React, { createContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Content from 'Settings/Content';
import {
  findSearchGroups,
  findSearchProfileGroups,
  findSearchProfiles,
  findSecComponents,
  findSecGroupComponents,
} from '../SecFunctions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Applications from './ApplicationPermissions';
import Codes from './CodePermissions';

import { showAddSecRole, showEditSecRole } from 'reducers/DialogsReducer';

import PermissionsComponent from './PermissionsComponent';
import { hideSpinner, showSpinner } from 'reducers/UiReducer';

const useStyles = makeStyles(theme => ({
  autoComplete: {
    maxWidth: 300,
    marginTop: '10px',
  },
  tabs: {
    width: '100%',
    '& button': {
      '& > span': {
        textTransform: 'none',
      },
    },
  },
  settings: {
    height: '100%',
    overflowY: 'auto',
  },
  actions: {
    textAlign: 'right',
    marginRight: '10px',
  },
  msg: {
    marginTop: 5,
    color: '#ff1744',
  },
}));
export const PermissionContext = createContext();
function ViewPermissions(props) {
  const classes = useStyles();
  const { dataUpdate } = props;
  const [allProfiles, setAllProfiles] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [tabValue, setTabValue] = useState('applications');
  const [rolesList, setRolesList] = useState([]);
  const [allComponents, setAllComponents] = useState([]);
  const [allGroupComponents, setAllGroupComponents] = useState([]);
  const addActive = tabValue !== 'create' && tabValue !== 'code';

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    if (selectedProfile && selectedProfile.ptsProfileID) {
      fetchProfileGroups();
    }
  }, [selectedProfile]);

  const fetchData = async () => {
    try {
      props.showSpinner();
      // setAllComponents([]);
      // setAllGroupComponents([]);
      const result = await Promise.all([
        findSearchProfiles(),
        findSecComponents(),
        findSecGroupComponents(),
      ]);

      if (result.length > 0) {
        setAllProfiles(result[0] || []);
        setAllComponents(result[1] || []);
        setAllGroupComponents(result[2] || []);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      props.hideSpinner();
    }
  };
  const fetchProfileGroups = async () => {
    const allGroupOfProfile = await findSearchProfileGroups(selectedProfile.ptsProfileID);

    const roleList = allGroupOfProfile.map(item => item.ptsGroupID);
    setRolesList([...roleList]);
  };

  const renderRoles = () => {
    const handleChange = (e, newValue) => {
      setSelectedProfile(newValue);
    };
    return (
      <div style={{ display: 'flex', gap: '40px', width: '100%' }}>
        <Autocomplete
          disablePortal
          options={allProfiles}
          style={{ minWidth: '300px' }}
          className={classes.autoComplete}
          renderInput={params => <TextField {...params} label="Profiles" variant="outlined" />}
          onChange={handleChange}
          getOptionLabel={option => option.Label}
          renderOption={option => `${option.Label} ${option.Type ? ' - ' + option.Type : ''}`}
          getOptionSelected={(option, value) => option.ptsGroupID === value.ptsGroupID}
          size="small"
          value={selectedProfile}
        />
      </div>
    );
  };

  const renderTabs = () => {
    const handleTabChange = (event, newValue) => setTabValue(newValue);
    return (
      <div className={classes.settings}>
        <Tabs
          variant="scrollable"
          value={tabValue}
          onChange={handleTabChange}
          className={classes.tabs}>
          <Tab label="Applications" value="applications" />
          <Tab label="Global" value="global" />
          <Tab label="Code" value="code" />
          <Tab label="CAD" value="cad" />
          <Tab label="OMS" value="oms" />
          <Tab label="Incident" value="rms" />
          <Tab label="Evidence" value="evidence" />
          {/* <Tab label="Settings" value="settings" /> */}
          {/* <Tab label="Default" value="default" /> */}
        </Tabs>
        <PermissionContext.Provider
          value={{
            allComponents,
            allGroupComponents,
            rolesList,
            selectedProfile,
          }}>
          <div role="tabpanel" className={classes.tabPanel}>
            <Box p={3}>
              {tabValue === 'applications' && selectedProfile && <Applications />}
              {tabValue === 'global' && selectedProfile && (
                <PermissionsComponent type="Core" title="Global" />
              )}
              {tabValue === 'code' && selectedProfile && <Codes />}
              {tabValue === 'cad' && selectedProfile && (
                <PermissionsComponent type="CAD" title="CAD" />
              )}
              {tabValue === 'oms' && selectedProfile && (
                <PermissionsComponent type="OMS" title="OMS" />
              )}
              {tabValue === 'rms' && selectedProfile && (
                <PermissionsComponent type="RMS" title="Incident" />
              )}
              {tabValue === 'evidence' && selectedProfile && (
                <PermissionsComponent type="Evidence" title="Evidence" />
              )}
              {tabValue === 'settings' && selectedProfile && (
                <PermissionsComponent type="Settings" title="Settings" />
              )}
              {/* {tabValue === 'default' && <DefaultPermissions />} */}
            </Box>
          </div>
        </PermissionContext.Provider>
      </div>
    );
  };

  return (
    <>
      <Content>
        <div className="m-3">
          <h5>Combined permissions of all the roles within a profile</h5>
          {renderRoles()}
          {!selectedProfile && tabValue !== 'default' && (
            <p className={classes.msg}>Select the role to see permissions</p>
          )}

          {renderTabs()}
        </div>
      </Content>
    </>
  );
}

const mapStateToProps = state => {
  return {
    dataUpdate: state.dataUpdate,
  };
};

export default connect(mapStateToProps, {
  showAddSecRole,
  showEditSecRole,
  showSpinner,
  hideSpinner,
})(ViewPermissions);
