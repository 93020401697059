import { getService } from './service';
import { handleError } from './ErrorReducer';
import { formatDateFrom, formatDateTo } from '../utils/functions';

const UPDATE_RECIPIENTS = 'PAGING/UPDATE_RECIPIENTS';
const UPDATE_GROUPS = 'PAGING/UPDATE_GROUPS';
const UPDATE_GROUP_RECIPIENTS = 'PAGING/UPDATE_GROUP_RECIPIENTS';
const UPDATE_TEMPLATES = 'PAGING/UPDATE_TEMPLATES';

export const updateRecipients = () => async (dispatch) => {
  try {
    const service = getService();
    const recipients = await service.get({ type: 'recipients' });
    dispatch({ type: UPDATE_RECIPIENTS, recipients });
  } catch (error) {
    dispatch(handleError(error, 'Error, cannot update paging information.'));
  }
}

export const updateGroups = () => async (dispatch) => {
  try {
    const service = getService();
    const groups = await service.get({ type: 'pag-groups' });
    dispatch({ type: UPDATE_GROUPS, groups });
  } catch (error) {
    dispatch(handleError(error, 'Error, cannot update paging information.'));
  }
}

export const updateGroupsRecipients = () => async (dispatch) => {
  try {
    const service = getService();
    const groupRecipients = await service.get({ type: 'groups-recipients' });
    dispatch({ type: UPDATE_GROUP_RECIPIENTS, groupRecipients });
  } catch (error) {
    dispatch(handleError(error, 'Error, cannot update recipient groups information.'));
  }
}

export const updateTemplates = () => async (dispatch) => {
  try {
    const service = getService();
    const templates = await service.get({ type: 'pag-templates' });
    dispatch({ type: UPDATE_TEMPLATES, templates });
  } catch (error) {
    dispatch(handleError(error, 'Error, cannot update paging information.'));
  }
}

const defaultState = {
  recipients: [],
  groups: [],
  groupRecipients: null,
  templates: [],
}

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case UPDATE_RECIPIENTS:
      return { ...state, recipients: action.recipients }
    case UPDATE_GROUPS:
      return { ...state, groups: action.groups }
    case UPDATE_GROUP_RECIPIENTS:
      return { ...state, groupRecipients: action.groupRecipients }
    case UPDATE_TEMPLATES:
      return { ...state, templates: action.templates }
    default:
      break;
  }
  return state;
}

export const getRecipient = async (ptsRecipientID) => {
  const service = getService();
  return service.get({
    type: 'recipient',
    data: ptsRecipientID
  });
}

export const saveRecipient = (data) => {
  const service = getService();
  if (data.ptsRecipientID) {
    return service.patch(data.ptsRecipientID, { type: 'update-recipient', data });
  } else {
    return service.create({ type: 'create-recipient', data });
  }
}

export const removeRecipient = (ptsRecipientID) => {
  const service = getService();
  return service.patch(
    ptsRecipientID,
    { type: 'remove-recipient' }
  );
}

export const saveGroup = (data) => {
  const service = getService();
  if (data.ptsGroupID) {
    return service.patch(data.ptsGroupID, { type: 'update-pag-group', data });
  } else {
    return service.create({ type: 'create-pag-group', data });
  }
}

export const removeGroup = (ptsGroupID) => {
  const service = getService();
  return service.patch(
    ptsGroupID,
    { type: 'remove-pag-group' }
  );
}

export const getGroup = async (ptsGroupID) => {
  const service = getService();
  return service.get({
    type: 'pag-group',
    data: ptsGroupID
  });
}

export const saveTemplate = (data) => {
  const service = getService();
  if (data.ROWGUID !== true) {
    return service.patch(data.ROWGUID, { type: 'update-pag-template', data });
  } else {
    return service.create({ type: 'create-pag-template', data });
  }
}

export const removeTemplate = (ROWGUID) => {
  const service = getService();
  return service.patch(
    ROWGUID,
    { type: 'remove-pag-template' }
  );
}

export const getTemplate = (ROWGUID) => {
  const service = getService();
  return service.get({ type: 'pag-template', data: ROWGUID });
}

export const sendPage = (data) => {
  const service = getService();
  return service.get({ type: 'send-page', data });
}

export const getPagingHistory = async (dateFrom, dateTo, filter) => {
  const service = getService();
  return service.get({
    type: 'pag-history',
    data: { 
      dateFrom: formatDateFrom(dateFrom),
      dateTo: formatDateTo(dateTo),
      filter,
    }
  });
}

export const getEventPages = (EventId) => {
  const service = getService();
  return service.get({ type: 'event-pages', data: EventId });
}

export const testEmail = (data) => {
  const service = getService();
  return service.create({ type: 'test-email', data });
}

export const testSMS = (data) => {
  const service = getService();
  return service.create({ type: 'test-sms', data });
}

export const savePagingSettings = (data) => {
  const service = getService();
  return service.create({ type: 'save-paging-settings', data });
}

export const getPagingSettings = () => {
  const service = getService();
  return service.get({ type: 'paging-settings' });
}