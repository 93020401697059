import { Box, Checkbox, FormControlLabel, Grid, TextField, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import DatePicker2 from 'components/DatePicker2';
import { getService } from 'reducers/service';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { notify } from 'reducers/NotifierReducer';
import { dateTimePicker, displayDate } from 'reducers/TimeReducer';

const useStyles = makeStyles(theme => ({
  filter: {
    width: '100%',
    marginTop: '10px',
  },
  datePicker: {
    width: '100%',
    marginTop: '10px',
  },
}));
const fields = [
  { field: 'Code', headerName: 'Code', type: 'text', size: 2 },
  { field: 'RSA', headerName: 'RSA', type: 'text', size: 3 },
  { field: 'Section', headerName: 'Section', type: 'text', size: 1 },
  { field: 'Description', headerName: 'Description', type: 'text', size: 5 },
  { field: 'IsActive', headerName: 'Active', type: 'bool', size: 1 },

  { field: 'Category', headerName: 'Category', type: 'select', codes: 'codeStatuteCategory' },
  {
    field: 'Severity',
    headerName: 'Severity',
    type: 'select',
    codes: 'codeStatuteSeverity',
  },
  { field: 'Level', headerName: 'Level', type: 'select', codes: 'codeStatuteLevel', size: 2 },
  {
    field: 'Status',
    headerName: 'Status',
    type: 'select',
    codes: 'codeStatuteStatus',
    size: 2,
  },
  {
    field: 'EnactmentDate',
    headerName: 'Enactment Date',
    type: 'date',
    size: 2,
    required: true,
    valueFormatter: params => dateTimePicker(params.value),
  },
  {
    field: 'ptsFBICodeID',
    headerName: 'FBI Code',
    type: 'select-FBI',
  },
  { field: 'StatuteID', headerName: 'Statute ID', type: 'text' },
  { field: 'DNASampleRequired', headerName: 'DNA Required', type: 'bool' },
  { field: 'Jurisdiction', headerName: 'Jurisdiction', type: 'text' },
  { field: 'BondAmount', headerName: 'Bond Amount', type: 'number', size: 2 },
  { field: 'MustAppear', headerName: 'Must Appear', type: 'bool', size: 1 },
  { field: 'Keyword', headerName: 'Keyword', type: 'text', size: 4 },
  {
    field: 'RepealDate',
    headerName: 'Repeal Date',
    type: 'date',
    size: 2,
    valueFormatter: params => dateTimePicker(params.value),
  },
  { field: 'Notes', headerName: 'Notes', type: 'text', size: 12 },

  {
    field: 'AffidavitText',
    headerName: 'Affidavit Text',
    type: 'text',
    multiline: true,
    minRows: 4,
    size: 12,
  },
];
const defaultValues = {
  Code: '',
  Section: '',
  Description: '',
  Category: '',
  RSA: '',
  EnactmentDate: '',
  Jurisdiction: '',
  Keyword: '',
  Level: '',
  RepealDate: '',
  Status: '',
  AffidavitText: '',
  BondAmount: 0,
  Severity: '',
  IsActive: 1,
  MustAppear: 0,
  DNASampleRequired: 0,
  Notes: '',
  ptsFBICodeID: null,
};
const RenderStatutes = props => {
  const classes = useStyles();
  const {
    dictionary,
    wsClient,
    showSpinner,
    hideSpinner,

    notify,
  } = props;
  const { values, setValues, handleChange, mode } = props;
  const service = getService('ptsfbicode');
  const [FBICodes, setFBICodes] = useState([]);
  const [fbiLoading, setFbiLoading] = useState(false);
  const { StatuteStatus = [], StatuteSeverity = [], StatuteLevel = [], StatuteCategory = [] } =
    dictionary || {};

  const codes = {
    codeStatuteSeverity: StatuteSeverity,

    codeStatuteCategory: StatuteCategory,

    codeStatuteLevel: StatuteLevel,

    codeStatuteStatus: StatuteStatus,
  };
  useEffect(() => {
    if (wsClient) {
      getFBICodes();
    }
  }, [wsClient]);
  useEffect(() => {
    if (mode === 'add') {
      setValues(defaultValues);
    }
  }, [mode]);

  const getFBICodes = async () => {
    try {
      setFbiLoading(true);
      const result = await service.find({
        query: {
          $limit: 0,
        },
      });

      setFBICodes(result.data);
    } catch (error) {
      console.log('err', error);
    } finally {
      setFbiLoading(false);
    }
  };
  const getTextFilter = (info, idx) => (
    <Grid
      item
      xs={info.size == 6 ? 12 : 6}
      md={info.size ? info.size : 3}
      style={{ width: '100%' }}>
      <Box style={{ marginLeft: 10 }}>
        <TextField
          type="search"
          size="small"
          variant="outlined"
          multiline={info.multiline}
          rows={info.minRows || 1}
          label={info.headerName}
          key={idx}
          className={classes.filter}
          value={values[info.field] || ''}
          onChange={e => handleChange(info.field, e.target.value || '')}
        />
      </Box>
    </Grid>
  );
  const getNumberFilter = (info, idx) => (
    <Grid
      item
      xs={info.size == 6 ? 12 : 6}
      md={info.size ? info.size : 3}
      style={{ width: '100%' }}>
      <Box style={{ marginLeft: 10 }}>
        <TextField
          size="small"
          variant="outlined"
          label={info.headerName}
          type="number"
          key={idx}
          className={classes.filter}
          value={Number(values[info.field]) || 0}
          onChange={e => handleChange(info.field, e.target.value || '')}
        />
      </Box>
    </Grid>
  );

  const getDateFilter = (info, idx) => {
    const rawDate = values[info.field] || null;
    const value = rawDate && typeof rawDate === 'string' ? moment(rawDate, 'YYYY-MM-DD') : rawDate;
    return (
      <Grid
        item
        xs={info.size == 6 ? 12 : 6}
        md={info.size ? info.size : 2}
        style={{ width: '100%' }}>
        <Box style={{ marginLeft: 10 }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              margin="normal"
              error={info.required && !value}
              required={info.required || false}
              label={info.headerName}
              value={value}
              onChange={date => handleChange(info.field, date)}
              autoOk
              size="small"
              className={classes.filter}
            />
          </MuiPickersUtilsProvider>

          {/* <DatePicker2
            onChange={(n, date) => {
              const formatedDate = moment(date).format('YYYY-MM-DD');
              handleChange(info.field, date);
            }}
            label={info.headerName}
            style={{ width: '100%' }}
            margin="none"
            value={values[info.field] || null}
            className={classes.filter}
          /> */}
        </Box>
      </Grid>
    );
  };

  const getSelectFilter = (info, idx) => (
    <Grid
      item
      xs={info.size == 6 ? 12 : 6}
      md={info.size ? info.size : 3}
      style={{ width: '100%' }}>
      <Box style={{ marginLeft: 10 }}>
        <Autocomplete
          variant="outlined"
          className={classes.filter}
          key={idx}
          autoHighlight
          autoSelect
          getOptionSelected={() => true}
          size="small"
          value={{
            Code: values[info.field] || '',
          }}
          onChange={(event, newValue) => {
            handleChange(info.field, newValue?.Code || '');
          }}
          options={[{ Code: '', Description: '' }, ...codes[info.codes]]}
          getOptionLabel={option => option.Code || ''}
          renderOption={option => (
            <>
              <b>{option.Code || ''} </b> - {option.Description || ''}
            </>
          )}
          renderInput={params => (
            <TextField {...params} variant="outlined" label={info.headerName} />
          )}
        />
      </Box>
    </Grid>
  );
  const getSelectFilterForFBICode = (info, idx) => (
    <Grid item xs={12} md={6} style={{ width: '100%' }}>
      <Box style={{ marginLeft: 10 }}>
        <Autocomplete
          id="fbi-codes"
          autoHighlight
          autoSelect
          size="small"
          className={classes.filter}
          value={FBICodes.find(item => item.ptsFBICodeID === values.ptsFBICodeID) || ''}
          onChange={(event, newValue) => {
            handleChange('ptsFBICodeID', newValue?.ptsFBICodeID || '');
          }}
          options={FBICodes || []}
          getOptionLabel={option => option.Description || ''}
          getOptionSelected={(option, value) => {
            return option.ptsFBICodeID === value?.ptsFBICodeID || '';
          }}
          style={{ width: '100%' }}
          renderOption={option => (
            <>
              <b>{option.FBICode || ''} </b> - {option.Description || ''}
            </>
          )}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              label="FBI Code"
              value={params.inputProps.value}
            />
          )}
        />
      </Box>
    </Grid>
  );
  const getCheckboxFilter = (info, idx) => {
    return (
      <Grid
        item
        xs={info.size == 6 ? 12 : 6}
        md={info.size ? info.size : 3}
        style={{ width: '100%', marginTop: '5px' }}
        className={classes.filter}
        id={idx}>
        <Box style={{ marginLeft: 10 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={values[info.field]}
                onChange={e => handleChange(info.field, e.target.checked)}
              />
            }
            label={info.headerName}
          />
        </Box>
      </Grid>
    );
  };
  return (
    <>
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        {fields.map((field, id) => {
          switch (field.type) {
            case 'text':
              return getTextFilter(field, id);
            case 'select':
              return getSelectFilter(field, id);
            case 'bool':
              return getCheckboxFilter(field, id);
            case 'date':
              return getDateFilter(field, id);
            case 'number':
              return getNumberFilter(field, id);
            case 'select-FBI':
              return getSelectFilterForFBICode(field, id);
          }
        })}
      </Grid>
    </>
  );
};
const mapStateToProps = state => {
  return {
    dictionary: state.dictionary,
    wsClient: state.websocket,
    user: state.user,
  };
};
export default connect(mapStateToProps, {
  showSpinner,
  hideSpinner,

  notify,
})(RenderStatutes);
