import React from 'react';
import { Fab, Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

export default function EditButton(props) {
  const { onClick, disabled } = props;
  return (
    <Tooltip title={props.tooltipText || 'Edit'}>
      <Fab size="small" disabled={disabled} color="secondary" aria-label="edit">
        <EditIcon onClick={onClick} />
      </Fab>
    </Tooltip>
  );
}
