import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Content from 'Settings/Content';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import { getService } from 'reducers/service';
import { handleError } from 'reducers/ErrorReducer';
import { notify } from 'reducers/NotifierReducer';
import Autocomplete2 from 'components/Autocomplete2';
import { gridStyle, Row, RowInner, ColCard, Fills } from 'utils/formStyles';
import TextField2 from 'components/TextField2';
import Checkbox2 from 'components/Checkbox2';
import { InputAdornment, TextField, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  item: {
    ...gridStyle(200, 800),
  },
}));

function UnitInactiveTime(props) {
  const { wsClient } = props;
  const classes = useStyles();
  const service = getService('cdr');
  const [inactiveTimeLimit, setInactiveTimeLimit] = useState(null);
  const [editMode, setEditMode] = useState(false);
  useEffect(() => {
    if (wsClient.websocket) {
      search();
    }
  }, [wsClient]);

  const search = async () => {
    const data = await service.get({
      Path: 'Unit.InactivationTime',
      CDRKey: 'TimeLimit',
    });

    if (data && data.length > 0) {
      setEditMode(true);
      setInactiveTimeLimit(data[0].ValueNumeric || 0);
    }
  };

  const handleSaveClick = async () => {
    // const data = formatSaveData();
    try {
      if (editMode) {
        await service.patch(null, {
          Path: 'Unit.InactivationTime',
          CDRKey: 'TimeLimit',
          ValueNumeric: Number(inactiveTimeLimit),
        });
        props.notify('Time Saved.', 'success');
      } else {
        await service.create({
          Path: 'Unit.InactivationTime',
          CDRKey: 'TimeLimit',
          ValueNumeric: Number(inactiveTimeLimit),
        });
        props.notify('Time Saved.', 'success');
      }
    } catch (err) {
      props.handleError(err, 'Error Saving Time.');
    }
  };

  const renderInactiveTime = () => {
    return (
      <TextField
        label="Time Limit"
        id="outlined-inactive-time"
        style={{ width: '250px' }}
        variant="outlined"
        size="small"
        type="number"
        value={inactiveTimeLimit || ''}
        onChange={e => setInactiveTimeLimit(e.target.value || 0)}
        InputProps={{
          endAdornment: <InputAdornment position="start">Minutes</InputAdornment>,
        }}
      />
    );
  };

  const renderSaveBtn = () => {
    return (
      <Button onClick={handleSaveClick} variant="contained" className="mt-4" color="primary">
        <SaveIcon className="mr-2" /> Save
      </Button>
    );
  };

  return (
    <Content>
      {/* <div className="m-4">
        <Typography variant="h5" className="mb-2">
          Inactivation Time Limit
        </Typography>

        <div style={{ width: '100%', textAlign: 'right' }}>{renderSaveBtn()}</div>

        <div style={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
          <Typography variant="h6" className="mb-2">
            Do not display unit on the map if inactive for
          </Typography>
          {renderInactiveTime()}
        </div>
      </div> */}
    </Content>
  );
}

const mapStateToProps = state => {
  return {
    wsClient: state.websocket,
  };
};

export default connect(mapStateToProps, {
  handleError,
  notify,
})(UnitInactiveTime);
