import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Content from './Content';
import PagingSetup from './PagingSetup';

const useStyles = makeStyles((theme) => ({
  
}));

function Notifications(props) {
  const classes = useStyles();
  return (
    <Content>
      <PagingSetup />
    </Content>
  );
}

export default Notifications;
