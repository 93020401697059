
    import React from 'react';
    import SvgIcon from '@material-ui/core/SvgIcon';
    
    function icons8fight100Icon(props) {
      return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="100" height="100"><path d="M8 2.004a2 2 0 0 0-2 2 2 2 0 0 0 2 2 2 2 0 0 0 2-2 2 2 0 0 0-2-2zm12 1a2 2 0 0 0-2 2 2 2 0 0 0 2 2 2 2 0 0 0 2-2 2 2 0 0 0-2-2zm-4.842 1.623-9.02 2.379a1.002 1.002 0 0 0-.536.357l-2.329 3.028a.995.995 0 0 0-.02 1.19 1.01 1.01 0 0 0 1.136.366l2.845-.978-2.015 1.633-.174 2.931L2.979 22h2.1l1.892-5.924c.009-.029.31-1.203.31-1.203l.703 1.988c.005.04.016.078.016.118V22h2v-5.021c0-.271-.037-.54-.11-.803l-.546-2.86.834-5.277 5.492-1.478-.512-1.934zm2.524 2.428a.987.987 0 0 0-.34.13l-3.24 1.997-3.498.992.544 1.924 3.633-1.032c.088-.025.174-.063.252-.11l1.805-1.11-1.47 2.96-1.718 3.243a1.01 1.01 0 0 0-.13.422L13.076 22h2.006l.412-5.146.037-.061 1.51-1.902L19.086 22h2.062l-1.67-6.732.588-1.875-1.191-1.764 2.617 1.685a1 1 0 0 0 1.475-1.185L21.54 8.2a1 1 0 0 0-.758-.642l-2.736-.504a1.013 1.013 0 0 0-.365 0z"/></SvgIcon>
      );
    }
  
    export default icons8fight100Icon;