
    import React from 'react';
    import SvgIcon from '@material-ui/core/SvgIcon';
    
    function icons8bankcardmissing100Icon(props) {
      return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="100" height="100"><path d="M4 5a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h10.23a8.97 8.97 0 0 1-.23-2 9 9 0 0 1 14-7.482V7a2 2 0 0 0-2-2H4zm1 3h5v4H5V8zm10 1h9a1 1 0 0 1 0 2h-9a1 1 0 0 1 0-2zm8 7a7 7 0 1 0 0 14 7 7 0 0 0 0-14zm0 2a2.763 2.763 0 0 1 2.76 2.76c0 1.182-.74 1.863-1.334 2.412-.457.422-.737.697-.737 1.033h-1.378c0-.958.653-1.56 1.18-2.047.55-.509.888-.85.888-1.4A1.38 1.38 0 0 0 23 19.378a1.38 1.38 0 0 0-1.379 1.38h-1.379A2.76 2.76 0 0 1 23 18zM5 19h3v2H5a1 1 0 0 1 0-2zm5 0h4v2h-4v-2zm13 6.932A1.034 1.034 0 1 1 22.998 28 1.034 1.034 0 0 1 23 25.932z"/></SvgIcon>
      );
    }
  
    export default icons8bankcardmissing100Icon;