import React from 'react';
import ankleBraceletIcon from './ankle-bracelet-50';
import army62Icon from './army-62';
import army67Icon from './army-67';
import army67Icon2 from './army-67-(1)';
import businessDocumentationIcon from './business-documentation-50';
import child50Icon from './child-50';
import child50Icon2 from './child-50-(1)';
import cityHallIcon from './city-hall-50';
import courthouseIcon from './courthouse-50';
import crime50Icon from './crime-50';
import crosswalkIcon from './crosswalk-50';
import deafIcon from './deaf-50';
import disclaimerIcon from './disclaimer-50';
import fistIcon from './fist-50';
import gangIcon from './gang-50';
import grandfatherIcon from './grandfather-50';
import highRiskIcon from './high-risk-50';
import lawCourtIcon from './law-court-50';
import masterSergeantIcon from './master-sergeant-msg-50';
import medicalIcon from './medical-50';
import meetingIcon from './meeting-50';
import MentalHealthIcon from './mental-health';
import NamesIcon from './names-50';
import PoliceIcon from './police-50';
import ScheduleIcon from './schedule-50';
import SexOffenderIcon from './sex-offender-50';
import SkullCrossboneIcon from './skull-crossbones-50';
import SoldierManIcon from './soldier-man-50';
import PaperIcon from './paper-50';
import PassFailIcon from './pass-fail-50';
import PoliceBadgeIcon from './police-badge-50';
import PoliticianIcon from './politician-50';
import SheriffIcon from './sheriff-50';

function EmptyIcon() {
  return <span style={{ color: 'black' }}>-</span>;
}

export default {
  0: EmptyIcon,
  1: ankleBraceletIcon,
  2: army62Icon,
  3: army67Icon,
  4: army67Icon2,
  5: businessDocumentationIcon,
  6: child50Icon,
  7: child50Icon2,
  8: cityHallIcon,
  9: courthouseIcon,
  10: crime50Icon,
  11: crosswalkIcon,
  12: deafIcon,
  13: disclaimerIcon,
  14: fistIcon,
  15: gangIcon,
  16: grandfatherIcon,
  17: highRiskIcon,
  18: lawCourtIcon,
  19: masterSergeantIcon,
  20: medicalIcon,
  21: meetingIcon,
  22: MentalHealthIcon,
  23: NamesIcon,
  24: PoliceIcon,
  25: ScheduleIcon,
  26: SexOffenderIcon,
  27: SkullCrossboneIcon,
  28: SoldierManIcon,
  29: PaperIcon,
  30: PassFailIcon,
  31: PoliceBadgeIcon,
  32: PoliticianIcon,
  33: SheriffIcon,
};
