import React from 'react';

function CourtHouseIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" {...props}>
      <g
        transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none">
        <path d="M130 399 c-109 -86 -143 -120 -106 -106 14 6 16 -8 16 -128 l0 -135 210 0 210 0 0 135 c0 120 2 134 16 128 38 -14 3 21 -106 106 -63 50 -117 91 -120 91 -3 0 -57 -41 -120 -91z m130 -80 c0 -5 5 -7 11 -3 6 3 28 1 49 -6 34 -11 41 -20 65 -77 27 -61 27 -64 10 -83 -23 -26 -67 -26 -90 0 -17 19 -17 21 4 69 26 62 26 68 -1 76 -16 6 -26 1 -40 -16 l-18 -24 -18 24 c-14 17 -24 22 -40 16 -27 -8 -27 -14 -1 -76 21 -48 21 -50 4 -69 -23 -26 -67 -26 -90 0 -17 19 -17 22 10 83 24 57 31 66 65 77 21 7 43 9 49 6 6 -4 11 -2 11 3 0 6 5 11 10 11 6 0 10 -5 10 -11z" />
        <path d="M132 205 c-16 -35 -16 -35 18 -35 17 0 30 2 30 5 0 11 -24 55 -30 55 -4 0 -12 -11 -18 -25z" />
        <path d="M332 205 c-16 -35 -16 -35 18 -35 17 0 30 2 30 5 0 11 -24 55 -30 55 -4 0 -12 -11 -18 -25z" />
      </g>
    </svg>
  );
}

export default CourtHouseIcon;
