import React, { createContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Content from 'Settings/Content';
import { findSearchGroups, findSecComponents, findSecGroupComponents } from '../SecFunctions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Applications from './ApplicationPermissions';
import Codes from './CodePermissions';
import CreateNewPermissions from './CreateNewPermissions';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';
import { showAddSecRole, showEditSecRole } from 'reducers/DialogsReducer';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Tooltip from 'components/Tooltip';
// import DefaultPermissions from './DefaultPermissions';
import PermissionsComponent from './PermissionsComponent';
import AddPermission from './AddPermission';
import { hideSpinner, showSpinner } from 'reducers/UiReducer';
import { useParams } from 'react-router';

import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import {} from '@material-ui/core';
import { Button } from '@mui/material';
import Dialog from 'components/Dialog';
import ReportPermission from './ReportPermission';

const useStyles = makeStyles(theme => ({
  autoComplete: {
    maxWidth: 300,
    marginTop: '10px',
  },
  tabs: {
    width: '100%',
    '& button': {
      '& > span': {
        textTransform: 'none',
      },
    },
  },
  settings: {
    height: '100%',
    overflowY: 'auto',
  },
  actions: {
    textAlign: 'right',
    marginRight: '10px',
  },
  msg: {
    marginTop: 5,
    color: '#ff1744',
  },
}));
export const MainPermissionContext = createContext();

function Permissions(props) {
  const classes = useStyles();
  const { dataUpdate } = props;
  const [allGroups, setAllGroups] = useState([]);
  const [group, setGroup] = useState(null);
  const [tabValue, setTabValue] = useState('applications');
  const [showAddPermission, setShowAddPermission] = useState(false);
  const addActive = tabValue !== 'create' && tabValue !== 'code';
  const [roleAgencyID, setRoleAgencyID] = useState('');
  const [allComponents, setAllComponents] = useState([]);
  const [allGroupComponents, setAllGroupComponents] = useState([]);
  const [refreshComponets, setRefreshComponent] = useState(false);
  const { role: selectedRole } = useParams();
  const [loaded, setLoaded] = useState(false);
  const [openCopyConfirmation, setOpenCopyConfirmation] = useState(false);
  useEffect(() => {
    fetchData();
  }, [refreshComponets]);
  useEffect(() => {
    if (selectedRole && loaded) {
      const role = allGroups.find(item => item.ptsGroupID == selectedRole);
      if (role) {
        handleRoleChange(null, role);
      }
    }
  }, [selectedRole, loaded]);
  useEffect(() => {
    if (dataUpdate && (dataUpdate.type === 'add-role' || dataUpdate.type === 'edit-role'))
      fetchData();
  }, [dataUpdate]);

  const fetchData = async () => {
    try {
      setLoaded(false);
      props.showSpinner();
      // setAllComponents([]);
      // setAllGroupComponents([]);
      const result = await Promise.all([
        findSearchGroups(),
        findSecComponents(),
        findSecGroupComponents(),
      ]);

      if (result.length > 0) {
        setAllGroups(result[0] || []);
        setAllComponents(result[1] || []);
        setAllGroupComponents(result[2] || []);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoaded(true);
      props.hideSpinner();
    }
  };
  const addSecRole = () => {
    setOpenCopyConfirmation(false);
    props.showAddSecRole({ type: 'copy-role', ptsGroupID: group.ptsGroupID });
  };

  const editSecRole = () => props.showEditSecRole(group.ptsGroupID);

  const handleRoleChange = (e, newValue) => {
    setRoleAgencyID(newValue?.AgencyID || '');
    setGroup(newValue);
  };
  const handleCloseCopyConfirmation = () => {
    setOpenCopyConfirmation(false);
  };

  const renderCopyRoleActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={addSecRole}>
          <SaveIcon /> Confirm
        </Button>
        <Button color="primary" onClick={handleCloseCopyConfirmation}>
          <CloseIcon /> Cancel
        </Button>
      </>
    );
  };
  const renderRoles = () => {
    return (
      <div style={{ display: 'flex', gap: '40px', width: '100%', alignItems: 'center' }}>
        <Autocomplete
          // disablePortal
          options={allGroups}
          style={{ minWidth: '300px' }}
          className={classes.autoComplete}
          renderInput={params => <TextField {...params} label="Roles" variant="outlined" />}
          onChange={handleRoleChange}
          getOptionLabel={option => option.Label}
          renderOption={option => `${option.Label} ${option.Type ? ' - ' + option.Type : ''}`}
          getOptionSelected={(option, value) => option.ptsGroupID === value.ptsGroupID}
          size="small"
          value={group}
        />
        {roleAgencyID && (
          <p className="mt-4">
            Agency ID: <strong>{roleAgencyID}</strong>
          </p>
        )}
        {group && (
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => setOpenCopyConfirmation(true)}
            startIcon={<FileCopyIcon />}
            style={{ width: '130px', maxHeight: '30px' }}>
            Copy Role
          </Button>
        )}
      </div>
    );
  };

  const renderTabs = () => {
    const handleTabChange = (event, newValue) => setTabValue(newValue);
    return (
      <div className={classes.settings}>
        <Tabs
          variant="scrollable"
          value={tabValue}
          onChange={handleTabChange}
          className={classes.tabs}>
          <Tab label="Applications" value="applications" />
          <Tab label="Global" value="global" />
          <Tab label="Code" value="code" />
          <Tab label="CAD" value="cad" />
          <Tab label="OMS" value="oms" />
          <Tab label="Incident" value="rms" />
          <Tab label="Evidence" value="evidence" />
          <Tab label="Reports" value="reports" />
          {/* <Tab label="Settings" value="settings" /> */}
          {/* <Tab label="Default" value="default" /> */}
          <Tab label="Create New" value="create" />
        </Tabs>
        <MainPermissionContext.Provider
          value={{
            role: group,
            allComponents,
            allGroupComponents,
            refreshComponets,
            setRefreshComponent,
          }}>
          <div role="tabpanel" className={classes.tabPanel}>
            <Box p={3}>
              {tabValue === 'applications' && group && <Applications />}
              {tabValue === 'global' && group && (
                <PermissionsComponent type="Core" title="Global" />
              )}
              {tabValue === 'code' && group && <Codes />}
              {tabValue === 'cad' && group && <PermissionsComponent type="CAD" title="CAD" />}
              {tabValue === 'oms' && group && <PermissionsComponent type="OMS" title="OMS" />}
              {tabValue === 'rms' && group && <PermissionsComponent type="RMS" title="Incident" />}
              {tabValue === 'evidence' && group && (
                <PermissionsComponent type="Evidence" title="Evidence" />
              )}
              {tabValue === 'settings' && group && (
                <PermissionsComponent type="Settings" title="Settings" />
              )}

              {tabValue === 'reports' && group && (
                <ReportPermission type="Report" title="Reports" />
              )}
              {/* {tabValue === 'default' && <DefaultPermissions />} */}
              {tabValue === 'create' && <CreateNewPermissions />}
            </Box>
          </div>
        </MainPermissionContext.Provider>
      </div>
    );
  };

  return (
    <>
      <Content>
        <div className="m-3">
          <h5>Roles</h5>
          {renderRoles()}
          {!group && tabValue !== 'default' && tabValue !== 'create' && (
            <p className={classes.msg}>Select the role to see permissions</p>
          )}
          {/* <div className={classes.actions}>
            <Tooltip title="Add New Role" onClick={addSecRole}>
              <Fab size="small" color="secondary">
                <AddIcon />
              </Fab>
            </Tooltip>
            <Tooltip title="Edit Role" className="ml-2">
              <span>
                <Fab size="small" color="secondary" disabled={!group} onClick={editSecRole}>
                  <EditIcon />
                </Fab>
              </span>
            </Tooltip>
          </div> */}
          <h5 className="mt-4">Permissions</h5>
          <div className={classes.actions}>
            <Tooltip title="Add New Permission">
              <span>
                <Fab
                  size="small"
                  color="secondary"
                  onClick={() => setShowAddPermission(true)}
                  disabled={!addActive}>
                  <AddIcon />
                </Fab>
              </span>
            </Tooltip>
          </div>
          {renderTabs()}
        </div>
      </Content>
      {showAddPermission && (
        <AddPermission close={() => setShowAddPermission(false)} tabValue={tabValue} />
      )}
      {
        <Dialog
          open={openCopyConfirmation}
          onClose={handleCloseCopyConfirmation}
          title={'Copy And Create New Roles'}
          actions={renderCopyRoleActions()}
          toolbar>
          Do you want to copy this role and create new one?
        </Dialog>
      }
    </>
  );
}

const mapStateToProps = state => {
  return {
    dataUpdate: state.dataUpdate,
  };
};

export default connect(mapStateToProps, {
  showAddSecRole,
  showSpinner,
  hideSpinner,
  showEditSecRole,
})(Permissions);
