
    import React from 'react';
    import SvgIcon from '@material-ui/core/SvgIcon';
    
    function icons8magnifyingglass100Icon(props) {
      return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="100" height="100"><path d="M252.844 11.117a214.728 214.728 0 0 0-59.787 419.961 214.5 214.5 0 0 0 214.798-53.564 214.653 214.653 0 0 0 0-303.631 214.728 214.728 0 0 0-155.011-62.766zM256 45.365c99.623 0 180.383 80.737 180.383 180.332S355.623 406.03 256 406.03 75.617 325.292 75.617 225.697 156.377 45.365 256 45.365zm97.256 83.006a35.352 35.352 0 0 0-24.483 10.352l-93.06 93.062a15.792 15.792 0 0 1-22.334 0l-30.137-30.139a35.362 35.362 0 0 0-50.01 0 35.362 35.362 0 0 0 0 50.01l57.327 57.326a47.952 47.952 0 0 0 67.795.02L378.756 188.73a35.352 35.352 0 0 0 .014-50.007 35.352 35.352 0 0 0-25.514-10.352zM197.416 445.11v37.387A18.53 18.53 0 0 0 215.93 501h80.142a18.53 18.53 0 0 0 18.512-18.504V445.11a229.945 229.945 0 0 1-117.168 0z"/></SvgIcon>
      );
    }
  
    export default icons8magnifyingglass100Icon;