import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Content from 'Settings/Content';

import { getService } from 'reducers/service';
import { handleError } from 'reducers/ErrorReducer';
import { notify } from 'reducers/NotifierReducer';

import { gridStyle } from 'utils/formStyles';
import { XGrid } from '@material-ui/x-grid';
import { Fab } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { showCodeCategories } from 'reducers/DialogsReducer';
import QuickFilter from 'components/QuickFilter';
import { displayDateTime } from 'reducers/TimeReducer';
import MenuList from '../LookupCodes/Menulist';

const useStyles = makeStyles(theme => ({
  item: {
    ...gridStyle(200, 800),
  },
  list: {
    width: '100%',
    height: 500,
  },
  wrap: {
    padding: theme.spacing(2),
    '& > h5': {
      marginBottom: 20,
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: `calc(100vh - 240px)`,
  },
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0, 2),
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
}));
const columnsItems = [
  { field: 'CategoryCode', headerName: 'Code', width: 150 },
  { field: 'CategoryKey', headerName: 'Category Type', width: 250 },
  { field: 'Description', headerName: 'Description', width: 150 },

  { field: 'IsDefault', headerName: 'Default', width: 150 },
  { field: 'IsDeleted', headerName: 'Deleted', width: 150 },
  { field: 'IsActive', headerName: 'Active', width: 150 },
  { field: 'Flags', headerName: 'Flags', width: 100 },

  { field: 'Description2', headerName: 'Description 2', width: 150 },

  { field: 'Description3', headerName: 'Description 3', width: 150 },

  { field: 'UpdatedBy', headerName: 'UpdatedBy', width: 150 },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 150,
    valueFormatter: params => displayDateTime(params.value),
    format: 'date',
  },
  { field: 'DeletedBy', headerName: 'Deleted By', width: 150 },
  { field: 'CreatedBy', headerName: 'Created By', width: 150 },
];

function CodeCategories(props) {
  const { wsClient, addEditCodeCategories } = props;
  const classes = useStyles();
  const service = getService('codecategories');
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (addEditCodeCategories == null) {
      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsClient.websocket, addEditCodeCategories]);

  const fetchData = async () => {
    setLoaded(false);
    service.timeout = 20000;
    try {
      const formDetails = await service.find({
        query: {
          $limit: 0,
          IsDeleted: 0,
          $sort: {
            CategoryKey: 1,
          },
        },
      });
      // console.log('formDetails', formDetails);
      const newList = formDetails.data.map(item => {
        return { ...item, id: item.ptsCategoryID };
      });
      setRows(newList);
    } catch (error) {
      console.log(error);
    } finally {
      setLoaded(true);
    }
  };
  const handleSelectChange = ({ selectionModel }) => {
    setSelection(selectionModel);
  };

  const onClickAdd = () => {
    dispatch(showCodeCategories({ type: 'add' }));
  };
  const onClickEdit = () => {
    const obj = rows.find(row => row.ptsCategoryID == selection[0]);

    dispatch(showCodeCategories({ type: 'edit', info: obj }));
  };
  const onClickDelete = async () => {
    const removeAgency = await service.remove(selection[0]);
    const newRows = rows.filter(el => el.ptsCategoryID !== removeAgency.ptsCategoryID);
    setRows(newRows);
    props.notify('Deleted', 'warning');
    setSelection([]);
  };
  const renderActions = () => {
    const disabled = selection.length === 0;
    const selected = rows.find(a => a.id === selection[0]);
    //const dltDisabled = selected?.Icon === 0;
    return (
      <div className={classes.actions}>
        <div>
          <QuickFilter rows={rows} columns={columnsItems} setFilteredRows={setFilteredRows} />
        </div>
        <div>
          <Fab size="small" color="secondary" onClick={onClickAdd}>
            <AddIcon />
          </Fab>
          <Fab size="small" color="secondary" onClick={onClickEdit} disabled={disabled}>
            <EditIcon />
          </Fab>
          <Fab size="small" color="secondary" onClick={onClickDelete} disabled={disabled}>
            <DeleteIcon />
          </Fab>
        </div>
      </div>
    );
  };
  return (
    <Content>
      <div className="m-4">
        <h4>Code Categories</h4>
        {renderActions()}
        <div className={classes.gridWrap}>
          <XGrid
            columns={columnsItems}
            rows={filteredRows}
            loading={!loaded}
            rowHeight={38}
            getRowId={row => row.ptsCategoryID}
            // checkboxSelection
            onSelectionModelChange={handleSelectChange}
          />
        </div>
      </div>
    </Content>
  );
}

const mapStateToProps = state => {
  return {
    wsClient: state.websocket,
    addEditCodeCategories: state.dialogs.addEditCodeCategories,
  };
};

export default connect(mapStateToProps, {
  handleError,
  notify,
})(CodeCategories);
