import { Button, Grid, TextField, makeStyles } from '@material-ui/core';

import React, { useState } from 'react';
import { ColCard, Row, RowInner } from 'utils/formStyles';
import { getFields } from './helperFunctions';
const useStyles = makeStyles(theme => ({
  item: {
    width: '100%',
    flex: '80%',
    marginTop: '-15px',
  },
  label: {
    padding: '10px',
  },
}));
const ShowMessage = props => {
  const classes = useStyles();
  const { inputText, values, typeTCP } = props;
  const [parsedFormData, setParsedFormData] = useState({});
  const handleShow = () => {
    const newObj = {};
    getFields(typeTCP ? '2' : '1', true).forEach(field => {
      if (values[field.Path]) {
        if (typeTCP) {
          const node = values[field.Path]['XmlNode'];
          if (node) {
            newObj[field.Path] = extractWordBetweenWords(node);
          }
        } else {
          const start = parseInt(values[field.Path]['StartPosition']);
          const end = parseInt(values[field.Path]['EndPosition']);

          if ((start === 0 || start) && end) {
            newObj[field.Path] = inputText.slice(start, end + 1);
          }
        }
      }
    });
    setParsedFormData(newObj);
  };
  const extractWordBetweenWords = node => {
    const startIndex = inputText.indexOf(`<${node}>`);
    const endIndex = inputText.indexOf(`</${node}>`);

    if (startIndex !== -1 && endIndex !== -1 && startIndex < endIndex) {
      const startSlice = startIndex + `<${node}>`.length;
      const extractedWord = inputText.substring(startSlice, endIndex).trim();
      return extractedWord;
    }

    return '';
  };
  return (
    <div style={{ width: '100%', padding: '10px' }}>
      <Grid xs={12} style={{ textAlign: 'right', marginBottom: '10px' }}>
        <Button color="primary" variant="contained" onClick={handleShow}>
          Show
        </Button>
      </Grid>
      {getFields(typeTCP ? '2' : '1', true).map(field => (
        <Row className="pb-2">
          <ColCard minWidth={500}>
            <RowInner
              style={{
                justifyContent: 'space-between',
                width: '100%',
                gap: '25px',
                alignItems: 'center',
              }}>
              <p className={classes.label}>{field.label}</p>

              <TextField
                variant="outlined"
                value={parsedFormData[field.Path] || ''}
                size="small"
                className={classes.item}
              />
            </RowInner>
          </ColCard>
        </Row>
      ))}
    </div>
  );
};

export default ShowMessage;
