import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  findSearchProfileGroups,
  findSecComponents,
  findSecGroupComponents,
  saveGroupBooleans,
} from '../SecFunctions';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import { handleError } from 'reducers/ErrorReducer';
import { notify } from 'reducers/NotifierReducer';
import { Fills } from 'utils/formStyles';
import { isDevelopment } from 'utils/functions';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { PermissionContext } from './index';
import FormDivider from 'components/FormDivider';
import { sortPPlusModules } from '../Permissions/ApplicationPermissions';

const useStyles = makeStyles(theme => ({
  checkStyle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '5px',
  },
  checkStyleForpPlus: {
    border: `1px solid ${theme.colors.border}`,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '5px',
    borderRadius: '15px',
    marginBottom: '10px',
  },
  item: {
    flex: '1 1 250px',
    margin: '5px',
  },
  saveBtn: {
    textAlign: 'right',
  },
}));

function PermissionsApps(props) {
  const classes = useStyles();

  const { allComponents = [], allGroupComponents, rolesList, selectedProfile } = useContext(
    PermissionContext
  );
  const [platinumPlusModules, setPlatinumPlusComponents] = useState([]);

  const [allModules, setAllModules] = useState([]);
  const [currentRoleWithModules, setCurrentRoleWithModules] = useState(null);
  const [existingModuleIds, setExistingModuleIds] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    ProcessData();
  }, [selectedProfile, allComponents, allGroupComponents, rolesList]);

  const ProcessData = async () => {
    props.showSpinner();

    const filteredComponets = allComponents.filter(c => c.Type === 'Modules');
    const p_plusModules = [];
    const other_modules = [];
    filteredComponets.forEach(component => {
      if (component?.Flags?.trim() === 'P+') {
        p_plusModules.push(component);
      } else {
        other_modules.push(component);
      }
    });
    setAllModules(other_modules);
    setPlatinumPlusComponents(sortPPlusModules(p_plusModules));

    const filtered = allGroupComponents.filter(
      el => rolesList.includes(el.ptsGroupID) && el.Permissions === 1
    );

    //console.log('all group components ', allGroupComponents);
    const modules = [];
    for (const module of filteredComponets) {
      const found = filtered.find(el => module.ptsComponentID === el.ptsComponentID);
      if (found) modules.push(module.ptsComponentID);
    }

    const newRole = { ...selectedProfile, Applications: modules };
    setCurrentRoleWithModules(newRole);

    const existingComponents = allGroupComponents.filter(el => rolesList.includes(el.ptsGroupID));
    const existingIds = [];
    for (const module of filteredComponets) {
      const found = existingComponents.find(el => module.ptsComponentID === el.ptsComponentID);
      if (found) existingIds.push(module.ptsComponentID);
    }
    setExistingModuleIds(existingIds);
    if (initialLoad) {
      props.hideSpinner();
      setInitialLoad(false);
    } else {
      setTimeout(() => {
        props.hideSpinner();
      }, 1000);
    }
  };

  const handleChecked = module => {
    if (!currentRoleWithModules) return false;
    const currentModulesIds = [...currentRoleWithModules.Applications];
    const found = currentModulesIds.find(modId => modId === module.ptsComponentID);
    return Boolean(found);
  };

  const renderModule = module => {
    const label = isDevelopment ? `${module.Label} (${module.ptsComponentID})` : module.Label;
    return (
      <FormControlLabel
        control={<Checkbox checked={handleChecked(module)} color="primary" />}
        label={label}
      />
    );
  };

  return (
    <div>
      <FormDivider title="Platinum Plus" textOnly={true} />
      <div className={classes.checkStyleForpPlus}>
        {platinumPlusModules.map(m => (
          <span key={m.ptsComponentID} className={classes.item}>
            {renderModule(m)}
          </span>
        ))}
        <Fills className={classes.item} />
      </div>
      <FormDivider title="Others" textOnly={true} />

      <div className={classes.checkStyle}>
        {allModules.map(m => (
          <span key={m.ptsComponentID} className={classes.item}>
            {renderModule(m)}
          </span>
        ))}
        <Fills className={classes.item} />
      </div>
    </div>
  );
}

export default connect(null, { handleError, notify, showSpinner, hideSpinner })(PermissionsApps);
