import React from 'react';
import Content from './Content';

function SettingsPage() {
  return (
    <Content></Content>
  );
}

export default SettingsPage;
