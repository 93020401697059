import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { findPartyPerson, getPersonContactInfo } from '../reducers/EventsReducer';
import { handleError } from '../reducers/ErrorReducer';

const useStyles = makeStyles(theme => ({
  edit: {
    padding: theme.spacing(2),
    paddingBottom: 12,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  form: {
    margin: '0 -4px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexGrow: 1,
  },
  textField: {
    margin: '0 4px 8px',
  },
  w180: {
    maxWidth: '100%',
    flexBasis: 180,
    minWidth: 180,
    flexGrow: 1
  },
  w240: {
    maxWidth: '100%',
    flexBasis: 240,
    minWidth: 240,
    flexGrow: 1
  },
  w320: {
    maxWidth: '100%',
    flexBasis: 320,
    minWidth: 320,
    flexGrow: 1
  },
  w120x180: {
    maxWidth: 180,
    flexBasis: 120,
    minWidth: 120,
    flexGrow: 1
  },
  search: {
    marginBottom: 10,
  },
  checkbox: {
    margin: '0 4px 4px',
  },
  activeWarrant: {
    background: theme.palette.error.main,
    color: theme.card.bg,
    width: 'calc(100% + 32px)',
    display: 'inline-block',
    padding: '8px 16px',
    margin: '-8px -16px',
  },
}));

function PersonSearchSimple(props) {
  const classes = useStyles();
  const throttleRef = useRef(0);
  const [searchMatch, setSearchMatch] = useState([]);
  const [personData, setPersonData] = useState(null);

  const searchPerson = (text) => {
    clearTimeout(throttleRef.current);
    throttleRef.current = setTimeout(() => {
      findPartyPerson(text)
        .then(result => setSearchMatch(result))
        .catch(err => props.handleError(err, 'Cannot contact API'));
    }, 500);
  }

  const onSearchSelect = (ev, selection) => {
    if (!selection) {
      setPersonData(null);
      props.onPersonChange(null);
      return;
    }
    setPersonData(selection);
    getPersonContactInfo(selection.ptsPersonID)
      .then(result => {
        props.onPersonChange({ ...selection, ContactInfo: result });
      })
      .catch(err => props.handleError(err, 'Cannot contact API'));
  }

  const getOptionSelected = (option, value) => {
    return option.ptsPersonID === value.ptsPersonID
  }

  return (
    <Autocomplete
      disabled={props.disabled}
      fullWidth
      options={searchMatch}
      value={personData}
      getOptionLabel={(option) => option.FullName}
      className={clsx(classes.textField, props.className)}
      size="small"
      onChange={onSearchSelect}
      onInputChange={(ev, val) => searchPerson(val)}
      renderInput={(params) => <TextField {...params} label="Search Person" variant="outlined" />}
      getOptionSelected={getOptionSelected}
      renderOption={option => <span
        className={option.ActiveWarrant ? classes.activeWarrant : null}>
          {option.LastName}, {option.FirstName}
        </span>
      }
    />
  );
  
}

export default connect(null, { handleError })(PersonSearchSimple);