import { formatDate } from 'utils/functions';

export const getColumnsName = key => {
  switch (key) {
    case 'cadUnit':
      return cadUnits;
    case 'secUser':
      return secUsers;
    case 'secGroup':
      return secGroup;
    case 'cadDispositions':
      return cadDisposition;
    case 'incDispositions':
      return incDisposition;
    case 'ptsCustomAgencyID':
      return customIDs;
    case 'CitationDefinition':
      return citationsDefinations;
    case 'ptsWFlows':
      return ptsWFlows;
    case 'cadAlerts':
      return cadAlerts;
    case 'incAlerts':
      return incAlerts;
    case 'codeESNs':
      return codeESNS;
    case 'ptsGeofences':
      return ptsGeofences;
    case 'ptsShares':
      return ptsShares;
    default:
      return [];
  }
};
const cadUnits = [
  { field: 'ptsUnitID', headerName: 'ID', hide: true, width: 150, key: true },
  { field: 'Unit', headerName: 'Unit', width: 150 },
  { field: 'UnitStatus', headerName: 'Unit Status', width: 150 },
  { field: 'Location', headerName: 'Location', width: 150 },
  { field: 'IsActive', headerName: '', width: 150 },
  { field: 'Division', headerName: 'Division', width: 150 },
  { field: 'Zone', headerName: 'Zone', width: 150 },
  { field: 'Notes', headerName: 'Notes', width: 150 },
  { field: 'ContactType', headerName: 'Contact Type', width: 150 },
  { field: 'AttentionRequired', headerName: 'Attention Required', width: 150 },
];
const secUsers = [
  { field: 'ptsUserID', headerName: 'ID', hide: true, width: 150, key: true },
  { field: 'Username', headerName: 'User Name', width: 150 },
  { field: 'ApplicationName', headerName: 'Application Name', width: 150 },
  { field: 'NCICUsername', headerName: 'NCIC User Name', width: 150 },
  { field: 'PasswordEmail', headerName: 'Email', width: 150 },
  { field: 'PhoneNumber', headerName: 'Phone Number', width: 150 },
  { field: 'IsLockedOut', headerName: 'Locked Out', width: 150, format: 'bool' },
];
const secGroup = [
  { field: 'ptsGroupID', headerName: 'ID', hide: true, width: 150, key: true },
  { field: 'Label', headerName: 'Name', width: 150 },
  { field: 'Type', headerName: 'Type', width: 150 },
];
const cadDisposition = [
  { field: 'EventID', headerName: 'Event ID', width: 150 },
  { field: 'Disposition', headerName: 'Disposition', width: 150 },
  {
    field: 'Filed',
    headerName: 'Filed Date',
    width: 150,
    valueFormatter: params => formatDate(params.value),
    format: 'date',
  },
  { field: 'ROWGUID', hide: true, headerName: 'ROWGUID', width: 150, key: true },
];
const incDisposition = [
  { field: 'IncidentID', headerName: 'Incident ID', width: 150 },
  { field: 'Disposition', headerName: 'Disposition', width: 150 },
  {
    field: 'Filed',
    headerName: 'Filed Date',
    width: 150,
    valueFormatter: params => formatDate(params.value),
    format: 'date',
  },
  { field: 'ROWGUID', hide: true, headerName: 'ROWGUID', width: 150, key: true },
];
const customIDs = [
  { field: 'Application', headerName: 'Application', width: 150 },
  { field: 'EntityName', headerName: 'Entity Name', width: 150 },
  { field: 'Prefix', headerName: 'Prefix', width: 150 },
  { field: 'Suffix', headerName: 'Suffix', width: 150 },
  { field: 'Sequence', headerName: 'Sequence', width: 150 },
  { field: 'SequenceLength', headerName: 'Sequence Length', width: 150 },
  { field: 'PrefixOrder', headerName: 'Prefix Order', width: 150 },
  { field: 'SuffixOrder', headerName: 'Suffix Order', width: 150 },
  { field: 'MonthOrder', headerName: 'Month Order', width: 150 },
  { field: 'DayOrder', headerName: 'Day Order', width: 150 },
  { field: 'YearOrder', headerName: 'Year Order', width: 150 },
  { field: 'SequenceOrder', headerName: 'Sequence Order', width: 150 },
  { field: 'SequenceResetType', headerName: 'Sequence Reset Type', width: 150 },
  { field: 'Separator', headerName: 'Separator', width: 150 },
  { field: 'UseSeparator', headerName: 'Use Separator', width: 150 },

  { field: 'UseFourDigitYear', headerName: 'Use Four Digit Year', width: 150 },
  { field: 'Sequencer', headerName: 'Sequencer', width: 150 },
  { field: 'LastUsed', headerName: 'Last Used', width: 150 },
  { field: 'ROWGUID', hide: true, headerName: 'ROWGUID', width: 150, key: true },
];

const citationsDefinations = [
  { field: 'CitationDefinitionID', headerName: 'ID', hide: true, width: 150, key: true },
  { field: 'FileName', headerName: 'FileName', width: 150 },
  { field: 'IsDefault', headerName: 'Default', width: 150, type: 'boolean' },
  { field: 'Description', headerName: 'Description', width: 150 },
  { field: 'NumberOfViolation', headerName: 'Number Of Violations', width: 150 },
  { field: 'IsWarning', headerName: 'Warning Allowed', width: 150, type: 'boolean' },
  { field: 'NumberOfWarning', headerName: 'Number Of Warnings', width: 150 },
  { field: 'FormType', headerName: 'Form Type', width: 150 },

  { field: 'County', headerName: 'County', width: 150 },
];

const ptsWFlows = [
  { field: 'ptsWFlowID', headerName: 'ID', hide: true, width: 150, key: true },
  { field: 'Name', headerName: 'Name', width: 150 },
  { field: 'Type', headerName: 'Type', width: 150 },
  { field: 'WFlowID', headerName: 'WFlowID', width: 150 },
  { field: 'Flags', headerName: 'Flags', width: 150 },
  { field: 'ROWGUID', headerName: 'ROWGUID', width: 150 },
  { field: 'DefaultForIncident', headerName: 'DefaultForIncident', width: 150 },
];
const cadAlerts = [
  { field: 'ptsAlertID', headerName: 'ptsAlertID', width: 150, key: true, hide: true },
  { field: 'AlertID', headerName: 'AlertID', width: 150 },
  { field: 'AlertType', headerName: 'AlertType', width: 150 },
  { field: 'Notification', headerName: 'Notification', width: 150 },
  { field: 'Priority', headerName: 'Priority', width: 150 },
  { field: 'RangeOfEffectiveness', headerName: 'RangeOfEffectiveness', width: 150 },
  {
    field: 'Beginning',
    headerName: 'Beginning',
    valueFormatter: params => formatDate(params.value),
    format: 'date',
    width: 150,
  },
  {
    field: 'Ending',
    headerName: 'Ending',
    valueFormatter: params => formatDate(params.value),
    format: 'date',
    width: 150,
  },
  { field: 'Plate', headerName: 'Plate', width: 150 },
  { field: 'PlateState', headerName: 'PlateState', width: 150 },
  { field: 'OLN', headerName: 'OLN', width: 150 },
  { field: 'OLNState', headerName: 'OLNState', width: 150 },

  { field: 'ptsPersonID', headerName: 'ptsPersonID', width: 150 },
  { field: 'ptsVehicleID', headerName: 'ptsVehicleID', width: 150 },
  { field: 'ptsEventID', headerName: 'ptsEventID', width: 150 },
  { field: 'ptsAddressID', headerName: 'ptsAddressID', width: 150 },
];
const incAlerts = [
  { field: 'ptsAlertID', headerName: 'ptsAlertID', width: 150, key: true, hide: true },
  { field: 'AlertID', headerName: 'AlertID', width: 150 },
  { field: 'AlertType', headerName: 'AlertType', width: 150 },
  { field: 'Notification', headerName: 'Notification', width: 150 },
  { field: 'Priority', headerName: 'Priority', width: 150 },
  { field: 'RangeOfEffectiveness', headerName: 'RangeOfEffectiveness', width: 150 },
  {
    field: 'Beginning',
    headerName: 'Beginning',
    valueFormatter: params => formatDate(params.value),
    format: 'date',
    width: 150,
  },
  {
    field: 'Ending',
    headerName: 'Ending',
    valueFormatter: params => formatDate(params.value),
    format: 'date',
    width: 150,
  },
  { field: 'Plate', headerName: 'Plate', width: 150 },
  { field: 'PlateState', headerName: 'PlateState', width: 150 },
  { field: 'OLN', headerName: 'OLN', width: 150 },
  { field: 'OLNState', headerName: 'OLNState', width: 150 },

  { field: 'ptsPersonID', headerName: 'ptsPersonID', width: 150 },
  { field: 'ptsVehicleID', headerName: 'ptsVehicleID', width: 150 },
  { field: 'ptsIncidentID', headerName: 'ptsIncidentID', width: 150 },
];
const codeESNS = [
  { field: 'ZoneCode', headerName: 'Zone', width: 150, key: true },
  { field: 'Description', headerName: 'Description', width: 150 },
  { field: 'Category', headerName: 'Category', width: 150 },
  { field: 'IsDefault', headerName: 'Default', width: 150, type: 'boolean' },
  { field: 'IsActive', headerName: 'Active', width: 150, type: 'boolean' },
  { field: 'AttachToEvent', headerName: 'AttachToEvent', width: 150, type: 'boolean' },
  { field: 'ptsGeofenceID', headerName: 'Geofence ID', width: 150, type: 'boolean' },
];
const ptsGeofences = [
  { field: 'ptsGeofenceID', headerName: 'Geofence ID', width: 150, key: true },
  { field: 'Code', headerName: 'Code', width: 150 },
  { field: 'Description', headerName: 'Description', width: 150 },
  { field: 'ZoneCode', headerName: 'Zone', width: 150 },
  { field: 'Surface', headerName: 'Surface', width: 150 },
  { field: 'Color', headerName: 'Color', width: 150 },
];
const ptsShares = [
  { field: 'ptsShareID', headerName: 'ptsShareID', width: 150, key: true, hide: true },
  { field: 'ShareID', headerName: 'ShareID', width: 150 },
  { field: 'ParentType', headerName: 'ParentType', width: 150 },
  { field: 'ptsParentID', headerName: 'ptsParentID', width: 150 },
  { field: 'ptsUserID', headerName: 'ptsUserID', width: 150 },
  { field: 'ShareDate', headerName: 'ShareDate', width: 150 },
  { field: 'Comment', headerName: 'Comment', width: 150 },
  { field: 'IsReaded', headerName: 'IsReaded', width: 150 },
  { field: 'ReadDate', headerName: 'ReadDate', width: 150 },
  { field: 'Flags', headerName: 'Flags', width: 150 },
  { field: 'IsLocked', headerName: 'IsLocked', width: 150 },
  { field: 'Locked', headerName: 'Locked', width: 150 },
  { field: 'LockedBy', headerName: 'LockedBy', width: 150 },
  { field: 'SharedBy', headerName: 'SharedBy', width: 150 },
];
export const tabsList = {
  users: {
    serviceName: 'secUser',
    label: 'Users',
  },
  roles: {
    serviceName: 'secGroup',
    label: 'Roles',
  },
  units: {
    serviceName: 'cadUnit',
    label: 'Units',
  },
  'custom-ids': {
    serviceName: 'ptsCustomAgencyID',
    label: 'Custom IDs',
  },

  citationDefinations: {
    serviceName: 'CitationDefinition',
    label: 'Citations',
  },
  ptsWFlows: {
    serviceName: 'ptsWFlows',
    label: 'Work Flows',
  },
  cadAlerts: {
    serviceName: 'cadAlerts',
    label: 'Alerts',
  },

  codeESNs: {
    serviceName: 'codeESNs',
    label: 'ESNs',
  },
  ptsGeofences: {
    serviceName: 'ptsGeofences',
    label: 'Geofences',
  },
  ptsShares: {
    serviceName: 'ptsShares',
    label: 'Shares',
  },
};
