
import settings from '../config/settings';
import { getService } from './service';
import { handleError } from './ErrorReducer';

export const SET_UNITS = 'UNITS/SET_UNITS';

let unitsService = false;

let getUnitsTimeout = 0;
export const getUnits = () => {
  return async dispatch => {
    clearTimeout(getUnitsTimeout);
    getUnitsTimeout = setTimeout(() => {
      const service = getService('cmplx-units');
      service.find()
        .then(units => dispatch({ type: SET_UNITS, units: processUnits(units) }))
        .catch(error => dispatch(handleError(error)));
    }, settings.reqThrottlingTime);
  };
};

export const updateUnits = () => async dispatch => {
  if (settings.synchronizeData) {
    dispatch(getUnits());
  }
}

export const clearUnits = () => dispatch => {
  dispatch({ type: SET_UNITS, units: false });
}

export const getUnitData = (ptsUnitID) => {
  const service = getService();
  return service.get({ type: 'unit-data', data: ptsUnitID });
}

export const updateUnitData = (ptsUnitID, data) => {
  const service = getService();
  return service.patch(ptsUnitID, { type: 'update-unit-data', data });
}

export const setUnitZone = (ptsUnitID, Zone) => {
  const service = getService();
  return service.patch(ptsUnitID, { type: 'set-unit-zone', data: Zone });
}

// export const subscribeUnits = () => {
//   const state = store.store.getState();
//   const authenticated = state.user.isAuthenticated;
//   const client = state.websocket.websocket;
//   return async dispatch => {
//     if (!client || !authenticated) return;
//     dispatch(getUnits());
//     unitsService = client.service('cmplx-units');
//     unitsService.on('newUnitsData', units => {
//       dispatch({ type: SET_UNITS, units: processUnits(units) });
//     });
//     unitsService.on('unhandledRejection', (reason, p) => {
//       console.log("Units Reducer Unhandled Rejection at: Promise ", p, " reason: ", reason);
//     });
//   }
// }

export const subscribeUnits = () => {
  return async dispatch => {
    const service = getService('cmplx-units');
    dispatch(getUnits());
    service.on('newUnitsData', units => {
      dispatch({ type: SET_UNITS, units: processUnits(units) });
    });
    service.on('unhandledRejection', (reason, p) => {
      console.log("Units Reducer Unhandled Rejection at: Promise ", p, " reason: ", reason);
    });
  }
}

export const unsubscribeUnits = () => {
  if (unitsService) {
    unitsService.off('newUnitsData');
    unitsService = false;
  }
  return () => {};
}
// ===========  REDUCERS  ======================

export default function reducer( state = false, action) {
  switch (action.type) {
    case SET_UNITS:
      return action.units;
    default:
      break;
  }
  return state;
}

// ===============  HELPER fUNCTIONS  ==============

function processUnits(units) {
  return units.map(unit => {
    return { ...unit, UnitStatuses: JSON.parse(unit.UnitStatuses)}
  });
}

export const getUnitsAsync = () => {
    const service = getService('cmplx-units');
    return new Promise((resolve, reject) => {
      service.find()
        .then(units => {
          resolve(processUnits(units));
        })
        .catch(error => {
          reject(error);
        });
    });
};