import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { findSearchUsers } from '../../SecFunctions';

import { XGrid } from '@material-ui/x-grid';
import CheckIcon from '@material-ui/icons/Check';

import { handleError } from 'reducers/ErrorReducer';
import { notify } from 'reducers/NotifierReducer';

import FormControlLabel from '@mui/material/FormControlLabel';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { showAddSecUser, showRemoveSecUser } from 'reducers/DialogsReducer';
import Fab from '@material-ui/core/Fab';
import Tooltip from 'components/Tooltip';
import { Box, Checkbox, Grid } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { displayDateTime } from 'reducers/TimeReducer';
import QuickFilter from 'components/QuickFilter';

import UserProfileChangeConfirmation from './UserProfileChangeConfirmation';
import { sortObjArr } from 'utils/functions';

const useStyles = makeStyles(theme => ({
  autoComplete: {
    marginTop: '15px',
  },

  saveBtn: {
    textAlign: 'right',
    // marginBottom: 15,
    display: 'flex',
    gap: '5px',
    justifyContent: 'right',
    paddingTop: '5px',
  },
  textField: { marginTop: '15px' },
  toggleItem: {
    flex: 33.33,
  },
  wrap: {
    padding: theme.spacing(2),
    '& > h5': {
      marginBottom: 20,
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: `calc(100vh - 240px)`,
    [theme.breakpoints.down('lg')]: {
      height: '300px',
    },
  },
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0, 2),
    alignItems: 'center',
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  actionFilterSection: {
    display: 'flex',
    gap: '15px',
    alignItems: 'center',
  },
}));

const bool = params => (params.value ? <CheckIcon /> : <span></span>);

function SearchUsers(props) {
  const classes = useStyles();

  const { profile } = props;
  const [usersData, setUsersData] = useState([]);
  const [allUsersData, setAllUsersData] = useState([]);
  const [selection, setSelection] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [profileFilter, setProfileFilter] = useState('all');
  const [filteredRows, setFilteredRows] = useState([]);
  const [rows, setRows] = useState([]);
  const [confirmationDialogData, setConfirmationDialogData] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchUsers();
  }, []);
  useEffect(() => {
    if (profile) {
      setProfileFilter('included');
      const selectedUser = allUsersData
        .filter(user => user.ptsProfileID === profile.ptsProfileID)
        .map(user => String(user.ptsUserID));

      setSelection(selectedUser || []);
    } else {
      setSelection([]);
      setProfileFilter('all');
    }
  }, [allUsersData, profile]);
  useEffect(() => {
    let filteredUsers = filteredRows;

    if (profileFilter && profile) {
      if (profileFilter === 'included') {
        filteredUsers = filteredUsers.filter(user => user.ptsProfileID === profile.ptsProfileID);
      } else if (profileFilter === 'excluded') {
        filteredUsers = filteredUsers.filter(user => user.ptsProfileID !== profile.ptsProfileID);
      }
    }
    setUsersData(filteredUsers);
  }, [filteredRows, profileFilter, profile]);

  // functions
  const fetchUsers = async () => {
    setLoading(true);
    props.setUserLoader(true);
    const result = await findSearchUsers();
    const sortedData = sortObjArr(result, 'Username');
    setRows(sortedData);
    props.setUserList(sortedData);
    setAllUsersData(sortedData);
    setLoading(false);

    props.setUserLoader(false);
  };
  const handleSelectChange = newSelection => {
    const { selectionModel } = newSelection;
    const currentUserId = Number(selectionModel);
    const currentUser = usersData.find(data => data.ptsUserID === currentUserId);
    setSelectedUser(currentUser);
    setSelection(selectionModel);
  };
  const handleProfileFilterChange = (e, newValue) => {
    setProfileFilter(newValue);
  };
  //end functions

  // render actions
  const renderActions = () => {
    const disabled = selection?.length === 0;

    return (
      <div className={classes.actions}>
        <div style={{ textAlign: 'left' }}>
          <QuickFilter
            minWidth="120px"
            rows={rows}
            columns={columns}
            marginTop="10px"
            setFilteredRows={setFilteredRows}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Box>
            <ToggleButtonGroup
              value={profileFilter}
              exclusive
              size="small"
              onChange={handleProfileFilterChange}
              style={{ width: '100%' }}
              aria-label="Profile Filter">
              <ToggleButton
                value="included"
                disabled={!profile}
                aria-label="included"
                className={classes.toggleItem}>
                Inc
              </ToggleButton>
              <ToggleButton value="all" aria-label="all" fullWidth className={classes.toggleItem}>
                All
              </ToggleButton>
              <ToggleButton
                disabled={!profile}
                value="excluded"
                aria-label="excluded"
                fullWidth
                className={classes.toggleItem}>
                Exc
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Tooltip
            title="Add"
            onClick={() =>
              props.showAddSecUser({
                fetchUsers: fetchUsers,
                setSelection: setSelection,
                userList: allUsersData,
                selectedProfile: profile,
              })
            }>
            <Fab size="small" color="secondary">
              <AddIcon />
            </Fab>
          </Tooltip>
          {!profile && (
            <>
              <Tooltip
                title="Edit"
                onClick={() =>
                  props.showAddSecUser({
                    selectedUser: selectedUser,
                    fetchUsers: fetchUsers,
                    userList: allUsersData,
                    setSelection: setSelection,
                  })
                }
                disabled={disabled}>
                <Fab size="small" color="secondary">
                  <EditIcon />
                </Fab>
              </Tooltip>
              <Tooltip
                title="Delete User"
                disabled={disabled}
                onClick={() => {
                  props.showRemoveSecUser({
                    selectedUser: selection,
                    fetchUsers: fetchUsers,
                    setSelection: setSelection,
                    userName: selectedUser.Username,
                  });
                }}>
                <Fab size="small" color="secondary">
                  <DeleteIcon />
                </Fab>
              </Tooltip>
            </>
          )}
        </div>
      </div>
    );
  };
  //end actions
  // columns
  const renderCheckbox = params => {
    const { row } = params;

    const handleChange = () => {
      setConfirmationDialogData({
        type: row.ptsProfileID == profile?.ptsProfileID ? 'change-profile' : 'assign-profile',
        user: row,
      });
    };
    const handleChecked = () => {
      return Boolean(row.ptsProfileID == profile?.ptsProfileID);
    };
    return profile ? (
      <FormControlLabel
        control={<Checkbox checked={handleChecked()} onChange={handleChange} color="primary" />}
        label={params.value}
      />
    ) : (
      <span>{params.value}</span>
    );
  };
  const columns = [
    { field: 'Username', headerName: 'Username', renderCell: renderCheckbox, width: 150 },
    { field: 'Profile', headerName: 'Profile', width: 120 },
    { field: 'FullName', headerName: 'Fullname', width: 200 },
    { field: 'IsDisabled', headerName: 'Disabled', width: 120, format: 'bool', renderCell: bool },
    {
      field: 'IsLockedOut',
      headerName: 'Locked Out',
      width: 120,
      format: 'bool',
      renderCell: bool,
    },
    {
      field: 'ActivityDateTime',
      headerName: 'Activity Date Time',
      width: 150,
      valueFormatter: params => displayDateTime(params.value),
      format: 'date',
    },
    { field: 'Activity', headerName: 'Activity', width: 120 },
    { field: 'ApplicationName', headerName: 'Application Name', width: 120 },
    { field: 'PersonID', headerName: 'PersonID', width: 120 },
    {
      field: 'ClearanceLevel',
      headerName: 'Clearance Level',
      width: 150,
      valueGetter: params => parseInt(params.value) || params.value,
    },
    { field: 'PasswordEmail', headerName: 'Email', width: 170 },
    { field: 'PhoneNumber', headerName: 'Phone Number', width: 150 },
  ];
  //end columns sections
  return (
    <div className="m-2">
      <h4>
        Users
        <span style={{ fontSize: '16px' }}> ({props.profile ? props.profile.Label : 'All'})</span>
      </h4>

      {renderActions()}

      <div className={classes.gridWrap}>
        <XGrid
          columns={columns}
          loading={loading}
          rows={usersData}
          rowHeight={38}
          getRowId={row => row.ptsUserID}
          onSelectionModelChange={handleSelectChange}
          disableSelectionOnClick={profile ? true : false}

          // hideFooter
        />
        {confirmationDialogData && (
          <UserProfileChangeConfirmation
            profile={profile}
            setConfirmationDialogData={setConfirmationDialogData}
            confirmationDialogData={confirmationDialogData}
            fetchUsers={fetchUsers}
          />
        )}
      </div>
    </div>
  );
}

export default connect(null, { handleError, notify, showAddSecUser, showRemoveSecUser })(
  SearchUsers
);
