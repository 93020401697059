import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Content from 'Settings/Content';

import { getService } from 'reducers/service';
import { handleError } from 'reducers/ErrorReducer';
import { notify } from 'reducers/NotifierReducer';

import { gridStyle } from 'utils/formStyles';
import { XGrid } from '@material-ui/x-grid';
import { Fab } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import {
  showRo,
  showRotationSeup,
  showRotationSeuptationSeupshowRotationSeup,
} from 'reducers/DialogsReducer';
import QuickFilter from 'components/QuickFilter';
import { displayDateTime } from 'reducers/TimeReducer';
export const columnsItems = [
  { field: 'Code', headerName: 'Code', width: 150 },
  { field: 'Description', headerName: 'Description', width: 250 },
  { field: 'Category', headerName: 'Category', width: 150 },
  { field: 'IsDefault', headerName: 'Default', width: 150 },
  { field: 'IsDeleted', headerName: 'Deleted', width: 150 },
  { field: 'IsActive', headerName: 'Active', width: 150 },
  { field: 'Flags', headerName: 'Flags', width: 100 },
  { field: 'Code2', headerName: 'Code 2', width: 150 },
  { field: 'Description2', headerName: 'Description 2', width: 150 },
  { field: 'Code3', headerName: 'Code 3', width: 100 },
  { field: 'Description3', headerName: 'Description 3', width: 150 },
  { field: 'Code4', headerName: 'Code 4', width: 100 },
  { field: 'Description4', headerName: 'Description 4', width: 150 },
  { field: 'UpdatedBy', headerName: 'UpdatedBy', width: 150 },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 150,
    valueFormatter: params => displayDateTime(params.value),
    format: 'date',
  },
  { field: 'DeletedBy', headerName: 'Deleted By', width: 150 },
  { field: 'CreatedBy', headerName: 'Created By', width: 150 },
];
const useStyles = makeStyles(theme => ({
  item: {
    ...gridStyle(200, 800),
  },
  list: {
    width: '100%',
    height: 500,
  },
  wrap: {
    padding: theme.spacing(2),
    '& > h5': {
      marginBottom: 20,
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: `calc(100vh - 240px)`,
  },
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0, 2),
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
}));

function RotationSetup(props) {
  const { wsClient, addRotation } = props;
  const classes = useStyles();
  const service = getService('coderotation');
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState([]);
  const [loaded, setLoaded] = useState(false);
  // console.log('coming rotaion');
  const dispatch = useDispatch();
  useEffect(() => {
    const search = async () => {
      service.timeout = 20000;
      try {
        const result = await service.find({ query: { IsDeleted: false, $limit: 0 } });
        console.log('result', result.data);
        setRows(result?.data || []);
      } catch (error) {
        console.log(error);
      } finally {
        setLoaded(true);
      }
    };
    if (addRotation == null) {
      search();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsClient.websocket, addRotation]);

  const handleSelectChange = ({ selectionModel }) => {
    setSelection(selectionModel);
  };

  const onClickAdd = () => {
    dispatch(showRotationSeup({ mode: 'add' }));
  };
  const onClickEdit = () => {
    const obj = rows.find(row => row.Code == selection[0]);

    dispatch(showRotationSeup({ mode: 'edit', info: obj }));
  };
  const onClickDelete = async () => {
    const removeRotation = await service.remove(selection[0]);
    const newRows = rows.filter(el => el.Code !== removeRotation.Code);
    setRows(newRows);
    props.notify('Deleted', 'warning');
    setSelection([]);
  };
  const renderActions = () => {
    const disabled = selection.length === 0;
    const selected = rows?.find(a => a.Code === selection[0]);
    //const dltDisabled = selected?.Icon === 0;
    return (
      <div className={classes.actions}>
        <div>
          <QuickFilter rows={rows} columns={columnsItems} setFilteredRows={setFilteredRows} />
        </div>
        <div>
          <Fab size="small" color="secondary" onClick={onClickAdd}>
            <AddIcon />
          </Fab>
          <Fab size="small" color="secondary" onClick={onClickEdit} disabled={disabled}>
            <EditIcon />
          </Fab>
          <Fab size="small" color="secondary" onClick={onClickDelete} disabled={disabled}>
            <DeleteIcon />
          </Fab>
        </div>
      </div>
    );
  };
  return (
    <Content>
      <div className="m-4">
        <h4>Rotation</h4>
        {renderActions()}
        <div className={classes.gridWrap}>
          <XGrid
            columns={columnsItems}
            rows={filteredRows}
            loading={!loaded}
            rowHeight={38}
            getRowId={row => row.Code}
            // checkboxSelection
            onSelectionModelChange={handleSelectChange}
          />
        </div>
      </div>
    </Content>
  );
}

const mapStateToProps = state => {
  return {
    wsClient: state.websocket,
    addRotation: state.dialogs.addRotation,
  };
};

export default connect(mapStateToProps, {
  handleError,
  notify,
})(RotationSetup);
