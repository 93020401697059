import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardContent, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import projectLogo from 'assets/images/icon-CAD.svg';
import ptsLogo from 'assets/images/PTS-logo.png';
import splashScreen from 'assets/images/login-splash.jpg';
import TextField2 from 'components/TextField2';
import { notify } from 'reducers/NotifierReducer';
import { validateResetPassHash, resetPass } from 'reducers/UserReducer';
import { RowInner } from 'utils/formStyles';
import { passValidationErrors } from 'utils/functions';
import { Alert, AlertTitle } from '@material-ui/lab';
import { CircularProgress } from '@material-ui/core';
import { useParams, Redirect } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  pageWrap: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${splashScreen})`,
    backgroundSize: 'cover',
  },
  card: {
    width: 450,
    maxWidth: '95%',
    background: '#fff',
    '& img': {
      width: '100%',
    },
    '& > h1': {
      textAlign: 'center',
      textTransform: 'uppercase',
      fontSize: 28,
      padding: '8px 0',
      marginBottom: 0,
      background: '#ce0600',
      color: '#fff',
    },
  },
  content: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  branding: {
    width: '100%',
    height: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#ff9900',
    '& img': {
      width: 150,
      height: 'auto',
    },
  },
  branding2: {
    width: '100%',
    textAlign: 'center',
    padding: '25px 0 5px',
    '& img': {
      height: 50,
      width: 'auto',
    },
  },
  action: {
    textAlign: 'center',
    marginTop: 30,
    justifyContent: 'center',
    '& button': {
      background: '#a80000',
      paddingLeft: '4em',
      paddingRight: '4em',
      textTransform: 'uppercase',
      '&:hover': {
        background: '#ce0600',
      },
    },
    '& button.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.26)',
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
    }
  },
  version: {
    position: 'absolute',
    bottom: '0.5em',
    left: '1em',
    lineHeight: 1,
  },
  passReset: {
    fontSize: 13,
    textAlign: 'right',
    color: theme.colors.grey3,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  textField: {
    width: '100%',
  },
  invalid: {
    textAlign: 'center',
    fontSize: '1.5em',
    margin: '2em 0',
  },
  alert: {
    marginBottom: 32,
    '&.MuiAlert-standardError': {
      color: 'rgb(84, 18, 18)',
      backgroundColor: 'rgb(250, 234, 234)',
    }
  },
  formWrap: {
    marginBottom: 16,
    '& .MuiInputBase-root': {
      color: '#333',
    },
    '& .MuiFormLabel-root': {
      color: '#999',
      '&.MuiInputLabel-shrink': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      boxShadow: 'none',
      textFillColor: 'inherit'
    },
    '& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const ResetPassword = (props) => {
  const classes = useStyles();
  const { websocket } = props;
  const [newPass, setNewPass] = useState('');
  const [confirmNewPass, setConfirmNewPass] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errors, setErrors] = useState([]);
  const [view, setView] = useState('waiting'); // waiting, valid, invalid, success
  const disabled = Boolean(error || !newPass || loading || newPass !== confirmNewPass);
  const repeatPassErr = newPass === confirmNewPass ? false : 'Passwords don\'t match';
  const { hash } = useParams();

  useEffect(() => {
    if (!websocket.websocket) return;
    validateHash();
    // eslint-disable-next-line
  }, [websocket]);

  useEffect(() => {
    const validationErrors = passValidationErrors('', newPass);
    if (newPass && validationErrors.length) {
      setError(validationErrors[0]);
    } else {
      if (error) setError(false);
    }
    // eslint-disable-next-line
  }, [newPass]);

  const validateHash = async () => {
    try {
      const valid = await validateResetPassHash(hash);
      setView(valid ? 'valid' : 'invalid');
    } catch (err) {
      console.log(err);
    }
  }

  const resetPassword = async () => {
    setLoading(true);
    try {
      await resetPass(hash, newPass);
      props.notify('Password updated!', 'success');
      setNewPass('');
      setConfirmNewPass('');
      setErrors([]);
      setView('success');
    } catch (err) {
      if (err.errors && err.errors.length) {
        setErrors(err.errors);
      } else {
        const message = err.message ? err.message : 'Error resetting password';
        setErrors([message]);
      }
    }
    setLoading(false);
  }

  const renderForm = () => (
    <>
      <RowInner className={classes.formWrap}>
        <TextField2
          className={classes.textField}
          label="New Password"
          value={newPass}
          onChange={(ev, val) => setNewPass(val)}
          type="mask"
          error={error}
          disabled={view === 'waiting' || loading}
        />
      </RowInner>
      <RowInner className={classes.formWrap} style={{ margin: '16px 0 32px' }}>
        <TextField2
          className={classes.textField}
          label="Repeat New Password"
          value={confirmNewPass}
          onChange={(ev, val) => setConfirmNewPass(val)}
          type="mask"
          error={repeatPassErr}
          disabled={view === 'waiting' || loading}
        />
      </RowInner>
      <RowInner className={classes.action}>
        <Button
          className={classes.w100pr}
          color="secondary"
          variant="contained"
          autoFocus
          onClick={resetPassword}
          disabled={disabled}>
          Change Password
        </Button>
        {loading && <CircularProgress style={{ marginLeft: 8 }} />}
      </RowInner>
    </>
  );

  const renderHashInvalid = () => {
    return (
      <p className={classes.invalid}>The link is invalid or expired</p>
    );
  }

  if (!hash || hash.length !== 36 || view === 'success') return <Redirect to="/" />

  return (
    <div className={classes.pageWrap}>
      <Card className={classes.card}>
        <h1>Settings</h1>
        <div className={classes.branding}>
          <img src={projectLogo} alt="CAD icon" />
        </div>
        <div className={classes.branding2}>
          <img src={ptsLogo} alt="PTS logo" />
        </div>
        <CardContent className={classes.content}>
          {errors.length > 0 && <Alert severity="error" className={classes.alert}>
            <AlertTitle>Password validation errors</AlertTitle>
            <ul>
              {errors.map((err, idx) => <li key={idx}>{err}</li>)}
            </ul>
          </Alert>}
          {view === 'valid' ? renderForm() : renderHashInvalid()}
        </CardContent>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  themeMode: state.theme.mode,
  websocket: state.websocket,
});

export default connect(mapStateToProps, {
  notify
})(ResetPassword);
