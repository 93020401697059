import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Content from '../Content';
import DispatchTooltips from './DispatchTooltips';
import TimeFormat from './TimeFormat';
import { Button, Typography } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { connect } from 'react-redux';
import { getService } from 'reducers/service';
import { handleError } from 'reducers/ErrorReducer';
import { notify } from 'reducers/NotifierReducer';
const useStyles = makeStyles(theme => ({
  tabs: {
    width: '100%',
    '& button': {
      '& > span': {
        textTransform: 'none',
      },
    },
  },
  settings: {
    height: '100%',
    overflowY: 'auto',
  },
  activeBar: {
    textAlign: 'right',
    width: '100%',
  },
  title: {
    padding: '15px 0px',
    fontWeight: 'bold',
  },
  container: {
    display: 'flex',
    gap: '60px',
    flexWrap: 'wrap',
  },
}));

function Appearance(props) {
  const classes = useStyles();
  const tooltipService = getService('settings-appearance-tooltips');
  const cdr = getService('cdr');

  const [is24Hours, setIs24Hours] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [duration, setDuration] = useState(0);
  const handleSaveClick = async () => {
    const CDRKey = '24hour';
    const Path = 'Application.TimeFormat';
    const Tooltipdata = [
      {
        CDRKey: 'Show',
        ValueString: null,
        ValueBoolean: isShow,
      },
      {
        CDRKey: 'ShowDuration',
        ValueString: duration || 0,
        ValueBoolean: null,
      },
    ];
    try {
      await cdr.patch(null, {
        ValueBoolean: is24Hours,
        Path,
        CDRKey,
      });
      await tooltipService.create(Tooltipdata);
      props.notify('Information Saved.', 'success');
    } catch (error) {
      props.handleError(error, 'Error Saving Information.');
      console.log(error);
    }
  };
  const renderSaveBtn = () => {
    return (
      <Button onClick={handleSaveClick} variant="contained" className="mt-2 ml-2" color="primary">
        <SaveIcon className="mr-2" /> Save
      </Button>
    );
  };
  return (
    <Content>
      <div className="m-4">
        <Typography variant="h6">Appearance</Typography>
        <div className={classes.activeBar}> {renderSaveBtn()}</div>
        <div className={classes.container}>
          <div className="p-1">
            <Typography variant="body1" className={classes.title}>
              Application Time Format
            </Typography>
            <TimeFormat is24Hours={is24Hours} setIs24Hours={setIs24Hours} />
          </div>
          <div className="p-1">
            <Typography variant="body1" className={classes.title}>
              Dispatch Tooltip Setting
            </Typography>
            <DispatchTooltips
              isShow={isShow}
              setIsShow={setIsShow}
              duration={duration}
              setDuration={setDuration}
            />
          </div>
        </div>
      </div>
    </Content>
  );
}
export default connect(null, {
  handleError,
  notify,
})(Appearance);
