import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

function DatePicker2(props) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        inputVariant="outlined"
        format="MM/dd/yyyy"
        margin="normal"
        autoOk
        size="small"
        {...props}
        onChange={(date) => props.onChange(null, date)}
      />
    </MuiPickersUtilsProvider>
  );
}

export default DatePicker2;