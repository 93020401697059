import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import {useIdleTimer} from 'react-idle-timer';
import { setUserAuthenticated } from 'reducers/UserReducer';
import Snackbar from '@material-ui/core/Snackbar';

function IdleTimerContainer (props) {
  const timeout = parseInt(process.env.REACT_APP_IDLE_TIMEOUT, 10) || 1800000;
  const {setUserAuthenticated, isAuthenticated} = props;
  const [remaining, setRemaining] = useState(timeout);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(getRemainingTime());
    }, 1000);
    return () => {
      clearInterval(interval);
    }
  }, []);
 
  useEffect(() => {
    if (remaining < 60000 && isAuthenticated)  {
      if (!open) setOpen(true);
    }
    else setOpen(false);
  }, [remaining])

  const handleOnIdle = () => { if (isAuthenticated) setUserAuthenticated(false); }

  const { getRemainingTime } = useIdleTimer({
    timeout: timeout,
    onIdle: handleOnIdle,
  })

  const handleClose = () => setOpen(false);  

  return (
    <>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        open={open}
        onClose={handleClose}
        message={ remaining < 60000 ? `Session timeout! You will be logged out in ${Math.ceil(remaining/1000)}` : ''}
      />
    </>
  )

}

const mapStateToProps = state => ({
  isAuthenticated: state.user.isAuthenticated,
});

export default connect(mapStateToProps, { setUserAuthenticated })(IdleTimerContainer);