import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Content from './Content';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { ColCard, Row, RowInner, gridStyle } from 'utils/formStyles';
import FormDivider from 'components/FormDivider';
import { getService } from 'reducers/service';
import { notify } from 'reducers/NotifierReducer';
import { connect, useDispatch, useSelector } from 'react-redux';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import SaveIcon from '@material-ui/icons/Save';
import TextField2 from 'components/TextField2';
import { handleError } from 'reducers/ErrorReducer';
import Autocomplete from '@mui/material/Autocomplete';

const useStyles = makeStyles(theme => ({
  saveBtn: {
    textAlign: 'right',
    marginBottom: '10px',
  },
  textField: {
    width: '100%',
  },
}));

function Automation(props) {
  const classes = useStyles();
  const service = getService('settings-automation');
  const { wsClient } = props;
  const [shiftStart, setShiftStart] = useState(false);
  const [shiftEnd, setShiftEnd] = useState(false);
  const [exportClosedEvents, setExportClosedEvents] = useState(false);
  const [enableEventExport, setEnableEventExport] = useState(false);
  const [eventExportPath, setEventExportPath] = useState('');
  const [eventReceivedDateTimeOn, setEventReceivedDateTimeOn] = useState('');
  const [allowUpdatingWithoutUnit, setAllowUpdatingWithoutUnit] = useState(false);
  const [allowCompletingEvents, setAllowCompletingEvents] = useState(false);
  const [copySOPAnswers, setCopySOPAnswers] = useState(false);
  const [copyPlaceName, setCopyPlaceName] = useState(false);
  const [copyEventAtAddress, setCopyEventAtAddress] = useState(false);
  const [copyAddressDescription, setCopyAddressDescription] = useState(false);
  const [autoDispatchRecommendations, setAutoDispatchRecommendations] = useState(false);
  const [addDateTimeToNotes, setAddDateTimeToNotes] = useState(false);
  const [showWarrantInfo, setShowWarrantInfo] = useState(false);
  const [fetchAttachmentFromAddress, setFetchAttachmentFromAddress] = useState(false);
  const [updatedData, setUpdatedData] = useState({});
  const [codeCategory, setCodeCategory] = useState('');
  const user = useSelector(state => state.user);
  const username = user?.userData?.user?.Username;
  const dispatch = useDispatch();
  //useEffect
  useEffect(() => {
    if (wsClient.websocket) {
      getSettings();
    }
  }, [wsClient]);
  const getSettings = async () => {
    try {
      dispatch(showSpinner());
      const data = await service.find();
      data.forEach(item => {
        const { Path, CDRKey, ValueBoolean, ValueString } = item;
        if (Path === 'PTS.Auto.Shift.Inservice' && CDRKey === 'AutoShift') {
          setShiftStart(ValueBoolean || false);
        } else if (Path === 'PTS.Auto.Shift.OffDuty' && CDRKey === 'AutoShift') {
          setShiftEnd(ValueBoolean || false);
        } else if (Path === 'PTS.Auto.Event.Export' && CDRKey === 'AutoEvent') {
          setExportClosedEvents(ValueBoolean || false);
        } else if (
          Path === 'CAD.Auto.Event.SetReceivedDateTime' &&
          CDRKey === 'CAD Event Received'
        ) {
          setEventReceivedDateTimeOn(ValueString || '');
        } else if (
          Path === 'PTS.Auto.Event.AllowUpdatingEventsWithoutUnits' &&
          CDRKey === 'AutoEvent'
        ) {
          setAllowUpdatingWithoutUnit(ValueBoolean || false);
        } else if (
          Path === 'PTS.Auto.Event.AllowCompletingEventsWhenAllUnitsComplete' &&
          CDRKey === 'AutoEvent'
        ) {
          setAllowCompletingEvents(ValueBoolean || false);
        } else if (Path === 'PTS.Auto.Event.CopySOPAnswersToNotes' && CDRKey === 'AutoEvent') {
          setCopySOPAnswers(ValueBoolean || false);
        } else if (Path === 'PTS.Auto.Event.CopyPlaceToNotes' && CDRKey === 'AutoEvent') {
          setCopyPlaceName(ValueBoolean || false);
        } else if (Path === 'PTS.Auto.Event.CopyEventsAtAddressToNotes' && CDRKey === 'AutoEvent') {
          setCopyEventAtAddress(ValueBoolean || false);
        } else if (
          Path === 'PTS.Auto.Event.CopyAddressDescriptionToNotes' &&
          CDRKey === 'AutoEvent'
        ) {
          setCopyAddressDescription(ValueBoolean || false);
        } else if (
          Path === 'PTS.Auto.Event.UseAutoDispatchRecommendations' &&
          CDRKey === 'AutoEvent'
        ) {
          setAutoDispatchRecommendations(ValueBoolean || false);
        } else if (Path === 'CAD.UnitStatus.Notes.DateTimeStamp' && CDRKey === 'Automation') {
          setAddDateTimeToNotes(ValueBoolean || false);
        } else if (Path === 'PTS.People.WarrantInfo' && CDRKey === 'Automation') {
          setShowWarrantInfo(ValueBoolean || false);
        } else if (Path === 'CAD.Event.Export' && CDRKey === 'CAD Event') {
          setEnableEventExport(ValueBoolean || false);
        } else if (Path === 'CAD.Event.Export.DefaultPath' && CDRKey === 'CAD Event') {
          setEventExportPath(ValueString || '');
        } else if (Path === 'CAD.Event.FetchAttachmentFromAddress' && CDRKey === 'Automation') {
          setFetchAttachmentFromAddress(ValueBoolean || false);
        }
      });
    } catch (error) {
      console.log(error.message);
    } finally {
      dispatch(hideSpinner());
    }
  };
  //handler
  const handleShiftStart = ev => {
    setShiftStart(ev.target.checked);
    saveChanges('PTS.Auto.Shift.Inservice', 'AutoShift', ev.target.checked);
  };

  const handleShiftEnd = ev => {
    setShiftEnd(ev.target.checked);
    saveChanges('PTS.Auto.Shift.OffDuty', 'AutoShift', ev.target.checked);
  };

  const handleExportClosedEvents = ev => {
    setExportClosedEvents(ev.target.checked);
    saveChanges('PTS.Auto.Event.Export', 'AutoEvent', ev.target.checked);
  };
  const handleEnableEventExport = ev => {
    setEnableEventExport(ev.target.checked);
    saveChanges('CAD.Event.Export', 'CAD Event', ev.target.checked);
  };
  const handleEventExportOutPath = (ev, val) => {
    setEventExportPath(val);
    saveChanges('CAD.Event.Export.DefaultPath', 'CAD Event', null, val);
  };

  const handleEventReceivedDateTimeOn = (ev, key) => {
    const value = ev.target.checked;
    let str = '';
    if (key == 'save') {
      if (value) str = 'Save';
      else str = '';
    } else {
      if (value) str = '';
      else str = 'Save';
    }
    setEventReceivedDateTimeOn(str);
    saveChanges('CAD.Auto.Event.SetReceivedDateTime', 'CAD Event Received', null, str);
  };

  const handleAllowUpdatingWithoutUnit = ev => {
    setAllowUpdatingWithoutUnit(ev.target.checked);
    saveChanges('PTS.Auto.Event.AllowUpdatingEventsWithoutUnits', 'AutoEvent', ev.target.checked);
  };

  const handleAllowCompletingEvents = ev => {
    setAllowCompletingEvents(ev.target.checked);
    saveChanges(
      'PTS.Auto.Event.AllowCompletingEventsWhenAllUnitsComplete',
      'AutoEvent',
      ev.target.checked
    );
  };

  const handleCopySOPAnswers = ev => {
    setCopySOPAnswers(ev.target.checked);
    saveChanges('PTS.Auto.Event.CopySOPAnswersToNotes', 'AutoEvent', ev.target.checked);
  };

  const handleCopyPlaceName = ev => {
    setCopyPlaceName(ev.target.checked);
    saveChanges('PTS.Auto.Event.CopyPlaceToNotes', 'AutoEvent', ev.target.checked);
  };

  const handleCopyEventAtAddress = ev => {
    setCopyEventAtAddress(ev.target.checked);
    saveChanges('PTS.Auto.Event.CopyEventsAtAddressToNotes', 'AutoEvent', ev.target.checked);
  };

  const handleCopyAddressDescription = ev => {
    setCopyAddressDescription(ev.target.checked);
    saveChanges('PTS.Auto.Event.CopyAddressDescriptionToNotes', 'AutoEvent', ev.target.checked);
  };

  const handleAutoDispatchRecommendations = ev => {
    setAutoDispatchRecommendations(ev.target.checked);
    saveChanges('PTS.Auto.Event.UseAutoDispatchRecommendations', 'AutoEvent', ev.target.checked);
  };

  const handleAddDateTimeToNotes = ev => {
    setAddDateTimeToNotes(ev.target.checked);
    saveChanges('CAD.UnitStatus.Notes.DateTimeStamp', 'Automation', ev.target.checked);
  };

  const handleShowWarrantInfo = ev => {
    setShowWarrantInfo(ev.target.checked);
    saveChanges('PTS.People.WarrantInfo', 'Automation', ev.target.checked);
  };

  const handleFetchAttachmentFromAddress = ev => {
    setFetchAttachmentFromAddress(ev.target.checked);
    saveChanges('CAD.Event.FetchAttachmentFromAddress', 'Automation', ev.target.checked);
  };

  const handleCodeExport = async () => {
    const service = getService('code-export');
    try {
      await service.create(codeCategory);
      props.notify('Code exported', 'success');
    } catch (err) {
      props.handleError(err);
    }
  };

  const saveChanges = async (Path, CDRKey, ValueBoolean, ValueString = null) => {
    const newObj = { ...updatedData };
    const key = Path + '-' + CDRKey;
    newObj[key] = {
      Path,
      CDRKey,
      ValueBoolean,
      ValueString,
    };
    setUpdatedData(newObj);
  };
  const handleSaveClick = async () => {
    try {
      dispatch(showSpinner());
      await Promise.all([
        Object.values(updatedData).map(value => {
          const { Path, CDRKey, ValueBoolean, ValueString } = value || {};
          return service.patch(null, {
            Path,
            CDRKey,
            ValueBoolean,
            ValueString,
            CreatedBy: username,
            UpdatedBy: username,
          });
        }),
      ]);

      dispatch(notify('Saved.', 'success'));
    } catch (err) {
      console.log('err', err);
    } finally {
      dispatch(hideSpinner());
    }
  };
  const renderSave = () => {
    return (
      <div className={classes.saveBtn}>
        <Button onClick={handleSaveClick} variant="contained" color="primary">
          <SaveIcon className="mr-2" /> Save
        </Button>
      </div>
    );
  };
  return (
    <Content>
      <div className="m-4">
        {renderSave()}
        <Row>
          <ColCard minWidth={1000}>
            <div>
              <FormDivider title="Events" />
              <Row>
                <ColCard minWidth={500}>
                  <div>
                    <FormDivider title="Export Events" />
                    <RowInner>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={exportClosedEvents}
                            onChange={handleExportClosedEvents}
                            color="primary"
                          />
                        }
                        label="Automatically Export Closed Events"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={enableEventExport}
                            onChange={handleEnableEventExport}
                            color="primary"
                          />
                        }
                        label="Enable Event Export"
                      />
                    </RowInner>
                    <RowInner>
                      <TextField2
                        disabled={!enableEventExport}
                        fullwidth
                        className={classes.textField}
                        label="Event Export Output Path"
                        value={eventExportPath}
                        onChange={handleEventExportOutPath}
                      />
                    </RowInner>
                    <RowInner>
                      <Autocomplete
                        disablePortal
                        disabled={!enableEventExport}
                        value={codeCategory}
                        onChange={(ev, val) => setCodeCategory(val)}
                        options={[
                          { label: 'UnitStatus', value: 'codeUnitActions' },
                          { label: 'PartyRelationShip', value: 'codePartyRelationship' },
                          { label: 'Dispositions', value: 'codeDispositions' },
                        ]}
                        sx={{ width: 300, marginBottom: 5 }}
                        renderInput={params => (
                          <TextField variant="outlined" {...params} label="Codes" />
                        )}
                      />
                      <Button
                        style={{ margin: 5 }}
                        variant="contained"
                        color="primary"
                        disabled={!enableEventExport || !codeCategory || !eventExportPath}
                        onClick={handleCodeExport}>
                        Export Codes
                      </Button>
                    </RowInner>
                  </div>
                </ColCard>
                <ColCard minWidth={500}>
                  <div>
                    <FormDivider title="Set Received Date/Time" />
                    <RowInner>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={eventReceivedDateTimeOn !== 'Save'}
                            onChange={ev => handleEventReceivedDateTimeOn(ev, 'incoming')}
                            color="primary"
                          />
                        }
                        label="New Event Incoming"
                      />
                    </RowInner>
                    <RowInner>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={eventReceivedDateTimeOn === 'Save'}
                            onChange={ev => handleEventReceivedDateTimeOn(ev, 'save')}
                            color="primary"
                          />
                        }
                        label="New Event Saved"
                      />
                    </RowInner>
                  </div>
                </ColCard>
                <ColCard minWidth={500}>
                  <div>
                    <FormDivider title="Update Event Status" />
                    <RowInner>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={allowUpdatingWithoutUnit}
                            onChange={handleAllowUpdatingWithoutUnit}
                            color="primary"
                          />
                        }
                        label="Allow Updating Without Units"
                      />
                    </RowInner>
                    <RowInner>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={allowCompletingEvents}
                            onChange={handleAllowCompletingEvents}
                            color="primary"
                          />
                        }
                        label="Autocomplete On All Units Complete"
                      />
                    </RowInner>
                  </div>
                </ColCard>
                <ColCard minWidth={500}>
                  <div>
                    <FormDivider title="Event Note Automation" />
                    <RowInner>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={copySOPAnswers}
                            onChange={handleCopySOPAnswers}
                            color="primary"
                          />
                        }
                        label="Copy SOP Answers To Notes"
                      />
                    </RowInner>
                    <RowInner>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={copyPlaceName}
                            onChange={handleCopyPlaceName}
                            color="primary"
                          />
                        }
                        label="Copy Place Name To Notes"
                      />
                    </RowInner>
                    <RowInner>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={copyEventAtAddress}
                            onChange={handleCopyEventAtAddress}
                            color="primary"
                          />
                        }
                        label="Copy Total Events At Address To Notes"
                      />
                    </RowInner>
                    <RowInner>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={copyAddressDescription}
                            onChange={handleCopyAddressDescription}
                            color="primary"
                          />
                        }
                        label="Copy Address Description To Notes"
                      />
                    </RowInner>
                  </div>
                </ColCard>
                <ColCard minWidth={500}>
                  <div>
                    <FormDivider title="Auto Dispatch" />

                    <RowInner>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={autoDispatchRecommendations}
                            onChange={handleAutoDispatchRecommendations}
                            color="primary"
                          />
                        }
                        label="Use Auto Dispatch Recommendations"
                      />
                    </RowInner>
                  </div>
                </ColCard>
              </Row>
            </div>
          </ColCard>
          <ColCard minWidth={500}>
            <div>
              <FormDivider title="Unit Statuses" />

              <RowInner>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={addDateTimeToNotes}
                      onChange={handleAddDateTimeToNotes}
                      color="primary"
                    />
                  }
                  label="Automatically Add Date/Time to Notes Field"
                />
              </RowInner>
            </div>
          </ColCard>
          <ColCard minWidth={500}>
            <div>
              <FormDivider title="People" />

              <RowInner>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showWarrantInfo}
                      onChange={handleShowWarrantInfo}
                      color="primary"
                    />
                  }
                  label="Automatically Show Warrant Information"
                />
              </RowInner>
            </div>
          </ColCard>
          <ColCard minWidth={500}>
            <div>
              <FormDivider title="Event Attachment Automation" />

              <RowInner>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fetchAttachmentFromAddress}
                      onChange={handleFetchAttachmentFromAddress}
                      color="primary"
                    />
                  }
                  label="Fetch Attachment from Address"
                />
              </RowInner>
            </div>
          </ColCard>
        </Row>
      </div>
    </Content>
  );
}
const mapStateToProps = state => {
  return {
    wsClient: state.websocket,
  };
};

export default connect(mapStateToProps, { handleError, notify })(Automation);
