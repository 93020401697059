import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getService } from 'reducers/service';
import { handleError } from 'reducers/ErrorReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { notify } from 'reducers/NotifierReducer';

export const MoveItems = props => {
  const {
    wsClient,
    selection,
    serviceName,
    keyName,
    moveDialogOpen,
    setMoveDialogOpen,
    AgencyID,
    processDataAfterActions,
  } = props;
  const [selectedAgencyID, setSelectedAgencyID] = useState(null);
  const [agencyOptions, setAgencyOptions] = useState([]);
  const service = getService('ptsagencies');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAgencies = async () => {
      setLoading(true);
      const result = await service.find({ query: { IsDeleted: false, $limit: 0 } });

      if (result.data) {
        const filtered = result.data.filter(item => item.AgencyID !== AgencyID);
        setAgencyOptions(filtered);
        setLoading(false);
      }
    };

    if (wsClient.websocket) {
      fetchAgencies();
    }
  }, [wsClient.websocket]);
  const closeMoveItemDialog = () => {
    setMoveDialogOpen(false);
  };
  const handleMove = async () => {
    if (
      !window.confirm(
        `Are you sure you want to move the selected records to "${selectedAgencyID}" Agency?`
      )
    )
      return;

    const service = getService('agency-related-records');
    try {
      setMoveDialogOpen(false);
      props.showSpinner();
      const result = await service.patch(
        null,
        {
          tableName: serviceName,
          keyName,
          targetAgencyID: selectedAgencyID,
        },
        {
          query: {
            records: selection,
          },
        }
      );
      processDataAfterActions();
      props.notify('Items Moved', 'success');
    } catch (err) {
      props.handleError(err, 'Error, cannot not saved.', err);
    } finally {
      props.hideSpinner();
    }
  };

  return (
    <Dialog open={moveDialogOpen} onClose={closeMoveItemDialog} aria-labelledby="move-items-dialog">
      <DialogTitle id="move-items-dialo">Move Selected Records</DialogTitle>
      <DialogContent>
        <DialogContentText>
          The Selected Records Will Be Moved From {AgencyID} Agency
        </DialogContentText>
        <Autocomplete
          size="small"
          fullWidth
          value={{ AgencyID: selectedAgencyID }}
          onChange={(event, selection) => {
            setSelectedAgencyID(selection?.AgencyID || null);
          }}
          loading={loading}
          loadingText={'Data is Loading, please Wait...'}
          options={agencyOptions}
          getOptionLabel={option => option.AgencyID || ''}
          renderOption={option => (
            <span>
              <strong>{option.AgencyID}</strong>
              {!!option.Description && <> - {option.Description}</>}
            </span>
          )}
          renderInput={params => <TextField {...params} label="Agency" variant="outlined" />}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeMoveItemDialog} color="primary">
          Cancel
        </Button>

        <Button onClick={handleMove} color="primary" disabled={!selectedAgencyID}>
          Move
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = state => ({
  wsClient: state.websocket,
});

const mapDispatchToProps = { showSpinner, hideSpinner, handleError, notify };

export default connect(mapStateToProps, mapDispatchToProps)(MoveItems);
