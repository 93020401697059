import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Content from 'Settings/Content';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Dialog from 'components/Dialog';

import CloseIcon from '@material-ui/icons/Close';
import {
  findSearchProfiles,
  findSearchGroups,
  getSearchProfileGroups,
  findSearchProfileGroups,
  saveSearchProfileGroups,
  removeProfile,
  findDeletedProfiles,
  restoreProfile,
} from '../../SecFunctions';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import { handleError } from 'reducers/ErrorReducer';
import { notify } from 'reducers/NotifierReducer';
import { Fills, Row } from 'utils/formStyles';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Tooltip from 'components/Tooltip';
import { showAddSecProfile, showEditSecProfile } from 'reducers/DialogsReducer';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Switch from '@mui/material/Switch';
import RestoreIcon from '@mui/icons-material/Restore';
import { XGrid } from '@material-ui/x-grid';
import QuickFilter from 'components/QuickFilter';
import { Box, DialogContentText, Grid } from '@material-ui/core';
import RolesSection from './RolesSection';
import UserSection from './UserSection';
import { getService } from 'reducers/service';
import { hideSpinner, showSpinner } from 'reducers/UiReducer';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { sortObjArr } from 'utils/functions';

const useStyles = makeStyles(theme => ({
  wrapper: {
    margin: 20,
  },
  header: {
    marginBottom: 30,
  },
  roles: {
    marginTop: 30,
  },
  autoComplete: {
    maxWidth: 300,
    marginTop: 10,
  },
  checkStyle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '5px',
  },
  item: {
    flex: '1 1 250px',
    margin: '5px',
  },
  btn: {
    textAlign: 'right',
    marginRight: '10px',
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: `calc(100vh - 240px)`,
    [theme.breakpoints.down('lg')]: {
      height: '300px',
    },
  },
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0, 2),
    textAlign: 'right',
    alignItems: 'center',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
}));
const renderProfile = params => {
  const { row, value } = params;
  return <span>{row.IsDeleted ? <s style={{ opacity: 0.5 }}>{value}</s> : value}</span>;
};
const columnsItems = [
  { field: 'Label', headerName: 'Profile Name', width: 150, renderCell: renderProfile },
  { field: 'Type', headerName: 'Type' },
];
function SearchProfiles(props) {
  const classes = useStyles();
  const { dataUpdate } = props;
  const [allProfileGroups, setAllProfileGroups] = useState([]);
  const [profile, setProfile] = useState(null);
  const [profileWithGroups, setProfileWithGroups] = useState(null);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState([]);
  const [profileLoader, setProfileLoader] = useState(true);
  const [rolesLoader, setRolesLoader] = useState(false);
  const [profileSwitchFilter, setProfileSwitchedFilter] = useState('active');
  const [processedData, setProcessedData] = useState([]);
  const [userList, setUserList] = useState([]);
  const [userLoader, setUserLoader] = useState(true);
  const [disableDelete, setDisableDelete] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    fetchProfiles();
    fetchProfileGroups();
  }, []);
  useEffect(() => {
    if (profileLoader || rolesLoader || userLoader) {
      dispatch(showSpinner());
    } else {
      dispatch(hideSpinner());
    }
  }, [profileLoader, userLoader, rolesLoader]);
  useEffect(() => {
    fetchCurrentProfileGroups();
  }, [profile]);

  useEffect(() => {
    if (dataUpdate && (dataUpdate.type === 'add-profile' || dataUpdate.type === 'edit-profile'))
      fetchProfiles();
  }, [dataUpdate]);
  useEffect(() => {
    handleProfileSwitchedFilter(null, profileSwitchFilter);
  }, [filteredRows]);
  const fetchProfiles = async () => {
    setProfileLoader(true);
    const secProfileService = getService('secprofile');
    let result = await secProfileService.find({ query: { $limit: 0 } });

    const sortedData = sortObjArr(result.data, 'Label');
    setRows(sortedData);

    setProfileLoader(false);
  };

  const fetchProfileGroups = async () => {
    const result = await findSearchProfileGroups();
    setAllProfileGroups(result);
  };

  const fetchCurrentProfileGroups = async () => {
    if (!profile) return;
    setRolesLoader(true);

    const groups = [];
    const currentProfileGroups = await getSearchProfileGroups(profile.ptsProfileID);
    currentProfileGroups.forEach(group => {
      groups.push(group.ptsGroupID);
    });
    const newProfile = { ...profile, Groups: groups };
    setProfileWithGroups(newProfile);
    setRolesLoader(false);
  };

  const handleSaveClick = async currentSelection => {
    const formatedCurrentSelection = currentSelection.map(item => Number(item));
    const prevValues = profileWithGroups.Groups || [];

    const existingGroups = allProfileGroups
      .filter(pg => pg.ptsProfileID === profile.ptsProfileID)
      .map(pg => pg.ptsGroupID);

    const formatedData = [];

    prevValues.forEach(item => {
      if (formatedCurrentSelection.indexOf(item) === -1) {
        formatedData.push({ ptsGroupID: item, action: 'delete' });
      }
    });
    formatedCurrentSelection.forEach(item => {
      if (prevValues.indexOf(item) === -1) {
        if (existingGroups.indexOf(item) !== -1) {
          formatedData.push({ ptsGroupID: item, action: 'update' });
        } else {
          formatedData.push({ ptsGroupID: item, action: 'create' });
        }
      }
    });
    const data = {
      ProfileId: profile.ptsProfileID,
      values: formatedData,
    };
    if (formatedData.length === 0) {
      props.notify('Nothing to updated', 'warning');
      return;
    }
    try {
      await saveSearchProfileGroups(data);
      fetchCurrentProfileGroups();
      props.notify('Profile Roles Updated', 'success');
    } catch (err) {
      props.handleError(err, 'Error Updating Roles');
    }
  };

  const addSecProfile = () => props.showAddSecProfile();

  const editSecProfile = () => props.showEditSecProfile(profile.ptsProfileID);

  const deleteSecProfile = async () => {
    try {
      await removeProfile(profile.ptsProfileID);
      setDeleteConfirmation(false);
      setProfile(null);
      fetchProfiles();
      props.notify('Profile Removed', 'success');
    } catch (err) {
      props.handleError(err, 'Error Removing Profile');
    }
  };

  const undeleteProfile = async () => {
    if (!window.confirm('Are you sure you want to restore this profile?')) return;
    try {
      await restoreProfile(profile.ptsProfileID);

      fetchProfiles();
      setProfile(null);
      props.notify('Profile Restored', 'success');
    } catch (err) {
      props.handleError(err, 'Error Restoring Profile');
    }
  };

  const handleSelectChange = data => {
    const { selectionModel } = data;

    if (selectionModel[0] == profile?.ptsProfileID) {
      setSelection([]);
    } else {
      setSelection(selectionModel);
    }
    const selected = rows.find(row => row.ptsProfileID == selectionModel[0]);
    const isUserExistWithThisProfile = userList.find(
      user => user.ptsProfileID === selected?.ptsProfileID && user.IsDeleted === false
    );
    if (isUserExistWithThisProfile) {
      setDisableDelete(true);
    } else {
      setDisableDelete(false);
    }
    setProfile(selected);
  };

  const handleProfileSwitchedFilter = (e, newValue) => {
    if (e) setProfileSwitchedFilter(newValue);
    let profileList = filteredRows;
    setProcessedData(profileList);

    if (newValue === 'active') {
      profileList = profileList.filter(item => item.IsDeleted === false);
    } else if (newValue === 'deleted') {
      profileList = profileList.filter(item => item.IsDeleted === true);
    }

    setProcessedData(profileList);
  };
  const renderDeleteRolesActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={deleteSecProfile}>
          <DeleteIcon /> Remove
        </Button>
        <Button color="primary" onClick={() => setDeleteConfirmation(false)}>
          <CloseIcon />
          Close
        </Button>
      </>
    );
  };
  const renderActions = () => {
    const disabled = selection.length === 0;

    return (
      <div className={classes.actions}>
        <div style={{ textAlign: 'left' }}>
          <QuickFilter
            minWidth={'150px'}
            marginTop="10px"
            rows={rows}
            columns={columnsItems}
            setFilteredRows={setFilteredRows}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Box>
            <ToggleButtonGroup
              value={profileSwitchFilter}
              exclusive
              size="small"
              onChange={handleProfileSwitchedFilter}
              style={{ width: '100%', textAlign: 'left' }}
              aria-label="Profile Filter">
              <ToggleButton value="active" aria-label="active" className={classes.toggleItem}>
                Active
              </ToggleButton>
              <ToggleButton value="all" aria-label="all" fullWidth className={classes.toggleItem}>
                All
              </ToggleButton>
              <ToggleButton
                value="deleted"
                aria-label="deleted"
                fullWidth
                className={classes.toggleItem}>
                Deleted
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Fab size="small" color="secondary" onClick={addSecProfile}>
            <AddIcon />
          </Fab>
          {profile && profile.IsDeleted ? (
            <Fab size="small" color="secondary" onClick={undeleteProfile} disabled={disabled}>
              <RestoreIcon />
            </Fab>
          ) : (
            <>
              <Fab size="small" color="secondary" onClick={editSecProfile} disabled={disabled}>
                <EditIcon />
              </Fab>
              <Fab
                size="small"
                color="secondary"
                onClick={() => {
                  setDeleteConfirmation(true);
                }}
                disabled={disabled || disableDelete}>
                <DeleteIcon />
              </Fab>
            </>
          )}
        </div>
      </div>
    );
  };
  return (
    <Content>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={6} xl={4}>
          <div className="m-2">
            <h4>Profiles</h4>

            {renderActions()}
            <div className={classes.gridWrap}>
              <XGrid
                columns={columnsItems}
                rows={processedData}
                loading={profileLoader}
                rowHeight={38}
                selectionModel={selection}
                getRowId={row => row.ptsProfileID}
                // checkboxSelection
                onSelectionModelChange={handleSelectChange}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} lg={6} xl={4}>
          <RolesSection
            profile={profile}
            profileWithGroups={profileWithGroups}
            rolesLoader={rolesLoader}
            handleSaveClick={handleSaveClick}
            setProfileWithGroups={setProfileWithGroups}
            allProfileGroups={allProfileGroups}
            allProfiles={rows}
          />
        </Grid>
        <Grid item xs={12} lg={6} xl={4}>
          <UserSection
            profile={profile}
            userList={userList}
            setUserList={setUserList}
            setUserLoader={setUserLoader}
          />
        </Grid>
      </Grid>
      {deleteConfirmation && (
        <Dialog
          toolbar
          title="Remove Profile"
          actions={renderDeleteRolesActions()}
          maxWidth="md"
          fullWidth>
          <DialogContentText>
            Are you sure you want to remove Profile <b> {profile.Label}</b>?
            <br />
          </DialogContentText>
        </Dialog>
      )}
    </Content>
  );
}

const mapStateToProps = state => {
  return {
    dataUpdate: state.dataUpdate,
  };
};

export default connect(mapStateToProps, {
  handleError,
  notify,
  showAddSecProfile,
  showEditSecProfile,
})(SearchProfiles);
