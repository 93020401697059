import { getService } from 'reducers/service';
export const processData = data => {
  const obj = {};
  let val = null;

  data.forEach(item => {
    const { Path, CDRKey, ValueString, ValueNumeric, ValueBoolean } = item;
    if (CDRKey === 'FieldUsed' || CDRKey === 'AddressLookup') {
      val = ValueBoolean;
    } else if (CDRKey === 'EndPosition' || CDRKey === 'StartPosition') {
      val = String(ValueNumeric);
    } else if (CDRKey === 'XmlNode') {
      val = ValueString;
    } else if (CDRKey === 'ConnectionSetup') {
      const valueType = checkValueType(Path);
      if (valueType === 'string') {
        val = ValueString;
      } else if (valueType === 'number') {
        val = String(ValueNumeric);
      }
    }

    if (obj[Path]) {
      obj[Path][CDRKey] = val;
    } else {
      obj[Path] = {
        [CDRKey]: val,
      };
    }
  });
  return obj;
};

export const handleSaveE911Setup = async (data, prevdata = [], userName) => {
  const service = getService('e911-setup');
  const queries = processQueries(data);

  const results = await Promise.all(
    queries.map(query => {
      const isExist = prevdata.find(
        prev => prev.Path === query.Path && prev.CDRKey === query.CDRKey
      );
      const { Path, CDRKey, ...value } = query;
      return isExist
        ? service.patch(null, { Path, CDRKey, valueType: { ...value }, UpdatedBy: userName })
        : service.create({
            Path,
            CDRKey,
            ...value,
            CreatedBy: userName,
            UpdatedBy: userName,
          });
    })
  );

  return results;
};
const processQueries = data => {
  const queries = [];
  Object.entries(data).forEach(([Path, val]) => {
    Object.entries(val).forEach(([CDRKey, inputValue]) => {
      const obj = { Path: Path, CDRKey };
      if (CDRKey === 'FieldUsed' || CDRKey === 'AddressLookup') {
        obj['ValueBoolean'] = inputValue;
      } else if (CDRKey === 'EndPosition' || CDRKey === 'StartPosition') {
        obj['ValueNumeric'] = Number(inputValue);
      } else if (CDRKey === 'XmlNode') {
        obj['ValueString'] = inputValue;
      } else if (CDRKey === 'ConnectionSetup') {
        const valueType = checkValueType(Path);
        if (valueType === 'string') {
          obj['ValueString'] = inputValue;
        } else if (valueType === 'number') {
          obj['ValueNumeric'] = Number(inputValue);
        }
      }
      queries.push({ ...obj });
    });
  });
  return queries;
};
const checkValueType = Path => {
  const stringType = {
    'E911Collector.ReadFileFolder': 1,
    'E911Collector.Parity': 1,
  };

  if (stringType[Path]) {
    return 'string';
  }
  return 'number';
};

export const getFields = (type, fromParser) => {
  const selectedFields = type == '2' ? FiledsForTcp : fileds;
  return selectedFields;
};
const stattionID = { Path: 'E911Collector.StationID', label: 'Station ID' };
const fileds = [
  { Path: 'E911Collector.StationID', label: 'Station ID', hideCheckbox: true },
  {
    Path: 'E911Collector.Date',
    label: 'Date',
  },
  {
    Path: 'E911Collector.Time',
    label: 'Time',
  },
  {
    Path: 'E911Collector.EID',
    label: 'EID',
  },
  {
    Path: 'E911Collector.Caller.Name',
    label: 'Caller Name',
  },
  {
    Path: 'E911Collector.Caller.AreaCode',
    label: 'Caller Area Code',
  },
  {
    Path: 'E911Collector.Caller.Phone',
    label: 'Caller Phone',
  },
  {
    Path: 'E911Collector.Location.Address.Number',
    label: 'Address Number',
  },
  {
    Path: 'E911Collector.Location.Address.Number.Suffix',
    label: 'Address Number Suffix',
  },
  {
    Path: 'E911Collector.Location.Address.Street',
    label: 'Address Street',
  },
  {
    Path: 'E911Collector.Location.Address.PreDirectional',
    label: 'Address Pre-Directional',
  },
  {
    Path: 'E911Collector.Location.Address.PostDirectional',
    label: 'Address Post-Directional',
  },
  {
    Path: 'E911Collector.Location.Address.UnitNumber',
    label: 'Address Unit Number',
  },
  {
    Path: 'E911Collector.Location.Address.Community',
    label: 'Comunity (City)',
    subLabel: 'Comunity',
  },
  {
    Path: 'E911Collector.Location.Address.State',
    label: 'State',
  },
  {
    Path: 'E911Collector.Location.Address.XCoordinate',
    label: 'X Coordinate',
  },
  {
    Path: 'E911Collector.Location.Address.YCoordinate',
    label: 'Y Coordinate',
  },
  {
    Path: 'E911Collector.ESNCode',
    label: 'ESN Code',
    subLabel: 'ESN',
  },
  {
    Path: 'E911Collector.PoliceAgency',
    label: 'Police Agency',
  },
  {
    Path: 'E911Collector.FireAgency',
    label: 'Fire Agency',
  },
  {
    Path: 'E911Collector.Notes',
    label: 'Notes',
  },
  {
    Path: 'E911Collector.ExtraPhone1',
    label: 'Extra Phone 1',
  },
  {
    Path: 'E911Collector.ExtraPhone2',
    label: 'Extra Phone 2',
  },
];
const FiledsForTcp = [
  { Path: 'E911Collector.StationID', label: 'Station ID' },
  {
    Path: 'E911Collector.Date',
    label: 'Date',
  },
  {
    Path: 'E911Collector.Time',
    label: 'Time',
  },
  {
    Path: 'E911Collector.EID',
    label: 'EID',
  },
  {
    Path: 'E911Collector.CallMethod',
    label: 'Call Method',
  },
  {
    Path: 'E911Collector.Caller.Name',
    label: 'Caller Name',
  },
  {
    Path: 'E911Collector.Caller.AreaCode',
    label: 'Caller Area Code',
  },
  {
    Path: 'E911Collector.Caller.Phone',
    label: 'Caller Phone',
  },
  {
    Path: 'E911Collector.Caller.Notes',
    label: 'Caller Note',
  },
  {
    Path: 'E911Collector.Location.Address.Number',
    label: 'Address Number',
  },
  {
    Path: 'E911Collector.Location.Address.Number.Suffix',
    label: 'Address Number Suffix',
  },
  {
    Path: 'E911Collector.Location.Address.Street',
    label: 'Address Street',
  },
  {
    Path: 'E911Collector.Location.Address.PreDirectional',
    label: 'Address Pre-Directional',
  },
  {
    Path: 'E911Collector.Location.Address.PostDirectional',
    label: 'Address Post-Directional',
  },
  {
    Path: 'E911Collector.Location.Address.UnitNumber',
    label: 'Address Unit Number',
  },
  {
    Path: 'E911Collector.Location.Address.BldgIdentifier',
    label: 'Building Identifier',
  },
  {
    Path: 'E911Collector.Location.Address.FloorIdentifier',
    label: 'Floor Identifier',
  },
  {
    Path: 'E911Collector.Location.Address.Community',
    label: 'Comunity (City)',
    subLabel: 'Comunity',
  },
  {
    Path: 'E911Collector.Location.Address.State',
    label: 'State',
  },
  {
    Path: 'E911Collector.Location.Address.XCoordinate',
    label: 'X Coordinate',
  },
  {
    Path: 'E911Collector.Location.Address.YCoordinate',
    label: 'Y Coordinate',
  },
  {
    Path: 'E911Collector.ESNCode',
    label: 'ESN Code',
    subLabel: 'ESN',
  },
  {
    Path: 'E911Collector.PoliceAgency',
    label: 'Police Agency',
  },
  {
    Path: 'E911Collector.FireAgency',
    label: 'Fire Agency',
  },
  {
    Path: 'E911Collector.Notes',
    label: 'Notes',
  },
  {
    Path: 'E911Collector.ExtraPhone1',
    label: 'Extra Phone 1',
  },
  {
    Path: 'E911Collector.ExtraPhone2',
    label: 'Extra Phone 2',
  },
];
