import { Button, makeStyles } from '@material-ui/core';
import AddressLookup from 'components/AddressLookup';
import PersonLookup from 'components/PersonLookup';
import PlaceLookup from 'components/PlaceLookup';
import React, { createContext, useContext, useState } from 'react';
import SaveIcon from '@material-ui/icons/Save';
import { gridStyle } from 'utils/formStyles';
import { useDispatch, useSelector } from 'react-redux';
import Dictionary from 'components/Dictionary';
import { getService } from 'reducers/service';
import { notify } from 'reducers/NotifierReducer';
import { handleError } from 'reducers/ErrorReducer';
import { hideSpinner, showSpinner } from 'reducers/UiReducer';
import { ReloadContext } from '.';
const useStyles = makeStyles(theme => ({
  cardWrapper: {
    padding: '10px',
    paddingLeft: '0px',
    display: 'flex',
    gap: '15px',
    justifyContent: 'left',
  },
  item: gridStyle(200, 320),
}));

const TopCard = props => {
  const classes = useStyles();
  const { parent: ParentType, ptsSOPID, rows, setRows } = props;
  const service = getService('ptsassignedsops');
  const [id, setId] = useState(null);
  const dictionary = useSelector(state => state.dictionary);
  const dispatch = useDispatch();
  const { reload, setReload } = useContext(ReloadContext);
  const user = useSelector(state => state.user);
  const username = user?.userData?.user?.Username;
  //   console.log('dictionary', dictionary);
  const renderLookup = () => {
    if (ParentType === 'Person') {
      return (
        <PersonLookup
          className={classes.item}
          onPersonChange={val => setId(val?.ptsPersonID || null)}
          label="Person"
          ptsPersonID={id}
          compact
        />
      );
    } else if (ParentType === 'Address') {
      return (
        <AddressLookup
          ptsPlaces={false}
          ptsAddresses={true}
          googleAddresses={false}
          onPlaceValueSet={val => setId(val?.ptsAddressID || null)}
          onAddressValueSet={val => setId(val?.ptsAddressID || null)}
          onReset={() => setId(null)}
          label="Search Address"
          className={classes.item}
          compact
        />
      );
    } else if (ParentType === 'Place') {
      return (
        <PlaceLookup
          onChange={val => setId(val?.ptsPlaceID || null)}
          className={classes.item}
          label="Place"
          ptsPlaceID={id}
        />
      );
    } else if (ParentType === 'Zone') {
      return (
        <Dictionary
          className={classes.item}
          options="Zones"
          value={id}
          onChange={(ev, val) => setId(val || null)}
          label="Zones"
        />
      );
    } else if (ParentType === 'CADType') {
      return (
        <Dictionary
          className={classes.item}
          options="CallTypes"
          value={id}
          onChange={(ev, val) => {
            setId(val || null);
          }}
          label="Event Types"
        />
      );
    }
  };
  const saveSop = async () => {
    let ptsParentID = id;
    if (ParentType === 'Zone' || ParentType === 'CADType') {
      ptsParentID = id.IntCode;
    }
    try {
      dispatch(showSpinner());
      const isExist = await service.find({
        query: { ptsSOPID, ParentType, ptsParentID },
      });

      if (isExist.total && isExist.total > 0) {
        if (isExist.data[0].IsDeleted === false) {
          dispatch(notify('Already Exist', 'warning'));
          return;
        }
        const data = await service.patch(
          null,
          {
            IsDeleted: false,
            Deleted: null,
            DeletedBy: null,
            DeletedIP: null,
            UpdatedBy: username,
          },
          { ptsSOPID, ParentType, ptsParentID }
        );
        setReload(!reload);
        dispatch(notify('SOP Updated', 'success'));
        console.log('update');
      } else {
        const data = await service.create({ ptsSOPID, ParentType, ptsParentID });
        setReload(!reload);
        dispatch(notify('SOP Updated', 'success'));
      }
    } catch (err) {
      console.log('error', err);
      dispatch(handleError(err, 'Error, SOP not saved'));
    } finally {
      dispatch(hideSpinner());
    }
  };
  return (
    <div className={classes.cardWrapper}>
      {renderLookup()}
      <Button
        style={{ maxHeight: '40px' }}
        variant="contained"
        color="primary"
        size="small"
        onClick={saveSop}
        disabled={!id}>
        <SaveIcon /> Save
      </Button>
    </div>
  );
};

export default TopCard;
