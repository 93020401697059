
import store from '../config/configureStore';
import { showSpinner, hideSpinner } from './UiReducer';
import { notify, notifyPanel } from './NotifierReducer';
import { updateUnits } from './UnitsReducer';
import { handleError } from './ErrorReducer';
import { getService } from './service';
import settings from '../config/settings';
import { processSOPs } from './SopsReducer';
import { formatDateFrom, formatDateTo } from '../utils/functions';
import { addCoordsToLocation } from '../utils/mapFunctions';
import { getZoneMatch } from './ZonesReducer';

let eventsService = false;

export const SET_EVENTS = 'EVENTS/SET_EVENTS';
export const CLEAR_EVENTS = 'EVENTS/CLEAR_EVENTS';

export const addDispositions = (dispositions) => {
  return async dispatch => {
    try {
      const service = getService('event-dispositions');
      await service.create(dispositions);
      dispatch(updateEvents());
      dispatch(updateUnits());
    } catch (error) {
      dispatch(handleError(error, 'Error, disposition not created'));
    }
  };
};

export const removeDisposition = (ptsEventID, disposition) => {
  const { AgencyID, Disposition } = disposition;
  return async dispatch => {
    try {
      const service = getService('event-dispositions');
      dispatch(showSpinner());
      await service.remove(ptsEventID, { query: { AgencyID, Disposition }});
      dispatch(updateEvents());
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

export const eventStatusChange = (ptsEventID, Status) => {
  const state = store.store.getState()
  const  { options, dictionary } = state.config;
  const event = state.events.find(ev => ev.ptsEventID === ptsEventID);
  const findDispositionIntCode = (disposition) => {
    const { Disposition, AgencyID } = disposition;
    const obj = dictionary.Dispositions.find(d => d.AgencyID === AgencyID && d.Code === Disposition);
    return obj ? obj.IntCode : null;
  }

  const getDispositionsRequired = () => {
    // Dispositions Required
    const agencies = [];
    const currentDispAgencies = [];
    const requiredDispAgencies = [];
    const requiredDispositionsCodes = [];
    const currentDispositionCodes = [];

    const { UnitStatuses, dispositions } = event;
    UnitStatuses && UnitStatuses.forEach(unit => {
      if (!agencies.find(agency => agency === unit.AgencyID)) {
        agencies.push(unit.AgencyID);
      }
    });
    dispositions && dispositions.forEach(disposition => {
      if (!currentDispAgencies.find(d => d === disposition.AgencyID)) {
        currentDispAgencies.push(disposition.AgencyID);
      }
      const IntCode = findDispositionIntCode(disposition);
      if (!currentDispositionCodes.find(d => d === IntCode)) {
        currentDispositionCodes.push(IntCode);
      }
    });
    
    agencies.forEach(agency => {
      // Require disposition
      if (options.RequireDisposition[agency] && !currentDispAgencies.find(d => d === agency)) {
        requiredDispAgencies.push(agency);
      }
      // Require Merged dispositions
      const IntCode = parseInt(options.DefaultMergedDispositionCode[agency]);
      if (
        options.RequireMergedDisposition[agency] &&
        !currentDispositionCodes.find(c => c === IntCode)
      ) {
        requiredDispositionsCodes.push(IntCode);
      }
    });

    // Check if Require dispositions are beign met
    if (requiredDispAgencies.length) {
      return 'A disposition is required by the following: ' +
        requiredDispAgencies.reduce((result, value, idx) => {
        if (idx) result += ', ';
        return result + value;
      }, '');
    }

    // Check if merged dispositions are beign met
    if (requiredDispositionsCodes.length) {
      return 'The following dispositions are missing: ' +
      requiredDispositionsCodes.reduce((result, value, idx) => {
        if (idx) result += ', ';
        const disposition = dictionary.Dispositions.find(d => d.IntCode === value);
        return `${result} ${disposition.Description} (${disposition.AgencyID})`;
      }, '');
    }

    return false;
  }

  return async dispatch => {
    const dispositionRequired = getDispositionsRequired();
    if (Status === 'Closed' && dispositionRequired) {
      const { EventID } = event;
      const notification = {
        title: EventID,
        message: dispositionRequired,
      };
      dispatch(notifyPanel(notification, 'error'));
    } else {
      try {
        const service = getService('event-status-change');
        await service.update(ptsEventID, { ptsEventID, Status });
        dispatch(updateEvents());
        dispatch(updateUnits());
      } catch (error) {
        dispatch(handleError(error));
      }
    }
  };
};

export const updateEvents = () => async dispatch => {
  if (settings.synchronizeData) {
    dispatch(getEvents());
  } else {
    dispatch(hideSpinner())
  }
}

let getEventsTimeout = 0;
export const getEvents = () => {
  return async dispatch => {
    clearTimeout(getEventsTimeout);
    getEventsTimeout = setTimeout(() => {
      const time = new Date().getTime();
      const service = getService('cmplx-events');
      service.find()
        .then(events => console.log('get events: ', new Date().getTime() - time) || dispatch({ type: SET_EVENTS, events: processEvents(events) }))
        .catch(error => dispatch(handleError(error)))
        .finally(() =>  dispatch(hideSpinner()));
    }, settings.reqThrottlingTime);
  };
};

export const newEvent = (eventData) => {
  const state = store.store.getState()
  const UserName = state.user.userData.user.Username;
  const data = { ...eventData, UserName };
  const service = getService();
  return service.create({ type: 'new-event', data });
};

export const newCaseID = (ptsEventID, AgencyID) => {
  const data = { ptsEventID, AgencyID };
  return async dispatch => {
    try {
      const service = getService();
      const result = await service.create({ type: 'add-caseid', data });
      if (result) {
        dispatch(notify('New Case ID created', 'success'));
      } else {
        dispatch(notify('Case ID not created, check agency specific settings', 'warning'));
      }
      dispatch(updateEvents());
    } catch (error) {
      dispatch(handleError(error, 'Error, Case ID not created'));
    }
  };
}

export const subscribeEvents = () => {
  const state = store.store.getState();
  const authenticated = state.user.isAuthenticated;
  const client = state.websocket.websocket;
  return async dispatch => {
    if (!client || !authenticated) return;
    dispatch(getEvents());
    try {
      eventsService = client.service('cmplx-events');
      eventsService.on('newEventsData', events => {
        dispatch({ type: SET_EVENTS, events: processEvents(events) });
      });
      eventsService.on('unhandledRejection', (reason, p) => {
        console.log("EventReducer Unhandled Rejection at: Promise ", p, " reason: ", reason);
      });
    } catch (error) {
      dispatch(handleError(error));
    }
  }
}

export const unsubscribeEvents = () => {
  if (eventsService) {
    try {
      eventsService.off('newEventsData');
      eventsService = false;
    } catch (error) {
      console.log('EventsReducer/unsubscribeEvents: error: ', error, error.code)
    }
  }
  return () => {};
}

export const clearEvents = () => dispatch => {
  dispatch({ type: CLEAR_EVENTS });
}


// ===========  REDUCERS  ======================

export default function reducer( state = false, action) {
  switch (action.type) {
    case SET_EVENTS:
      return action.events;
    case CLEAR_EVENTS:
      return false;
    default:
      break;
  }
  return state;
}

// ===============  HELPER fUNCTIONS  ==============

function processEvents(events) {
  const newEvents = events.map(event => {
    const newEvent = {
      ...event,
      CaseIds: JSON.parse(event.CaseIds),
      UnitStatuses: JSON.parse(event.UnitStatuses),
      attachments: JSON.parse(event.Attachments),
      EventRouting: JSON.parse(event.EventRouting),
    }

    const { 
      ArrivedDate, CancelledDate, CompletedDate, DispatchedDate, EnrouteDate, QueuedDate,
      ReceiveDate, 
    } = newEvent;

    let EventChangeDate = null;

    if (CancelledDate) { EventChangeDate = CancelledDate }
    else if (CompletedDate) { EventChangeDate = CompletedDate }
    else if (ArrivedDate) { EventChangeDate = ArrivedDate }
    else if (EnrouteDate) { EventChangeDate = EnrouteDate }
    else if (DispatchedDate) { EventChangeDate = DispatchedDate }
    else if (QueuedDate) { EventChangeDate = QueuedDate }
    else if (ReceiveDate) { EventChangeDate = ReceiveDate }
    newEvent.EventChangeDate = EventChangeDate;
    newEvent.sops = processSOPs(newEvent);

    return newEvent;
  });
  return newEvents;
}

// ============ Services that ommit redux and returns results directly =================
  /**
   *  Return SOPs numbers for different data types (0 if not found) 
   * 
   *  In data object we should provide AssignedType, ptsParentID and ParentCode params.
   * 
   *  AssignedType can be Person, Place, Address, CADType, CADSubType or Zone.
   *  If AssignedType is one of the following: Person, Place or Address we should provide ptsParentID
   *    pointing to key in ptsPerson, ptsPlace or ptsAddress.
   *  If AssignedType is CADType, CADSubType or Zone we should provide ParentCode pointing to
   *    Code from codeCADTypes table, codeCADSubTypes or codeZones.
   */
  export const veryfySOP = (AssignedType, ptsParentID, ParentCode) => {
    const service = getService('cad');
    return service.get({
      type: 'verify-sops',
      data: { AssignedType, ptsParentID, ParentCode }
    });
  }

export const getEventData = (ptsEventID) => {
  const service = getService('cad');
  return service.get({
    type: 'event-form-details',
    data: { ptsEventID }
  });
}

export const getEventRouting = (ptsEventID) => {
  const service = getService('cad');
  return service.get({
    type: 'event-routing',
    data: { ptsEventID }
  });
}

export const getEventLocatins = (ptsEventID) => {
  const service = getService('cad');
  return service.get({
    type: 'event-locations',
    data: { ptsEventID }
  });
}

export const addEventRouting = (AgencyID, ptsEventID) => {
  const service = getService('cad');
  return service.create({
    type: 'create-event-routing',
    data: { AgencyID, ptsEventID }
  });
}

export const saveEventNote = (data, ptsEventID = null) => {
  const { Comment, ptsCommentID } = data;
  const service = getService('cad');
  if (ptsCommentID) {
    return service.patch(ptsCommentID, {
      type: 'update-event-note',
      data: { Comment, ptsCommentID }
    });
  }
  return service.create({
    type: 'add-event-note',
    data: { Comment, ptsEventID }
  });
}

export const addEventNote = (Comment, ptsEventID) => {
  const service = getService('cad');
  return service.create({
    type: 'add-event-note',
    data: { Comment, ptsEventID }
  });
}

export const removeEventNote = (ptsCommentID) => {
  const service = getService('cad');
  return service.patch(
    ptsCommentID,
    { type: 'remove-event-note', ptsCommentID }
  );
}

export const saveEventLocation = (data, ptsEventID) => {
  const service = getService('cad');
  const { ptsLocationAddressID } = data;
  if (ptsLocationAddressID) {
    return service.patch(ptsEventID, {
      type: 'update-event-location',
      data
    });
  }
  return service.create({
    type: 'add-event-location',
    data: { data, ptsEventID }
  });
}

export const saveLocation = async (location, ptsEventID, dictionary) => {
  try {
    const updatedLocation = await addCoordsToLocation(location, dictionary);
    if (!updatedLocation) return;
    updatedLocation.zones = await getZoneMatch(updatedLocation);
    return await saveEventLocation(updatedLocation, ptsEventID);
  } catch (err) {
    throw new Error('Error, problems with adding location');
  }
}

export const saveEventCaller = (data, ptsEventID) => {
  const service = getService('cad');
  const { ptsCallerID } = data;
  if (ptsCallerID) {
    return service.patch(ptsCallerID, { type: 'update-event-caller', data });
  }
  return service.create({
    type: 'add-event-caller',
    data: { data, ptsEventID }
  });
}

export const saveEventEvent = (data) => {
  const service = getService('cad');
  return service.patch(data.ptsEventID, { type: 'update-event-event', data });
}

export const removeEventCaller = (ptsCallerID) => {
  const service = getService('cad');
  return service.patch(
    ptsCallerID,
    { type: 'remove-event-caller', ptsCallerID }
  );
}

export const removeEventLocation = (ptsLocationAddressID) => {
  const service = getService('cad');
  return service.patch(
    ptsLocationAddressID,
    { type: 'remove-event-location', ptsLocationAddressID }
  );
}

export const getPlace = (ptsPlaceID) => {
  const service = getService('cad');
  return service.get({
    type: 'get-place',
    data: { ptsPlaceID }
  });
}

export const getPlaceName = (ptsLocationID) => {
  const service = getService('cad');
  return service.get({
    type: 'get-place-name',
    data: { ptsLocationID }
  });
}

export const saveParty = (ROWGUID, data) => {
  const service = getService('cad');
  const { party } = data;
  if (ROWGUID) { // update data
    if (party === 'caller') {
      return service.patch(ROWGUID, { type: 'update-event-party-caller', data });
    } else if (party === 'person') {
      return service.patch(ROWGUID, { type: 'update-event-party-person', data });
    } else if (party ==='place') {
      return service.patch(ROWGUID, { type: 'update-event-party-place', data });
    }
  } else { // create data
    if (party === 'caller') {
      return service.create({ type: 'create-event-party-caller', data });
    } else if (party === 'person') {
      return service.create({ type: 'create-event-party-person', data });
    } else if (party ==='place') {
      return service.create({ type: 'create-event-party-place', data });
    }
  }

}

export const getPlaceDetails = (ptsPlaceID) => {
  const service = getService('cad');
  return service.get({
    type: 'party-place-details',
    data: { ptsPlaceID }
  });
}

export const findPartyPerson = (searchText) => {
  const service = getService('cad');
  return service.get({
    type: 'find-party-person',
    data: { searchText }
  });
}

export const getPersonContactInfo = (ptsPersonID) => {
  const service = getService('cad');
  return service.get({
    type: 'get-person-contact-info',
    data: { ptsPersonID }
  });
}

export const getPartyPerson = (ptsPersonID) => {
  const service = getService('cad');
  return service.get({
    type: 'get-party-person',
    data: { ptsPersonID }
  });
}

export const delPartyCaller = (ROWGUID) => {
  const service = getService('cad');
  return service.patch(
    ROWGUID,
    { type: 'remove-event-party-caller' }
  );
}

export const delPartyPerson = (ROWGUID) => {
  const service = getService('cad');
  return service.patch(
    ROWGUID,
    { type: 'remove-event-party-person' }
  );
}

export const delPartyPlace = (ROWGUID) => {
  const service = getService('cad');
  return service.patch(
    ROWGUID,
    { type: 'remove-event-party-place' }
  );
}

export const getEventHistory = async (dateFrom, dateTo, filter) => {
  const service = getService();
  return service.get({
    type: 'event-history',
    data: { 
      dateFrom: formatDateFrom(dateFrom),
      dateTo: formatDateTo(dateTo),
      filter,
    }
  });
}

export const getEventDetails = (ptsEventID) => {
  const service = getService('cad');
  return service.get({
    type: 'event-details',
    data: { ptsEventID }
  });
}
