import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';

export default function MyTooltip(props) {
  const options = useSelector(state => state.config.options);
  const [disabled, setDisabled] = useState(false);
  const dragging = !!localStorage.getItem('dragNdrop');

  useEffect(() => {
    setDisabled(!options?.Tooltip?.show);
    // eslint-disable-next-line
  }, [options]);

  if (dragging) return props.children;

  return <Tooltip
    {...props}
    enterDelay={1500}
    enterNextDelay={250}
    leaveDelay={2}
    disableHoverListener={disabled}
    disableFocusListener={disabled}
    disableTouchListener={disabled}
  />
}
