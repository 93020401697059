import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  makeStyles,
} from '@material-ui/core';
import FormDivider from 'components/FormDivider';
import React from 'react';
import { ColCard, Fills, Row, RowInner } from 'utils/formStyles';
import RenderRow from './RenderRow';
const useStyles = makeStyles(theme => ({
  item: {
    width: '100%',
    flex: '40%',
  },
  checkboxItem: {
    width: '100%',
    flex: '10%',
  },
}));
const ConnectionSetupSection = props => {
  const { handleChange, values, resourceType } = props;
  const classes = useStyles();

  return (
    <>
      <Row>
        <ColCard minWidth={500}>
          <FormDivider title="Read Source Type" />
          <RowInner
            style={{
              justifyContent: 'space-between',
              width: '100%',
              gap: '15px',
              alignItems: 'center',
            }}>
            <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
              <InputLabel htmlFor="sourceType" className={classes.selectLabel}>
                Source Type
              </InputLabel>
              <Select
                value={
                  (values['E911Collector.ReadSourceType'] &&
                    values['E911Collector.ReadSourceType']['ConnectionSetup']) ||
                  ''
                }
                onChange={ev =>
                  handleChange('E911Collector.ReadSourceType', 'ConnectionSetup', ev.target.value)
                }
                label="Source Type"
                inputProps={{ id: 'sourceType' }}>
                <MenuItem value={'0'}>ComPort</MenuItem>
                <MenuItem value={'1'}>File</MenuItem>
                <MenuItem value={'2'}>Tcp/IP Port</MenuItem>
              </Select>
            </FormControl>
            <Fills className={classes.item} />
          </RowInner>
        </ColCard>
      </Row>
      {resourceType === '1' && (
        <Row>
          <ColCard minWidth={500}>
            <FormDivider title="Read File Folder" />
            <RowInner
              style={{
                justifyContent: 'space-between',
                width: '100%',
                gap: '15px',
                alignItems: 'center',
              }}>
              <TextField
                label="Read File Folder"
                variant="outlined"
                value={
                  (values['E911Collector.ReadFileFolder'] &&
                    values['E911Collector.ReadFileFolder']['ConnectionSetup']) ||
                  ''
                }
                onChange={e =>
                  handleChange('E911Collector.ReadFileFolder', 'ConnectionSetup', e.target.value)
                }
                size="small"
                style={{ flex: '100%' }}
              />

              <Fills className={classes.item} />
            </RowInner>
          </ColCard>
        </Row>
      )}
      {resourceType === '0' && (
        <Row>
          <ColCard minWidth={500}>
            <FormDivider title="ComPort Settings Information" />
            <RowInner
              style={{
                justifyContent: 'space-between',
                width: '100%',
                gap: '15px',
                alignItems: 'center',
              }}>
              <TextField
                label="ComPort"
                variant="outlined"
                size="small"
                style={{ flex: '10%' }}
                onChange={e =>
                  handleChange('E911Collector.ComPort', 'ConnectionSetup', e.target.value)
                }
                value={
                  (values['E911Collector.ComPort'] &&
                    values['E911Collector.ComPort']['ConnectionSetup']) ||
                  ''
                }
              />
              <TextField
                label="Baud"
                onChange={e =>
                  handleChange('E911Collector.BAUD', 'ConnectionSetup', e.target.value)
                }
                value={
                  (values['E911Collector.BAUD'] &&
                    values['E911Collector.BAUD']['ConnectionSetup']) ||
                  ''
                }
                variant="outlined"
                size="small"
                style={{ flex: '10%' }}
              />
              <TextField
                label="Data Bits"
                variant="outlined"
                size="small"
                onChange={e =>
                  handleChange('E911Collector.DataBits', 'ConnectionSetup', e.target.value)
                }
                value={
                  (values['E911Collector.DataBits'] &&
                    values['E911Collector.DataBits']['ConnectionSetup']) ||
                  ''
                }
                style={{ flex: '15%' }}
              />
              <FormControl variant="outlined" size="small" style={{ flex: '25%' }}>
                <InputLabel htmlFor="stop-bits" className={classes.selectLabel}>
                  Stop Bit
                </InputLabel>
                <Select
                  value={
                    (values['E911Collector.StopBits'] &&
                      values['E911Collector.StopBits']['ConnectionSetup']) ||
                    ''
                  }
                  onChange={ev =>
                    handleChange('E911Collector.StopBits', 'ConnectionSetup', ev.target.value)
                  }
                  label="Source Type"
                  inputProps={{ id: 'stop-bits' }}>
                  <MenuItem value={0}>0</MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={1.5}>1.5 </MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                </Select>
              </FormControl>

              <FormControl variant="outlined" size="small" style={{ flex: '25%' }}>
                <InputLabel htmlFor="parity-input" className={classes.selectLabel}>
                  Parity
                </InputLabel>
                <Select
                  value={
                    (values['E911Collector.Parity'] &&
                      values['E911Collector.Parity']['ConnectionSetup']) ||
                    ''
                  }
                  onChange={ev =>
                    handleChange('E911Collector.Parity', 'ConnectionSetup', ev.target.value)
                  }
                  label="Source Type"
                  inputProps={{ id: 'parity-input' }}>
                  <MenuItem value="N">None</MenuItem>
                  <MenuItem value="E">Even</MenuItem>
                  <MenuItem value="M">Mark</MenuItem>
                  <MenuItem value="O">Odd</MenuItem>
                  <MenuItem value="S">Space</MenuItem>
                </Select>
              </FormControl>
              <Fills className={classes.item} />
            </RowInner>
          </ColCard>
        </Row>
      )}
      {resourceType === '2' && (
        <Row>
          <ColCard minWidth={500}>
            <FormDivider title="TCP/IP Setting Information" />
            <RowInner
              style={{
                justifyContent: 'space-between',
                width: '100%',
                gap: '15px',
                alignItems: 'center',
              }}>
              <TextField
                label="Listen Port"
                variant="outlined"
                size="small"
                value={
                  (values['E911Collector.TcpPort'] &&
                    values['E911Collector.TcpPort']['ConnectionSetup']) ||
                  ''
                }
                onChange={ev =>
                  handleChange('E911Collector.TcpPort', 'ConnectionSetup', ev.target.value)
                }
              />

              <Fills className={classes.item} />
            </RowInner>
          </ColCard>
        </Row>
      )}
    </>
  );
};

export default ConnectionSetupSection;
