import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function ElectricityIcon(props) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <path d="M 18 50 C 17.894531 50 17.789063 49.984375 17.6875 49.949219 C 17.367188 49.84375 17.121094 49.585938 17.035156 49.261719 L 14.035156 38.261719 C 13.953125 37.960938 14.015625 37.640625 14.203125 37.390625 C 14.394531 37.144531 14.6875 37 15 37 L 19.402344 37 L 25.324219 26 L 13.921875 26 C 13.582031 26 13.265625 25.824219 13.082031 25.539063 C 12.898438 25.25 12.871094 24.890625 13.015625 24.582031 L 24.09375 0.582031 C 24.253906 0.226563 24.609375 0 25 0 L 32 0 C 32.34375 0 32.660156 0.175781 32.84375 0.464844 C 33.027344 0.753906 33.050781 1.117188 32.902344 1.429688 L 24.578125 19 L 36 19 C 36.355469 19 36.683594 19.1875 36.863281 19.492188 C 37.042969 19.800781 37.046875 20.175781 36.875 20.484375 L 27.699219 37 L 32 37 C 32.425781 37 32.804688 37.269531 32.945313 37.671875 C 33.085938 38.074219 32.953125 38.523438 32.617188 38.785156 L 18.617188 49.785156 C 18.4375 49.925781 18.222656 50 18 50 Z" />
    </SvgIcon>
  );
}

export default ElectricityIcon;
