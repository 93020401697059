import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import CloseIcon from '@material-ui/icons/Close';

import ShowMessage from './ShowMessage';
import SelectPosition from './SelectPosition';
const useStyles = makeStyles(theme => ({
  item: {
    width: '100%',
    flex: '80%',
    marginTop: '-15px',
  },
  label: {
    // width: '100%',
    padding: '10px',
    // flex: '10%',
  },
  selectionArea: {
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '10px',
    height: '400px',
  },
}));
const MessageParser = props => {
  const classes = useStyles();
  const { values, handleChange } = props;
  const [inputText, setInputText] = useState('');
  const [typeTCP, setTypeTCP] = useState(false);
  const [parserType, setParserType] = useState('fill');

  useEffect(() => {
    setInputText('');
  }, [typeTCP]);
  const close = () => {
    props.setShowMessageParser(false);
  };

  const renderActions = () => {
    return (
      <Button color="primary" onClick={close}>
        <CloseIcon /> Close
      </Button>
    );
  };

  return (
    <Dialog
      onClose={close}
      title={'E-911 Message Parser'}
      open={true}
      actions={renderActions()}
      //   className={classes.dialog}

      // toolbar={false}
      // draggable={false}
      fullWidth
      maxWidth="lg">
      <DialogTitle> E-911 Message Parser</DialogTitle>
      <DialogContent className="py-2" style={{ height: '800px' }}>
        <Grid container spacing={2} style={{ width: '100%' }}>
          <Grid item xs={12}>
            <TextField
              size="small"
              style={{ width: '100%' }}
              type="text"
              value={inputText}
              label="Message"
              placeholder="Paste Your Message Here.."
              rows={4}
              multiline
              variant="outlined"
              onChange={e => {
                setInputText(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} className="pb-2">
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <FormControlLabel
                className={classes.checkboxItem}
                control={
                  <Checkbox
                    size="medium"
                    checked={typeTCP || false}
                    onChange={e => setTypeTCP(e.target.checked)}
                  />
                }
                label={`Source TPC/IP`}
              />
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="radio-group-for-parser-type"
                  name="radio-buttons-group-for-parser-type"
                  value={parserType}
                  onChange={e => setParserType(e.target.value)}>
                  <FormControlLabel value="fill" control={<Radio />} label="Fill Form" />
                  <FormControlLabel value="parse" control={<Radio />} label="Parse Message" />
                </RadioGroup>
              </FormControl>
            </div>
          </Grid>
          {parserType === 'parse' ? (
            <ShowMessage
              inputText={inputText}
              values={values}
              parserType={parserType}
              typeTCP={typeTCP}
            />
          ) : (
            <SelectPosition
              handleChange={handleChange}
              inputText={inputText}
              parserType={parserType}
              typeTCP={typeTCP}
            />
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className=" mb-1 mr-3">{renderActions()}</div>
      </DialogActions>
    </Dialog>
  );
};

export default MessageParser;
