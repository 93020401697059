import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { connect } from 'react-redux';

import { Sidebar, Header, Footer } from '../../layout-components';

const LeftSidebar = props => {
  const {
    children,
    sidebarToggle,
    sidebarFixed,
    footerFixed,
    contentBackground
  } = props;

  return (
    <div className={clsx('app-wrapper', contentBackground)}>
      <Header />
      <div
        className={clsx('app-main', {
          'app-main-sidebar-static': !sidebarFixed
        })}>
        <Sidebar />
        <div
          className={clsx('app-content', {
            'app-content-sidebar-collapsed': sidebarToggle,
            'app-content-sidebar-fixed': sidebarFixed,
            'app-content-footer-fixed': footerFixed
          })}>
          <div className="app-content--inner">
            <div className="app-content--inner__wrapper">{children}</div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

LeftSidebar.propTypes = {
  children: PropTypes.node
};

const mapStateToProps = state => ({
  sidebarToggle: state.theme.sidebarToggle,
  sidebarToggleMobile: state.theme.sidebarToggleMobile,
  sidebarFixed: state.theme.sidebarFixed,

  headerFixed: state.theme.headerFixed,
  headerSearchHover: state.theme.headerSearchHover,
  headerDrawerToggle: state.theme.headerDrawerToggle,

  footerFixed: state.theme.footerFixed,

  contentBackground: state.theme.contentBackground
});

export default connect(mapStateToProps)(LeftSidebar);
