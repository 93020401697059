import React, { useState } from 'react';

const ImageUpload = props => {
  const { image, setImage, setImageData, setNewImg } = props;

  const handleImageChange = event => {
    const file = event.target.files[0];

    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setImage(reader.result);
        setNewImg(true);
        setImageData({
          fileName: file.name,
          fileType: file.type,
        });
      };

      reader.readAsDataURL(file);
    } else {
      // Handle the case where the selected file is not an image
      alert('Please select a valid image file.');
    }
  };

  return (
    <div>
      <p>Agency Badge: </p>

      {image && (
        <div>
          <img
            src={image}
            alt="Uploaded Preview"
            style={{ maxWidth: '100%', maxHeight: '200px' }}
          />
        </div>
      )}
      <input type="file" accept="image/*" onChange={handleImageChange} />
    </div>
  );
};

export default ImageUpload;
