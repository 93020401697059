import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { handleError } from 'reducers/ErrorReducer';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextFieldPlus from './TextFieldPlus';
// import { showAddVehicle } from 'reducers/DialogsReducer';
import store from 'config/configureStore';

const getService = (serviceName = 'cad') => {
  let service;
  try {
    const state = store.store.getState();
    const client = state.websocket.websocket;
    service = client.service(serviceName);
    service.timeout = 20000;
  } catch (err) {
    throw new Error('Error, service not ready.');
  }
  return service;
};

const useStyles = makeStyles((theme) => ({
  textField: {
    margin: '0 4px 8px',
  },
  activeWarrant: {
    background: theme.palette.error.main,
    color: theme.card.bg,
    width: 'calc(100% + 32px)',
    display: 'inline-block',
    padding: '8px 16px',
    margin: '-8px -16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 14,
    '& em': {
      fontStyle: 'normal',
      fontSize: 12,
    },
  },
  option: {
    fontSize: 14,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    '& em': {
      fontStyle: 'normal',
      fontSize: 12,
    },
  },
}));

function VehicleLookup(props) {
  const { onChange, dataUpdate, ptsVehicleID, options } = props;
  const classes = useStyles();
  const [searchMatch, setSearchMatch] = useState([]);
  const [Value, setValue] = useState(null);
  const label = props.label ? props.label : 'Vehicle Lookup';
  const mountedRef = useRef(true);
  const throttleRef = useRef(0);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!options || !options.length) return;
    setSearchMatch(options);
    // eslint-disable-next-line
  }, [options]);

  useEffect(() => {
    if (!ptsVehicleID) return;
    getVehicle(ptsVehicleID);
  }, [ptsVehicleID]);

  useEffect(() => {
    if (!dataUpdate || dataUpdate.type !== 'add-vehicle') return;
    const { ptsVehicleID } = dataUpdate.data;
    getVehicle(ptsVehicleID);
    // eslint-disable-next-line
  }, [dataUpdate]);

  const getVehicle = async (ptsVehicleID) => {
    try {
      const service = getService('vehicle-lookup');
      const place = await service.get(ptsVehicleID);
      if (place) {
        setSearchMatch([place]);
        setValue(place);
        onSearchSelect(null, place);
      }
    } catch (err) {
      console.log('Vehicle lookup error: ', err);
    }
  };

  const onSearchSelect = (ev, selection) => {
    setValue(selection);
    onChange && onChange(selection);
  };

  const getOptionSelected = (option, value) => {
    return option.ptsVehicleID === value.ptsVehicleID;
  };

  const onInputChange = (ev, val) => {
    clearTimeout(throttleRef.current);
    throttleRef.current = setTimeout(() => {
      if (val.length > 2) searchVehicle(val);
    }, 1000);
  };

  const searchVehicle = async (search) => {
    try {
      const service = getService('vehicle-lookup');
      const result = await service.find({ query: { search } });
      if (!mountedRef) return;
      if (result) {
        setSearchMatch(result);
      }
    } catch (err) {
      console.log('Vehicle lookup error: ', err);
    }
  };

  const renderOption = (option) => {
    const { PrimaryColor, Make, Model, PlateNumber, VIN } = option;
    let label = Make ? Make + ' ' : '';
    label += Model ? Model + ' ' : '';
    label += PlateNumber ? `Plate: ${PlateNumber} ` : '';
    label += PrimaryColor ? `Color: ${PrimaryColor} ` : '';
    label += VIN ? `VIN: ${VIN}` : '';
    return <span className={classes.option}>{label}</span>;
  };

  const renderLabel = (option) => {
    const { PrimaryColor, Make, Model, PlateNumber, VIN } = option;
    let label = Make ? Make + ' ' : '';
    label += Model ? Model + ' ' : '';
    label += PlateNumber ? `${PlateNumber} ` : '';
    label += PrimaryColor ? `${PrimaryColor} ` : '';
    label += VIN ? `VIN: ${VIN}` : '';
    return label;
  };

  return (
    <Autocomplete
      disabled={props.disabled}
      className={props.className}
      fullWidth
      autoHighlight
      options={searchMatch}
      value={Value}
      getOptionLabel={renderLabel}
      size="small"
      onChange={onSearchSelect}
      onInputChange={onInputChange}
      renderInput={(params) => {
        params.label = label;
        params.variant = 'outlined';
        // params.onAdd = () => props.showAddVehicle();
        return <TextFieldPlus {...params} />;
      }}
      getOptionSelected={getOptionSelected}
      renderOption={renderOption}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    dataUpdate: state.dataUpdate,
  };
};

export default connect(mapStateToProps, {
  handleError,
  // showAddVehicle,
})(VehicleLookup);
