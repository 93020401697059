
    import React from 'react';
    import SvgIcon from '@material-ui/core/SvgIcon';
    
    function icons8warrefugee100Icon(props) {
      return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100" height="100"><path d="M38.5 8c-2.481 0-4.5 2.019-4.5 4.5s2.019 4.5 4.5 4.5 4.5-2.019 4.5-4.5S40.981 8 38.5 8zm-12.941 9c-.894 0-1.727.482-2.166 1.25l-2.957 4.928c-.593 1.038-.426 2.356.396 3.131a2.503 2.503 0 0 0 3.893-.557L27 22h2.58l-2.68 7.156a3.823 3.823 0 0 0 .473 4.148L38 47.131a2.17 2.17 0 0 0 1.734.869c.79 0 1.495-.411 1.883-1.098.39-.688.38-1.502-.031-2.188l-7.723-12.252 3.143-7.422 3.227 3.227a2.483 2.483 0 0 0 1.769.733h4.5a2.49 2.49 0 0 0 1.902-.883 2.513 2.513 0 0 0 .563-2.035C48.774 24.895 47.666 24 46.393 24h-3.357l-4.217-4.217A9.436 9.436 0 0 0 32.102 17h-6.543zm-.573 16.201L23.588 36H18.5c-1.379 0-2.5 1.12-2.5 2.498s1.121 2.5 2.5 2.5h6.789c.908 0 1.747-.494 2.188-1.291l.961-1.736-2.627-3.42a5.783 5.783 0 0 1-.825-1.35zM21.97 9.754a1.002 1.002 0 0 0-.806-.741L9 8l3.895-5.553A.998.998 0 0 0 12 1H1v8l5-3-2.982 6.369A.499.499 0 0 0 3.5 13l7.5-1-5.387 4.684a.5.5 0 0 0 .033.67L9 21l-5.93-1.495a.5.5 0 0 0-.53.692L6 27l-5-1v8h11a.998.998 0 0 0 .97-1.243L10 25l7.142-.01a1 1 0 0 0 .672-1.571L12 17l9.614-6.21c.314-.245.452-.65.356-1.036zM8 8l.001-.002L8 8z"/></SvgIcon>
      );
    }
  
    export default icons8warrefugee100Icon;