import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Content from '../../Content';
import {
  findSearchProfiles,
  findSearchClearanceLevels,
  findSearchUsers,
  updateSearchUser,
} from '../SecFunctions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { XGrid } from '@material-ui/x-grid';
import CheckIcon from '@material-ui/icons/Check';
import { formatUTCdate } from 'utils/functions';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { handleError } from 'reducers/ErrorReducer';
import { notify } from 'reducers/NotifierReducer';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { showAddSecUser, showRemoveSecUser } from 'reducers/DialogsReducer';
import Fab from '@material-ui/core/Fab';
import Tooltip from 'components/Tooltip';
import { Box, Grid } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { displayDateTime } from 'reducers/TimeReducer';

const useStyles = makeStyles(theme => ({
  autoComplete: {
    marginTop: '15px',
  },
  list: {
    width: '100%',
    boxSizing: 'border-box',
    height: `calc(100vh - 270px)`,
  },
  saveBtn: {
    textAlign: 'right',
    // marginBottom: 15,
    display: 'flex',
    gap: '5px',
    justifyContent: 'right',
    paddingTop: '5px',
  },
  textField: { marginTop: '15px' },
  toggleItem: {
    flex: 33.33,
  },
}));

const bool = params => (params.value ? <CheckIcon /> : <span></span>);

const columns = [
  { field: 'Username', headerName: 'Username', width: 150 },
  { field: 'FullName', headerName: 'Fullname', width: 200 },
  { field: 'Profile', headerName: 'Profile', width: 120 },
  { field: 'IsDisabled', headerName: 'Disabled', width: 120, format: 'bool', renderCell: bool },
  { field: 'IsLockedOut', headerName: 'Locked Out', width: 120, format: 'bool', renderCell: bool },
  {
    field: 'ActivityDateTime',
    headerName: 'Activity Date Time',
    width: 150,
    valueFormatter: params => displayDateTime(params.value),
    format: 'date',
  },
  { field: 'Activity', headerName: 'Activity', width: 120 },
  { field: 'ApplicationName', headerName: 'Application Name', width: 120 },
  { field: 'PersonID', headerName: 'PersonID', width: 120 },
  {
    field: 'ClearanceLevel',
    headerName: 'Clearance Level',
    width: 150,
    valueGetter: params => parseInt(params.value) || params.value,
  },
  { field: 'PasswordEmail', headerName: 'Email', width: 170 },
  { field: 'PhoneNumber', headerName: 'Phone Number', width: 150 },
];

function SearchUsers(props) {
  const classes = useStyles();
  const [allProfiles, setAllProfiles] = useState([]);
  const [allClearanceLevels, setAllClearanceLevels] = useState([]);
  const [profile, setProfile] = useState(null);
  const [clearance, setClearance] = useState(null);
  const [usersData, setUsersData] = useState([]);
  const [allUsersData, setAllUsersData] = useState([]);
  const [selection, setSelection] = useState(null);
  const [locked, setLocked] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [userName, setUsername] = useState('');
  const [fullName, setFullName] = useState('');
  const [isLockedFilter, setIsLockedFilter] = useState('Unlocked');
  const [disabledFilter, setDisabledFilter] = useState('Enabled');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    fetchUsers();
    fetchProfiles();
    fetchClearanceLevels();
  }, []);

  useEffect(() => {
    let filteredUsers = allUsersData;

    if (allUsersData) {
      if (profile) {
        filteredUsers = filteredUsers.filter(u => u.ptsProfileID === profile.ptsProfileID);
      }
      if (clearance) {
        filteredUsers = filteredUsers.filter(
          u => u.ptsClearanceLevelID === clearance.ptsClearanceLevelID
        );
      }

      if (userName) {
        filteredUsers = filteredUsers.filter(u =>
          u.Username.toLowerCase().includes(userName.toLowerCase())
        );
      }
      if (isLockedFilter) {
        const boolValue = isLockedFilter === 'Locked' ? true : false;
        filteredUsers = filteredUsers.filter(u => u.IsLockedOut === boolValue);
      }
      if (disabledFilter) {
        const boolValue = disabledFilter === 'Enabled' ? false : true;
        filteredUsers = filteredUsers.filter(u => u.IsDisabled === boolValue);
      }
      if (fullName) {
        filteredUsers = filteredUsers.filter(u => {
          const words = fullName.split(' ');
          let found = true;
          words.forEach(word => {
            if (word == '') {
            } else if (!u.FullName.toLowerCase().includes(word.toLowerCase())) {
              found = false;
              return;
            }
          });
          return found;
        });
      }
      if (email) {
        filteredUsers = filteredUsers.filter(u =>
          (u.PasswordEmail || '').toLowerCase().includes(email.toLowerCase())
        );
      }
      if (phoneNumber) {
        filteredUsers = filteredUsers.filter(u =>
          (u.PhoneNumber || '').replace(/\D/g, '').includes(phoneNumber)
        );
      }
      setUsersData(filteredUsers);
    }
  }, [
    profile,
    email,
    phoneNumber,
    clearance,
    userName,
    fullName,
    allUsersData,
    disabledFilter,
    isLockedFilter,
  ]);

  const fetchUsers = async () => {
    const result = await findSearchUsers();
    setAllUsersData(processData(result));
  };

  const processData = data => {
    return data.map(row => {
      return {
        ...row,
        id: row.ptsUserID,
      };
    });
  };

  const fetchProfiles = async () => {
    const result = await findSearchProfiles();
    setAllProfiles(result);
  };

  const fetchClearanceLevels = async () => {
    const result = await findSearchClearanceLevels();
    setAllClearanceLevels(result);
  };

  const handleSaveClick = async () => {
    const data = {
      ProfileId: profile.ptsProfileID,
      ClearanceId: clearance ? clearance.ptsClearanceLevelID : null,
      IsLockedOut: locked ? 1 : 0,
      UserId: selection,
    };

    try {
      await updateSearchUser(selection, data);
      fetchUsers();
      props.notify('User Updated', 'success');
    } catch (err) {
      props.handleError(err, 'Error Updating User');
    }
  };

  const handleSelectChange = newSelection => {
    const currentUserId = parseInt(newSelection.selectionModel[0], 10);
    const currentUser = usersData.find(data => data.ptsUserID === currentUserId);
    setSelectedUser(currentUser);
    setSelection(currentUserId);
  };

  const renderSave = () => {
    return (
      <div className={classes.saveBtn}>
        <Button
          onClick={handleSaveClick}
          disabled={!profile || !selection}
          variant="contained"
          color="primary">
          <SaveIcon className="mr-2" /> Save
        </Button>
      </div>
    );
  };

  const renderUsername = () => {
    return (
      <Box>
        <TextField
          label="Username"
          variant="outlined"
          value={userName}
          size="small"
          onChange={e => setUsername(e.target.value)}
          fullWidth
          className={classes.textField}
        />
      </Box>
    );
  };

  const renderFullName = () => {
    return (
      <Box>
        <TextField
          label="Full Name"
          variant="outlined"
          value={fullName}
          size="small"
          onChange={e => setFullName(e.target.value)}
          fullWidth
          className={classes.textField}
        />
      </Box>
    );
  };
  const renderEmail = () => {
    return (
      <Box>
        <TextField
          label="Email"
          variant="outlined"
          value={email}
          size="small"
          onChange={e => setEmail(e.target.value)}
          fullWidth
          className={classes.textField}
        />
      </Box>
    );
  };
  const renderPhoneNumber = () => {
    return (
      <Box>
        <TextField
          label="Phone Number"
          variant="outlined"
          value={phoneNumber}
          size="small"
          onChange={e => setPhoneNumber(e.target.value)}
          fullWidth
          className={classes.textField}
        />
      </Box>
    );
  };
  const renderProfiles = () => {
    const handleChange = (e, newValue) => setProfile(newValue);
    return (
      <Box>
        <Autocomplete
          disablePortal
          options={allProfiles}
          className={classes.autoComplete}
          renderInput={params => <TextField {...params} label="Profiles" variant="outlined" />}
          onChange={handleChange}
          getOptionLabel={option => option.Label}
          renderOption={option => `${option.Label} ${option.Type ? -option.Type : ''}`}
          size="small"
          value={profile}
        />
      </Box>
    );
  };

  const renderClearance = () => {
    const handleChange = (e, newValue) => setClearance(newValue);
    return (
      <Box>
        <Autocomplete
          disablePortal
          options={allClearanceLevels}
          className={classes.autoComplete}
          renderInput={params => (
            <TextField {...params} label="Clearance Levels" variant="outlined" />
          )}
          onChange={handleChange}
          getOptionLabel={option => option.Level}
          renderOption={option => {
            return (
              <span>
                <b>{option.Level || ''}</b> - {option.Description || ''}
              </span>
            );
          }}
          size="small"
          value={clearance}
        />
      </Box>
    );
  };

  const renderIsLockedFilter = () => {
    const handleChange = (e, newValue) => {
      setIsLockedFilter(newValue);
    };
    return (
      <Box>
        <ToggleButtonGroup
          value={isLockedFilter}
          exclusive
          size="small"
          onChange={handleChange}
          style={{ marginTop: '16px', width: '100%' }}
          aria-label="User Status">
          <ToggleButton value="Unlocked" aria-label="Unlocked" className={classes.toggleItem}>
            Unlocked
          </ToggleButton>
          <ToggleButton value="" aria-label="All" fullWidth className={classes.toggleItem}>
            All
          </ToggleButton>
          <ToggleButton value="Locked" aria-label="Locked" fullWidth className={classes.toggleItem}>
            Locked
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    );
  };
  const renderDisabledUserFilter = () => {
    const handleChange = (e, newValue) => setDisabledFilter(newValue);
    return (
      <Box>
        <ToggleButtonGroup
          value={disabledFilter}
          exclusive
          size="small"
          onChange={handleChange}
          style={{ marginTop: '16px' }}
          aria-label="Enabled Filter">
          <ToggleButton value="Enabled" aria-label="Enabled">
            Enabled
          </ToggleButton>
          <ToggleButton value="" aria-label="All">
            All
          </ToggleButton>
          <ToggleButton value="Disabled" aria-label="Disabled">
            Disabled
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    );
  };
  const renderUsers = () => {
    return (
      <div className={classes.list}>
        <XGrid
          columns={columns}
          rows={usersData}
          rowHeight={38}
          onSelectionModelChange={handleSelectChange}
          disableMultipleSelection
          hideFooter
        />
      </div>
    );
  };

  const renderActions = () => {
    return (
      <div className={classes.saveBtn}>
        <Tooltip
          title="Add"
          onClick={() =>
            props.showAddSecUser({
              fetchUsers: fetchUsers,
              setSelection: setSelection,
              userList: allUsersData,
            })
          }>
          <Fab size="small" color="secondary">
            <AddIcon />
          </Fab>
        </Tooltip>
        <Tooltip
          title="Edit"
          onClick={() =>
            props.showAddSecUser({
              selectedUser: selectedUser,
              fetchUsers: fetchUsers,
              userList: allUsersData,
              setSelection: setSelection,
            })
          }
          disabled={!selection}>
          <Fab size="small" color="secondary">
            <EditIcon />
          </Fab>
        </Tooltip>
        <Tooltip
          title="Delete User"
          disabled={!selection}
          onClick={() => {
            props.showRemoveSecUser({
              selectedUser: selection,
              fetchUsers: fetchUsers,
              setSelection: setSelection,
              userName: selectedUser.Username,
            });
          }}>
          <Fab size="small" color="secondary">
            <DeleteIcon />
          </Fab>
        </Tooltip>
      </div>
    );
  };

  return (
    <Content>
      <div className="m-4">
        <h4 className="mb-2">Search Users</h4>
        <Grid container item xs={12} style={{ marginTop: 10, marginBottom: 15 }}>
          <Grid item xs={12} container spacing={1}>
            <Grid item xs={2}>
              {renderUsername()}
            </Grid>
            <Grid item xs={2}>
              {renderFullName()}
            </Grid>
            <Grid item xs={2}>
              {renderProfiles()}
            </Grid>
            <Grid item xs={2}>
              {renderEmail()}
            </Grid>
            <Grid item xs={2}>
              {renderPhoneNumber()}
            </Grid>
            <Grid item xs={2}>
              {renderClearance()}
            </Grid>
          </Grid>
          <Grid item xs={12} container justify="space-between" className="mt-2" alignItems="center">
            <Grid item xs={6} container spacing={1}>
              <Grid item>{renderIsLockedFilter()}</Grid>
              <Grid item>{renderDisabledUserFilter()}</Grid>
            </Grid>
            <Grid item xs={4}>
              {renderActions()}
            </Grid>
          </Grid>
        </Grid>
        {renderUsers()}
      </div>
    </Content>
  );
}

export default connect(null, { handleError, notify, showAddSecUser, showRemoveSecUser })(
  SearchUsers
);
