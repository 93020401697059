import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { getFormStyle } from '../../utils/functions';

const useStyles = makeStyles(theme => ({
  formControl: {
    ...getFormStyle(100, 100),
  },
}));

function RangeValue (props) {
  const classes = useStyles();
  const { value, setValue} = props;

  const setVal = no => ev => {
    const newValue = [...value];
    newValue[no] = ev.target.value;
    setValue(newValue);
  }

  return (
    <>
      <TextField
        className={classes.formControl}
        label="From"
        value={value[0]}
        onChange={setVal(0)}
        size="small"
        error={value[0] === ''}
        type="number"
      />
      <span>&nbsp;and&nbsp;</span>
      <TextField
        className={classes.formControl}
        label="To"
        value={value[1]}
        onChange={setVal(1)}
        size="small"
        error={value[1] === ''}
        type="number"
      />
    </>
  );
}

export default RangeValue;