import React, { Fragment } from 'react';
import { OverlayView } from '@react-google-maps/api';
import { IoIosCar } from 'react-icons/io';

import { getUnitColor } from '../../../utils/uiUtils';

const positionOverlayView = (width, height) => ({
  x: -13,
  y: -26
});

const MarkersUnits = props => {
  const { units } = props;

  const onClick = unit => {
    console.log('units clicked');
  };

  const renderMarker = unit => {
    if (unit.UnitLatitudeDegree !== null && unit.UnitLongitudeDegree !== null) {
      const latitude = `${
        unit.UnitLatitudeSign === '-' ? unit.UnitLatitudeSign : ''
      }${unit.UnitLatitudeDegree}`;
      const longitude = `${
        unit.UnitLongitudeSign === '-' ? unit.UnitLongitudeSign : ''
      }${unit.UnitLongitudeDegree}`;
      const position = {
        lat: parseFloat(latitude),
        lng: parseFloat(longitude)
      };
      return (
        <OverlayView
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          key={unit.ptsUnitID}
          position={position}
          getPixelPositionOffset={positionOverlayView}>
          <Fragment>
            <div
              style={{
                position: 'sticky',
                marginLeft: '-15px',
                border: '1px solid white',
                padding: 0,
                background: 'rgba(255,255,255,0.6)',
                borderTopRightRadius: 5,
                borderBottomLeftRadius: 5
              }}>
              {unit.Unit}
            </div>
            <IoIosCar
              style={{ cursor: 'pointer' }}
              onClick={() => onClick(unit)}
              size={26}
              color={getUnitColor(unit.Path)}
            />
          </Fragment>
        </OverlayView>
      );
    } else {
      return null;
    }
  };

  if (units && units.length > 0) {
    return units.map((unit, index) => renderMarker(unit));
  } else {
    return null;
  }
};

export default MarkersUnits;
