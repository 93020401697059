import React from 'react';
import { Fab, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

export default function DeleteButton(props) {
  const { onClick, disabled } = props;
  return (
    <Tooltip title={props.tooltipText || 'Delete'}>
      <Fab size="small" disabled={disabled} color="secondary" aria-label="delete">
        <DeleteIcon onClick={onClick} />
      </Fab>
    </Tooltip>
  );
}
