/**
 * Ver 1.02
 */
import { getService } from './service';
import { handleError } from './ErrorReducer';
import moment from 'moment';
import mtz from 'moment-timezone';
import store from '../config/configureStore';

const localTZoffset = new Date().getTimezoneOffset();
const TIME_SETTINGS = 'TIME_SETTINGS';
const TIME_OFFSET = 'TIME_OFFSET';

// Used to display date and time on the screen
export function displayDateTime(date) {
  return formatTimeDate(date, 'L HH:mm:ss', 'L LT');
}

// Used to display time on the screen
export function displayTime(date) {
  return formatTimeDate(date, 'HH:mm:ss', 'LT');
}

// Used to display date on the screen
export function displayDate(date) {
  return typeof date === 'string'
    ? moment(date, 'YYYY-MM-DD').format('MM/DD/YYYY')
    : formatTimeDate(date, 'L', 'L');
}

let timeSyncInitiated = false;
export const timeSyncInit = () => {
  return async dispatch => {
    if (timeSyncInitiated) return;
    timeSyncInitiated = true;
    setInterval(() => {
      const state = store.store.getState();
      const { isAuthenticated } = state.user;
      if (isAuthenticated) {
        dispatch(getServerTimeSettings());
      }
    }, 5 * 60 * 1000);
  };
};

let timeOffsetSet = false;
export const getServerTimeSettings = () => async dispatch => {
  const service = getService('time');
  try {
    const time1 = new Date().getTime();
    const result = await service.get(0);
    if (timeOffsetSet) {
      delete result.timeOffset;
    } else {
      timeOffsetSet = true;
    }
    const time2 = new Date().getTime();
    result.clockDiff = Math.round(time1 + (time2 - time1) / 2 - result.timestamp);
    result.localTZoffset = new Date().getTimezoneOffset();
    dispatch({ type: TIME_SETTINGS, payload: result });
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const displayAgencyTime = () => dispatch => {
  dispatch({ type: TIME_SETTINGS, payload: { localTime: false } });
};

export const displayLocalTime = () => dispatch => {
  dispatch({ type: TIME_SETTINGS, payload: { localTime: true } });
};

// Time Format is set in the settings and this shouldn't be used in productions
export const toggleTimeFormat = () => dispatch => {
  const state = store.store.getState();
  const { format24h } = state.time;
  dispatch({ type: TIME_SETTINGS, payload: { format24h: !format24h } });
};

export const toggleAgencyTime = () => dispatch => {
  const state = store.store.getState();
  const { localTime } = state.time;
  dispatch({ type: TIME_SETTINGS, payload: { localTime: !localTime } });
};

export const setTimeOffset = timeOffset => dispatch => {
  dispatch({ type: TIME_OFFSET, payload: { timeOffset } });
};

// const useLocalTime = process.env.REACT_APP_LOCAL_TIME_ON === 'true';
const useLocalTime = true; // Agency time doesn't work well currently
const defaultState = {
  format24h: false, //  Do we use 24 hour format to display time or not
  dbTZoffset: 0, //      Difference between server time and UTC time (including time offset if server in the wrong timezone)
  clockDiff: 0, //      Time difference between server and local machine in ms. Used to sync internal clock
  localTime: useLocalTime, //   Time should be displayed in local or agency time [default: false]
  localTZoffset,
  timeOffset: 0,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case TIME_SETTINGS:
      return { ...state, ...action.payload, localTime: true };
    case TIME_OFFSET:
      return { ...state, ...action.payload, localTime: true };
    default:
      break;
  }
  return state;
}

export const getFormat24 = () => {
  const state = store.store.getState();
  return state.time.format24h;
};

export const dateTimePicker = date => {
  return parseInDate(date);
};

export const formatSaveDate = date => {
  const calcOffset = getApiTimeOffset(date);
  const state = store.store.getState();
  const { localTime, timeOffset } = state.time;
  if (!date) return null;
  const localTZoffset = getLocalTimeOffset(date);
  const m = moment(date);
  if (!m.isValid()) return null;
  let d = m.utc();
  if (localTime) {
    d = m.add(-calcOffset, 'minutes');
  } else {
    d = m.add(-timeOffset - localTZoffset, 'minutes');
  }
  return d.format('YYYY-MM-DD HH:mm:ss');
};

export const formatSaveDateFrom = date => {
  if (!date) return null;
  const state = store.store.getState();
  const { timeOffset } = state.time;
  const d =
    moment(date)
      .add(1, 'days')
      .format('YYYY-MM-DD') + ' 00:00:00.000';
  const m = moment(d).subtract(timeOffset, 'minutes');
  if (!m.isValid()) return null;
  const result = m.format('YYYY-MM-DD HH:mm:ss.SSS');
  return result;
};

export const formatSaveDateTo = date => {
  if (!date) return null;
  const state = store.store.getState();
  const { timeOffset } = state.time;
  const d = moment(date).format('YYYY-MM-DD') + ' 00:00:00.000';
  const m = moment(d).subtract(timeOffset, 'minutes');
  if (!m.isValid()) return null;
  const result = m.format('YYYY-MM-DD HH:mm:ss.SSS');
  return result;
};

// Formats locale date to YYYY-MM-DD fromat - save to DB
export const formatDBDate = date => {
  if (!date) return null;
  if (typeof date === 'string') {
    date = new Date(date);
  }
  return (
    date.getFullYear() +
    '-' +
    ((date.getMonth() + 1).length != 2 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) +
    '-' +
    (date.getDate().length != 2 ? '0' + date.getDate() : date.getDate())
  );
};

export function getTimeFromDate(date) {
  return moment(formatSaveDate(date)).format('HH:mm:ss.SSS');
}

export function compareDate(date1, date2) {
  return moment(date1).isSame(date2);
}
export function formatTimeDate(date, militaryFormat, localFormat) {
  if (!date) return '';
  const state = store.store.getState();
  const { format24h } = state.time;
  const m = parseInDate(date, state);
  const format = format24h ? militaryFormat : localFormat;

  return m.format(format);
}

export const parseInDate = (date, state = null) => {
  if (!state) state = store.store.getState();
  let { localTime, timeOffset } = state.time;
  localTime = true;
  if (!date) return null;
  let m = moment(date);
  if (!m.isValid()) return null;
  const apiOffset = getApiTimeOffset(date);
  const localOffset = getLocalTimeOffset(date);
  if (m._tzm === undefined) {
    m = m.add(apiOffset - localOffset, 'minutes'); // fix local sql time - without Z at the end
  }

  if (!localTime) {
    m = m.add(timeOffset - apiOffset + localOffset, 'minutes');
  }
  return m;
};

// Return current server time and date (UTC)
// Clock difference between local computer and server are calculated
export function getServerDateTime() {
  const state = store.store.getState();
  const { clockDiff } = state.time;
  const time = new Date().getTime() - clockDiff;
  return new Date(time);
}

export function getClockTime() {
  const state = store.store.getState();
  const { format24h, localTime, dbTZoffset, localTZoffset, clockDiff, timeOffset } = state.time;

  const time = new Date().getTime() - clockDiff;
  let m = moment(time);
  if (!localTime) {
    m = m.add(timeOffset + localTZoffset - dbTZoffset, 'minutes');
  }
  return format24h ? m.format('HH:mm:ss') : m.format('LTS');
}

export function getDateTimeDisplay() {
  const state = store.store.getState();
  const { format24h, localTime, dbTZoffset, localTZoffset, clockDiff, timeOffset } = state.time;
  const time = new Date().getTime() - clockDiff;
  let m = moment(time);
  if (!localTime) {
    m = m.add(timeOffset + localTZoffset - dbTZoffset, 'minutes');
  }
  return format24h ? m.format('MM/DD/yyyy HH:mm') : m.format('MM/DD/yyyy hh:mm a');
}

export function getCurrentDate() {
  const state = store.store.getState();
  const { localTime, dbTZoffset, localTZoffset, clockDiff, timeOffset } = state.time;
  const time = new Date().getTime() - clockDiff;
  let m = moment(time).utc();
  if (!localTime) {
    m = m.utc().add(timeOffset + localTZoffset - dbTZoffset, 'minutes');
  }
  return m;
}

// For displaying date time widget
export function getDateTimeFormat() {
  const state = store.store.getState();
  const { format24h } = state.time;
  return format24h ? 'MM/dd/yyyy HH:mm' : 'MM/dd/yyyy hh:mm a';
}

// For displaying time widget
export function getTimeFormat() {
  const state = store.store.getState();
  const { format24h } = state.time;
  return format24h ? 'HH:mm' : 'hh:mm a';
}

// Functions that need to be tested - copied from functions

/** converts number of minutes to String hours and minutes [hh:mm] (must be positive) */
export function minsToHours(min) {
  const mins = min % 60;
  const hours = Math.floor(min / 60);
  return `00${hours}`.substr(-2) + ':' + `00${mins}`.substr(-2);
}

export function isValidDate(date) {
  if (typeof date !== 'string' || date.length > 24) return false;
  var dateReg = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;
  if (date.match(dateReg) !== null && moment(date).isValid()) return true;
  return false;
}

/** Returs passed time in minutes */
export function getPassedTime(date) {
  if (typeof date !== 'string' || date.length > 24) return 0;
  const m = parseInDate(date);
  if (!m.isValid()) return 0;
  const currentDate = moment(getCurrentDate());
  const passedTime = currentDate.diff(m, 'minutes');
  return passedTime;
}

export const getApiTimeOffset = date => {
  const state = store.store.getState();
  const x = mtz(date);
  if (!x.isValid()) {
    return state.time.dbTZoffset;
  }
  const apiTimeZone = state.time.apiTimeZone;
  return -mtz.tz(date, apiTimeZone).utcOffset();
};

export const getLocalTimeOffset = date => new Date(date).getTimezoneOffset();
