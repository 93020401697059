
    import React from 'react';
    import SvgIcon from '@material-ui/core/SvgIcon';
    
    function icons8train100Icon(props) {
      return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100" height="100"><path d="M19.5 0c-1.758 0-3.191 1.309-3.438 3H15c-3.86 0-7 3.016-7 6.719V38.28c0 2.578 1.52 4.813 3.75 5.938l-4.813 4A1.01 1.01 0 0 0 8.22 49.78L14 44.938c.328.046.66.062 1 .062h20c.34 0 .672-.016 1-.063l5.781 4.844a1.01 1.01 0 0 0 1.282-1.562l-4.813-4c2.23-1.125 3.75-3.36 3.75-5.938V9.72C42 6.016 38.86 3 35 3h-1.063c-.246-1.691-1.68-3-3.437-3-1.758 0-3.191 1.309-3.438 3h-4.125c-.246-1.691-1.68-3-3.437-3Zm0 2a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm11 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM19 10h12a1 1 0 0 1 1 1v2H18v-2c0-.55.45-1 1-1Zm-4 5h20c1.105 0 2 .832 2 1.844v7.312C37 25.168 36.105 26 35 26H15c-1.105 0-2-.832-2-1.844v-7.312c0-1.012.895-1.844 2-1.844Zm1 18a3 3 0 1 1-.002 6.002A3 3 0 0 1 16 33Zm18 0a3 3 0 1 1-.002 6.002A3 3 0 0 1 34 33Z"/></SvgIcon>
      );
    }
  
    export default icons8train100Icon;