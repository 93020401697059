
    import React from 'react';
    import SvgIcon from '@material-ui/core/SvgIcon';
    
    function DisturbanceFightIcon(props) {
      return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" ><circle cx="197" cy="105.3" r="34.5"/><path d="M261.1 250.7s-56.4-53.5-58-55.4l-24.7 34.8c1.2 7.9 11.3 73.7 12.5 81.4V459c0 10.8 8.7 19.5 19.5 19.5s19.5-8.7 19.5-19.5V306.1l15.2-2.1v154.9c0 6.5-1.8 12.5-4.9 17.7 2.5 1.1 5.2 1.8 8.2 1.8 10.8 0 19.5-8.7 19.5-19.5V300.8l-6.8-50.1z"/><path d="m161.8 230.6 41.1-35.4c-8.9-10.4-10.3-25.1-4.1-36.8.5-1 1.1-2 1.7-3l-8.8 1.2c-6.4.9-12.1 4.2-16 9.4-2.6 3.4-4.1 7.5-4.6 11.7l12.3-6.3-47.4 40.8c-3.6 3.1-5.6 7.7-5.3 12.5l4 74c.5 8.5 7.7 15 16.2 14.5s15-7.7 14.5-16.2l-3.6-66.4zM144.6 83.9 103.1 70c-3.8-1.3-7.9.8-9.2 4.6-1.3 3.8.8 7.9 4.6 9.2L140 97.7c3.8 1.3 7.9-.8 9.2-4.6 1.3-3.8-.7-7.9-4.6-9.2zm4 29.1c-1-3.9-4.9-6.3-8.8-5.3L85 121.2c-3.9 1-6.3 4.9-5.3 8.8 1 3.9 4.9 6.3 8.8 5.3l54.8-13.6c3.9-.9 6.3-4.8 5.3-8.7zm233.5 176.3S364 181.6 361.2 164.6c-2.1-12.3-13.8-20.6-26.1-18.5l-40.8 6.9c-10.3 1.8-17.8 10.3-18.7 20.2l6.9 6.7 26.7-10.3-34 25.2-34.8-33.7c-6.9-6.7-17.9-6.5-24.5.4-6.7 6.9-6.5 17.9.4 24.5l45.4 44c6.1 5.9 15.6 6.5 22.4 1.5l45.9-34-42.1 52.1 8.3 48.8v160c0 10.8 8.8 19.6 19.6 19.6s19.6-8.8 19.6-19.6V297.2l7.9-1.3.4 162.6c0 10.8 8.8 19.5 19.6 19.5h.1c10.8 0 19.5-8.8 19.5-19.6l-.8-169.1z"/><circle cx="301.5" cy="102.6" r="36"/></SvgIcon>
      );
    }
  
    export default DisturbanceFightIcon;