import React from 'react';

function PoliceIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      {...props}
      preserveAspectRatio="xMidYMid meet">
      <g
        transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none">
        <path d="M170 459 c-35 -21 -81 -44 -102 -50 -46 -12 -47 -23 -8 -64 l31 -32 52 18 c66 23 148 23 214 0 l52 -18 31 32 c39 40 38 52 -7 64 -21 6 -65 29 -98 51 -33 22 -69 40 -80 39 -11 0 -49 -18 -85 -40z m105 -29 c7 -12 -12 -50 -25 -50 -13 0 -32 38 -25 50 3 6 15 10 25 10 10 0 22 -4 25 -10z" />
        <path d="M169 321 c-48 -16 -69 -32 -69 -52 0 -11 -7 -27 -15 -35 -20 -21 -19 -50 3 -66 10 -7 28 -34 41 -60 29 -60 82 -108 121 -108 39 0 92 48 121 108 13 26 31 53 41 60 22 16 23 45 3 66 -8 8 -15 24 -15 35 0 31 -54 54 -135 57 -38 2 -82 0 -96 -5z m81 -91 c26 0 66 7 89 15 39 13 41 13 41 -6 0 -11 5 -17 10 -14 20 12 22 -26 3 -48 -11 -12 -33 -46 -49 -76 -57 -108 -132 -105 -192 7 -17 32 -39 65 -47 72 -19 16 -19 40 0 40 8 0 15 9 15 20 0 18 2 18 41 5 23 -8 63 -15 89 -15z" />
        <path d="M145 210 c-10 -16 4 -48 24 -55 25 -8 49 3 56 26 8 25 42 25 50 0 12 -38 68 -37 80 0 11 34 -4 39 -105 39 -56 0 -102 -4 -105 -10z" />
      </g>
    </svg>
  );
}

export default PoliceIcon;
