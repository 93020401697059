/* ver 1.04 */
import { getService } from './service';
import { handleError } from './ErrorReducer';
//import { getDictionaries } from './DictionaryReducer';
import store from 'config/configureStore';
import { options } from './PermissionsReducer.conf.json';

export const DICTIONARY_PERMISSIONS = 'DICTIONARY_PERMISSIONS';
const PERMISSIONS = 'PERMISSIONS';
const mergeAgencyPermissions = process.env.REACT_APP_MERGE_AGENCY_PERMISSIONS === 'true';

const defaultState = {
  loaded: false,
  settings: {},
  agencyList: [],
};

export const getAccessPermission = (permGroup, permName, AgencyID = null) => {
  const state = store.store.getState();
  const permissions = state.permissions;
  return _getAccessPermission(permGroup, permName, AgencyID, permissions);
};

const _getAccessPermission = (permGroup, permName, AgencyID, p) => {
  if (!p.loaded || !p[permGroup]) return false;
  if (mergeAgencyPermissions || AgencyID === 'any') {
    // If merged access permission
    // 1. Check generic access permission
    if (p[permGroup][permName]) return true;
    // 2. Check if user has access for any of the agencies
    const agencies = Object.keys(p.agencies);
    if (!agencies.length) return false;
    let access = false;
    for (let i = 0; i < agencies.length; ++i) {
      if (p.agencies[agencies[i]][permGroup][permName]) {
        access = true;
        break;
      }
    }
    return access;
  } else if (!AgencyID) {
    const access = p[permGroup][permName];
    return Boolean(access);
  } else {
    let access = Boolean(p[permGroup][permName]);
    if (access) return true;
    if (p.agencies[AgencyID] && p.agencies[AgencyID][permGroup]) {
      access = Boolean(p.agencies[AgencyID][permGroup][permName]);
    }
    return access;
  }
};

export const getAgenciesAccessPermission = (permGroup, permName) => {
  const state = store.store.getState();
  const perms = state.permissions;
  if (_getAccessPermission(permGroup, permName, null, perms)) return perms.agencyList;
  return perms.agencyList.filter(AgencyID =>
    _getAccessPermission(permGroup, permName, AgencyID, perms)
  );
};

export const getGroups = () => {
  const state = store.store.getState();
  const p = state.permissions;
  if (!p.groups) return [];
  return p.groups.map(g => g.Label);
};

export const getProfile = () => {
  const state = store.store.getState();
  const p = state.permissions;
  if (!p.profile) return 'Unknown';
  return p.profile.Label;
};

let permissionsService;
export const subscribePermissions = () => {
  return async dispatch => {
    try {
      console.log('called subscribe');
      permissionsService = getService('permissions');
      permissionsService.on('updated', () => {
        dispatch(getUserPermissions());
        //dispatch(getDictionaries());
      });
      permissionsService.on('error', () => console.log('Error in permissions service listener'));
      permissionsService.on('unhandledRejection', (reason, p) => {
        console.log('PermissionsReducer Unhandled Rejection at: Promise ', p, ' reason: ', reason);
      });
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

export const unsubscribePermissions = () => dispatch => {
  if (permissionsService) {
    try {
      permissionsService.off('created');
      permissionsService.off('error');
      permissionsService.off('unhandledRejection');
      permissionsService = false;
    } catch (error) {
      console.log('PermissionsReducer/unsubscribePermissions: error: ', error, error.code);
    }
  }
};

export const notifyUpdatedPermissions = () => {
  const service = getService('permissions');
  return service.get(0);
};

export const getUserPermissions = () => async dispatch => {
  try {
    const service = getService('permissions');
    const permissions = await service.find({
      query: { options },
    });

    await dispatch({ type: PERMISSIONS, payload: { ...permissions, loaded: true } });
  } catch (err) {
    dispatch(handleError(err));
  }
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case PERMISSIONS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
