import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import PlaceLookup from 'components/PlaceLookup';
import Content from './Content';
import { getService } from 'reducers/service';
import { handleError } from 'reducers/ErrorReducer';
import { notify } from 'reducers/NotifierReducer';
import { connect } from 'react-redux';


const useStyles = makeStyles((theme) => ({}));

function AddVehicleDefaultPlace(props) {
  const classes = useStyles();
  const service = getService('settings-add-vehicle-default-place');
  const [place, setPlace] = useState(null);
  const [ptsPlaceID, setPlaceID] = useState(null);

  useEffect(() => {
    search();
  }, []);

  const search = async () => {
    try {
      const result = await service.find();
      if (result) setPlaceID(result.ValueNumeric);
    }
    catch(err) {
      props.handleError(err, 'Could not fetch data');
    }
  }

  const handleSaveClick = async () => {
    const ptsPlaceID = place ? place.ptsPlaceID : null;
    try {
      await service.create({ ptsPlaceID });
      props.notify("Default Place Updated.", "success");
    }
    catch(err) {
      props.handleError(err, 'Error Updating Default Place');
    }
  }

  const renderPlace = () => {
    const handleChange = (value) => setPlace(value);
    return (
      <PlaceLookup
        onChange={handleChange}
        className={classes.item}
        label="Place"
        ptsPlaceID={ptsPlaceID}
      />
    );
  }

  const renderSaveBtn = () => {
    return (
      <Button 
        onClick={handleSaveClick} 
        variant="contained" 
        className="mt-4"
        disabled={!place}
        color="primary">
        <SaveIcon className="mr-2" /> Save 
      </Button> 
    )
  }

  return (
    <Content>
      <div className='m-4'>
        <h4 className='mb-4'> Add Vehicle Default Place </h4>
        {renderPlace()}
        {renderSaveBtn()}
      </div>
    </Content>
  );
}

export default connect(null, { handleError, notify })(AddVehicleDefaultPlace);
