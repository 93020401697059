import { getService } from 'reducers/service';
import store from 'config/configureStore';
const path_mail = `Platinumplus.settings.email.users.template`;
const path_username = `Platinumplus.settings.username.users.template`;
const path_url = `Platinumplus.settings.users.login.url`;
const Paths = {
  email: path_mail,
  username: path_username,
  link: path_url,
};
export const findEmailInDatabase = async CDRKey => {
  const cdr = getService('cdr');
  const exits = {
    email: false,
    username: false,
    link: false,
  };
  const existData = await cdr.find({
    query: {
      $or: [
        { Path: path_mail, CDRKey: CDRKey },
        { Path: path_username, CDRKey: CDRKey },
        { Path: path_url, CDRKey: CDRKey },
      ],
    },
  });

  if (existData.length < 0) return exits;
  const isEmailExist = existData.find(item => item.Path === path_mail);
  const isUernameExist = existData.find(item => item.Path === path_username);
  const isLinkExist = existData.find(item => item.Path === path_url);
  return {
    email: isEmailExist ? true : false,
    username: isUernameExist ? true : false,
    link: isLinkExist ? true : false,
  };
};

export const saveCDRdata = async (CDRKey, emailText, usernameText, siteUrl) => {
  const user = store.store.getState().user;
  const userName = user?.userData?.user?.Username || '';
  const cdr = getService('cdr');
  const exitsResult = await findEmailInDatabase(CDRKey);
  const data = { email: emailText, username: usernameText, link: siteUrl };
  try {
    Object.entries(exitsResult).forEach(async ([key, val]) => {
      let res;
      if (val) {
        res = await updateCDRdata(Paths[key], CDRKey, data[key], userName);
      } else {
        res = await createCDRData(Paths[key], CDRKey, data[key], userName);
      }
    });

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};
const createCDRData = async (path, key, value, userName) => {
  const cdr = getService('cdr');
  const obj = {
    ValueBoolean: 1,
    Path: path,
    CDRKey: key,
    CreatedBy: userName,
    UpdatedBy: userName,
  };
  if (path === path_mail || path === path_username) {
    obj['ValueJson'] = value;
  } else {
    obj['ValueString'] = value;
  }

  return await cdr.create(obj);
};
const updateCDRdata = async (path, key, value, userName) => {
  const cdr = getService('cdr');
  const obj = {
    ValueBoolean: 1,
    Path: path,
    CDRKey: key,
    UpdatedBy: userName,
  };
  if (path === path_mail || path === path_username) {
    obj['ValueJson'] = value;
  } else {
    obj['ValueString'] = value;
  }

  return await cdr.patch(null, obj);
};
// export const updateCDRdata = async (CDRKey, emailText, smsText, siteUrl) => {
//   const cdr = getService('cdr');

//   try {
//     await cdr.patch(null, {
//       ValueBoolean: 1,
//       Path: path_mail,
//       CDRKey,
//       ValueString: emailText,
//     });
//     await cdr.patch(null, {
//       ValueBoolean: 1,
//       Path: path_username,
//       CDRKey,
//       ValueString: smsText,
//     });
//     await cdr.patch(null, {
//       ValueBoolean: 1,
//       Path: path_url,
//       CDRKey,
//       ValueString: siteUrl,
//     });

//     return true;
//   } catch (error) {
//     console.log(error);

//     return false;
//   }
// };

export const findMailTemplateData = async CDRKey => {
  const cdr = getService('cdr');
  const emailData = await cdr.find({ query: { Path: path_mail, CDRKey: CDRKey } });
  const usernameData = await cdr.find({ query: { Path: path_username, CDRKey: CDRKey } });
  const emailUrl = await cdr.find({ query: { Path: path_url, CDRKey: CDRKey } });

  return {
    emailText: emailData[0]?.ValueJson || '',
    usernameText: usernameData[0]?.ValueJson || '',
    url: emailUrl[0]?.ValueString || '',
  };
};
