import React, { useEffect, useState } from 'react';

import CheckIcon from '@material-ui/icons/Check';
import { makeStyles } from '@material-ui/core';

import { XGrid } from '@material-ui/x-grid';
import { displayDateTime } from 'reducers/TimeReducer';
import TopCard from './TopCard';
import AttatchmentsActions from './AttatchmentsActions';

const useStyles = makeStyles(theme => ({
  gridWrap: {
    width: '100%',
    // boxSizing: 'border-box',
    height: '450px',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    minHeight: theme.spacing(6),
    width: '100%',
  },
  filterWrap: {
    flexShrink: 1,
    flexBasis: 400,
  },
  filter: {
    width: '100%',
  },
  action: {
    flexGrow: 0,
    flexShrink: 0,
    width: 'auto',
    marginLeft: theme.spacing(1),
    textAlign: 'right',
  },
}));

const bool = params => (params.value ? <CheckIcon /> : <span></span>);

const columns = [
  { field: 'ZoneCode', headerName: 'Zone Code', width: 150, hide: false },
  { field: 'AgencyID', headerName: 'Agency ID', width: 150, hide: false },
  { field: 'Description', headerName: 'Description', width: 150, hide: false },
  { field: 'Zone1', headerName: 'Zone 1', width: 150, hide: false },
  { field: 'Zone2', headerName: 'Zone 2', width: 150, hide: false },
  { field: 'Zone3', headerName: 'Zone 3', width: 150, hide: false },
  { field: 'Zone4', headerName: 'Zone 4', width: 150, hide: false },
  { field: 'Zone5', headerName: 'Zone 5', width: 150, hide: false },
  {
    field: 'Created',
    headerName: 'Created',
    width: 120,
    valueFormatter: params => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 150, hide: false },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 120,
    valueFormatter: params => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 150, hide: false },
  {
    field: 'IsDeleted',
    headerName: 'Is Deleted',
    width: 100,
    format: 'bool',
    renderCell: bool,
    hide: false,
  },
  {
    field: 'Deleted',
    headerName: 'Deleted',
    width: 120,
    valueFormatter: params => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'DeletedBy', headerName: 'Deleted By', width: 150, hide: false },
  { field: 'DeletedIP', headerName: 'Deleted IP', width: 140, hide: false },
];
const ZoneTab = props => {
  const { zoneList, ptsSOPID } = props;
  const classes = useStyles();
  const [rows, setRows] = useState(zoneList);

  const [selection, setSelection] = useState(null);

  useEffect(() => {
    setRows(zoneList);
  }, [zoneList]);

  return (
    <div>
      <TopCard parent="Zone" ptsSOPID={ptsSOPID} />
      <h5>Zones</h5>
      <AttatchmentsActions ParentType="Zone" ptsSOPID={ptsSOPID} selection={selection} />

      <div className={classes.gridWrap}>
        <XGrid
          columns={columns}
          rows={rows}
          rowHeight={38}
          disableMultipleSelection
          getRowId={row => row.IntCode}
          onSelectionModelChange={newSelection => {
            setSelection(parseInt(newSelection.selectionModel[0]));
          }}
        />
      </div>
    </div>
  );
};

export default ZoneTab;
