import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { getFormStyle } from '../../utils/functions';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  formControl: {
    ...getFormStyle(300, 500),
  },
  popper: {
    padding: theme.spacing(2),
  },
  newOption: {
    ...getFormStyle(100, 100),
  },
  addOption: {
    marginTop: theme.spacing(1),
  },
}));

function ActionValueFormatted (props) {
  const classes = useStyles();
  const { value, setValue, code, allowAdd, multiple } = props;
  const [data, setData] = useState([]);
  const [newOption, setNewOption] = useState('');
  const [addOpen, setAddOpen] = useState('');
  const [newOptionValid, setNewOptionValid] = useState();

  useEffect(() => {
    if (props.data) {
      setData(props.data);
    } else {
      setData([]);
    }
    // eslint-disable-next-line
  }, [props.data]);

  useEffect(() => {
    newOption && !newOptionValid && setNewOptionValid(true);
    !newOption && newOptionValid && setNewOptionValid(false);
    // eslint-disable-next-line
  }, [newOption]);

  const getLabel = (row) => {
    const { Description } = row;
    const Code = row[code]
    return Description ? Description : Code;
  }

  const addOption = () => {
    setAddOpen(true);
    setNewOption('');
  }

  const addSave = () => {
    if (!newOption) return;
    const val = { [code]: newOption };
    const newData = [...data];
    newData.push(val);
    setData(newData);
    const newValue = [...value];
    newValue.push(newOption);
    setValue(newValue);
    setAddOpen(false);
  }

  return (
    <>
      {data.length > 0 && !addOpen && <FormControl className={classes.formControl} size="small">
        <InputLabel>Value</InputLabel>
        <Select
          value={value}
          onChange={ev => setValue(ev.target.value)}
          label="Value"
          multiple={multiple}
          className={classes.select}
          error={!value || value && value.length === 0}
        >
          <MenuItem value=''></MenuItem>
          {data.map(row => <MenuItem key={row[code]} value={row[code]}>{getLabel(row)}</MenuItem>)}
        </Select>
      </FormControl>}
      {!addOpen && allowAdd && <IconButton className={classes.margin} onClick={addOption}>
        <AddIcon fontSize="small" />
      </IconButton>}
      {addOpen && (
        <>
          <TextField
            size="small"
            value={newOption}
            onChange={ev => setNewOption(ev.target.value)}
            className={classes.addOption}
            error={newOption.length === 0}
          />
          <IconButton className={classes.margin} onClick={addSave} disabled={!newOptionValid}>
            <CheckIcon fontSize="small" />
          </IconButton>
          <IconButton className={classes.margin} onClick={() => setAddOpen(false)}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      )}
    </>
  );
}

export default ActionValueFormatted;
