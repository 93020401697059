/**
 * Oryginal Drag and drop - problem with translate, but state is remained
 */

 import React, { useState } from 'react';
 import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
 import { makeStyles } from '@material-ui/core/styles';
 import Card from '@material-ui/core/Card';
 import { reorderDnd } from '../utils/functions';
 
 const useStyles = makeStyles(theme => ({
   wrap: {
     padding: 15,
   },
   column: {
     width: 250,
   },
   card: {
     padding: 15,
     marginBottom: 5,
   },
 }));
 
 const rawData = [
   { id: 1, title: 'John Smith', text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'},
   { id: 2, title: 'Antony Hopkins', text: 'Cras nibh lorem, vehicula a condimentum nec'},
   { id: 3, title: 'Mark Brown', text: 'Nulla et eros convallis, scelerisque turpis eget'},
   { id: 4, title: 'Ann Brainfull', text: 'Vivamus sit amet consequat est, in mollis leo'},
   { id: 5, title: 'Justin Donovan', text: 'Quisque fermentum lacinia odio, in pharetra purus tincidunt at'},
   { id: 6, title: 'Michael Jackson', text: 'Curabitur elementum auctor purus sit amet venenatis'},
   { id: 7, title: 'Samatha Fox', text: 'Praesent malesuada faucibus nisi, tincidunt ornare ante'},
 ];
 
 const DndExample = () => {
   const classes = useStyles();
   const [data, setData] = useState(rawData);
 
   const onDragEnd = (result) => {
     if (!result.destination) return;
     setData(reorderDnd(data, result));
   }
 
   return (
     <div className={classes.wrap}>
       <div className={classes.column}>
         <DragDropContext onDragEnd={ onDragEnd } >
           <Droppable droppableId="droppable">
             {({innerRef, droppableProps, placeholder }, snapshot) => (
               <div ref={innerRef} {...droppableProps}>
                 {data.map((item, idx) => (
                   <Draggable key={item.id} draggableId={item.id + '-dnd-item'} index={idx}>
                     {({innerRef, draggableProps, dragHandleProps }, snapshot) => (
                      <div
                        ref={innerRef}
                        {...draggableProps}
                        {...dragHandleProps}
                      >
                        <NameCard data={item} />
                      </div>
                    )}
                   </Draggable>
                 ))}
                 {placeholder}
               </div>
             )}
           </Droppable>
         </DragDropContext>
       </div>
     </div>
   );
 }
 
 const NameCard = (props) => {
   const { title, text } = props.data;
   const classes = useStyles();
   const [clicks, setClicks] = useState(0);
 
   return (
     <Card className={classes.card} onClick={() => setClicks(clicks + 1)}>
       <h5>{title} {clicks}</h5>
       <div>{text}</div>
     </Card>
   )
 }
 
 export default DndExample;
 