import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Content from '../Content';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { getService } from '../../reducers/service';
import { notify } from '../../reducers/NotifierReducer';

const useStyles = makeStyles(theme => ({}));

function AddressesDuplicate(props) {
  const service = getService('settings-duplicate-checking-addresses');

  const [checkForDuplicates, setCheckForDuplicates] = useState(1);
  const [addressNum, setAddressNum] = useState(0);
  const [addressNumPrefix, setAddressNumPrefix] = useState(0);
  const [addressNumSuffix, setAddressNumSuffix] = useState(0);
  const [cityDescription, setCityDescription] = useState(0);
  const [postalCode, setPostalCode] = useState(0);
  const [postalCodeExtension, setPostalCodeExtension] = useState(0);
  const [postDirection, setPostDirection] = useState(0);
  const [preDirection, setPreDirection] = useState(0);
  const [state, setState] = useState(0);
  const [streetName, setStreetName] = useState(0);
  const [streetType, setStreetType] = useState(0);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    service
      .find()
      .then(res => {
        handleFetchedData(res);
      })
      .catch(err => console.log(err));
  };

  const handleSaveClick = async () => {
    try {
      await service.create({
        Path: 'PTS.Address.DuplicateAddress.CheckForDuplicates',
        ValueBoolean: checkForDuplicates,
      });
      await service.create({
        Path: 'PTS.Address.DuplicateAddress.SearchParameters.AddressNum',
        ValueBoolean: addressNum,
      });
      await service.create({
        Path: 'PTS.Address.DuplicateAddress.SearchParameters.AddressNumPrefix',
        ValueBoolean: addressNumPrefix,
      });
      await service.create({
        Path: 'PTS.Address.DuplicateAddress.SearchParameters.AddressNumSuffix',
        ValueBoolean: addressNumSuffix,
      });
      await service.create({
        Path: 'PTS.Address.DuplicateAddress.SearchParameters.CityDescription',
        ValueBoolean: cityDescription,
      });
      await service.create({
        Path: 'PTS.Address.DuplicateAddress.SearchParameters.PostalCode',
        ValueBoolean: postalCode,
      });
      await service.create({
        Path: 'PTS.Address.DuplicateAddress.SearchParameters.PostalCodeExtension',
        ValueBoolean: postalCodeExtension,
      });
      await service.create({
        Path: 'PTS.Address.DuplicateAddress.SearchParameters.PostDirection',
        ValueBoolean: postDirection,
      });
      await service.create({
        Path: 'PTS.Address.DuplicateAddress.SearchParameters.PreDirection',
        ValueBoolean: preDirection,
      });
      await service.create({
        Path: 'PTS.Address.DuplicateAddress.SearchParameters.State',
        ValueBoolean: state,
      });
      await service.create({
        Path: 'PTS.Address.DuplicateAddress.SearchParameters.StreetName',
        ValueBoolean: streetName,
      });
      await service.create({
        Path: 'PTS.Address.DuplicateAddress.SearchParameters.StreetType',
        ValueBoolean: streetType,
      });
      props.notify('Duplicate Settings Saved!', 'success');
    } catch (err) {
      console.log(err);
    }
  };

  const boolToBit = value => {
    return value === true ? 1 : 0;
  };

  const handleFetchedData = data => {
    data.forEach(d => {
      switch (d.Path) {
        case 'PTS.Address.DuplicateAddress.CheckForDuplicates':
          setCheckForDuplicates(boolToBit(d.ValueBoolean));
          break;
        case 'PTS.Address.DuplicateAddress.SearchParameters.AddressNum':
          setAddressNum(boolToBit(d.ValueBoolean));
          break;
        case 'PTS.Address.DuplicateAddress.SearchParameters.AddressNumPrefix':
          setAddressNumPrefix(boolToBit(d.ValueBoolean));
          break;
        case 'PTS.Address.DuplicateAddress.SearchParameters.AddressNumSuffix':
          setAddressNumSuffix(boolToBit(d.ValueBoolean));
          break;
        case 'PTS.Address.DuplicateAddress.SearchParameters.CityDescription':
          setCityDescription(boolToBit(d.ValueBoolean));
          break;
        case 'PTS.Address.DuplicateAddress.SearchParameters.PostalCode':
          setPostalCode(boolToBit(d.ValueBoolean));
          break;
        case 'PTS.Address.DuplicateAddress.SearchParameters.PostalCodeExtension':
          setPostalCodeExtension(boolToBit(d.ValueBoolean));
          break;
        case 'PTS.Address.DuplicateAddress.SearchParameters.PostDirection':
          setPostDirection(boolToBit(d.ValueBoolean));
          break;
        case 'PTS.Address.DuplicateAddress.SearchParameters.PreDirection':
          setPreDirection(boolToBit(d.ValueBoolean));
          break;
        case 'PTS.Address.DuplicateAddress.SearchParameters.State':
          setState(boolToBit(d.ValueBoolean));
          break;
        case 'PTS.Address.DuplicateAddress.SearchParameters.StreetName':
          setStreetName(boolToBit(d.ValueBoolean));
          break;
        case 'PTS.Address.DuplicateAddress.SearchParameters.StreetType':
          setStreetType(boolToBit(d.ValueBoolean));
          break;
        default:
          console.log('no setState available');
          break;
      }
    });
  };

  const toggle = num => {
    return num === 0 ? 1 : 0;
  };

  const isDisabled = () => {
    if (checkForDuplicates === 0) return false;
    let count = 0;
    if (addressNum === 1) count++;
    if (addressNumPrefix === 1) count++;
    if (addressNumSuffix === 1) count++;
    if (cityDescription === 1) count++;
    if (postalCode === 1) count++;
    if (postalCodeExtension === 1) count++;
    if (postDirection === 1) count++;
    if (preDirection === 1) count++;
    if (state === 1) count++;
    if (streetName === 1) count++;
    if (streetType === 1) count++;
    return count < 2;
  };

  return (
    <Content>
      <div className="ml-4 mt-4">
        <h4>Addresses Duplicate Checking</h4>
        <div className="text-right pr-4">
          <Button
            onClick={handleSaveClick}
            variant="contained"
            color="primary"
            disabled={isDisabled()}>
            <SaveIcon /> Save
          </Button>
        </div>
        <div className="mt-4">
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkForDuplicates === 1}
                  onChange={() => setCheckForDuplicates(toggle(checkForDuplicates))}
                  name="Check"
                  color="primary"
                />
              }
              label="Check for Duplicates"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={addressNumPrefix === 1}
                  onChange={() => setAddressNumPrefix(toggle(addressNumPrefix))}
                  name="addressNumPrefix"
                  color="primary"
                  disabled={checkForDuplicates === 0}
                />
              }
              label="Address Number Prefix"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={addressNum === 1}
                  onChange={() => setAddressNum(toggle(addressNum))}
                  name="addressNum"
                  color="primary"
                  disabled={checkForDuplicates === 0}
                />
              }
              label="Address Number"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={addressNumSuffix === 1}
                  onChange={() => setAddressNumSuffix(toggle(addressNumSuffix))}
                  name="addressNumSuffix"
                  color="primary"
                  disabled={checkForDuplicates === 0}
                />
              }
              label="Address Number Suffix"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={preDirection === 1}
                  onChange={() => setPreDirection(toggle(preDirection))}
                  name="preDirection"
                  color="primary"
                  disabled={checkForDuplicates === 0}
                />
              }
              label="Pre Direction"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={postDirection === 1}
                  onChange={() => setPostDirection(toggle(postDirection))}
                  name="postDirection"
                  color="primary"
                  disabled={checkForDuplicates === 0}
                />
              }
              label="Post Direction"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={streetName === 1}
                  onChange={() => setStreetName(toggle(streetName))}
                  name="streetName"
                  color="primary"
                  disabled={checkForDuplicates === 0}
                />
              }
              label="Street Name"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={streetType === 1}
                  onChange={() => setStreetType(toggle(streetType))}
                  name="StreetType"
                  color="primary"
                  disabled={checkForDuplicates === 0}
                />
              }
              label="Street Type"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={cityDescription === 1}
                  onChange={() => setCityDescription(toggle(cityDescription))}
                  name="City"
                  color="primary"
                  disabled={checkForDuplicates === 0}
                />
              }
              label="City"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state === 1}
                  onChange={() => setState(toggle(state))}
                  name="state"
                  color="primary"
                  disabled={checkForDuplicates === 0}
                />
              }
              label="State"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={postalCode === 1}
                  onChange={() => setPostalCode(toggle(postalCode))}
                  name="postalCode"
                  color="primary"
                  disabled={checkForDuplicates === 0}
                />
              }
              label="Postal Code"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={postalCodeExtension === 1}
                  onChange={() => setPostalCodeExtension(toggle(postalCodeExtension))}
                  name="postalCodeExtension"
                  color="primary"
                  disabled={checkForDuplicates === 0}
                />
              }
              label="Postal Code Extension"
            />
          </div>
        </div>
      </div>
    </Content>
  );
}

export default connect(null, {
  notify,
})(AddressesDuplicate);
