import store from '../config/configureStore';
import { getSettings, clearSettings, setConfigurationMenuEnabled } from './ConfigReducer';
import { clearEvents } from './EventsReducer';
import { clearUnits } from './UnitsReducer';
import { updateZones } from './ZonesReducer';
import { notify } from './NotifierReducer';
import { getService } from './service';
import {
  getUserPermissions,
  getAccessPermission,
  getAgenciesAccessPermission,
} from './PermissionsReducer';

export const USER_SET_AUTHENTICATED = 'USER/SET_AUTHENTICATED';
export const USER_SET_DATA = 'USER/SET_DATA';
export const USER_SET_USER_UNIT = 'USER/USER_SET_USER_UNIT';
export const USER_SET_USER_UNITS = 'USER/SET_USER_UNITS';
export const USER_CLEAR_DATA = 'USER/USER_CLEAR_DATA';
let logOutSent = false;

export const submitLoginForm = (username, password) => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      const user = await client.authenticate({
        strategy: 'local',
        username: username,
        password: password,
        AppName: 'SETTINGS',
      });
      logOutSent = false;

      dispatch(setConfigurationMenuEnabled(false));

      await dispatch(getUserPermissions());

      dispatch({ type: USER_SET_DATA, payload: user });
      dispatch({ type: USER_SET_AUTHENTICATED, payload: true });
      dispatch(getSettings());
      dispatch(updateZones());

      const accessPermission =
        getAccessPermission('modules', 'Settings') ||
        Boolean(getAgenciesAccessPermission('modules', 'Settings').length);
      const isPermissionLoaded = store.store.getState().permissions.loaded;
      if (isPermissionLoaded && !accessPermission) {
        dispatch(notify("You don't have the permission to access this application", 'error'));
        dispatch(logOut());
      }
    } catch (error) {
      const { code } = error;
      switch (code) {
        case 408:
          dispatch(
            notify(
              'It takes too long to connect to the server. Please check your connection and try again.',
              'warning'
            )
          );
          break;
        case 401:
          dispatch(notify('Login failed. Please try again.', 'error'));
          break;
        default:
          dispatch(
            notify(`Unknown error ${code}. Please check you connection and try again.`, 'error')
          );
      }
    }
  };
};

export const setUserAuthenticated = state => {
  const client = store.store.getState().websocket.websocket;
  return dispatch => {
    if (client) {
      if (state === false) {
        dispatch(logOut());
      } else {
        dispatch({ type: USER_SET_AUTHENTICATED, payload: true });
      }
    }
  };
};

export const logOut = () => dispatch => {
  if (logOutSent) return;
  logOutSent = true;
  try {
    const client = store.store.getState().websocket.websocket;
    if (client) {
      client
        .service('authenticate')
        .remove('SETTINGS')
        .catch(e => {
          console.log('logout err', e);
        });
      client.logout();
    } else {
      window.localStorage.removeItem('auth');
    }
    dispatch({ type: USER_CLEAR_DATA });
    dispatch(clearSettings());
    dispatch(clearEvents());
    dispatch(clearUnits());
    dispatch(setConfigurationMenuEnabled(false));

    dispatch({ type: USER_SET_AUTHENTICATED, payload: false });
  } catch (err) {
    console.log(err);
  }

  // dispatch(setUserData(null));
};

export const sendPassResetLink = email => {
  const service = getService('pass-reset');
  return service.create({
    action: 'pass-reset',
    email,
    ResetMethod: 'email',
    AppName: 'SETTINGS',
    RedirectTo: process.env.REACT_APP_PUBLIC_URL + '/Reset-password',
  });
};

export const resetPass = (hash, newPass) => {
  const service = getService('pass-reset');
  return service.patch(hash, { newPass, AppName: 'SETTINGS' });
};

export const validateResetPassHash = hash => {
  const service = getService('pass-reset');
  return service.get(hash);
};

export const changePass = (currentPass, newPass) => {
  const service = getService('authenticate');
  return service.patch(0, { currentPass, newPass, AppName: 'SETTINGS' });
};

// export const showPassExpiration = () => (dispatch) => {
//   dispatch({ type: PASS_RESET, payload: true });
// }

// export const hidePassExpiration = () => (dispatch) => {
//   const state = store.store.getState();
//   const ptsUserID = state?.user?.userData?.user?.ptsUserID;
//   const savedSetting = { [`changePassReminder${ptsUserID}`]: new Date().getTime() }
//   saveLocalSettings(savedSetting);
//   dispatch({ type: PASS_RESET, payload: false });
// }

export const setUserData = data => {
  return dispatch => {
    dispatch({ type: USER_SET_DATA, payload: data });
  };
};

// ----------------------- units

export const setUserUnits = units => {
  return dispatch => {
    dispatch({ type: USER_SET_USER_UNITS, payload: units });
  };
};

export const setUserUnit = unit => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    if (unit) {
      dispatch({ type: USER_SET_USER_UNIT, payload: unit });
    } else {
      if (!client) return;
      try {
        const unitID = store.store.getState().user.userUnit.ptsUnitID;
        const unit = await client.service('units').get(unitID);
        dispatch({ type: USER_SET_USER_UNIT, payload: unit[0] });
      } catch (error) {
        console.log('UserReducer/setUserUnit: error: ', error, error.code);
      }
    }
  };
};

const defaultState = {
  isAuthenticated: false,
  userData: null,
};

/* Workstation protection - should be removed in production */
const wrkKey = process.env.REACT_APP_WORSTATION_KEY;
const savedWrkKey = window.atob(window.localStorage.getItem('wrk-ps'));
const wrkKeyMatch = !wrkKey || savedWrkKey === wrkKey;

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case USER_SET_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.payload && wrkKeyMatch,
      };
    case USER_SET_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    case USER_CLEAR_DATA:
      return defaultState;
    default:
      break;
  }
  return state;
}
