import React, { useEffect, useState } from 'react';
import SettingsPage from './../Content';
import {
  Grid,
  Paper,
  Typography,
  Divider,
  Box,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import { getService } from './../../reducers/service';
import { Context } from 'immutability-helper';
export default function Appearance(props) {
  const cdr = getService('cdr');
  const { is24Hours, setIs24Hours } = props;

  const handleChangingTime = event => {
    //TODO:need to implement
    setIs24Hours(event.target.checked);
  };

  const getTimeSetting = async () => {
    try {
      const timeSetting = await cdr.find({ query: { Path: 'Application.TimeFormat' } });
      if (timeSetting.length) setIs24Hours(timeSetting[0].ValueBoolean);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getTimeSetting();
  }, []);

  return (
    <div>
      <FormControlLabel
        style={{ paddingLeft: '10px' }}
        control={<Checkbox checked={is24Hours} onChange={handleChangingTime} />}
        label="24h Format"
      />
    </div>
  );
}
