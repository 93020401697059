/**
 * Menu item configurations for desktop and mobile
 */

export default [
  {
    label: 'SETTINGS',
    tooltip: 'Settings',
    link: '/settings',
    icon: 'map-pin',
  },
  // {
  //   label: 'Dev Page',
  //   tooltip: 'Dev test',
  //   link: '/dev-test',
  //   icon: 'map-pin',
  // },
];
