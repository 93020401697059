import React from 'react';
import { connect } from 'react-redux';

// import TopBar from '../../components/TopBar';
// import BottomBar from '../../layout-components/BottomBar';
import DrawerNavigation from '../../components/DrawerNavigation';
// import DrawerEventsDesktop from '../../components/DrawerEventsDesktop';
// import DrawerUnitsDesktop from '../../components/DrawerUnitsDesktop';
// import DrawerEventsMobile from '../../components/DrawerEventsMobile';
// import DrawerUnitsMobile from '../../components/DrawerUnitsMobile';

import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;
const drawerHeight = 80;

const useStyles = makeStyles(theme => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.bgElements,
  },
  appBarBottom: {
    backgroundColor: theme.palette.bgElements,
    zIndex: theme.zIndex.drawer + 1,
    top: 'auto',
    bottom: 0,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: {
    height: drawerHeight,
    backgroundColor: theme.palette.bgElements,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.bgElements,
    padding: '0px 5px',
  },
  content: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    backgroundColor: theme.palette.bg,
  },
  contentInner: {
    flex: 1,
    position: 'relative',
    boxSizing: 'border-box',
    padding: `${drawerHeight + 20}px 20px 20px`,
    minHeight: '100%',
    maxWidth: '100%',
    '& > div': {
      minHeight: '100%',
    },
  },
  '@media(max-width: 850px)': {
    contentInner: {
      padding: `${drawerHeight + 10}px 10px 10px`,
    },
  },
}));

const MainLayout = props => {
  const { children, contentBackground } = props;

  const classes = useStyles();

  return (
    <div className={clsx('app-wrapper', contentBackground)}>
      {/* <TopBar /> */}
      <DrawerNavigation />
      {/* <DrawerEventsDesktop /> */}
      {/* <DrawerUnitsDesktop /> */}
      {/* <DrawerEventsMobile /> */}
      {/* <DrawerUnitsMobile /> */}
      {/* <BottomBar /> */}
      <div className={classes.content}>
        <div className={classes.contentInner}>{children}</div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  sidebarToggle: state.theme.sidebarToggle,
  sidebarToggleMobile: state.theme.sidebarToggleMobile,
  sidebarFixed: state.theme.sidebarFixed,
  headerFixed: state.theme.headerFixed,
  headerSearchHover: state.theme.headerSearchHover,
  headerDrawerToggle: state.theme.headerDrawerToggle,
  footerFixed: state.theme.footerFixed,
  contentBackground: state.theme.contentBackground,
});

export default connect(mapStateToProps)(MainLayout);
