export const SET_MENU_STATE = 'SET_MENU_STATE';

export const setMenuState = (mnuState) => dispatch => {
  dispatch({ type: SET_MENU_STATE, payload: mnuState });
}

export default function reducer(state = null, action) {
  switch (action.type) {
    case SET_MENU_STATE:
      return action.payload;
    default:
      return state;
  }
}