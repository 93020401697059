import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { getFormStyle } from '../../utils/functions';

const useStyles = makeStyles(theme => ({
  formControl: {
    ...getFormStyle(190, 190),
  },
}));

function Operators (props) {
  const classes = useStyles();
  const { value, setValue } = props;

  return (
    <FormControl className={classes.formControl} size="small">
      <InputLabel>Operators</InputLabel>
      <Select
        value={value}
        onChange={ev => setValue(ev.target.value)}
        label="Operators"
        className={classes.select}
      >
        <MenuItem value='equal'>Equals</MenuItem>
        <MenuItem value='notEqual'>Does not Equal</MenuItem>
        <MenuItem value='greater'>Is greater than</MenuItem>
        <MenuItem value='greaterEqual'>Is greater than or equal to</MenuItem>
        <MenuItem value='less'>Is less than</MenuItem>
        <MenuItem value='lessEqual'>Is less than or equal to</MenuItem>
        <MenuItem value='between'>Is between</MenuItem>
        <MenuItem value='contains'>Contains</MenuItem>
        <MenuItem value='notContains'>Does not contain</MenuItem>
        <MenuItem value='beginsWith'>Begins with</MenuItem>
        <MenuItem value='endsWith'>Ends with</MenuItem>
        <MenuItem value='anyOf'>Is any of</MenuItem>
        <MenuItem value='noneOf'>Is none of</MenuItem>
      </Select>
    </FormControl>
  );
}

export default Operators;
