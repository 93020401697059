import { Fab, Tooltip } from '@material-ui/core';
import React from 'react';
import AddIcon from '@material-ui/icons/Add';

export default function AddButton(props) {
  return (
    <Tooltip title={props.tooltipText || 'Add'}>
      <Fab style={{ marginLeft: '10px' }} size="small" color="secondary" aria-label="add">
        <AddIcon onClick={props.onClick} />
      </Fab>
    </Tooltip>
  );
}
