import React from 'react';
import TextValue from './TextValue';
import RangeValue from './RangeValue';
import ActionValueFormatted from './ActionValueFormatted';
import NumberValue from './NumberValue';

const actionKey = {
  Agency: 'Agencies',
  EventStatus: 'EventStatuses',
  EventType: 'CallTypes',
  RequestedActions: 'RequestedActions',
  CallMethods: 'CallMethods',
}

const arrayOperators = [ 'anyOf', 'noneOf' ];
const textOperators = [ 
  'beginsWith', 'endsWith'
];
const selectOperators = ['equal', 'notEqual', 'contains', 'notContains'];

function ActionValue (props) {
  const { value, setValue, actionType, operator, dictionary } = props;

  const isSelect = selectOperators.indexOf(operator) !== -1
  const isText = textOperators.indexOf(operator) !== -1;
  const isArray = arrayOperators.indexOf(operator) !== -1;
  const isTouple = operator === 'between';
  const isNumber = !isText && !isArray && !isTouple && !isSelect;
  const actionData = actionKey[actionType];

  return (
    <>
      {(((isText || isSelect) && !actionData) || isText && Boolean(actionData)) && <TextValue value={value} setValue={setValue} />}
      {isSelect   && Boolean(actionData) && <ActionValueFormatted
        value={value}
        setValue={setValue}
        data={dictionary[actionData]}
        code={actionType === 'Agency' ? 'AgencyID' : 'Code'}
        allowAdd={!actionData}
      />}
      {isTouple && <RangeValue value={value} setValue={setValue} />}
      {isNumber && <NumberValue value={value} setValue={setValue} />}
      {isArray && <ActionValueFormatted 
        value={value}
        setValue={setValue}
        data={dictionary[actionData]}
        code={actionType === 'Agency' ? 'AgencyID' : 'Code'}
        allowAdd={!actionData}
        multiple
      />}
    </>
  );
}

export default ActionValue;
