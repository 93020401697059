import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import {
  Grid,
  TextField,
  Typography,
  Checkbox,
  Button,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import RLDD from 'react-list-drag-and-drop/lib/RLDD';

import Content from '../../Content';

import { getService } from '../../../reducers/service';
import AddButton from '../AddButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

function AddEditWorkFlow() {
  const params = useParams();
  const history = useHistory();
  const workflowService = getService('ptsworkflow');
  const workflowStepService = getService('ptsworkflowstep');
  const [workflow, setWorkflow] = useState({});
  const [steps, setSteps] = useState([]);

  const handleRLDDChange = newItems => {
    setSteps(newItems);
  };

  const handleChange = (name, value) => {
    setWorkflow({
      ...workflow,
      [name]: value,
    });
  };

  const handleSaveClick = () => {
    if (!workflow.ptsWorkflowID) {
      workflowService
        .create(workflow)
        .then(res => history.goBack())
        .catch(err => console.log(err));
    } else {
      let updatedSteps = steps.map((s, i) => {
        s.intSequence = i + 1;
        delete s.id;
        return s;
      });
      let updatedWorkflow = {
        ...workflow,
        Step: updatedSteps,
      };
      workflowService
        .patch(workflow.ptsWorkflowID, updatedWorkflow)
        .then(res => history.goBack())
        .catch(err => console.log(err));
    }
  };

  const handleOnClickAddPage = () => {
    history.push(`/workflows/${workflow.ptsWorkflowID}/steps/add`);
  };

  const handleEditClick = id => {
    history.push(`/workflows/${workflow.ptsWorkflowID}/steps/edit/${id}`);
  };

  const handleDeleteClick = id => {
    workflowStepService
      .remove(id)
      .then(res => console.log(res))
      .catch(err => console.log(err));
  };

  useEffect(() => {
    if (params.id) {
      workflowService
        .get(params.id)
        .then(res => {
          console.log(res);
          setWorkflow(res);
          let d = res.Step.map((r, i) => {
            return { ...r, id: i + 1 };
          });
          d.sort((a, b) => (a.intSequence > b.intSequence ? 1 : -1));
          setSteps(d);
        })
        .catch(err => console.log(err));
    }
  }, [params.id, workflowService]);

  return (
    <Content>
      <Grid container className="p-4">
        <Grid xs={11} item>
          <Typography variant="h6">{workflow.ptsWorkflowID ? 'Edit' : 'Add'} Step</Typography>
        </Grid>
        <Grid item>
          <AddButton tooltipText="Add Step to this workflow" onClick={handleOnClickAddPage} />
        </Grid>
        <Grid container className="pt-4" spacing={3}>
          <Grid item xs={4} lg={4}>
            <TextField
              autoComplete="hidden"
              size="small"
              id="standard-textarea-workflow-name"
              label="Workflow Name"
              placeholder="Workflow Name"
              variant="outlined"
              fullWidth
              multiline
              defaultValue={workflow?.Workflow || ''}
              onBlur={event => handleChange(event.target.name, event.target.value)}
              InputLabelProps={{ shrink: true }}
              name="Workflow"
            />
          </Grid>

          <Grid item xs={4} lg={4}>
            <TextField
              autoComplete="hidden"
              size="small"
              id="standard-textarea-workflow-type"
              label="Type"
              placeholder="Type"
              variant="outlined"
              fullWidth
              multiline
              defaultValue={workflow?.Type || ''}
              onBlur={event => handleChange(event.target.name, event.target.value)}
              InputLabelProps={{ shrink: true }}
              name="Type"
            />
          </Grid>

          <Grid item>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    id="isused-checkbox"
                    key={workflow?.IsUsed}
                    checked={workflow?.IsUsed}
                    onChange={event => handleChange(event.target.name, event.target.checked)}
                    name="IsUsed"
                  />
                }
                label="Is Used"
              />
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    id="isactive-checkbox"
                    key={workflow?.IsActive}
                    checked={workflow?.IsActive}
                    onChange={event => handleChange(event.target.name, event.target.checked)}
                    name="IsActive"
                  />
                }
                label="Is Active"
              />
            </FormControl>
          </Grid>

          <Grid xs={6} item>
            <Typography variant="h5">Steps</Typography>
            {steps.length === 0 && 'No Steps Found'}
            <List>
              <RLDD
                items={steps}
                itemRenderer={step => {
                  return (
                    <ListItem
                      style={{
                        cursor: 'pointer',
                        background: '#ddd',
                        color: '#000',
                        marginTop: '20px',
                      }}>
                      <ListItemText primary={step.Step} />
                      <ListItemSecondaryAction>
                        <Tooltip title="Edit Step">
                          <IconButton edge="end" aria-label="edit" className="ml-2">
                            <EditIcon onClick={() => handleEditClick(step.ptsWorkflowStepID)} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete Step">
                          <IconButton edge="end" aria-label="delete" className="ml-1">
                            <DeleteIcon onClick={() => handleDeleteClick(step.ptsWorkflowStepID)} />
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                }}
                onChange={handleRLDDChange}
              />
            </List>
          </Grid>
        </Grid>
        <Grid item className="pt-4">
          <Button onClick={handleSaveClick} variant="contained" color="primary">
            Save
          </Button>
        </Grid>
        <Grid item className="pl-3 pt-4">
          <Button onClick={() => history.goBack()} variant="contained" color="default">
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Content>
  );
}

export default AddEditWorkFlow;
