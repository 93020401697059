import React, { useState, useRef, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

import { handleError } from '../reducers/ErrorReducer';
import { showSpinner, hideSpinner } from '../reducers/UiReducer';
import { notify } from '../reducers/NotifierReducer';
import { closeAvailableReports } from '../reducers/DialogsReducer';
import { Dialog, DialogActions, DialogTitle, Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { DialogContent } from '@mui/material';
import { getService } from 'reducers/service';
import DatePicker2 from 'components/DatePicker2';
import { processPhoneNoWithoutExtra } from 'utils/functions';
import Checkbox2 from 'components/Checkbox2';
import Dictionary from 'components/Dictionary';

const useStyles = makeStyles(theme => ({
  actions: {
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  textField: {
    width: 600,
    maxWidth: '100%',
    marginTop: theme.spacing(1),
  },
  dialog: {
    '& $content': {
      margin: 0,
      padding: 0,
      background: 'red !important',
    },
  },
}));
const reportType = [
  { Code: 'RMS', Description: 'RMS' },
  { Code: 'RMSR', Description: 'RMSR' },
];
const formFields = [
  { name: 'ReportID', required: true, Description: 'Report ID', type: 'text', disabled: true },
  { name: 'ReportTitle', required: true, Description: 'Report Title', type: 'text' },
  { name: 'Description', Description: 'Description', type: 'text', size: 6 },
  {
    name: 'Type',
    Description: 'Type',
    required: true,
    type: 'select',
    options: reportType,
    max: 4,
  },
  { name: 'Agency', Description: 'Agency', type: 'text', max: 6 },
  { name: 'FileName', Description: 'File Name', type: 'text' },
  { name: 'DataSetName', Description: 'DataSet Name', type: 'text' },
  { name: 'DefaultParams', Description: 'Default Params', type: 'text', size: 10 },
  { name: 'IsActive', Description: 'Active', type: 'bool', size: 2 },
  { name: 'DefaultPath', Description: 'Default Path', type: 'text', maxRow: 3, size: 6 },
  { name: 'DefaultSQL', Description: 'Default SQL', type: 'text', maxRow: 3, size: 6 },
];

function AddAvailableReports(props) {
  const classes = useStyles();
  const { user, data } = props;

  const username = user?.userData?.user?.Username;
  const codeCriminalActivities = useSelector(state => state.dictionary.CriminalActivities || []);
  const service = getService('reportcatalog');
  const permissionService = getService('seccomponent');

  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (data.ptsReportID) {
      const form = {
        ReportID: data.ReportID || null,
        ReportTitle: data.ReportTitle || null,
        Description: data.Description || null,
        Type: data.Type || null,
        IsActive: data.IsActive || false,
        Agency: data.Agency || false,
        FileName: data.FileName || false,
        DefaultPath: data.DefaultPath || false,
        DefaultSQL: data.DefaultSQL || false,
        DefaultParams: data.DefaultParams || false,
        DataSetName: data.DataSetName || false,
        IsDeleted: data.IsDeleted || false,
        IsActive: data.IsActive || false,
      };

      setFormData(form);
    } else {
      setFormData({ IsActive: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFormChange = (name, data) => {
    const newForm = { ...formData };
    newForm[name] = data;
    setFormData({ ...newForm });
  };

  const save = async () => {
    props.showSpinner();
    try {
      //   await addEventNote(text, ptsEventID);
      const newForm = { ...formData, Type: formData.Type?.Code || '' };

      if (data.ptsReportID) {
        const id = data?.ptsReportID;
        newForm['UpdatedBy'] = username;
        const updatedData = await service.patch(id, newForm);
        props.notify('Record Updated', 'success', updatedData);
      } else {
        newForm['CreatedBy'] = username;
        await service.create(newForm);
        const permissionObj = {
          Label: newForm.ReportID,
          Type: 'Report.bool',
          CreatedBy: username,
        };
        // need to create new permission component as well
        await permissionService.create(permissionObj);
        props.notify('New Record Created', 'success');
      }
    } catch (err) {
      props.handleError(err, 'Error, Record not saved.', err);
    }
    props.hideSpinner();
    close();
  };

  const close = () => {
    props.closeAvailableReports();
  };

  const renderActions = () => {
    const { Type, ReportID, ReportTitle } = formData;
    const disable = !Type || !ReportID || !ReportTitle;
    return (
      <>
        <Button disabled={disable} color="primary" variant="contained" autoFocus onClick={save}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  const renderTextField = item => {
    return (
      <Grid item sm={item.size || 3}>
        <TextField
          //fullWidth
          size="small"
          style={{ width: '100%' }}
          type="text"
          value={formData[item.name] || ''}
          label={item.Description}
          required={item.required}
          error={item.required && !formData[item.name]}
          inputProps={{
            maxLength: item.max || 100,
          }}
          disabled={data.ptsReportID && item.disabled}
          multiline={item.maxRow}
          //   rowsMax={item.maxRow || 1}
          rows={item.maxRow || 1}
          variant="outlined"
          onChange={e => handleFormChange(item.name, e.target.value)}
        />
      </Grid>
    );
  };
  const renderCheckbox = item => {
    return (
      <Grid item sm={item.size || 3}>
        <Checkbox2
          checked={formData[item.name] || false}
          onChange={e => handleFormChange(item.name, e.target.checked)}
          label={item.Description}
        />
      </Grid>
    );
  };
  const renderDatepicker = item => {
    return (
      <DatePicker2
        onChange={(n, date) => handleFormChange(item.name, date)}
        label={item.Description}
        style={{ width: '100%' }}
        margin="none"
        value={formData[item.name] || null}
        className={classes.field}
      />
    );
  };
  const renderAutoComplete = item => {
    return (
      <Grid item sm={item.size || 3}>
        {item.multi ? (
          <Autocomplete
            key={item.name}
            multiple
            id={item.name}
            options={codeCriminalActivities || []}
            getOptionLabel={option => option.Code || ''}
            value={formData[item.name] || []}
            onChange={(_, newValue) => handleFormChange(item.name, newValue)}
            getOptionSelected={(option, value) => option.Code == value.Code}
            renderOption={option => (
              <span>
                <strong>{option.Code}</strong>
                {!!option.Description && <> - {option.Description}</>}
              </span>
            )}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label={`Select ${item.name}`}
                placeholder={`Select ${item.name}`}
              />
            )}
          />
        ) : (
          <Dictionary
            options={item.options}
            className={classes.item}
            onChange={(ev, val) => handleFormChange(item.name, val)}
            value={formData[item.name]}
            compact
            error={item.required && !formData[item.name]}
            label={item.Description}
            multiple={item.multi || false}
          />
        )}
      </Grid>
    );
  };

  return (
    <Dialog
      onClose={close}
      title={data?.ptsReportID ? 'Edit Report' : 'Add Report'}
      open={true}
      actions={renderActions()}
      //   className={classes.dialog}

      // toolbar={false}
      // draggable={false}
      fullWidth
      maxWidth="lg">
      <DialogTitle>{data?.ptsReportID == 'edit' ? 'Edit' : 'Add'} Report</DialogTitle>
      <DialogContent className="py-2">
        <Grid container spacing={2} style={{ width: '100%' }}>
          {formFields.map(item => {
            switch (item.type) {
              case 'text':
                return renderTextField(item);
              case 'bool':
                return renderCheckbox(item);
              case 'select':
                return renderAutoComplete(item);
              case 'date':
                return renderDatepicker(item);
              default:
                return null;
            }
          })}
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className=" mb-1 mr-3">{renderActions()}</div>
      </DialogActions>
    </Dialog>
  );
}
const mapStateToProps = state => {
  return {
    dictionary: state.dictionary,
    wsClient: state.websocket,
    user: state.user,
  };
};
export default connect(mapStateToProps, {
  showSpinner,
  hideSpinner,
  handleError,
  notify,
  closeAvailableReports,
})(AddAvailableReports);
