
    import React from 'react';
    import SvgIcon from '@material-ui/core/SvgIcon';
    
    function icons8documentwriter100Icon(props) {
      return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="100" height="100"><path d="M15 3c-4.24 0-6 2.72-6 6 0 1.11.53 2.22.53 2.22-.21.12-.56.51-.48 1.19.17 1.29.72 1.61 1.08 1.64.13 1.19 1.42 2.73 1.87 2.95v2c-.002.007-.008.011-.01.018a.994.994 0 0 0-.775.539l-.313.629C8.434 21.569 3 21.226 3 27h14V17c0-1.65 1.35-3 3-3h.08c.34-.13.73-.53.87-1.59.08-.69-.27-1.07-.48-1.19 0 0 .53-1.01.53-2.22 0-2.43-.95-4.5-3-4.5 0 0-.71-1.5-3-1.5zm5 13a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h9a1 1 0 0 0 1-1v-9.586a1 1 0 0 0-.293-.707l-2.414-2.414a.997.997 0 0 0-.707-.293H20zm6 1 3 3h-2.5a.5.5 0 0 1-.5-.5V17zm-4.5 6h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1 0-1zm0 2h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1 0-1zm0 2h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1 0-1z"/></SvgIcon>
      );
    }
  
    export default icons8documentwriter100Icon;