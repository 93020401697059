import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function TowTruckIcon(props) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <path d="M 4.96875 4 C 4.726563 4.007813 4.464844 4.097656 4.28125 4.28125 L 3.28125 5.28125 C 2.917969 5.644531 2.890625 6.226563 3.21875 6.625 L 17 23.34375 L 17 26 L 27 26 L 27 23 C 27 22.707031 26.878906 22.441406 26.65625 22.25 L 5.65625 4.25 C 5.457031 4.082031 5.210938 3.992188 4.96875 4 Z M 4 10.71875 L 4 19 C 4 19.550781 4.449219 20 5 20 C 5.550781 20 6 20.449219 6 21 C 6 21.550781 5.550781 22 5 22 C 4.449219 22 4 21.550781 4 21 L 2 21 C 2 22.652344 3.347656 24 5 24 C 6.652344 24 8 22.652344 8 21 C 8 19.695313 7.164063 18.570313 6 18.15625 L 6 13.15625 Z M 32 13 C 30.347656 13 29 14.347656 29 16 L 29 38 L 33.09375 38 C 33.574219 40.832031 36.03125 43 39 43 C 41.96875 43 44.429688 40.832031 44.90625 38 L 47 38 C 48.652344 38 50 36.652344 50 35 L 50 25.375 C 50 23.363281 48.550781 21.308594 48.375 21.0625 L 44.21875 15.5 C 43.265625 14.351563 41.773438 13 40 13 Z M 38 19 L 44.34375 19 L 46.78125 22.25 C 47.085938 22.675781 47.816406 23.902344 47.96875 25 L 38 25 C 37.550781 25 37 24.449219 37 24 L 37 20 C 37 19.445313 37.546875 19 38 19 Z M 0 28 L 0 35 C 0 36.652344 1.347656 38 3 38 L 7.09375 38 C 7.574219 40.832031 10.03125 43 13 43 C 15.96875 43 18.425781 40.832031 18.90625 38 L 27 38 L 27 28 Z M 13 33 C 15.207031 33 17 34.792969 17 37 C 17 39.207031 15.207031 41 13 41 C 10.792969 41 9 39.207031 9 37 C 9 34.792969 10.792969 33 13 33 Z M 39 33 C 41.207031 33 43 34.792969 43 37 C 43 39.207031 41.207031 41 39 41 C 36.792969 41 35 39.207031 35 37 C 35 34.792969 36.792969 33 39 33 Z" />
    </SvgIcon>
  );
}

export default TowTruckIcon;
