import React from 'react';

function NamesIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      preserveAspectRatio="xMidYMid meet"
      {...props}>
      <g
        transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none">
        <path d="M56 444 c-24 -23 -24 -365 0 -388 13 -13 45 -16 193 -16 160 0 180 2 194 18 25 27 25 362 1 386 -23 24 -365 24 -388 0z m234 -69 c17 -9 31 -23 31 -33 4 -45 -3 -100 -18 -134 -20 -48 -13 -65 41 -88 25 -10 50 -28 56 -39 11 -21 9 -21 -150 -21 -158 0 -160 0 -150 20 6 11 32 30 58 42 52 26 64 53 38 90 -10 15 -16 44 -16 85 0 53 3 64 22 77 28 20 51 20 88 1z" />
      </g>
    </svg>
  );
}

export default NamesIcon;
