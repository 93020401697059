
    import React from 'react';
    import SvgIcon from '@material-ui/core/SvgIcon';
    
    function icons8bully100Icon(props) {
      return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100" height="100"><path d="M13 2c-2.757 0-5 2.243-5 5s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5zm13.988.994a1 1 0 0 0-.36.078l-5 2a1 1 0 1 0 .743 1.856l5-2a1 1 0 0 0-.383-1.934zM42 3c-2.757 0-5 2.243-5 5s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5zM21.982 7.994a1 1 0 0 0-.353 1.934l5 2a1 1 0 1 0 .742-1.856l-5-2a1 1 0 0 0-.389-.078zM9 14a6 6 0 0 0-6 6v10.889c0 .996.68 1.922 1.662 2.084A2.002 2.002 0 0 0 7 31v-9.53c0-.27.229-.5.5-.5s.5.23.5.5v23.97C8 46.792 9.05 48 10.402 48 11.884 47.998 13 46.84 13 45.3V32h2v12.592c.003.004.007 1.008.01 1.012A2.496 2.496 0 0 0 20 45.5c0-.042-.01-.08-.012-.121.002-.028.012-.05.012-.078V20a2 2 0 0 1 2-2h7.889c.996 0 1.922-.68 2.084-1.662A2.002 2.002 0 0 0 30 14H9zm30 0c-6 0-8 7-8 16v15.5a2.5 2.5 0 1 0 5 0V35l1.707-5.977C36.919 28.398 36 27.24 36 25.183V21a1 1 0 0 1 2 0l.027 4.172a4.189 4.189 0 0 0 .096.873c.024.108.056.211.09.31.157.474.41.861.789 1.145l4 3c.435.327.952.5 1.496.5.784 0 1.533-.374 2.002-1a2.503 2.503 0 0 0-.5-3.5l-3-2.25V18c0-2.286-2-4-4-4zm-.01 17.676L38 35.143V46c0 1.103.897 2 2 2s2-.897 2-2v-7.787c0-.765-.152-1.51-.453-2.213l-2.557-4.324z"/></SvgIcon>
      );
    }
  
    export default icons8bully100Icon;