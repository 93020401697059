import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { getAddressFromLocation } from 'utils/mapFunctions';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

import { handleError } from 'reducers/ErrorReducer';
import Checkbox2 from 'components/Checkbox2';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { genColHeaderName } from 'utils/functions';
import { getService } from 'reducers/service';

const useStyles = makeStyles(theme => ({
  error: {
    marginBottom: 16,
  },
  stats: {
    '& td': {
      fontSize: 16,
      paddingRight: 16,
    },
  },
  targetCityStyle: {
    display: 'inline-block',
    padding: '8px 16px',
    fontSize: 20,
    fontSize: 18,
    fontWeight: 500,
    marginBottom: 16,
    '& span': {
      display: 'inline-block',
      color: theme.palette.secondary.main,
      marginRight: 8,
    },
  },
}));

function Review(props) {
  const {
    source,
    target,
    data,

    dictionary,
    setLoading,
    cityDetails,
    setCityDetails,

    deleteSource,
    setDeleteSource,
  } = props;
  const [noCity, setNoCity] = useState(null);
  const [stats, setStats] = useState(null);
  const mountedRef = useRef(true);
  const classes = useStyles();

  useEffect(() => {
    processTargetData();

    checkStats();
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, []);

  const processTargetData = () => {
    const newData = { ...target };
    data.forEach(i => {
      const key = i.field;
      const val = i.isTargetVal ? i.targetVal : i.sourceVal;
      newData[key] = val;
    });

    setCityDetails(newData);
  };

  const checkMergeStats = async id => {
    const service = getService('city-merge');
    return service.find({ query: { ptsCityID: id } });
  };

  const checkStats = async () => {
    try {
      setLoading(true);
      const result = await checkMergeStats(source.ptsCityID);

      if (!result || !mountedRef.current) return;
      const stats = [];
      Object.entries(result).forEach(([key, val]) => {
        stats.push({ key, val });
      });
      setStats(stats);
    } catch (err) {
      props.handleError(err);
    } finally {
      setLoading(false);
    }
  };

  const renderCityDetails = () => {
    if (!cityDetails) return '';
    const { CityDescription, ptsCityID, Category } = cityDetails;
    return (
      <Paper variant="outlined" className={classes.targetCityStyle}>
        <span> ID: {ptsCityID}</span> Name: {CityDescription}
      </Paper>
    );
  };
 
  const renderDataUpdates = () => {
    if (!stats) return '';
    return (
      <div className={classes.stats}>
        <h5>Records to be updated:</h5>
        <table>
          <tbody>
            {stats.map(row => (
              <tr key={row.key}>
                <td>{genColHeaderName(row.key)}:</td>
                <td>{row.val}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderDeleteSource = () => {
    return (
      <div>
        <FormControlLabel
          control={
            <Checkbox2 checked={deleteSource} onChange={(ev, val) => setDeleteSource(val)} />
          }
          label={`Delete Source: ID - ${source.ptsCityID} Name - ${source.CityDescription || ''}`}
        />
      </div>
    );
  };

  // const displayErrors = () => {
  //   return (
  //     <>
  //       {Boolean(noCity) && (
  //         <Alert severity="error" className={classes.error}>
  //           Error, no configuration found for the city: <strong>{addressData.City}</strong>
  //         </Alert>
  //       )}
  //     </>
  //   );
  // };

  return (
    <div>
      {/* {displayErrors()} */}
      {renderCityDetails()}
      {renderDeleteSource()}
      {renderDataUpdates()}
    </div>
  );
}

const mapStateToProps = state => ({
  dictionary: state.dictionary,
});

export default connect(mapStateToProps, { handleError })(Review);
