import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '../../components/Dialog';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ImportKlmZones from './ImportKmlZones';
import ImportZonesOther from './ImportZonesOther';
import RestoreZones from './RestoreZones';
import BackupZones from './BackupZones';
import { closeImportZones } from 'reducers/DialogsReducer';

const useStyles = makeStyles(() => ({
  wrap: {
    width: 900,
    maxWidth: '100%',
    minHeight: 400,
    marginTop: -24,
  },
  content: {
    paddingTop: 16,
  },
}));

function ImportZones(props) {
  const classes = useStyles();
  const [tab, setTab] = useState('kml'); // export, import, kml, other, delete

  const close = () => props.closeImportZones();

  const renderActions = () => {
    return (
      <>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  return (
    <Dialog
      open={true}
      onClose={close}
      title="Import / Export Geofences"
      actions={renderActions()}
      toolbar>
      <div className={classes.wrap}>
        <Tabs value={tab} onChange={(ev, val) => setTab(val)}>
          <Tab label="Backup Zones" value="export" />
          <Tab label="Restore Zones" value="import" />
          <Tab label="Import KML file" value="kml" />
          <Tab label="Other" value="other" />
        </Tabs>
        <div className={classes.content}>
          {tab === 'export' && <BackupZones />}
          {tab === 'import' && <RestoreZones />}
          {tab === 'other' && <ImportZonesOther />}
          {tab === 'kml' && <ImportKlmZones />}
        </div>
      </div>
    </Dialog>
  );
}

export default connect(null, { closeImportZones })(ImportZones);
