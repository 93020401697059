import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import { updateRecipients } from '../../reducers/PagingReducer';
import TransferList from '../../components/TransferList';
import { sortObjArr } from '../../utils/functions';
import { handleError } from '../../reducers/ErrorReducer';
import { showSpinner, hideSpinner } from '../../reducers/UiReducer';
import { getGroup } from '../../reducers/PagingReducer';
import Dialog from '../../components/Dialog';
import { closeEditPageGroup } from '../../reducers/DialogsReducer';
import { saveGroup, updateGroups } from '../../reducers/PagingReducer';

const useStyles = makeStyles(theme => ({
  wrap: {
    width: 800,
    maxWidth: '100%',
    margin: '0 auto',
  },
  form: {
    margin: `0 -${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  edit: {
    padding: theme.spacing(2),
    paddingBottom: 12,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  textField: {
    margin: `0 ${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
  },
  w100pr: {
    width: '100%',
    margin: `0 4px 8px`,
  },
  actions2: {
    '& button': {
      marginLeft: theme.spacing(1),
      opacity: 0,
      transition: 'opacity 0.3s',
    },
  },
  actions: {
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  row: {
    '&:hover': {
      '& button': {
        opacity: 1,
      },
    },
  },
}));

function GrouopEdit(props) {
  const classes = useStyles();
  const { ptsGroupID } = props;
  const [data, setData] = useState(null);
  const [Group, setGroup] = useState('');
  const [Recipients, setRecipients] = useState([]);
  const [availRecipients, setAvailRecipients] = useState([]);
  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (ptsGroupID && ptsGroupID !== true) {
      loadGroup();
    } else {
      setGroup('');
      setRecipients([]);
      setValid(false);
    }
    // eslint-disable-next-line
  }, [ptsGroupID]);

  useEffect(() => {
    props.updateRecipients();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const recipients = props.recipients.filter(recipient => {
      return !Recipients.find(rec => rec.ptsRecipientID === recipient.ptsRecipientID);
    });
    sortSetAvailRecipients(recipients);
    // eslint-disable-next-line
  }, [props.recipients, Recipients]);

  useEffect(() => {
    if (!data) return;
    setGroup(data.Label);
    sortSetRecipients(data.Recipients);
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    validate();
    // eslint-disable-next-line
  }, [Recipients, Group]);

  const loadGroup = () => {
    props.showSpinner();
    getGroup(ptsGroupID)
      .then(result => setData(result))
      .catch(err => props.handleError(err, 'Error, Group data not loaded.'))
      .finally(() => props.hideSpinner());
  }

  const sortSetRecipients = (recipients) => {
    setRecipients(sortObjArr(recipients, 'Recipient', 'ASC'));
  }

  const sortSetAvailRecipients = (recipients) => {
    setAvailRecipients(sortObjArr(recipients, 'Recipient', 'ASC'));
  }

  const handleGroupChange = ev => {
    const value = ev.target.value;
    if (value.length > 60) return;
    setGroup(value);
  }

  const closeEdit = () => {
    props.closeEditPageGroup();
  }

  const save = () => {
    props.showSpinner();
    saveGroup({ Label: Group, Recipients, ptsGroupID })
    .then(() => { 
      props.updateGroups();
      closeEdit();
    })
    .catch(err =>  props.handleError(err, 'Unable to save group data'))
    .finally(() => props.hideSpinner());
  }

  const validate = () => {
    const formValid = Boolean(Group) && Recipients.length > 0;
    if (valid !== formValid) {

      setValid(formValid);
    }
  }

  const renderGroupForm = () => {
    return (
      <div className={classes.form}>
        <TextField
          className={clsx(classes.textField, classes.w100pr)}
          label="Group"
          value={Group}
          onChange={handleGroupChange}
          variant="outlined"
          size="small"
        />
      </div>
    );
  }

  const renderActions = () => {
    return (
      <div className={classes.actions}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={save}
          disabled={!valid}
        ><SaveIcon /> Save</Button>
        <Button onClick={closeEdit} color="primary" size="small">
          <CloseIcon /> Close
        </Button>
      </div>
    );
  }

  return (
    <Dialog
      open={true}
      onClose={closeEdit}
      title={'Edit Group'}
      actions={renderActions()}
      toolbar
    >

      <div className={classes.wrap}>
        {renderGroupForm()}
        <div>
          <TransferList
            options1={Recipients}
            options2={availRecipients}
            setOptions1={sortSetRecipients}
            setOptions2={sortSetAvailRecipients}
            title1="Group Recipients"
            title2="Available Recipients"
            keyID="ptsRecipientID"
            labelID="Recipient"
            height={400}
          />
        </div>
      </div>
    </Dialog>
  );
}

const mapStateToProps = state => {
  return {
    recipients: state.paging.recipients
  }
}

export default connect(mapStateToProps, { 
  updateRecipients, handleError, showSpinner, hideSpinner, closeEditPageGroup, updateGroups
})(GrouopEdit);
