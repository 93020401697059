import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import CustomAutocomplete from '../../components/CustomAutocomplete';
import { handleError } from '../../reducers/ErrorReducer';
import { showSpinner, hideSpinner } from '../../reducers/UiReducer';
import { notify } from '../../reducers/NotifierReducer';
import Tooltip from '../../components/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import { asyncForEach } from '../../utils/functions';
import { zonesChanged } from '../../reducers/DataChangeReducer';
import { getZones, removeZone } from '../../reducers/ZonesReducer';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import { pathToStr } from 'utils/mapFunctions';
import { validateGeofence } from 'reducers/ZonesReducer';
import { Alert, AlertTitle } from '@material-ui/lab';

function getFormStyle(minWidth, maxWidth) {
  return {
    maxWidth: maxWidth,
    flexBasis: minWidth,
    minWidth: minWidth,
    flexGrow: 1,
    margin: `0 4px 12px`,
  };
}

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
  },
  warning: {
    marginBottom: theme.spacing(1),
  },
  error: {
    marginBottom: theme.spacing(1),
  },
  textField: {
    ...getFormStyle(200, 200),
  },
  importBtn: {
    margin: `0 ${theme.spacing(0.5)}px`,
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  btn: {
    margin: `-${theme.spacing(1)}px ${theme.spacing(0.5)}px 0`,
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
}));

function ImportZones(props) {
  const classes = useStyles();
  const [errors, setErrors] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const [zones, setZones] = useState([]);
  const [agency, setAgency] = useState(null);
  const [zonesNo, setZonesNo] = useState(0);

  useEffect(() => {
    loadZones();
    // eslint-disable-next-line
  }, []);

  const loadZones = async () => {
    try {
      const agencies = [{ Code: 'All' }];
      const zones = await getZones();
      zones.forEach(z => {
        if (agencies.findIndex(el => el.Code === z.AgencyID) === -1)
          agencies.push({ Code: z.AgencyID });
      });
      setAgencies(agencies);
      setAgency(agencies[0]);
      setZones(zones);
      setZonesNo(zones.length);
    } catch (err) {
      props.handleError(err);
    }
  };

  const renderAgencies = () => {
    const onChange = val => {
      setAgency(val);
      let agencyZones = [...zones];
      if (val?.Code !== 'All') agencyZones = zones.filter(z => z.AgencyID === val?.Code);
      setZonesNo(agencyZones.length);
    };

    return (
      <CustomAutocomplete
        className={classes.textField}
        label="Agency*"
        options={agencies}
        value={agencies.length > 0 ? agency : null}
        onChange={onChange}
      />
    );
  };

  const deleteZones = async data => {
    props.showSpinner();
    try {
      await asyncForEach(data, async zone => {
        await removeZone(zone);
      });
    } catch (err) {
      props.handleError(err);
    }
    props.hideSpinner();
    props.zonesChanged();
    loadZones();
  };

  const validatePolygons = async () => {
    const errors = [];
    props.showSpinner();
    try {
      const zones = await getZones();
      const zonesWithPaths = zones.filter(e => e.path && e.path.length > 0);
      console.log('zones with geofences: ', zonesWithPaths);
      await asyncForEach(zonesWithPaths, async zone => {
        const isOk = await validateGeofence(pathToStr(zone.path));
        if (!isOk) {
          errors.push(
            `Error, Invalid geofence ${zone.ZoneCode} from ${zone.AgencyID}. Check for possible polygon intersections.`
          );
        }
      });
    } catch (err) {
      console.log(err);
      errors.push('Error processing the request');
    }
    props.hideSpinner();
    if (errors.length) {
      props.notify('There were valildation errors', 'error');
    } else {
      props.notify('No errors in geofences found', 'success');
    }
    setErrors(errors);
  };

  const onDelete = () => {
    if (!window.confirm('Are you sure you want to delete all selected zones?')) return;
    const data = agency.Code === 'All' ? zones : zones.filter(z => z.AgencyID === agency.Code);
    deleteZones(data);
  };

  return (
    <div>
      <div className={classes.row}>
        {renderAgencies()}
        <div>
          <Button
            color="primary"
            onClick={onDelete}
            variant="contained"
            className={classes.importBtn}
            disabled={!zonesNo}>
            <DeleteIcon /> Delete
          </Button>
          <Tooltip title="Test Zone geofences for intersections">
            <span className={classes.btn}>
              <Button color="primary" onClick={validatePolygons} variant="outlined">
                <LabelImportantIcon />
                Validate Zone Polygons
              </Button>
            </span>
          </Tooltip>
        </div>
      </div>
      <p>
        {' '}
        No of zones: <span>{zonesNo}</span>
      </p>
      {errors.length > 0 && (
        <Alert severity="error" className={classes.error}>
          <AlertTitle>Error processing the file</AlertTitle>
          {errors.map((err, idx) => (
            <p key={idx}>{err}</p>
          ))}
        </Alert>
      )}
    </div>
  );
}

export default connect(null, {
  notify,
  showSpinner,
  hideSpinner,
  handleError,
  zonesChanged,
})(ImportZones);
