import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PresonalCapabilities from './PersonalCapabilities';
import UnitCapabilities from './UnitCapabilities';
import VehicleCapabilities from './VehicleCapabilities';
import ItemCapabilities from './ItemCapabilities';

const useStyles = makeStyles(theme => ({
  wrap: {
    padding: theme.spacing(2),
    '& > h5': {
      marginBottom: 20,
    },
  },
  form: {
    margin: '0 -4px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexGrow: 1,
  },
  textField: {
    margin: '0 4px 8px',
  },
  autocomplete: {
    margin: '0 4px 12px',
    maxWidth: 400,
    flexBasis: 400,
    minWidth: 400,
    flexGrow: 1
  },
}));

const options = ['Unit Capabilities', 'Vehicle Roles', 'Item Capabilities', 'Personal Skills'];

function AssignCapability(props) {
  const classes = useStyles();
  const [view, setView] = useState('Unit Capabilities');

  const onSelect = (ev, selection) => {
    setView(selection);
  }

  const renderForm = () => {
    return (
        <div className={classes.form}>
        <Autocomplete
          fullWidth
          options={options}
          value={view}
          className={classes.autocomplete}
          size="small"
          onChange={onSelect}
          renderInput={(params) => <TextField {...params} label="Capability Type" variant="outlined" />}
        />
      </div>
    );
  }


  return (
    <div className={classes.wrap}>
      {renderForm()}
      {view === 'Personal Skills' && <PresonalCapabilities />}
      {view === 'Unit Capabilities' && <UnitCapabilities />}
      {view === 'Vehicle Roles' && <VehicleCapabilities />}
      {view === 'Item Capabilities' && <ItemCapabilities />}
    </div>
  );
}

export default AssignCapability;
