import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import { XGrid } from '@material-ui/x-grid';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import { saveCapability, removeCapabilityType } from '../../reducers/EsnReducer';
import { handleError } from '../../reducers/ErrorReducer';
import { showSpinner, hideSpinner } from '../../reducers/UiReducer';
import { getSettings } from '../../reducers/ConfigReducer';
import settings from '../../config/settings';

const useStyles = makeStyles(theme => ({
  wrap: {
    padding: theme.spacing(2),
    '& > h5': {
      marginBottom: 20,
    },
  },
  edit: {
    padding: theme.spacing(2, 0),
    paddingBottom: 12,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  },
  form: {
    margin: '0 -4px',
    display: 'flex',
    flexWrap: 'wrap',
    // alignItems: {(Code.length>10 || Description.length>100) ? 'start' : 'center'},
    flexGrow: 1,
  },
  buttons: {
    '& button': {
      marginLeft: theme.spacing(1),
    },
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  textField: {
    margin: '0 4px 8px',
  },
  list: {
    width: '100%',
    boxSizing: 'border-box',
    height: `calc(100vh - 285px)`,
  },
  actions: {
    width: '100%',
    padding: theme.spacing(1, 0, 2),
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
}));

const CapabilityColumns = [
  { field: 'Code', headerName: 'Code', width: 150 },
  { field: 'Description', headerName: 'Description', width: 300 },
];

const { CapabilityCategories } = settings;

function CustomIdentifiers(props) {
  const classes = useStyles();
  const { dictionary } = props;
  const { CapabilityTypes } = dictionary;
  const [Category, setCategory] = useState('');
  const [view, setView] = useState('list');
  const [Code, setCode] = useState('');
  const [Description, setDescription] = useState('');
  const [filteredCapabilities, setFilteredCapabilities] = useState([]);
  const [selection, setSelection] = useState([]);
  const [edit, setEdit] = useState(false);
  const [addOrEdit, setAddOrEdit] = useState('');
  const [saveCapaError, setSaveCapaError] = useState(null);
  const [capabilityTypes, setCapabilityTypes] = useState([]);

  useEffect(() => {
    if (CapabilityTypes) setCapabilityTypes(CapabilityTypes);
  }, [CapabilityTypes]);

  useEffect(() => {
    const filteredCapabilities = capabilityTypes
      .filter(row => row.Category === Category)
      .map(row => {
        return { ...row, id: row.Code };
      });
    setFilteredCapabilities(filteredCapabilities);
    // eslint-disable-next-line
  }, [Category, CapabilityTypes]);

  const save = async () => {
    props.showSpinner();
    try {
      if (Description.length <= 100) {
        const result = await saveCapability({ Code, Description, Category, addOrEdit });
        if (typeof result === 'string') {
          setSaveCapaError(result);
          props.hideSpinner();
          return;
        }
        setSaveCapaError(null);
        props.getSettings();
        setView('list');
      }
    } catch (err) {
      props.handleError(err, 'Error saving capability');
    }
    props.hideSpinner();
    setEdit(false);
  };

  const renderEditCapability = () => {
    const CodeErrorHelpertext = () => {
      if (Code.length > 10) return '* Code should be 10 characters max!';
      if (saveCapaError) return `* ${saveCapaError}`;
    };
    const handleCodeChange = ev => {
      setCode(ev.target.value);
      setSaveCapaError(null);
    };
    return (
      <div className={classes.edit}>
        <div
          className={classes.form}
          style={{
            alignItems:
              Code.length > 10 || Description.length > 100 || saveCapaError ? 'start' : 'center',
          }}>
          <TextField
            className={classes.textField}
            error={Code.length > 10 || typeof saveCapaError === 'string'}
            helperText={CodeErrorHelpertext()}
            label="Code"
            value={Code}
            onChange={handleCodeChange}
            variant="outlined"
            size="small"
            disabled={edit}
          />
          <TextField
            error={Description.length > 100}
            helperText={
              Description.length <= 100
                ? ''
                : '* Description should be no more than 100 characters!'
            }
            className={classes.textField}
            label="Description"
            value={Description}
            onChange={ev => setDescription(ev.target.value)}
            variant="outlined"
            size="small"
          />
          <div className={classes.addressWrap}>
            <div className={classes.buttons}>
              <Button
                variant="contained"
                color="primary"
                disabled={!Code || !Description || Code.length > 10 || Description.length > 100}
                onClick={save}>
                <SaveIcon />
                Save
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  setView('list');
                }}>
                <CloseIcon />
                Close
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const delCapability = async () => {
    if (!window.confirm('Are you sure you want to remove this capability?')) return;
    try {
      removeCapabilityType({ Code: selection[0], Category });
      props.getSettings();
    } catch (err) {
      props.handleError(err, 'Error deleting capability');
    }
  };

  const addCapability = () => {
    setAddOrEdit('add');
    setCode('');
    setDescription('');
    setView('edit');
    setEdit(false);
  };

  const editCapability = () => {
    setAddOrEdit('edit');
    const CapabilityType = capabilityTypes.find(c => c.Code === selection[0]);
    if (!CapabilityType) return;
    setCode(CapabilityType.Code);
    setDescription(CapabilityType.Description);
    setView('edit');
    setEdit(true);
  };

  const renderActions = () => {
    const disabled = selection.length === 0;
    return (
      <div className={classes.actions}>
        <Fab size="small" color="secondary" onClick={addCapability} disabled={!Category}>
          <AddIcon />
        </Fab>
        <Fab size="small" color="secondary" onClick={editCapability} disabled={disabled}>
          <EditIcon />
        </Fab>
        <Fab size="small" color="secondary" onClick={delCapability} disabled={disabled}>
          <DeleteIcon />
        </Fab>
        {view !== 'list' && (
          <Fab size="small" color="secondary" onClick={() => setView('list')}>
            <CloseIcon />
          </Fab>
        )}
      </div>
    );
  };

  const handleSelectChange = ({ selectionModel }) => {
    setSelection(selectionModel);
  };

  const renderCapabilities = () => {
    return (
      <div className={classes.list}>
        <XGrid
          columns={CapabilityColumns}
          rows={filteredCapabilities}
          rowHeight={38}
          onSelectionModelChange={handleSelectChange}
          disableSelectionOnClick={view === 'edit'}
          disableMultipleSelection
          hideFooter
        />
      </div>
    );
  };

  const onCategorySelect = (ev, selection) => {
    setCategory(selection);
  };

  const renderCategory = () => {
    return (
      <Autocomplete
        options={CapabilityCategories}
        value={Category}
        style={{ width: 300 }}
        renderInput={params => <TextField {...params} label="Capability Type" variant="outlined" />}
        onChange={onCategorySelect}
        size="small"
        disabled={view !== 'list'}
      />
    );
  };

  return (
    <div className={classes.wrap}>
      {renderCategory()}
      {view === 'edit' && renderEditCapability()}
      {view === 'list' && renderActions()}
      {renderCapabilities()}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    dictionary: state.dictionary,
  };
};

export default connect(mapStateToProps, {
  handleError,
  showSpinner,
  hideSpinner,
  getSettings,
})(CustomIdentifiers);
