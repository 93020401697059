import React from 'react';

function FistIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      {...props}
      preserveAspectRatio="xMidYMid meet">
      <g
        transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none">
        <path d="M99 431 c-22 -21 -39 -46 -39 -55 0 -25 14 -19 28 13 7 15 28 36 47 47 37 20 45 34 19 34 -9 0 -34 -17 -55 -39z" />
        <path d="M267 454 c-8 -8 21 -44 35 -44 5 0 8 10 6 22 -3 23 -27 35 -41 22z" />
        <path d="M175 418 c-52 -19 -62 -34 -67 -104 -4 -58 -7 -68 -42 -103 -33 -34 -37 -43 -31 -70 9 -44 55 -87 105 -101 42 -11 44 -11 78 24 l36 35 -27 12 c-15 7 -27 16 -27 21 0 11 11 10 40 -7 41 -23 76 -18 116 17 l36 32 -26 19 c-14 10 -26 22 -26 27 0 10 -56 70 -66 70 -4 0 -16 8 -27 18 -19 17 -20 17 -3 -15 18 -36 21 -83 6 -83 -5 0 -10 10 -10 23 0 12 -9 39 -20 60 -11 20 -20 41 -20 47 0 17 33 11 56 -11 24 -22 54 -20 54 3 -1 7 -13 31 -28 53 -30 43 -56 51 -107 33z" />
        <path d="M320 405 c-10 -12 -10 -21 -1 -42 11 -25 12 -25 32 -7 23 20 22 57 -2 62 -9 1 -22 -4 -29 -13z" />
        <path d="M341 325 c-25 -29 -30 -40 -20 -51 6 -8 17 -14 23 -14 20 0 68 57 64 76 -6 32 -34 27 -67 -11z" />
        <path d="M381 266 c-26 -28 -22 -56 7 -56 25 0 55 34 50 57 -5 27 -31 27 -57 -1z" />
        <path d="M427 160 c-15 -24 -44 -50 -96 -87 -2 -2 -2 -6 2 -9 17 -17 127 85 127 118 0 17 -17 6 -33 -22z" />
        <path d="M432 97 c-36 -38 -41 -53 -12 -37 22 12 56 58 48 65 -2 3 -19 -10 -36 -28z" />
      </g>
    </svg>
  );
}

export default FistIcon;
