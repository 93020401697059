import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { findDistinctPermissionTypes, createSecComponent } from '../SecFunctions';
import FormControlLabel from '@mui/material/FormControlLabel';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import { handleError } from 'reducers/ErrorReducer';
import { notify } from 'reducers/NotifierReducer';
import Switch from '@mui/material/Switch';

const useStyles = makeStyles(theme => ({
  typeSwitch: {
    textAlign: 'right',
  },
  fieldWidth: {
    maxWidth: 300,
    marginTop: 20,
  },
}));

function NewPermissions(props) {
  const classes = useStyles();
  const [isNewType, setIsNewType] = useState(false);
  const [allTypes, setAllTypes] = useState([]);
  const [currentType, setCurrentType] = useState(null);
  const [label, setLabel] = useState('');
  const [customType, setCustomType] = useState('');

  useEffect(() => {
    fetchDistinctTypes();
  }, []);

  const fetchDistinctTypes = async () => {
    const result = await findDistinctPermissionTypes();
    setAllTypes(result);
  };

  const handleSaveClick = async () => {
    const data = {
      Label: label,
      Type: isNewType ? customType : currentType.type,
    };
    try {
      const result = await createSecComponent(data);
      props.notify('New Component Created', 'success');
    } catch (err) {
      props.handleError(err, 'Error Creating New Component');
    }
  };

  const renderIsNewType = () => {
    const handleChange = () => setIsNewType(!isNewType);
    return (
      <div className="mt-4">
        <FormControlLabel
          control={<Switch checked={isNewType} onChange={handleChange} />}
          label="New Type"
        />
      </div>
    );
  };

  const renderTypes = () => {
    const handleChange = (e, newValue) => setCurrentType(newValue);
    return (
      <Autocomplete
        disablePortal
        options={allTypes}
        className={classes.fieldWidth}
        renderInput={params => <TextField {...params} label="Types" variant="outlined" />}
        onChange={handleChange}
        getOptionLabel={option => option.type}
        renderOption={option => option.type}
        size="small"
        value={currentType}
        ListboxProps={{ style: { maxHeight: '15rem' } }}
      />
    );
  };

  const renderLabel = () => {
    return (
      <TextField
        label="Label"
        variant="outlined"
        value={label}
        size="small"
        onChange={e => setLabel(e.target.value)}
        className={classes.fieldWidth}
        fullWidth
      />
    );
  };

  const renderCustomType = () => {
    return (
      <div>
        <TextField
          label="Custom Type"
          variant="outlined"
          value={customType}
          size="small"
          onChange={e => setCustomType(e.target.value)}
          className={classes.fieldWidth}
          fullWidth
        />
      </div>
    );
  };

  const renderSaveButton = () => {
    return (
      <div style={{ textAlign: 'right' }}>
        <Button
          onClick={handleSaveClick}
          disabled={!label || (!isNewType && !currentType)}
          variant="contained"
          color="primary">
          <SaveIcon className="mr-2" /> Save
        </Button>
      </div>
    );
  };

  return (
    <div>
      <h5>Add New Permission</h5>
      {renderSaveButton()}
      {renderIsNewType()}
      {!isNewType && renderTypes()}
      {isNewType && renderCustomType()}
      {renderLabel()}
    </div>
  );
}

export default connect(null, { handleError, notify })(NewPermissions);
