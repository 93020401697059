import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import CustomAutocomplete from '../../components/CustomAutocomplete';
import { handleError } from '../../reducers/ErrorReducer';
import { showSpinner, hideSpinner } from '../../reducers/UiReducer';
import { notify, closeSnackbar } from '../../reducers/NotifierReducer';
import { zonesChanged } from '../../reducers/DataChangeReducer';
import { getZones } from '../../reducers/ZonesReducer';
import GetAppIcon from '@material-ui/icons/GetApp';

function getFormStyle(minWidth, maxWidth) {
  return {
    maxWidth: maxWidth,
    flexBasis: minWidth,
    minWidth: minWidth,
    flexGrow: 1,
    margin: `0 4px 12px`,
  };
}

const useStyles = makeStyles(theme => ({
  warning: {
    marginBottom: theme.spacing(1),
  },
  error: {
    marginBottom: theme.spacing(1),
  },
  textField: {
    ...getFormStyle(200, 200),
  },
  importBtn: {
    margin: `0 ${theme.spacing(0.5)}px`,
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
}));

function ImportZones(props) {
  const classes = useStyles();
  const [errors, setErrors] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const [zones, setZones] = useState([]);
  const [agency, setAgency] = useState(null);
  const [zonesNo, setZonesNo] = useState(0);

  useEffect(() => {
    loadZones();
    // eslint-disable-next-line
  }, []);

  const loadZones = async () => {
    try {
      const agencies = [{ Code: 'All' }];
      const zones = await getZones();
      zones.forEach(z => {
        if (agencies.findIndex(el => el.Code === z.AgencyID) === -1)
          agencies.push({ Code: z.AgencyID });
      });
      setAgencies(agencies);
      setAgency(agencies[0]);
      setZones(zones);
      setZonesNo(zones.length);
    } catch (err) {
      props.handleError(err);
    }
  };

  const renderAgencies = () => {
    const onChange = val => {
      setAgency(val);
      let agencyZones = [...zones];
      if (val?.Code !== 'All') agencyZones = zones.filter(z => z.AgencyID === val?.Code);
      setZonesNo(agencyZones.length);
    };

    return (
      <CustomAutocomplete
        className={classes.textField}
        label="Agency*"
        options={agencies}
        value={agencies.length > 0 ? agency : null}
        onChange={onChange}
      />
    );
  };

  const renderExport = () => {
    const onClick = () => {
      const data = agency.Code === 'All' ? zones : zones.filter(z => z.AgencyID === agency.Code);
      data.forEach(z => {
        delete z.ROWGUID;
        delete z.ptsGeofenceID;
      });
      const dataStr = JSON.stringify(data);
      const file = new Blob([dataStr], { type: 'text/json' });
      const dateStr = new Date().toISOString().replaceAll(':', '-');
      const name = `Zones-${agency.Code}-${dateStr}.bak.json`;
      const $a = document.createElement('a');
      $a.href = window.URL.createObjectURL(file);
      $a.download = name;
      $a.click();
    };

    return (
      <div>
        <div className={classes.row}>
          {renderAgencies()}
          <div>
            <Button
              color="primary"
              onClick={onClick}
              variant="contained"
              className={classes.importBtn}
              disabled={!zonesNo}>
              <GetAppIcon /> Export
            </Button>
          </div>
        </div>
        <p>
          {' '}
          No of zones: <span>{zonesNo}</span>
        </p>
      </div>
    );
  };

  return renderExport();
}

export default connect(null, {
  notify,
  showSpinner,
  hideSpinner,
  handleError,
  closeSnackbar,
  zonesChanged,
})(ImportZones);
