import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { closeOptionsDialog } from '../reducers/DialogsReducer';
import Dialog from 'components/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { getFormStyle } from 'utils/functions';
import ChangeUserPass from 'components/ChangeUserPass';

const useStyles = makeStyles(theme => ({
  content: {
    width: 800,
    height: 500,
    maxWidth: '100%',
    display: 'flex',
    '& h4': {
      marginBottom: theme.spacing(3),
    },
  },
  settingsWrap: {
    flex: 1,
    boxSizing: 'border-box',
    paddingLeft: theme.spacing(2),
  },
  form: {
    margin: `0 -${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  w150x250: {
    ...getFormStyle(150, 250),
  },
  tabsWrap: {
    width: 200,
    flex: 0,
    marginLeft: theme.spacing(-3),
  },  
}));

function AddSOP (props) {
  const classes = useStyles();
  const [tab, setTab] = useState('Password');

  const close = () => {
    props.closeOptionsDialog();
  }

  const handleSave = () => {
    props.closeOptionsDialog();
  }

  const handleTabChange = (ev, value) => {
    setTab(value);
  };

  const renderTabs = () => {
    return (
      <div className={classes.tabsWrap}>
        <Tabs orientation="vertical" variant="scrollable" value={tab} onChange={handleTabChange}>
          <Tab label="Password" value="Password" size="small" />
        </Tabs>
      </div>
    );
  };

  const renderActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={handleSave} >
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  return (
    <Dialog toolbar onClose={close} title="Local Settings" actions={renderActions()}>
      <div className={classes.content}>
        {renderTabs()}
        <div className={classes.settingsWrap}>
          {tab === 'Password' && <ChangeUserPass />}
        </div>
      </div>
    </Dialog>
  );
}

export default connect(null, { closeOptionsDialog })(AddSOP);
