import { getService } from 'reducers/service';

export const getReportsForPermission = async () => {
  const service = getService('reportcatalog');

  try {
    const result = await service.find({
      query: {
        IsDeleted: false,
        IsActive: true,
        $or: [{ Type: 'RMSR' }, { Type: 'RMS' }],
        $limit: 0,
      },
    });

    return result?.data || [];
  } catch (error) {
    console.log({ error });

    return [];
  }
};
