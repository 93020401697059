import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Content from './Content';

const useStyles = makeStyles((theme) => ({
  
}));

function E911Workstations(props) {
  const classes = useStyles();
  
  return (
    <Content>
      <h1>E911Workstations</h1>
    </Content>
  );
}

export default E911Workstations;
