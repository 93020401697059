const defaultUserObj = {
  ApplicationName: null,
  UserId: null,
  userName: '',
  ptsProfileLookupId: null,
  ptsClearanceLevelId: null,
  ptsPersonId: null,
  email: '',
  defaultAgencyId: null,
  NCICUserName: '',
  rid: '',
  ori: '',
  isLockedOut: false,
  isDisable: false,
  forcePWChange: false,
};

export default defaultUserObj;
