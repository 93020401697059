import React from 'react';

function Army67_2(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="67"
      height="67"
      viewBox="0 0 67 67"
      {...props}
      preserveAspectRatio="xMidYMid meet">
      <g
        transform="translate(0.000000,67.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none">
        <path d="M245 647 c-62 -21 -97 -42 -139 -84 -122 -121 -122 -327 1 -450 66  -66 123 -88 228 -88 78 0 97 4 141 27 65 34 134 111 157 174 10 29 17 77 17 119 0 124 -74 237 -189 288 -56 25 -162 32 -216 14z m150 -224 c1 -2 26 -7 55 -13 29 -6 56 -12 58 -15 3 -3 -14 -22 -37 -42 -46 -39 -46 -37 -25 -141 l7 -34 -51 26 c-63 32 -71 32 -132 1 -28 -14 -50 -22 -50 -17 0 5 5 35 11 68 l11 59 -47 41 -47 42 63 11 c34 6 63 12 64 13 1 2 15 27 30 56 l28 52 30 -52 c16 -29 31 -54 32 -55z" />
      </g>
    </svg>
  );
}

export default Army67_2;
