import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Content from 'Settings/Content';
import { findClearanceLevels, findUsersHistory } from '../SecFunctions';
import { XGrid } from '@material-ui/x-grid';
import { handleError } from 'reducers/ErrorReducer';
import Fab from '@material-ui/core/Fab';
import QuickFilter from 'components/QuickFilter';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from 'components/Tooltip';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import InfoIcon from '@mui/icons-material/Info';
import { formatUTCdate, sortObjArr } from 'utils/functions';
import { TablePagination } from '@material-ui/core';
import { displayDateTime } from 'reducers/TimeReducer';

const useStyles = makeStyles(theme => ({
  wrap: {
    padding: theme.spacing(2),
    '& > h5': {
      marginBottom: 20,
    },
  },
  edit: {
    padding: theme.spacing(2, 0),
    paddingBottom: 12,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  },
  form: {
    margin: '0 -4px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'start',
    flexGrow: 1,
  },
  buttons: {
    '& button': {
      marginLeft: theme.spacing(1),
    },
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  textField: {
    margin: '0 4px 8px',
  },
  list: {
    width: '100%',
  },
  actions: {
    width: '100%',
    padding: theme.spacing(1, 0, 2),
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: `calc(100vh - 200px)`,
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const columns = [
  { field: 'Username', headerName: 'Username', width: 150 },
  { field: 'FullName', headerName: 'Full Name', width: 150 },
  { field: 'ApplicationName', headerName: 'Application Name', width: 150 },
  {
    field: 'ActivityDateTime',
    headerName: 'Activity Date Time',
    width: 200,
    valueFormatter: params => displayDateTime(params.value),
    format: 'date',
  },
  { field: 'Activity', headerName: 'Activity', width: 150 },
  { field: 'ActivityMsg', headerName: 'Activity Msg', width: 150 },
  { field: 'NetName', headerName: 'Net Name', width: 150 },
  { field: 'UserMachineName', headerName: 'User Machine Name', width: 150 },
  { field: 'UserIP', headerName: 'User IP', width: 150 },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 200,
    valueFormatter: params => displayDateTime(params.value),
    format: 'date',
  },
  { field: 'UpdatedBy', headerName: 'CreatedBy', width: 150 },
];

function UsersHistory(props) {
  const classes = useStyles();
  const [selection, setSelection] = useState(null);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchData();
  }, [pageNumber, rowsPerPage]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await findUsersHistory(pageNumber, rowsPerPage);
      const sorted = sortObjArr(result, 'ActivityDateTime');
      setRows(processData(sorted));
    } catch (err) {
      props.handleError(err, 'Error, cannot get data.');
    } finally {
      setLoading(false);
    }
  };

  const processData = data => {
    return data.map(row => {
      return {
        ...row,
        id: row.ptsUserHistoryID,
      };
    });
  };

  const renderList = () => {
    return (
      <div className={classes.gridWrap}>
        <XGrid
          loading={loading}
          columns={columns}
          rows={filteredRows}
          rowHeight={38}
          disableMultipleSelection={true}
          showToolbar
          disableColumnFilter
          rowCount={rows[0]?.TotalCount || 0}
          // hideFooter
          pagination
          page={0}
          pageSize={50}
          paginationMode="server"
          rowsPerPageOptions={[50, 100, 500, 1000]}
          onSelectionModelChange={newSelection => {
            setSelection(newSelection.selectionModel[0]);
          }}
          onPageChange={(params, newValue) => {
            setPageNumber(params.page);
          }}
          onPageSizeChange={(params, newValue) => {
            setPageNumber(0);
            setRowsPerPage(params.pageSize);
          }}
        />
      </div>
    );
  };

  return (
    <Content>
      <div className={classes.wrap}>
        <h4> Users History </h4>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className={classes.filterWrap}>
            <div className={classes.filterWrap}>
              <QuickFilter rows={rows} columns={columns} setFilteredRows={setFilteredRows} />
            </div>
            {/* <div className={classes.actions}>
              <Fab onClick={addSecUser} size="small" color="secondary">
                <AddIcon />
              </Fab>
              {selection !== null && (
                <Tooltip title="Details">
                  <Fab className="ml-2" size="small" color="secondary" onClick={handleDetails}>
                    <InfoIcon />
                  </Fab>
                </Tooltip>
              )}
            </div> */}
          </div>
        </MuiPickersUtilsProvider>
        {renderList()}
      </div>
    </Content>
  );
}

export default connect(null, { handleError })(UsersHistory);
